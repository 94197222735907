import React, { Component } from "react";
//import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import LoadingMessage from "../common/LoadingMessage";
import ErrorMessageConstantClose from "../common/ErrorMessageConstantClose";


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";

import { confirmAlert } from 'react-confirm-alert';




//import axios from "axios";


import { connect } from "react-redux";
//import { getVehiclesForOwner, showStatusMessage, deleteVehicle,deleteVehicleModifyStatus } from "../../actions/parkingActions";

import { getVehiclesForOwner, showStatusMessage, deleteVehicleModifyStatus } from "../../actions/parkingActions";
import VehicleFormRenter from "./VehicleFormRenter";
import ParkingSummary from "../general/ParkingSummary";
import { getActiveCounts } from '../parking/AjaxHelper';
import msgHelper from '../../config/msgHelper';
import { getTodayAsString, getRowHighlightColor } from "../parking/DateHelper";

//import 
//


//import axios from "axios";
//import classnames from "classnames";


//import TextFieldGroup from "../common/TextFieldGroupPlain";

class RenterVehicles extends Component {
  constructor() {
    super();
    this.state = {
      selected_vehicle: null,
      showvehicleform: false,
      ownercount: 0,
      rentercount: 0,
      // allcount: 0,  // current active
      // maxallactive: 0,  // hoa spots available

      showphoneview: false,
      whichphoneview: 1,
      showoverlimit: false,
      showoverlimitmessage: "what",
      errors: {}
    };

    this.onChange = this.onChange.bind(this);
    // this.onSubmit = this.onSubmit.bind(this);
    // this.onSubmitPin = this.onSubmitPin.bind(this);



  }

  componentDidMount() {
    if (this.props.auth.isAuthenticated) {

      let viewnum = 1;
      if (window.innerWidth < 800) {
        viewnum = 2;
      }
      let role = this.props.auth.user.role;
      //let oid = this.props.auth.user._id;
      let oid = sessionStorage.getItem('alias_userid'); //this.props.auth.user._id;
      this.setState({
        name: this.props.auth.user.name,
        email: this.props.auth.user.email,
        unitnumber: this.props.auth.user.unitnumber,
        pincode: this.props.auth.user.pincode,
        userid: oid,
        whichphoneview: viewnum
        // showphoneview: showphone
      })
      this.doGetVehicles(oid);
    }

  }

  doGetVehicles = (oid) => {
    // let cid = this.props.auth.user.company_id;
    //   console.log('getting vehicles for ', oid);
    this.setState({ selected_vehicle: null, vehicles_ary: [], showvehicleform: false });
    this.props.getVehiclesForOwner(oid, 'renter');
  }

  static getDerivedStateFromProps(props, state) {
    return {
      errors: props.errors
    };
  }

  //componentDidUpdate(prevProps) {
  // Typical usage (don't forget to compare props):
  // if (this.props.errors) {
  //   this.setState({ errors: this.props.errors });
  // }
  //}

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onCancelClick(e) {
    e.preventDefault();
    window.location = "/";
  }

  closevehicleform = () => {
    // console.log('this is close form');
    this.setState({ showvehicleform: false });
  }
  closemessage = () => {
    this.setState({ showoverlimit: false });
  }

  showModifyVehicleForm = (e, v) => {
    e.preventDefault();

    let { userid } = this.state;
    this.setState({
      selected_vehicle: v, showvehicleform: true
    });
  }


  showAddVehicleForm = (e, v) => {
    e.preventDefault();
    let { userid } = this.state;
    getActiveCounts(userid).then(res => {
      //console.log('get active counts ', res);
      let ownercount = res.ownercount;
      let rentercount = res.rentercount;
    //  let unitcount = res.unitcount;

    //  let allcount = res.allcount;  // number of active
    //  console.log('getActiveCounts ownercount',ownercount,'rentercount',rentercount);

      // let maxallactive = res.maxallactive;  // hoa parking spaces
      if (!res.ownercountok) {
        let msg = "The maximum number of active parking spaces (" + res.maxunitactive + ") for this unit has been reached.";
        msg = msg + ' Owner: ' + ownercount + ' Renter: ' + rentercount;
        this.setState({ showoverlimit: true, showoverlimitmessage: msg });
        return;
      }
      if (!res.rentercountok) {
        let msg = "The maximum number of renter parking spaces (" + res.maxrenteractive + ") for this unit has been reached.";
        this.setState({ showoverlimit: true, showoverlimitmessage: msg });
        return;
      }
      if (!res.allcountok) {
        this.setState({ showoverlimit: true, showoverlimitmessage: msgHelper.over_total_parking_limit });
        return;
      }
      this.setState({
        selected_vehicle: v, showvehicleform: true,
        ownercount: ownercount, rentercount: rentercount
      });
    }).catch(error => {
      console.log('error', error);
    });

  }

 

  deleteTheVehicle = (vid) => {
    let unitnumber = sessionStorage.getItem('unitnumber');
    let hoaid = sessionStorage.getItem('hoaid');
   // console.log('renter vehicles unitnumber',unitnumber,'hoaid',hoaid);
   // this.props.deleteVehicle(vid);
    this.props.deleteVehicleModifyStatus(vid,hoaid,unitnumber);
   
    
  }
  deleteVehicle = (e, v) => {
    e.preventDefault();
    const okelement = <FontAwesomeIcon icon={faCheckCircle} color="black" size="2x" title="enter / submit" />
    const cancelelement = <FontAwesomeIcon icon={faTimesCircle} color="black" size="2x" title="cancel / close" />
    confirmAlert({
      customUI: ({ onClose }) => {
        let vid = v['_id'];
        let lbl = v['make'] + ' ' + v['plate'] + ' ' + v['plate_state'];
        // console.log('the vid is', vid);
        return (
          <div className='confirmmessage' >
            <h6>Are you sure you want to delete?</h6>
            <p>{lbl}</p>
            <span className="iconbuttonspan"
              onClick={() => {
                this.deleteTheVehicle(vid);
                onClose();
              }
              }> {okelement}</span>
            <span className="iconbuttonspan"
              onClick={() => {
                onClose();
              }}
            >
              {cancelelement}
            </span>
          </div>
        );
      }
    });
  }



  togglePhoneView = (e, which) => {
    e.preventDefault();
    this.setState({ whichphoneview: which });
  }

  renderVehicleRecords = () => {
    let booltest = false;
    let vehicles_ary2 = this.props.parking.current_vehicles_owner;
   // console.log('rv vehicles_ary2',vehicles_ary2)
    let vehicles_template_grid_ary = [];
    let cellcname = 'grid-item-normal';
    let renter_free_parking = sessionStorage.getItem('renter_free_parking');
   // console.log('renter_free_parking',renter_free_parking);
  

     
   
     vehicles_ary2.forEach((vehicle, index) => {
      let bcolor = getRowHighlightColor('warning',vehicle);
   
         let datarow = (
           <div key={index} className='grid-container-8b' style={{ backgroundColor: bcolor }}>

             <div className='grid-item-normal'>
               <Link to="" className="xbtn xbtn-xs xbtn-info" style={{ width: "150px" }}
                 onClick={(e) => this.showModifyVehicleForm(e, vehicle)} >{vehicle.plate} - {vehicle.plate_state}</Link>
             </div>
             <div className="grid-item-normal">{vehicle.vehicle_type}-{vehicle.carownertype}</div>
             <div className="grid-item-normal">{vehicle.make}</div>
             <div className="grid-item-normal">{vehicle.model}</div>
             <div className="grid-item-normal">{vehicle.year}</div>

             <div className="grid-item-normal">{vehicle.carowner_lname}, {vehicle.carowner_fname}</div>
             <div className="grid-item-normal">{vehicle.enddate}</div>


             <div className="grid-item-normal">
               <Link to=""
                 onClick={(e) => this.deleteVehicle(e, vehicle)} >delete</Link> {vehicle.status_flag}
             </div>
           </div>

         )
         vehicles_template_grid_ary.push(datarow);
      //  } else {
      //   console.log('we are deleting vehicle');
      //   this.deleteTheVehicle(vehicle['_id']);
      //   return;
      //  }

     })
     return vehicles_template_grid_ary;

  }

  render() {
    // const { errors } = this.state;
    //const { properties_ary } = this.state;
    let { selected_vehicle } = this.state;
    const { showvehicleform } = this.state;
    let { ownercount } = this.state;
    let { rentercount } = this.state;
    // let { allcount } = this.state;
    // let { maxallactive } = this.state;
    const { showoverlimit } = this.state;
    const { showoverlimitmessage } = this.state;



    const vehicles_ary = this.props.parking.current_vehicles_owner;

    let numvehicles = vehicles_ary.length;
    //  console.log('ownercount', ownercount, 'rentercount', rentercount);

//        // <div className="grid-item-normal">{vehicle.vehicle_type}</div>


  

    let vehicles_template_grid = vehicles_ary.map((vehicle, index) => (
      <div key={index} className="grid-container-8b">
        <div className="grid-item-normal">
          <Link to="" className="xbtn xbtn-xs xbtn-info" style={{ width: "150px" }}
            onClick={(e) => this.showModifyVehicleForm(e, vehicle)} >{vehicle.plate} - {vehicle.plate_state}</Link>
        </div>
        <div className="grid-item-normal">{vehicle.vehicle_type}</div>
        <div className="grid-item-normal">{vehicle.make}</div>
        <div className="grid-item-normal">{vehicle.model}</div>
        <div className="grid-item-normal">{vehicle.year}</div>

        <div className="grid-item-normal">{vehicle.carowner_lname}, {vehicle.carowner_fname}</div>
        <div className="grid-item-normal">{vehicle.enddate}</div>


        <div className="grid-item-normal">
          <Link to=""
            onClick={(e) => this.deleteVehicle(e, vehicle)} >delete</Link> {vehicle.status_flag} 
        </div>
      </div>
    ));

  //   vehicles_template_grid_ary = [];
  //   vehicles_ary.forEach((vehicle, index) => {
  //  // let vehicles_template_grid = vehicles_ary.map((vehicle, index) => (
  //   let datarow = '123';
  //     // <div key={index} className="grid-container-8b">
  //     //   <div className="grid-item-normal">
  //     //     <Link to="" className="xbtn xbtn-xs xbtn-info" style={{ width: "150px" }}
  //     //       onClick={(e) => this.showModifyVehicleForm(e, vehicle)} >{vehicle.plate} - {vehicle.plate_state}</Link>
  //     //   </div>
  //     //   <div className="grid-item-normal">{vehicle.vehicle_type}</div>
  //     //   <div className="grid-item-normal">{vehicle.make}</div>
  //     //   <div className="grid-item-normal">{vehicle.model}</div>
  //     //   <div className="grid-item-normal">{vehicle.year}</div>

  //     //   <div className="grid-item-normal">{vehicle.carowner_lname}, {vehicle.carowner_fname}</div>
  //     //   <div className="grid-item-normal">{vehicle.enddate}</div>


  //     //   <div className="grid-item-normal">
  //     //     <Link to=""
  //     //       onClick={(e) => this.deleteVehicle(e, vehicle)} >delete</Link> {vehicle.status_flag} 
  //     //   </div>
  //     // </div>
  //   //}
  //   vehicles_template_grid_ary.push(datarow)
  //   }
  //   );



    let vehicles_template_grid_phone = vehicles_ary.map((vehicle, index) => (
      <div className="bordershadow" key={index}>
        <div className="grid-item-bold">{index + 1}</div>
        <div key={index} className="grid-container-3">
          <div className="grid-item-bold">Plate</div>
          <div className="grid-item-bold">Name</div>
          <div className="grid-item-bold">Type</div>
          <div className="grid-item-bold">
            <Link to=""
              onClick={(e) => this.showModifyVehicleForm(e, vehicle)} >{vehicle.plate} - {vehicle.plate_state}</Link>
          </div>
          <div className="grid-item-normal">{vehicle.carowner_lname}, {vehicle.carowner_fname}</div>

          <div className="grid-item-bold">{vehicle.vehicle_type}</div>


          <div className="grid-item-bold">Make</div>
          <div className="grid-item-bold">Model</div>
          <div className="grid-item-bold">Year</div>


          <div className="grid-item-normal">{vehicle.make}</div>
          <div className="grid-item-normal">{vehicle.model}</div>
          <div className="grid-item-normal">{vehicle.year}</div>


          <div className="grid-item-bold">Checkout</div>
          <div className="grid-item-bold">&nbsp;</div>
          <div className="grid-item-bold">Action</div>


          <div className="grid-item-normal">{vehicle.enddate}</div>
          <div className="grid-item-normal">&nbsp;</div>

          <div className="grid-item-normal">
            <Link to=""
              onClick={(e) => this.deleteVehicle(e, vehicle)} >delete</Link>
          </div>
        </div>
      </div>
    ));







    let { showphoneview } = this.state;
    let { whichphoneview } = this.state;
    let popupclass = 'popupdialog bordershadow';
    if (whichphoneview !== 1) popupclass = 'popupdialog_phone bordernoshadow';
    let chbclass = 'normalbuttonwrapper';
    const addicon = <FontAwesomeIcon icon={faPlusCircle} color="#65598a" size="2x" />
    //let ptop = window.scrollY;
    let itop = 160; // + ptop;
    let millis = Date.now();
    let dstr = getTodayAsString();
    return (
      <div style={{ marginTop: "10px" }}>






        <div className="inputbox">
          <div className="innerinputbox2">
            {(this.props.parking.isloading) ? (<LoadingMessage />) : null}
            {showoverlimit ? (
              <ErrorMessageConstantClose message={showoverlimitmessage} closemessage={this.closemessage} />
            ) : (null)}



            <div className="parking_label">Renter Vehicles {dstr}

              {/* <div className="row">
                <div className="col-sm-4 tacenter" >Renter Vehicles
                  <span style={{ marginLeft: "10px", display: "none" }}>Owner: {this.state.name}</span> </div>
                <div className="col-sm-4 tacenter" >
                  Unit: {this.state.unitnumber}
                </div>
                <div className="col-sm-4 tacenter" >
                  <ParkingSummary millis={millis} />
                </div>
              </div> */}




            </div>

            <div style={{ marginBottom: "10px" }}>
              <Link to=""
                onClick={(e) => this.showAddVehicleForm(e, null)} >{addicon}</Link>
            </div>

            {this.state.showvehicleform ? (
              <div id="rentervehicleform" className={popupclass} style={{ top: itop }}>
                <VehicleFormRenter vehicle={selected_vehicle} carownertype={"renter"}
                  carcount={rentercount}

                  closevehicleform={this.closevehicleform} />
              </div>
            ) : (null)}





            <div style={{ fontSize: "10pt" }}>
              {numvehicles === 0 ? (
                <h3>No vehicles registered</h3>
              ) : (
                <div >
                  <div id="one" className="phoneview">
                    <div>{vehicles_template_grid_phone}</div>
                  </div>
                  <div id="two" className="tableview">
                    <div className="grid-container-8b">
                      <div className="grid-item-bold">Plate</div>
                      <div className="grid-item-bold">Type</div>
                      <div className="grid-item-bold">Make</div>
                      <div className="grid-item-bold">Model</div>
                      <div className="grid-item-bold">Year</div>
                      <div className="grid-item-bold">Name</div>
                      <div className="grid-item-bold">Checkout</div>

                      <div className="grid-item-bold">Action</div>
                    </div>
                    <div>
                      {/* {vehicles_template_grid} */}
                      {this.renderVehicleRecords()}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>






      </div >

    );
  }
}



// map errors to this component
// then we can use this.props.auth.user etc
// since we mapped error, we can use componentWillReceiveProps method
const mapStateToProps = state => ({
  auth: state.auth,
  parking: state.parking,
  errors: state.errors
});
// the state.auth above comes from rootReducer in index.js in reducers.

export default connect(
  mapStateToProps,
  { getVehiclesForOwner, showStatusMessage,deleteVehicleModifyStatus }
)((RenterVehicles));
// wrap the Register with withRouter so the authAction can use history to redirect
