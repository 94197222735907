import React, { Component } from "react";
//import PropTypes from "prop-types";
//import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import LoadingMessage from "../common/LoadingMessage";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import SmartButtonHPS from "../common/SmartButtonHPS";


import Violations from "./Violations";

//import axios from "axios";


import { connect } from "react-redux";
import { getAllActiveVehicles, showStatusMessage } from "../../actions/parkingActions";

import ParkingSummary from "../general/ParkingSummary";
import { getTodayAsString, generateDateDisplay,getRowHighlightColor } from "../parking/DateHelper";

//import RecordViolation from "./RecordViolation";

//


//import axios from "axios";
//import classnames from "classnames";


// import TextFieldGroup from "../common/TextFieldGroupPlain";

class AdminActiveVehicles extends Component {
  constructor() {
    super();
    this.state = {
      errors: {},
      showphoneview: false,
      hoa: "Yampa View",
      whichphoneview: 1,
      showviolations: false,
      showplatesonly: false,
      whichsort: 'plate',
      sortdirection: 1,


      show: false
    };

    this.onChange = this.onChange.bind(this);
    // this.onSubmit = this.onSubmit.bind(this);
    // this.onSubmitPin = this.onSubmitPin.bind(this);

  }
  componentDidMount() {
    let viewnum = 1;
    if (window.innerWidth < 800) {
      viewnum = 2;
    }
    this.setState({ whichphoneview: viewnum });
    // console.log('admin active vehicles cdm');

    this.props.getAllActiveVehicles();
  }

  // componentDidMount() {
  //   let showphone = false;
  //   if (window.innerWidth < 800) {
  //     showphone = true;
  //   }
  //   this.setState({ showphoneview: showphone });
  //   this.props.getAllActiveVehicles();
  // }


  static getDerivedStateFromProps(props, state) {
    return {
      errors: props.errors
    };
  }

  //componentDidUpdate(prevProps) {
  // Typical usage (don't forget to compare props):
  // if (this.props.errors) {
  //   this.setState({ errors: this.props.errors });
  // }
  //}

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onCancelClick(e) {
    e.preventDefault();
    window.location = "/";
  }

  downloadVehicles = (e) => {
    e.preventDefault();
    console.log('do the download');
    alert('download to csv file');
  }

  showPlatesOnly = (e, which) => {
    // this.doSort(e, 'plate');
    e.preventDefault();
    // let view = !this.state.showplatesonly;
    // this.setState({ showplatesonly: which, whichsort: 'plate', sortdirection: -1 });
    this.setState({ showplatesonly: which });

    // console.log('do the download');
  }

  showPlatesOnlyToggle = (e) => {
    // this.doSort(e, 'plate');
    e.preventDefault();
    let setflag = true;
    let cur = this.state.showplatesonly;
    if (cur) {
      setflag = false;

    }

    // let view = !this.state.showplatesonly;
    // this.setState({ showplatesonly: which, whichsort: 'plate', sortdirection: -1 });
    this.setState({ showplatesonly: setflag });

    // console.log('do the download');
  }

  handleClose(e) {
    e.preventDefault();
    // this.setState({ show: false });
    this.setState({
      show: false,
      errors: {}


    });
  }

  closeform = () => {
    // console.log('this is close form');
    this.setState({ showvehicleform: false, show: false });
  }

  showRecordViolation(e) {
    e.preventDefault();
    this.setState({
      show: true
    });
  }

  showViolations(e) {
    e.preventDefault();
    let which = true; //!this.state.showviolations;
    this.setState({
      showviolations: which
    });
  }

  closeViolations = () => {
    this.setState({
      showviolations: false
    });
  }

  doSort = (e, which) => {
    e.preventDefault();
    let sd = this.state.sortdirection;
    sd = sd * -1;
    this.setState({ whichsort: which, sortdirection: sd });
  }





  renderCarRecords = (vehicles_ary) => {
   
    let rdata = [];

    let dstr = getTodayAsString();
    // console.log('dstr', dstr);
    vehicles_ary.forEach((vehicle, index) => {
      let bcolor = getRowHighlightColor('warning',vehicle);
      let row = (
    
         <div key={index} className='grid-container-8b' style={{ backgroundColor: bcolor }}>
      <div className="grid-item-normal">
        {vehicle.plate} - {vehicle.plate_state}
      </div>
      <div className="grid-item-normal">{vehicle.vehicle_type} - {vehicle.make}</div>
      <div className="grid-item-normal">{vehicle.model}</div>
      <div className="grid-item-normal">{vehicle.year}</div>
      <div className="grid-item-normal">
        {vehicle['carowner_lname']}, {vehicle['carowner_fname']}
      </div>
      <div className="grid-item-normal">{vehicle.carownertype}</div>
      <div className="grid-item-normal">{vehicle.unitnumber}</div>
      <div className="grid-item-normal">{generateDateDisplay(vehicle.enddate, dstr)}</div>
    </div>
      )
    rdata.push(row)
    })
    return rdata
  }
    

  renderPlatesOnly = (vehicles_ary) => {
    // const vehicles_ary = this.props.parking.current_vehicles_owner;
    let rdata = [];
    let dstr = getTodayAsString();
    let sorted_vehicles_ary = vehicles_ary.sort(function (a, b) {
      if (a['plate'] < b['plate']) return -1;
      if (a['plate'] > b['plate']) return 1;
      if (a['plate'] === b['plate']) return 0;
    })
    sorted_vehicles_ary.forEach((vehicle, index) => {
      let bcolor = getRowHighlightColor('warning',vehicle);
      let row = (
        <div key={index} className="bordershadowsm" style={{
          fontSize: "14pt", width: "250px",
          textAlign: "center", backgroundColor: bcolor
        }}>
          <div>
            {vehicle.plate} - {vehicle.plate_state}
          </div>
          <div style={{ fontSize: "12pt" }}>{vehicle.carownertype} &nbsp; {vehicle.unitnumber}</div>
          <div style={{ fontSize: "10pt" }}>Checkout: {generateDateDisplay(vehicle.enddate, dstr)}</div>
        </div >
      )
      rdata.push(row)
    })
    return rdata
  }

  renderCarRecordsPhone = (vehicles_ary) => {
   
    let rdata = [];

    let dstr = getTodayAsString();
    // console.log('dstr', dstr);
    vehicles_ary.forEach((vehicle, index) => {
      let bcolor = getRowHighlightColor('warning',vehicle);
      let row = (
      <div className="bordershadow" key={index} style={{backgroundColor:bcolor}}>
      <div className="grid-item-bold">{index + 1}</div>
      <div key={index} className="grid-container-3">
        <div className="grid-item-bold">Plate</div>
        <div className="grid-item-bold">Name</div>
        <div className="grid-item-bold">Unit</div>
        <div className="grid-item-normal" >
          {vehicle.plate} - {vehicle.plate_state}
        </div>
        <div className="grid-item-normal">
          {vehicle['carowner_lname']}, {vehicle['carowner_fname']}
        </div>
        <div className="grid-item-normal">{vehicle.unitnumber}</div>
        <div className="grid-item-bold">Make</div>
        <div className="grid-item-bold">Model</div>
        <div className="grid-item-bold">Year</div>
        <div className="grid-item-normal">{vehicle.vehicle_type} - {vehicle.make}</div>
        <div className="grid-item-normal">{vehicle.model}</div>
        <div className="grid-item-normal">{vehicle.year}</div>
        <div className="grid-item-bold">Checkout</div>
        <div className="grid-item-bold">Type</div>
        <div className="grid-item-bold">Action</div>
        <div className="grid-item-normal">{generateDateDisplay(vehicle.enddate, dstr)}</div>
        <div className="grid-item-normal">{vehicle.carownertype}</div>
        <div className="grid-item-normal">
          <Link to=""
            onClick={(e) => this.deleteVehicle(e, vehicle)} >delete</Link>
        </div>
      </div>
    </div>
      )
      rdata.push(row)

    })
    return rdata;
    }

  render() {




    let dstr = getTodayAsString();

    let role = this.props.auth.user.role;

    let vehicles_ary = this.props.parking.current_vehicles_owner;
   
    let { whichsort } = this.state;
    let { sortdirection } = this.state;
    vehicles_ary = vehicles_ary.sort(function (a, b) {
      if (a[whichsort] < b[whichsort]) return (1 * sortdirection);
      if (a[whichsort] > b[whichsort]) return (-1 * sortdirection);
      if (a[whichsort] === b[whichsort]) return 0;
    })

    let { whichphoneview } = this.state;
    let { errors } = this.state;

    let { showviolations } = this.state;
    let { showplatesonly } = this.state;
    let millis = Date.now();

    const cancelelement = <FontAwesomeIcon icon={faTimesCircle} color="white" size="1x" title="cancel / close" />






    let chbclass = 'normalbuttonwrapper';
    let title = 'Parking Violation';
    // const okelement = <FontAwesomeIcon icon="check-circle" color="#65598a" size="2x" />
    // const cancelelement = <FontAwesomeIcon icon="times-circle" color="black" size="2x" />

    let phonesorttable = (
      <div style={{ borderBottom: "0px solid black", marginBottom: "10px" }}>
        <table>
          <thead><tr><th colSpan="5">Sort</th></tr></thead>
          <tbody>
            <tr>
              <td style={{ paddingRight: "10px" }}>
                <Link to=""
                  onClick={(e) => this.doSort(e, 'plate')} >
                  <b>Plate</b>
                </Link>
              </td>

              <td style={{ paddingRight: "10px" }}>
                <Link to=""
                  onClick={(e) => this.doSort(e, 'unitnumber')} >
                  <b>Unit</b>
                </Link>
              </td>
              <td style={{ paddingRight: "10px" }}>
                <Link to=""
                  onClick={(e) => this.doSort(e, 'carownertype')} >
                  <b>Type</b>
                </Link>
              </td>
              <td style={{ paddingRight: "10px" }}>
                <Link to=""
                  onClick={(e) => this.doSort(e, 'enddate')} >
                  <b>Checkout</b>
                </Link>
              </td>

            </tr>
          </tbody>
        </table>
      </div>


    );

    // console.log('rendering admin active vehicles');
    const closeicon = <FontAwesomeIcon icon={faTimesCircle} color="#fff" size="1x" title="cancel / close" />

    return (
      <div style={{ marginTop: "10px" }}>
        <div style={{ textAlign: "center", marginBottom: "5px" }}>

          <SmartButtonHPS
            name={showplatesonly ? <span>Records</span> : <span>Plates</span>}
            buttonclass="btn-basic button_12"
            onClick={(e) => this.showPlatesOnlyToggle(e)}
          />

        </div>

        <div className="inputbox">
          <div className="innerinputbox2">
            {(this.props.parking.isloading) ? (<LoadingMessage />) : null}



            {showplatesonly ? (
              <div>
                <p className="parking_label">Onsite Vehicles Valid Plates
                  <span style={{ float: "right" }}>
                    <Link to=""
                      onClick={(e) => this.showPlatesOnly(e, false)} >
                      {cancelelement}
                    </Link>
                  </span>
                </p>
                <div style={{ textAlign: "center" }}>
                  Today: {dstr}
                </div>
                <div style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  width: "100%",
                }}>
                  {this.renderPlatesOnly(vehicles_ary)}
                </div>
              </div>
            ) : (
              <div>
                <div className="phoneview">
                  <div className="parking_label">
                    <div style={{ textAlign: "left" }}>Onsite Vehicles
                      <span style={{ float: "right" }}>
                        <Link to=""
                          onClick={(e) => this.props.closeactive()} >
                          {closeicon}
                        </Link>
                      </span>
                    </div>
                  </div>
                  {phonesorttable}

                  {/* {vehicles_template_grid_phone} */}
                  {this.renderCarRecordsPhone(vehicles_ary)}
                </div>

                <div className="tableview">
                  <div className="parking_label">Onsite Vehicles
                    {/* {role === 'admin' ? ( */}
                    <span style={{ float: "right" }}>
                      <Link to=""
                        onClick={(e) => this.props.closeactive()} >
                        {closeicon}
                      </Link>
                    </span>
                    {/* ) : (null)
                    } */}
                  </div>

                  <div>
                    <div className="grid-container-8b">
                      <div className="grid-item-bold">
                        <Link to=""
                          onClick={(e) => this.doSort(e, 'plate')} >
                          Plate
                        </Link>
                      </div>
                      <div className="grid-item-bold">Make</div>
                      <div className="grid-item-bold">Model</div>
                      <div className="grid-item-bold">Year</div>

                      <div className="grid-item-bold">Name</div>
                      <div className="grid-item-bold">
                        <Link to=""
                          onClick={(e) => this.doSort(e, 'carownertype')} >
                          Type
                        </Link>

                      </div>
                      <div className="grid-item-bold">
                        <Link to=""
                          onClick={(e) => this.doSort(e, 'unitnumber')} >
                          Unit
                        </Link>

                      </div>
                      <div className="grid-item-bold">
                        <Link to=""
                          onClick={(e) => this.doSort(e, 'enddate')} >
                          Checkout
                        </Link>

                      </div>
                    </div>
                    <div>
                      {this.renderCarRecords(vehicles_ary)}
                    </div>
                  </div>
                </div>


              </div>
            )}
          </div>
        </div>


        <div style={{ textAlign: "center", marginTop: "10px" }}>

          {role === 'xxadmin' ? (
            <span className={chbclass}>
              <Link to="" className="btn btn-sm btn-info btnparking" style={{ width: "130px" }}
                onClick={(e) => this.downloadVehicles(e)} >Download Report</Link>
            </span>
          ) : (
            null
          )}



        </div>

        {showviolations ? (
          <Violations closeviolations={this.closeViolations} />
        ) : (null)}






      </div >
    );
  }
}

//closeviolations={this.closeViolations}
// this is good practice because it will help debugging
// it is not checked when in production mode.
//onClick={(e) => props.gotoBooking(e, obj.accommodations.booking_url)}
// Profile.propTypes = {

//   auth: PropTypes.object.isRequired,
//   errors: PropTypes.object.isRequired
// };

// map errors to this component
// then we can use this.props.auth.user etc
// since we mapped error, we can use componentWillReceiveProps method
const mapStateToProps = state => ({
  auth: state.auth,
  parking: state.parking,
  errors: state.errors
});
// the state.auth above comes from rootReducer in index.js in reducers.

export default connect(
  mapStateToProps,
  { getAllActiveVehicles, showStatusMessage }
)((AdminActiveVehicles));
// wrap the Register with withRouter so the authAction can use history to redirect
