import React, { Component } from "react";
import PropTypes from "prop-types";
//import axios from "axios";
import { connect } from "react-redux";
import { uploadUserCSV } from "../../actions/parkingActions";
//import TextFieldGroup from "../common/TextFieldGroup";

class AdminUserCsvUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "JJV",
      name: "",
      errors: {},
      statusReducer: {},
      file: null,
      fname: null
    };

    this.onChange = this.onChange.bind(this);
    this.onFileInputChange = this.onFileInputChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    // if (this.props.auth.isAuthenticated) {
    //   this.props.history.push("/dashboard");
    // }
  }

  // if we have errors this will run
  // componentWillReceiveProps(nextProps) {
  //   //console.log("register componentWillReceiveProps");
  //   if (nextProps.errors) {
  //     this.setState({ errors: nextProps.errors });
  //     // setState triggers a render
  //   }
  // }

  onChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    // console.log(name + " " + value);

    // this.setState({ [e.target.name]: e.target.value });
    this.setState({ [name]: value });
  }

  onFileInputChange(e) {
    console.log(e.target.files[0]);
    if (e.target.files[0]) {
      //let fileurlobj = URL.createObjectURL(e.target.files[0]);
      this.setState({
        file: e.target.files[0],
        //fileurl: fileurlobj,
        statusmsg: ""
      });
    }
  }


  onSubmit = e => {
    e.preventDefault();
    let formdata = new FormData();
    formdata.append("file", this.state.file);
    formdata.append("id", this.state.id);
    formdata.append("name", this.state.name);
    formdata.append("hoaid", sessionStorage.getItem('hoaid'));
    this.props.uploadUserCSV(formdata);
  }; // end submit

  render() {
    const { errors } = this.state;
    const perrors = this.props.errors;
    const { statusMsg } = this.props;
    // same as const errors = this.state.errors
    // console.log("statusMsg are ", statusMsg);
    //console.log("statusReducer are ", statusReducer);  

    // this was used to show user from props
    // const { user } = this.props.auth; // const user = this.props.auth.user
    // this shows user {user ? user.name : null}

    return (
      <div className="xcontainer-fluid">
        <h4 style={{ textAlign: "center", color: "white", marginTop: "10px" }} >File Upload</h4>
        <div className="inputbox" >
          <div className="innerinputbox2" >
            <p className="parking_label">Upload User CSV File

            </p>

            <p>This is used to preload the HOA with owners from a csv file.</p>

            <div className="xrow">
              <div className="xcol-md-8 xoffset-md-2 ">

                {/* {statusMsg ? (
                  <p className="display-32 text-center">
                    {statusMsg}
                  </p>
                ) : null} */}

                <form noValidate onSubmit={this.onSubmit}>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-12">
                        <input
                          type="file"
                          name="file"
                          onChange={this.onFileInputChange}
                        />
                      </div>
                    </div>
                    <input type="submit" className="btn btn-info btn-block mt-4" />
                  </div>
                </form>
                {perrors && (
                  <div className="error-display">{perrors.message}</div>
                )}
                {errors && (
                  <div className="error-display">{errors.generic}</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AdminUserCsvUpload.propTypes = {
  // registerAdvertiser: PropTypes.func.isRequired,
  // modifyAdvertiser: PropTypes.func.isRequired,
  // auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  // auth: state.auth,
  errors: state.errors,
  statusMsg: state.statusMsg,
  // statusReducer: state.statusReducer
  // advertise: state.advertise
});

export default connect(
  mapStateToProps,
  { uploadUserCSV }
)((AdminUserCsvUpload));


