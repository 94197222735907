
//const isEmpty = require("./is-empty");
//import { isEmpty } from './is-empty';

//const Validator = require("validator");
import { isitEmpty } from './isitempty';
import { getTodayAsString } from "../components/parking/DateHelper";


export const validateInsertModifyVehicleInput = (which, data) => {
  let errors = {};
  // console.log('validateModifyVehicleInput data', data);
  // console.log('validateModifyVehicleInput data', data.carownername);
  // console.log('hard call ', isitEmpty('no'))
  let showpaymentform = false; //data.showpaymentform;

  data.carowner_fname = !isitEmpty(data.carowner_fname) ? data.carowner_fname : "";
  data.carowner_lname = !isitEmpty(data.carowner_lname) ? data.carowner_lname : "";
  data.carownerphone = !isitEmpty(data.carownerphone) ? data.carownerphone : "";
  data.make = !isitEmpty(data.make) ? data.make : "";
  data.plate = !isitEmpty(data.plate) ? data.plate : "";
  data.plate_state = !isitEmpty(data.plate_state) ? data.plate_state : "";
  data.enddate = !isitEmpty(data.enddate) ? data.enddate : "";

  // console.log('data.enddate*', data.enddate, '*', data.enddate.length);

  if (showpaymentform) {
    data.ccname = !isitEmpty(data.ccname) ? data.ccname : "";
    data.ccnumber = !isitEmpty(data.ccnumber) ? data.ccnumber : "";
    data.ccexpdate = !isitEmpty(data.ccexpdate) ? data.ccexpdate : "";
    data.cccode = !isitEmpty(data.cccode) ? data.cccode : "";
  }


  // let d = new Date();
  // let yr = d.getFullYear();
  // let mo = d.getMonth() + 1;
  // let day = d.getDate();
  // if (mo < 10) mo = "0" + mo;
  // if (day < 10) day = "0" + day;
  // let dstr = yr + '-' + mo + '-' + day;
  let dstr = getTodayAsString();

  // data.terms = !isEmpty(data.terms) ? data.enddate : "";
  //data.password = !isEmpty(data.password) ? data.password : "";


  if (showpaymentform) {
    if (isitEmpty(data.ccname)) {
      errors.ccname = "Card Name is required";
    }
    if (isitEmpty(data.ccnumber)) {
      errors.ccnumber = "Card number is required";
    }
    if (isitEmpty(data.ccexpdate)) {
      errors.ccexpdate = "Card expiry is required";
    } else {
      // let parts = data.ccexpdate.split('/');
      // if (parts.length !== 2) {
      //   errors.ccexpdate = "Invalid expiry format";
      // } else {
      // let mo = parts[0];
      // let yr = parts[1];
      // let ismo = /^\d{2}/.test(mo);
      // let isyr = /^\d{4}/.test(yr);
      // if ((!ismo) || (!isyr)) {
      //   errors.ccexpdate = "Invalid expiry date";
      // }
      let isfour = /^\d{4}/.test(data.ccexpdate)
      // let mo = parts[0];
      // let yr = parts[1];
      // let ismo = /^\d{2}/.test(mo);
      // let isyr = /^\d{4}/.test(yr);
      if (!isfour) {
        errors.ccexpdate = "Must be mmyy";
      }
    }


    if (isitEmpty(data.cccode)) {
      errors.cccode = "Card CCV is required";
    } else {
      let isthree = /^\d{3}/.test(data.cccode)
      if (!isthree) {
        errors.cccode = "Must be 3 numeric";
      }
    }

  }

  if (isitEmpty(data.carowner_fname)) {
    errors.carowner_fname = "First name field is required";
  }
  if (isitEmpty(data.carowner_lname)) {
    errors.carowner_lname = "Last name field is required";
  }
  if (isitEmpty(data.make)) {
    errors.make = "Make field is required";
  }
  // if (isitEmpty(data.enddate)) {
  //   errors.enddate = "End Date field is required";
  // }
  if (isitEmpty(data.plate)) {
    errors.plate = "License Plate field is required";
  }
  if (isitEmpty(data.plate_state)) {
    errors.plate_state = "License Plate State field is required";
  }

  if (isitEmpty(data.carownerphone)) {
    errors.carownerphone = "Phone field is required";
  }

  // let edate = data.enddate;
  // if (edate.length > 0) {
  //   if (data.enddate < dstr) {
  //     errors.enddate = "End Date field cannot be before today";
  //   }
  // }

  if (which === 'renter') {
    if (data.enddate < dstr) {
      errors.enddate = "End Date field cannot be before today";
    }
  }
  // if (data.isguest) {
  //   if (isitEmpty(data.carownerphone)) {
  //     errors.carownerphone = "Phone field is required";
  //   }
  //   if (data.enddate < dstr) {
  //     errors.enddate = "End Date field cannot be before today";
  //   }
  // }

  return {
    errors,
    isValid: isitEmpty(errors)
  };
};




