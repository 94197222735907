import React, { Component } from "react";
// we need to get into git
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//import axios from "axios";

//import { useRanger } from "react-ranger";
//import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
//
//import { getDomainData, getDealSpecials } from "../../actions/dealsActions";
//import styled, { createGlobalStyle } from "styled-components";
import AdminOwners from "./AdminOwners";
import AdminVehicles from "./AdminVehicles";
import AdminActiveVehicles from "./AdminActiveVehicles";
import AdminProfile from "./AdminProfile";
import AdminNotifications from "./AdminNotifications";
import Violations from "./Violations";
import ReportTabs from "../reports/ReportTabs";
import SmartButtonHPS from "../common/SmartButtonHPS";
import ParkingSummary from "../general/ParkingSummary";






class AdminDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showowners: true,
      showvehicles: false,
      showactivevehicles: false,
      showviolations: false,
      shownotifications: false,
      showreports: false,
      showprofile: false,
      selary: [1, 0, 0, 0, 0, 0],


      errors: {}
    }; //shuttles: ["one", "two", "three"] };
    // console.log("Dashboard props", props);

  }

  componentDidMount() {
    // this.props.getDomainData();
  }

  showOwners = (e) => {
    e.preventDefault();
    let ary = [1, 0, 0, 0, 0, 0];
    // this.props.history.push("/profile");
    this.setState({
      showowners: true, showvehicles: false, showactivevehicles: false,
      showprofile: false, shownotifications: false, xshowviolations: false, showreports: false, selary: ary

    });
  }
  showVehicles = (e) => {
    e.preventDefault();
    let ary = [0, 1, 0, 0, 0, 0];
    this.setState({
      showowners: false, showvehicles: true, showactivevehicles: false,
      showprofile: false, shownotifications: false, xshowviolations: false, showreports: false, selary: ary
    });
  }
  showActiveVehicles = (e) => {
    e.preventDefault();
    let ary = [0, 0, 1, 0, 0, 0];
    this.setState({
      showowners: false, showvehicles: false, showactivevehicles: true,
      showprofile: false, shownotifications: false, showviolations: false, showreports: false, selary: ary
    });
  }
  showViolations = (e) => {
    e.preventDefault();
    let ary = [0, 0, 0, 1, 0, 0];

    if (this.state.showactivevehicles) {
      ary = [0, 0, 1, 1, 0, 0]
    }
    // this.setState({
    //   showowners: false, showvehicles: false, showactivevehicles: false,
    //   showprofile: false, shownotifications: false, showviolations: true, selary: ary
    // });
    this.setState({
      showviolations: true, selary: ary
    });
  }

  showNotifications = (e) => {
    e.preventDefault();
    let ary = [0, 0, 0, 0, 1, 0];
    this.setState({
      showowners: false, showvehicles: false, showactivevehicles: false,
      showprofile: false, shownotifications: true, showviolations: false, showreports: false, selary: ary
    });
  }

  showReports = (e) => {
    e.preventDefault();
    let ary = [0, 0, 0, 0, 0, 1];
    // this.props.history.push("/profile");
    // this.setState({ showprofile: true, showvehicles: false });
    this.setState({
      showowners: false, showvehicles: false, showactivevehicles: false,
      showprofile: false, shownotifications: false, showviolations: false, showreports: true, selary: ary
    });
  }

  showProfile = (e) => {
    e.preventDefault();
    let ary = [0, 0, 0, 0, 0, 0];
    // this.props.history.push("/profile");
    // this.setState({ showprofile: true, showvehicles: false });
    this.setState({
      showowners: false, showvehicles: false, showactivevehicles: false,
      showprofile: true, shownotifications: false, showviolations: false, showreports: false, selary: ary
    });
  }

  // closeViolations = () => {
  //   let ary = [0, 1, 0]
  //   this.setState({
  //     showviolations: false,
  //     showallvehicles: true,
  //     showvehicles: false,
  //     selary: ary
  //   });
  // }

  closeViolations() {
    let ary = [0, 0, 1, 0, 0, 0];
    this.setState({
      showowners: false, showvehicles: false, showactivevehicles: true,
      showprofile: false, shownotifications: false, showviolations: false, showreports: false, selary: ary
    });
  }

  closeactive() {
    // console.log('admin dashboard closing active')
    let showvio = false;
    let ary = [0, 0, 0, 0, 0, 0];
    if (this.state.showviolations) {
      showvio = true
      ary = [0, 0, 0, 1, 0, 0];
    }
    this.setState({
      showowners: false, showvehicles: false, showactivevehicles: false,
      showprofile: false, shownotifications: false, showviolations: showvio, showreports: false, selary: ary
    });
  }


  // closeactive = () => {
  //   let ary = [0, 0, 0]
  //   this.setState({
  //     showviolations: false,
  //     showallvehicles: false,
  //     showvehicles: false,
  //     selary: ary
  //   });
  // }








  render() {
    //  console.log('DOING A DASBOARD RENDER');

    // console.log('perrors are', perrors);

    //let valary = [100, 400];
    // console.log('dashboard render dd ', dd);
    // minmax[0] = parseInt(dd['ppn']);
    /*
    backgroundColor: "#231259",
          color: "white",
          */
    const { showowners } = this.state;
    const { showvehicles } = this.state;
    const { showactivevehicles } = this.state;
    const { showprofile } = this.state;
    const { shownotifications } = this.state;
    const { showviolations } = this.state;
    const { showreports } = this.state;


    let bclass = 'btn-basic';
    let bclasssel = 'btn-basic-selected';
    let selary = this.state.selary;
    let millis = Date.now();

    return (
      <div className="container-fluid">

        <h5 style={{ textAlign: "center", color: "white", marginTop: "10px" }} >Administrator Dashboard</h5>
        <div style={{ textAlign: "center", color: "white" }}>{sessionStorage.getItem('hoaname')}</div>
        <div style={{ textAlign: "center", color: "white", marginBottom: "20px" }}><ParkingSummary millis={millis} /></div>




        {/* <h5 style={{ textAlign: "center", color: "white", marginTop: "15px" }} >Admin Dashboard</h5> */}
        <div className="flex_row_center">


          <div className="flex_item">
            <SmartButtonHPS
              name="Owners"
              buttonclass={selary[0] === 0 ? bclass : bclasssel}
              onClick={(e) => this.showOwners(e)} />

            {/* // <Link to="" className="btn btn-sm btn-info btnparking"
            // onClick={(e) => this.showOwners(e)} >Owners</Link> */}
          </div>

          <div className="flex_item">
            <SmartButtonHPS
              name="Vehicles"
              buttonclass={selary[1] === 0 ? bclass : bclasssel}
              onClick={(e) => this.showVehicles(e)} />
            {/* <Link to="" className="btn btn-sm btn-info btnparking"
            onClick={(e) => this.showVehicles(e)} >Vehicles</Link> */}

          </div>
          <div className="flex_item">
            <SmartButtonHPS
              name="Onsite Vehicles"
              buttonclass={selary[2] === 0 ? bclass : bclasssel}
              onClick={(e) => this.showActiveVehicles(e)} />
            {/*             
            <Link to="" className="btn btn-sm btn-info btnparking"
            onClick={(e) => this.showActiveVehicles(e)} >Onsite Vehicles</Link> */}
          </div>




          <div className="flex_item">
            <SmartButtonHPS
              name="Violations"
              buttonclass={selary[3] === 0 ? bclass : bclasssel}
              onClick={(e) => this.showViolations(e)} />
            {/* <Link to="" className="btn btn-sm btn-info btnparking"
            onClick={(e) => this.showViolations(e)} >Violations</Link> */}

          </div>

          <div className="flex_item">
            <SmartButtonHPS
              name="Feedback"
              buttonclass={selary[4] === 0 ? bclass : bclasssel}
              onClick={(e) => this.showNotifications(e)} />
            {/* <Link to="" className="btn btn-sm btn-info btnparking"
            onClick={(e) => this.showNotifications(e)} >Feedback</Link> */}
          </div>
          <div className="flex_item">
            <SmartButtonHPS
              name="Reports"
              buttonclass={selary[5] === 0 ? bclass : bclasssel}
              onClick={(e) => this.showReports(e)} />
            {/* <Link to="" className="btn btn-sm btn-info btnparking"
            onClick={(e) => this.showReports(e)} >Reports</Link> */}
          </div>

        </div>






        {showowners ? (<AdminOwners />) : (null)}

        {showvehicles ? (<AdminVehicles />) : (null)}

        {showactivevehicles ? (<AdminActiveVehicles closeactive={() => this.closeactive()} />) : (null)}

        {showprofile ? (<AdminProfile />) : (null)}

        {shownotifications ? (<AdminNotifications />) : (null)}

        {showreports ? (<ReportTabs />) : (null)}

        {showviolations ? (<Violations isadmin={true} closeviolations={() => this.closeViolations()} />) : (null)}









      </div >
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  parking: state.parking,
  errors: state.errors,
});

export default connect(mapStateToProps, {})(AdminDashboard);

//export default Dashboard;
