import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'


import { connect } from "react-redux";
import { registerUser } from "../../actions/authActions";

//import axios from "axios";
//import classnames from "classnames";

import TextFieldGroup from "../common/TextFieldGroup";

class RegisterViaAdmin extends Component {
  constructor() {
    super();
    this.state = {
      first_name: "",
      last_name: "",
      phone: "",
      email: "", //"new@retretia.com",
      unitnumber: "",
      pincode: "", //"4321",
      password: "", //"123456",
      password2: "", //"123456",
      errors: {}
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      //  this.props.history.goBack();
      //this.props.history.push("/dashboard");
    }

    // let maxowneractive = parseInt(ahoa['parking_allowed_unit']);
    // let maxallactive = parseInt(ahoa['parking_allowed_hoa']);
  }

  // if we have errors this will run
  // componentWillReceiveProps(nextProps) {
  //   //console.log("register componentWillReceiveProps");
  //   if (nextProps.errors) {
  //     this.setState({ errors: nextProps.errors });
  //     // setState triggers a render
  //   }
  // }
  static getDerivedStateFromProps(props, state) {
    return {
      errors: props.errors
    };
  }
  // componentDidUpdate(prevProps) {
  //   // Typical usage (don't forget to compare props):
  //   if (this.props.errors) {
  //     this.setState({ errors: this.props.errors });
  //   }
  // }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onCancelClick(e) {
    e.preventDefault();
    this.props.closeform();
  }

  onSubmit(e) {
    e.preventDefault();


    let shoastr = sessionStorage.getItem('selectedhoa');
    let ahoa = JSON.parse(shoastr);

    // console.log('the hoa data for register is ', ahoa);
    let parking_allowed_unit = ahoa['parking_allowed_unit'];
    let parking_allowed_renter = ahoa['parking_allowed_renter'];
    let owner_free_parking = ahoa['owner_free_parking_spots'];
    let renter_free_parking = ahoa['renter_free_parking_spots'];


    const newOwner = {
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      phone: this.state.phone,
      email: this.state.email,
      unitnumber: this.state.unitnumber,
      pincode: this.state.pincode,
      password: this.state.password,
      password2: this.state.password2,
      parking_allowed: parking_allowed_unit,
      parking_allowed_renter: parking_allowed_renter,
      owner_free_parking: owner_free_parking,
      renter_free_parking: renter_free_parking,
      hoaid2: 'YV',
      hoaid: sessionStorage.getItem('hoaid'),
      role: 'owner'

    };
    // console.log('new owner is ', newOwner);


    this.props.registerUser(newOwner, this.props.history);

  }

  render() {
    const { errors } = this.state;
    // console.log('registration errors', errors);


    const okelement = <FontAwesomeIcon icon={faCheckCircle} color="#65598a" size="2x" title="enter / submit" />
    const cancelelement = <FontAwesomeIcon icon={faTimesCircle} color="black" size="2x" title="cancel / close" />
    // same as const errors = this.state.errors

    // this was used to show user from props
    // const { user } = this.props.auth; // const user = this.props.auth.user
    // this shows user {user ? user.name : null}

    return (
      <div className="xlanding">
        <div className="container-fluid">
          <div className="inputbox">
            <div className="innerinputbox">
              <h3 className="text-center">Owner Registration</h3>
              <form noValidate onSubmit={this.onSubmit}>
                <div className="form-group">
                  <TextFieldGroup
                    type="text"
                    label="First Name"
                    placeholder="Name"
                    name="first_name"
                    value={this.state.first_name}
                    onChange={this.onChange}
                    error={errors.first_name}
                  />
                </div>
                <div className="form-group">
                  <TextFieldGroup
                    type="text"
                    label="Last Name"
                    placeholder="Name"
                    name="last_name"
                    value={this.state.last_name}
                    onChange={this.onChange}
                    error={errors.last_name}
                  />
                </div>
                <div className="form-group">
                  <TextFieldGroup
                    type="email"
                    label="Email"
                    placeholder="Email Address"
                    name="email"
                    value={this.state.email}
                    onChange={this.onChange}
                    error={errors.email}
                  />
                </div>
                <div className="form-group">
                  <TextFieldGroup
                    type="text"
                    label="Phone"
                    placeholder="Phone Number"
                    name="phone"
                    value={this.state.phone}
                    onChange={this.onChange}
                    error={errors.phone}
                  />
                </div>
                <div className="form-group">
                  <TextFieldGroup
                    type="unitnumber"
                    label="Unit Number"
                    placeholder="Unit Number"
                    name="unitnumber"
                    value={this.state.unitnumber}
                    onChange={this.onChange}
                    error={errors.unitnumber}
                  />
                </div>
                <div className="form-group">
                  <TextFieldGroup
                    type="pincode"
                    label="Pin Code"
                    placeholder="Pin Code"
                    name="pincode"
                    value={this.state.pincode}
                    onChange={this.onChange}
                    error={errors.pincode}
                  />
                </div>

                <div className="form-group">
                  <TextFieldGroup
                    type="password"
                    label="Password"
                    placeholder="Password"
                    name="password"
                    value={this.state.password}
                    onChange={this.onChange}
                    error={errors.password}
                  />
                </div>
                <div className="form-group">
                  <TextFieldGroup
                    type="password"
                    label="Confirm"
                    placeholder="Confirm Password"
                    name="password2"
                    value={this.state.password2}
                    onChange={this.onChange}
                    error={errors.password2}
                  />
                </div>
                <div className="row">
                  <div className="col-md-8 offset-md-4">

                    <span className="iconbuttonspan">
                      <Link to=""
                        onClick={(e) => this.onSubmit(e)} >
                        {okelement}
                      </Link>
                    </span>
                    <span className="iconbuttonspan">
                      <Link to=""
                        onClick={(e) => this.onCancelClick(e)} >
                        {cancelelement}
                      </Link>
                    </span>
                    {errors && errors['message'] ? (<div className="error-display">{errors['message']}</div>) : (null)}

                  </div>
                </div>
                {/* <span style={{ fontSize: "9pt" }}>For Demo Purposes:
                  <br />Passwords have been set to 123456
                  <br />Pincode have been set to 4321
                  <br />Email is set to new@retretia.com.  Change the 'new' to whatever you want

                </span> */}







                {/* <input type="submit" className="btn btn-info btn-block mt-4" />
                <a
                  href=""
                  className="btn btn-info btn-block mt-4"
                  onClick={this.onCancelClick.bind(this)}
                >
                  Cancel
                </a> */}
              </form>


            </div>

          </div>
        </div >
      </div >
    );
  }
}

// this is good practice because it will help debugging
// it is not checked when in production mode.
RegisterViaAdmin.propTypes = {
  registerUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

// map errors to this component
// then we can use this.props.auth.user etc
// since we mapped error, we can use componentWillReceiveProps method
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});
// the state.auth above comes from rootReducer in index.js in reducers.

export default connect(
  mapStateToProps,
  { registerUser }
)((RegisterViaAdmin));
// wrap the Register with withRouter so the authAction can use history to redirect
