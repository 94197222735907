import React, { Component } from "react";

import { Link } from "react-router-dom";


// import SectionGeneral from "./SectionGeneral";
// import SectionPermissions from "./SectionPermissions";
// import SectionContact from "./SectionContact";


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faTimesCircle, faColumns, faUserCircle, faSignOutAlt, faQuestionCircle, faHome, faCog, faBell, faPhone, faExclamationCircle, faComment } from '@fortawesome/free-solid-svg-icons';





class HelpNavigation extends Component {

  constructor(props) {
    super(props);
    this.state = {

      errors: {}
    };


  }

  componentDidMount() {


  }

  onCancelClick(e) {
    e.preventDefault();
    this.props.history.goBack();
    //let url = "/" + sessionStorage.getItem('hoaid');
    // window.location.href = url;
    // this.props.history.push(url);
    //window.location = "/id/" + sessionStorage.getItem('hoaid');
  }




  closeForm = (e) => {
    e.preventDefault();
    this.props.closeform('navigation');
  }


  render() {

    const helpicon = <FontAwesomeIcon icon={faQuestionCircle} color="#17a2b8" size="lg" />
    const signouticon = <FontAwesomeIcon icon={faSignOutAlt} color="#17a2b8" size="lg" />
    const usericon = <FontAwesomeIcon icon={faUserCircle} color="#17a2b8" size="lg" />
    const dashboardicon = <FontAwesomeIcon icon={faColumns} color="#17a2b8" size="lg" />
    const maintenanceicon = <FontAwesomeIcon icon={faCog} color="#17a2b8" size="lg" />
    const notificationicon = <FontAwesomeIcon icon={faBell} color="#17a2b8" size="lg" />
    const commenticon = <FontAwesomeIcon icon={faComment} color="#17a2b8" size="lg" />

    const phoneicon = <FontAwesomeIcon icon={faPhone} color="#17a2b8" size="lg" />

    const contactadminicon = <FontAwesomeIcon icon={faExclamationCircle} color="#17a2b8" size="lg" />

    const okicon = <FontAwesomeIcon icon={faCheckCircle} color="#65598a" size="lg" />
    const okicondisable = <FontAwesomeIcon icon={faCheckCircle} color="#ddd" size="lg" />
    const cancelicon = <FontAwesomeIcon icon={faTimesCircle} color="black" size="lg" />



    return (

      <div style={{ marginBottom: "10px" }}>
        <div style={{ textAlign: "right" }}>
          <span className="iconbuttonspan">
            <Link to=""
              onClick={(e) => this.closeForm(e)} >
              {cancelicon}
            </Link>
          </span>
        </div>
        <h6><b>Navigation</b></h6>

        {/* <b>How do I find where to go and what to do in the App?</b><br /> */}
        <p>
          The upper right hand corner is where you will find the navigation icons. These are the standard icons you may see on your page:</p>
        <div style={{ fontSize: "10pt" }}>
          <table border="0" cellSpacing="1" cellPadding="5">
            <thead></thead>
            <tbody>
              <tr>
                <td>{dashboardicon}</td>
                <td>Dashboard</td>
                <td>{usericon}</td>
                <td>Profile</td>
              </tr>
              <tr>
                <td>{phoneicon}</td>
                <td>Contact Us</td>

                <td>{helpicon}</td>
                <td>Help</td>
              </tr>
              <tr>
                <td>{signouticon}</td>
                <td>Exit / Logout</td>

                <td>{maintenanceicon}</td>
                <td>Maintenance (admin)</td>
              </tr>

              <tr>
                <td>{okicon}</td>
                <td>Enter / Submit</td>

                <td>{cancelicon}</td>
                <td>Close / Cancel</td>
              </tr>
            </tbody>
          </table>
        </div>











      </div>
    );
  }
}


export default ((HelpNavigation));
