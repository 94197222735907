import React, { Component } from "react";
// we need to get into git
import axios from "axios";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import LoadingMessage from "../common/LoadingMessage";
import TextFieldGroupPlain from "../common/TextFieldGroupPlain";
import SelectNameValuePlain from "../common/SelectNameValuePlain";

import { validateContactInformationInput } from "../../validation/client_contactvalidation";

import { showStatusMessage } from "../../actions/parkingActions";







class AdminContactInformation extends Component {
  constructor(props) {
    super(props);

    let shoa = JSON.parse(sessionStorage.getItem('selectedhoa'));

    this.state = {
      whichphoneview: 1,
      hoaid: sessionStorage.getItem('hoaid'),
      // selectedhoa: JSON.parse(sessionStorage.getItem('selectedhoa')),
      contact_information: shoa['contact_information'],
      contact_id: '',
      phone_description: '',
      phone_number: '',
      email: '',
      sort_order: '',
      display_category: 'All',
      submit_label: 'Modify',
      submit_enabled: false,
      commit_enabled: false,
      errors: {}
    }; //shuttles: ["one", "two", "three"] };
    // console.log("Dashboard props", props);
    this.onChange = this.onChange.bind(this);

  }


  /*
  let shoastr = sessionStorage.getItem('selectedhoa');

  let ahoa = JSON.parse(shoastr);
  */

  componentDidMount() {
    let viewnum = 1;
    if (window.innerWidth < 800) {
      viewnum = 2;
    }
    // console.log('window inner width is ', window.innerWidth);
    this.setState({ whichphoneview: viewnum });
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }








  togglePhoneView = (e, which) => {
    e.preventDefault();
    this.setState({ whichphoneview: which });
  }

  closeform = () => {
    // console.log('this is close form');
    this.setState({ show: false });
  }





  doSort = (e, which) => {
    e.preventDefault();
    let sd = this.state.sortdirection;
    sd = sd * -1;
    this.setState({ whichsort: which, sortdirection: sd });
  }

  togglePhoneView = (e, which) => {
    e.preventDefault();
    this.setState({ whichphoneview: which });
  }

  closenotifications = (e) => {
    e.preventDefault();
    this.props.closenotifications();
  }



  addmodifyContactInfo = (e) => {
    e.preventDefault();

    let { contact_id } = this.state;
    let { phone_description } = this.state;
    let { phone_number } = this.state;
    let { email } = this.state;
    let { sort_order } = this.state;
    let { submit_label } = this.state;
    let { display_category } = this.state;


    const { errors, isValid } = validateContactInformationInput(this.state);
    if (!isValid) {
      this.setState({ errors: errors });
      return;
    }

    let therecs = this.state.contact_information;

    let therecary = therecs.filter(el => el['contact_id'] === contact_id);
    let therec = therecary[0];
    if (submit_label === 'Modify') {
      therec['phone_description'] = phone_description;
      therec['phone_number'] = phone_number;
      therec['email'] = email;
      therec['sort_order'] = sort_order;
      therec['display_category'] = display_category;

    }
    if (submit_label === 'Add') {
      let nrec = {};
      nrec['contact_id'] = contact_id;
      nrec['phone_description'] = phone_description;
      nrec['phone_number'] = phone_number;
      nrec['email'] = email;
      nrec['sort_order'] = sort_order;
      nrec['display_category'] = display_category;
      therecs.push(nrec);

    }
    this.setState({ contact_information: therecs, errors: {} })


  }
  commitContactInfo = (e) => {
    e.preventDefault();
    //  console.log('commit the info');
    let hoaid = sessionStorage.getItem('hoaid');
    let therecs = this.state.contact_information;
    //  console.log('the recs after commit ', therecs);
    therecs.forEach((el, index) => {
      el['sort_order'] = (index + 1) * 10;
    });
    this.setState({ contact_information: therecs });

    let shoa = JSON.parse(sessionStorage.getItem('selectedhoa'));
    shoa['hoaid'] = hoaid;
    shoa['contact_information'] = therecs;

    let shoastr = JSON.stringify(shoa);
    // sessionStorage.setItem('selectedhoa', shoastr);



    // this.props.showStatusMessage("Contact Information Update Success");

    // AdminContactInformation
    let url = "/api/parking/update_contact_info";
    axios.post(url, shoa).then(data => {
      //console.log('contact info did update', data);
      this.props.showStatusMessage("Contact Information Update Success");
      sessionStorage.setItem('selectedhoa', shoastr);
    }).catch(err => {
      console.log(err);
    });

  }

  fillContactInfoEdit = (e, id) => {
    e.preventDefault();
    // console.log('find the data for id', id)
    // let therecs = this.state.selectedhoa['contact_information'];
    let therecs = this.state.contact_information;
    let therecary = therecs.filter(el => el['contact_id'] === id);
    let therec = therecary[0];
    //console.log('therec', therec);
    this.setState({
      contact_id: therec['contact_id'],
      phone_description: therec['phone_description'],
      phone_number: therec['phone_number'],
      email: therec['email'],
      sort_order: therec['sort_order'],
      display_category: therec['display_category'],
      submit_label: 'Modify',
      submit_enabled: true,
      commit_enabled: true
    })
  }

  deleteContactInfo = (e, id) => {
    e.preventDefault();
    let therecs = this.state.contact_information;
    let therecary = therecs.filter(el => el['contact_id'] !== id);
    this.setState({ contact_information: therecary });
    let shoa = JSON.parse(sessionStorage.getItem('selectedhoa'));
    shoa['contact_information'] = therecary;
    let shoastr = JSON.stringify(shoa);
    sessionStorage.setItem('selectedhoa', shoastr);

  }

  prepareAddContact = (e) => {
    e.preventDefault();
    this.setState({
      contact_id: '',
      phone_description: '',
      phone_number: '',
      email: '',
      sort_order: '',
      display_category: 'All',
      submit_label: 'Add',
      submit_enabled: true,
      commit_enabled: true
    })
  }


  render() {


    // let notifications = this.props.parking.current_notifications;
    // let numnotifications = notifications.length;

    const addicon = <FontAwesomeIcon icon={faPlusCircle} color="#65598a" size="2x" />

    let { errors } = this.state;
    let { submit_label } = this.state;
    let { submit_enabled } = this.state;
    let { commit_enabled } = this.state;



    let { contact_information } = this.state;


    let pnumbers = contact_information; //selectedhoa['contact_information'];

    pnumbers.sort(function (a, b) {
      let af = parseFloat(a['sort_order']);
      let bf = parseFloat(b['sort_order']);
      if (af < bf) return -1;
      if (af > bf) return 1;
      if (af === bf) return 0;

    })


    let rdata = [];


    pnumbers.forEach((pnum, index) => {
      let id = pnum['contact_id'];

      rdata.push(

        <div key={index} className="grid-container-7" >
          <div className="grid-item-normal">
            <Link to=""
              onClick={(e) => this.fillContactInfoEdit(e, id)} >{pnum['contact_id']}</Link>

          </div>
          <div className="grid-item-bold-ul">{pnum['phone_description']}</div>
          <div className="grid-item-normal">{pnum['phone_number']}</div>
          <div className="grid-item-normal">{pnum['email']}</div>
          <div className="grid-item-normal">{pnum['sort_order']}</div>
          <div className="grid-item-normal">{pnum['display_category']}</div>

          <div className="grid-item-normal">

            <Link to=""
              onClick={(e) => this.deleteContactInfo(e, id)} >Delete</Link>

          </div>

        </div>)


    });

    let disable_type = true;
    if (this.state.submit_label === 'Add') {
      disable_type = false;
    }


    let categories = [{ name: "All", value: "All" },
    { name: "Owner", value: "Owner" },
    { name: "Renter", value: "Renter" },
    { name: "Hide", value: "Hide" }];

    return (
      <div style={{ border: "0px solid yellow", marginTop: "10px" }}>

        <div className="inputbox">
          <div className="innerinputbox2">
            {(this.props.parking.isloading) ? (<LoadingMessage />) : null}

            <p className="parking_label">Contact Information</p>
            <div key={"0"} className="grid-container-7" >
              <div className="grid-item-bold-ul">Id</div>
              <div className="grid-item-bold-ul">Title</div>
              <div className="grid-item-bold-ul">Number</div>
              <div className="grid-item-bold-ul">Email</div>
              <div className="grid-item-bold-ul">Order</div>
              <div className="grid-item-bold-ul">Display</div>
              <div className="grid-item-bold-ul">&nbsp;</div>


            </div>
            {rdata}

            <div style={{ margin: "20px 0px" }}>
              <b>Add Contact Information</b><br />
              <Link to=""
                onClick={(e) => this.prepareAddContact(e)} >{addicon}</Link>
            </div>
            <div style={{ fontSize: "10pt" }}>
              <div className="row mb-2">
                <div className="col-sm-2">
                  <b>Contact Id</b>
                </div>
                <div className="col-sm-4">
                  <TextFieldGroupPlain
                    type="text"
                    label="Contact Id"
                    placeholder="Contact Id"
                    name="contact_id"
                    disabled={disable_type}
                    value={this.state.contact_id}
                    onChange={this.onChange}
                    error={errors.contact_id}
                  />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-sm-2">
                  <b>Description</b>
                </div>
                <div className="col-sm-4">
                  <TextFieldGroupPlain
                    type="text"
                    label=""
                    placeholder="Description"
                    name="phone_description"
                    value={this.state.phone_description}
                    onChange={this.onChange}
                    error={errors.phone_description}
                  />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-sm-2">
                  <b>Number</b>
                </div>
                <div className="col-sm-4">
                  <TextFieldGroupPlain
                    type="text"
                    label=""
                    placeholder="Number"
                    name="phone_number"
                    value={this.state.phone_number}
                    onChange={this.onChange}
                    error={errors.phone_number}
                  />
                </div>
              </div>

              <div className="row mb-2">
                <div className="col-sm-2">
                  <b>Email</b>
                </div>
                <div className="col-sm-4">
                  <TextFieldGroupPlain
                    type="text"
                    label=""
                    placeholder="Email"
                    name="email"
                    value={this.state.email}
                    onChange={this.onChange}
                    error={errors.email}
                  />
                </div>
              </div>

              <div className="row mb-2">
                <div className="col-sm-2">
                  <b>Sort Order</b>
                </div>
                <div className="col-sm-4">
                  <TextFieldGroupPlain
                    type="text"
                    label=""
                    placeholder="Sort Order"
                    name="sort_order"
                    value={this.state.sort_order}
                    onChange={this.onChange}
                    error={errors.sort_order}
                  />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-sm-2">
                  <b>Category</b>
                </div>
                <div className="col-sm-4">


                  <SelectNameValuePlain
                    label="Category"
                    list={categories}
                    name="display_category"
                    value={this.state.display_category}
                    onChange={this.onChange}
                    error={errors.display_category}
                  />
                </div>
              </div>

              <div className="row mb-2">
                <div className="col-sm-2">
                  <Link to="" className="btn btn-sm btn-info btnparking"
                    onClick={(e) => this.addmodifyContactInfo(e)} >{submit_label}</Link>
                </div>
                <div className="col-sm-2">
                  <Link to="" className="btn btn-sm btn-info btnparking"
                    onClick={(e) => this.commitContactInfo(e)} >Commit</Link>
                </div>
              </div>





            </div>





          </div >
        </div >





      </div >
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  parking: state.parking,
  errors: state.errors,
});

export default connect(mapStateToProps, { showStatusMessage })(AdminContactInformation);

//export default Dashboard;
