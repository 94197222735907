import React from "react";

//import SectionParking from "../parking/help/SectionParking";



const TermsAndConditions_Owner = () => {

  let hoaname = sessionStorage.getItem('hoaname')
  let hoastr = sessionStorage.getItem('selectedhoa');
  let hoaobj = { help_text: { general: "<h2>Nothing general" } };

  if (hoastr) hoaobj = JSON.parse(hoastr);


  return (
    <div className="tenpoint bordernoshadow" style={{ margin: "10px auto", width: "80%", backgroundColor: "white" }}>

      <div>
        <h5 style={{ textAlign: "center" }}>
          {hoaname} Parking<br />
        </h5>
        <h6 style={{ textAlign: "center" }}>
          Owner Terms and Conditions
        </h6>
        <div dangerouslySetInnerHTML={{ __html: hoaobj['help_text']['owner_tc'] }}></div>
        {/* <ol>
          <li>
            <h6>
              Prior to registering your vehicle(s), it is imperative that you read Yampa View’s Rules and Regulations, located in AppFolio, where the parking rules reside.
            </ h6>
          </li>
          <li>
            <h6>
              As an owner, it is your responsibility to register your vehicles in Yampa View’s Parking App and set the ONSITE value to “Yes” or “No”.
            </h6>
          </li>
          <li>
            <h6>
              It is your responsibility to provide a pincode and to inform your vacation renters that they must register their vehicles.
            </h6>
          </li>
          <li>
            <h6>VIOLATION OF THESE TERMS AND CONDITIONS MAY RESULT IN YOU BEING FINED, AND/OR YOU OR YOUR VACATION RENTER’S VEHICLE BEING TOWED OR BOOTED AT OWNER EXPENSE!!</h6>
          </li>
        </ol> */}
      </div>

      {/* <SectionParking /> */}

      {/* <h6>Refer to the Help page to learn more about using the App.</h6> */}














    </div>
  );
};



export default TermsAndConditions_Owner;
