import React from "react";

/*
export const getTodaysDate = () => {
  let cdate = new Date();
  let yr = cdate.getFullYear();
  let mo = cdate.getMonth();
  let day = cdate.getDate();
  // console.log(utcyr, utcmo, utcday);

  let ms1 = new Date(yr, (mo), day, 0, 0, 0);
  //  console.log('datemillis', datemillis);
  return new Date(ms1);
}
*/

export const geFutureAsString = (days) => {
  var ms = new Date().getTime() + (86400000 * days);
  let d = new Date(ms);

  //let d = new Date();
  let yr = d.getFullYear();
  let mo = d.getMonth() + 1;
  let day = d.getDate();
  if (mo < 10) mo = "0" + mo;
  if (day < 10) day = "0" + day;
  let dstr = yr + '-' + mo + '-' + day;
  return dstr;
}

export const getTomorrowAsString = () => {
  var ms = new Date().getTime() + 86400000;
  let d = new Date(ms);

  //let d = new Date();
  let yr = d.getFullYear();
  let mo = d.getMonth() + 1;
  let day = d.getDate();
  if (mo < 10) mo = "0" + mo;
  if (day < 10) day = "0" + day;
  let dstr = yr + '-' + mo + '-' + day;
  return dstr;
}

export const getTodayAsString = () => {

 // let mss = new Date().getTime() - (86400000 * 1);
  let mss = new Date().getTime() - (86400000 * 0);
  let d = new Date(mss);

  //let d = new Date();
  let ofm = d.getTimezoneOffset();
  let ms = d.getTime() - (ofm * 60 * 1000);
  let changedate = new Date(ms);

  let pdt = d.getTime();



  // console.log('puredate', d, 'offsetdate', changedate, 'timedate', new Date(pdt),
  //   'utcstr', d.toUTCString(), 'iso', d.toISOString());

  // var ms = new Date().getTime();
  // let d = new Date(ms);
  //let d = new Date();
  let yr = d.getFullYear();
  let mo = d.getMonth() + 1;
  let day = d.getDate();
  if (mo < 10) mo = "0" + mo;
  if (day < 10) day = "0" + day;
  let dstr = yr + '-' + mo + '-' + day; // + ' 00:00';
  // console.log('dstr date', d.toUTCString(), dstr, new Date(dstr));
  // console.log('D', d, 'DUTC', d.toUTCString());
  //  let dstrtime = dstr + 'T00:00:00';
  // let ztdate = new Date(dstrtime);
  //console.log('dstrtime date', ztdate, 'ZDUTC', ztdate.toUTCString());

  //console.log('bdate', d.toString(), 'dstr', dstr)

  //dstr = d.toString();
  return dstr;

}

export const formatReadableDate = (indate) => {
   let changedate = new Date(indate);
   let rdate = changedate.toDateString()
   //let rdate = changedate.toISOString()
  // let yr = changedate.getFullYear();
  // let mo = changedate.getMonth() + 1;
  // let day = changedate.getDate();
  // if (mo < 10) mo = "0" + mo;
  // if (day < 10) day = "0" + day;
  // let dstr = yr + '-' + mo + '-' + day;
  return rdate;
  // return dstr;
  //return indate;
  //console.log('dstr', dstr)
}


export const getOnsiteLabel = (d1, d2,vehicle) => {
  let lbl = 'Yes';
  //let lbl = (<span>Yes</span>);
  if (d1 < d2) {
    lbl = 'No';
    // lbl = (<span style={{ backgroundColor: "rgba(0, 0, 0, 0.6)", color: "white" }}>No</span>);
    //lbl = (<div style={{ display: "inline", padding: "3px", backgroundColor: "rgba(0, 0, 0, 0.6)", color: "white" }}>No</div>);
  }
  if (vehicle['status_flag'] === 9 || vehicle['status_flag'] === 8) {
    lbl = 'No';
  }
  return lbl;
}
// edate,dstr
export const generateDateDisplay = (d1, d2) => {
  // console.log('generate date label VEHedate TODAYdstr', d1, d2);
  let lbl = (<span>{d1}</span>);
  if (d1 === d2) {
    lbl = (<span style={{ display: "inline", padding: "3px", backgroundColor: "rgba(255, 0, 0, 0.3)" }}>{d1}</span>);
  }
  if (d1 < d2) {
    // console.log('d1 is less than d2')
    lbl = (<div style={{ display: "inline", padding: "3px", backgroundColor: "rgba(0, 0, 0, 0.5)", color: "white" }}>{d1}</div>);
  }
  return lbl;
}



// export const formatDateDisplay = (indate) => {
//   let rdate = indate;
//  // if (indate === '1970-01-01') rdate = '0000-00-00';

//   return indate; //rdate; //indate;

// }

export const getRowHighlightColor = (which, vehicle) => {
  let str = 'white';
 // console.log(which,vehicle)
  switch (which) {
    case 'warning':
     // console.log('we are in case')
      if ((vehicle['status_flag'] == 2)  && (sessionStorage['use_warning_status'] == 1)) {
        str = 'rgba(255, 0, 0, 0.2)'
      }
    default:
  }
  return str; //rdate; //indate;
}





