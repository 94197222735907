import React, { Component } from "react";
// import PropTypes from "prop-types";
import axios from "axios";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import ErrorMessageConstantClose from "../common/ErrorMessageConstantClose";
import LoadingMessage from "../common/LoadingMessage";
import { isitEmpty } from '../../validation/isitempty';







// import { connect } from "react-redux";
// import { changeAdminName, changePassword } from "../../actions/authActions";
//import { getVehiclesForOwner } from "../../actions/parkingActions";
//import VehicleForm from "./VehicleForm";
//


//import axios from "axios";
//import classnames from "classnames"; 


import TextFieldGroup from "../common/TextFieldGroupPlain";

class UserVerification extends Component {
  constructor(props) {
    console.log('user verification props', props)
    super(props);
    // console.log('user verification props', props);
    this.state = {
      whicharea: "verify",
      heading: "",
      title: "",

      password: "",
      password2: "",
      userid: "",
      hoaid: "",
      email: '',
      showmessage: false,
      isloading: false,

      errors: {}
    };

    this.onChange = this.onChange.bind(this);
    // this.onSubmitPin = this.onSubmitPin.bind(this);
    this.onSubmitPassword = this.onSubmitPassword.bind(this);
    // this.onSubmitChangeDays = this.onSubmitChangeDays.bind(this);
    // this.onSubmitLoadHoa = this.onSubmitLoadHoa.bind(this);


  }

  componentDidMount() {
    console.log('user verifications mounted props', this.props);
    //  console.log('user verifications mounted props which', this.props.which.match.params.which);
    //  console.log('user verifications mounted props which', this.props.which);
    // let which = this.props.which.match.params.which;
    // let userid = this.props.which.match.params.userid;
    // let hoaid = this.props.which.match.params.hoaid;
    let which = this.props.which;
    let userid = this.props.userid;
    let hoaid = this.props.hoaid;
    let email = '';

    if (which === 'verify') {
      this.setState({ whicharea: which, hoaid: hoaid, title: "Verify Email", heading: "Your email has been verified.  You may proceed to login" });
    }
    if (which === 'noverify') {
      this.setState({ whicharea: which, hoaid: hoaid, title: "Verify Email Failure", heading: "Your email has NOT been verified.  You will have to register again" });
    }
    if (which === 'nouserid') {
      this.setState({ whicharea: which, hoaid: hoaid, title: "No User Found", heading: "User id was not found in the database. " });
    }
    if (which === 'password') {
      email = this.props.email;
      this.setState({ whicharea: which, userid: userid, hoaid: hoaid, email: email, title: "Password Reset", heading: "Please reset your password" });
    }

    if (which === 'pwlinkexpired') {
      this.setState({ whicharea: which, hoaid: hoaid, title: "Link Has Expired", heading: "The link has expired.  Please submit your request again. " });
    }



  }



  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }



  onSubmitPassword(e) {
    e.preventDefault();
   //  console.log('onSubmitPassword submit password', this.state);

    let pw1 = this.state.password;
    let pw2 = this.state.password2;

    let errors = {};

    if (isitEmpty(pw1)) {
      errors.password = "Password Required";
      this.setState({ errors: errors });
      return;
    }
    if (isitEmpty(pw2)) {
      errors.password2 = "Password Confirmation Required";
      this.setState({ errors: errors });
      return;
    }

    if (pw1 !== pw2) {
      errors.password = "Passwords must be the same";
      this.setState({ errors: errors });
      return;
    }

    const userdata = {
      userid: this.state.userid,
      password: this.state.password,
      password2: this.state.password2
    };

    // console.log('submit password change ', userdata);

    // this.setState({ showmessage: true });

    this.setState({ isloading: true });


    axios
      .post("/api/users/changepassword", userdata)
      .then((res) => {
        //  console.log(res)
        //  alert('your password has been changed');
        this.setState({ showmessage: true, isloading: false });


      })
      .catch((err) => {
        // console.log(err)
        this.setState({ errors: err.response.data, isloading: false });
      }
      );

  }

  goToLogon = (e) => {
    e.preventDefault();
    let link = "/" + this.state.hoaid;
    window.location = link;
    //console.log('go to logon link is ', link);
    // this.props.history.push(link);
  }

  goToRegister = (e) => {
    e.preventDefault();
    let link = "/" + this.state.hoaid;

    // this.props.history.push(link);
  }


  renderPasswordForm = () => {
    let { errors } = this.state;
    let { email } = this.state;
    let { isloading } = this.state;
    const okelement = <FontAwesomeIcon icon={faCheckCircle} color="#65598a" size="2x" title="enter / submit" />
    const cancelelement = <FontAwesomeIcon icon={faTimesCircle} color="black" size="2x" title="cancel / close" />

    return (
      <div className="bordernoshadow" >
        <p style={{ marginBottom: "20px" }}>Password Reset {email}</p>
        {(isloading) ? (<LoadingMessage />) : null}
        <form noValidate  >
          <div style={{ display: "flex", gap: "20px", flexDirection: "column" }}>
            <div className="row">
              <div className="col-md-4">
                <b>Password</b>
              </div>
              <div className="col-md-8">
                <div className="form-group">
                  <TextFieldGroup
                    type="password"
                    label="Password"
                    placeholder="Password"
                    name="password"
                    value={this.state.password}
                    onChange={this.onChange}
                    error={errors.password}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <b>Confirm PW</b>
              </div>
              <div className="col-md-8">
                <div className="form-group">
                  <TextFieldGroup
                    type="password"
                    label="Confirm"
                    placeholder="Confirm Password"
                    name="password2"
                    value={this.state.password2}
                    onChange={this.onChange}
                    error={errors.password2}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-8 offset-md-4">
                <span className="iconbuttonspan">
                  <Link to=""
                    onClick={(e) => this.onSubmitPassword(e)} >
                    {okelement}
                  </Link>
                </span>
                <span className="iconbuttonspan">
                  <Link to=""
                    onClick={(e) => this.goToLogon(e)} >
                    {cancelelement}
                  </Link>
                </span>
              </div>
            </div>
          </div>
        </form >

      </div>
    )
  }
  renderVerifyOk = () => {
    const okelement = <FontAwesomeIcon icon={faCheckCircle} color="#65598a" size="2x" title="enter / submit" />
    return (
      <div className="bordernoshadow">
        <h5>Email Verified</h5>
        <h6>You may now login</h6>
        <Link to=""
          onClick={(e) => this.goToLogon(e)} >
          {okelement}
        </Link>
      </div>
    )

  }

  renderExpiredPWRequest = () => {
    const okelement = <FontAwesomeIcon icon={faCheckCircle} color="#65598a" size="2x" title="enter / submit" />
    return (
      <div className="bordernoshadow">
        <h5>Link Expired</h5>
        <h6>Please go to login screen and request another password reset</h6>
        <Link to=""
          onClick={(e) => this.goToLogon(e)} >
          {okelement}
        </Link>
      </div>
    )
  }

  renderNoUserFound = () => {
    const okelement = <FontAwesomeIcon icon={faCheckCircle} color="#65598a" size="2x" title="enter / submit" />
    return (
      <div className="bordernoshadow">
        <h5>No User Found</h5>
        <h6>Please Register</h6>
        <Link to=""
          onClick={(e) => this.goToLogon(e)} >
          {okelement}
        </Link>
      </div>
    )

  }

  renderVerifyNotOk = () => {
    const okelement = <FontAwesomeIcon icon={faCheckCircle} color="#65598a" size="2x" title="enter / submit" />
    return (
      <div className="bordernoshadow">
        <h5>Email Not Verified</h5>
        <h6>You will need to register again</h6>
        <Link to=""
          onClick={(e) => this.goToRegister(e)} >
          {okelement}
        </Link>
      </div>
    )

  }

  closemessage = () => {
    this.setState({ showmessage: false });
    let link = "/" + this.state.hoaid;
    this.props.history.push(link);
  }




  render() {
    const { errors } = this.state;
    //const { properties_ary } = this.state;
    // console.log(errors);
    let { isloading } = this.state;


    const { heading } = this.state;
    const { hoaid } = this.state;
    const { title } = this.state;
    const { whicharea } = this.state;
    let { showmessage } = this.state;
    let message = 'Your password has been reset. Thank You.';





    const okelement = <FontAwesomeIcon icon={faCheckCircle} color="#65598a" size="2x" title="enter / submit" />
    const cancelelement = <FontAwesomeIcon icon={faTimesCircle} color="black" size="2x" title="cancel / close" />

    if (whicharea === "") {
      return (null)
    }


    return (
      <div className="container-fluid">
        <h4 style={{ textAlign: "center", color: "white", marginTop: "10px" }} >User Information</h4>








        <div className="inputbox">
          <div className="innerinputbox2" style={{ maxWidth: "600px" }}>


            <p className="parking_label">{title}

            </p>

            {showmessage ? (
              <ErrorMessageConstantClose message={message} closemessage={this.closemessage} />
            ) : (null)}





            {whicharea === 'verify' ? (
              <div>
                {this.renderVerifyOk()}
              </div>
            ) : (
              null
            )}

            {whicharea === 'noverify' ? (
              <div>
                {this.renderVerifyNotOk()}
              </div>
            ) : (
              null
            )}

            {whicharea === 'password' ? (
              <div>
                {this.renderPasswordForm()}
              </div>
            ) : (
              null
            )}

            {whicharea === 'pwlinkexpired' ? (
              <div>
                {this.renderExpiredPWRequest()}
              </div>
            ) : (
              null
            )}














          </div >
        </div >







      </div >

    );
  }
}


// this is good practice because it will help debugging
// it is not checked when in production mode.
//onClick={(e) => props.gotoBooking(e, obj.accommodations.booking_url)}
// UserVerification.propTypes = {
//   auth: PropTypes.object.isRequired,
//   errors: PropTypes.object.isRequired
// };

// map errors to this component
// then we can use this.props.auth.user etc
// since we mapped error, we can use componentWillReceiveProps method
// const mapStateToProps = state => ({
//   auth: state.auth,
//   parking: state.parking,
//   errors: state.errors
// });
// the state.auth above comes from rootReducer in index.js in reducers.

// export default connect(
//   mapStateToProps,
//   {changeAdminName, changePassword}
// )(withRouter(UserVerification));

export default ((UserVerification));
// wrap the Register with withRouter so the authAction can use history to redirect
