import axios from "axios";
//import setAuthToken from "../utils/setAuthToken";
//import jwt_decode from "jwt-decode";
import { getTodayAsString } from "../components/parking/DateHelper";

import {
  GET_ERRORS,
  CLEAR_ERRORS,

  // SET_CURRENT_ADVERTISEMENT,
  // SET_CURRENT_ADVERTISEMENTS,
  // CREATE_ADVERTISEMENT,
  // MODIFY_ADVERTISEMENT,
  // REMOVE_ADVERTISEMENT,
  // CHANGE_ADVERTISEMENT_STATUS,

  // MODIFY_ACCOMMODATION,
  // INSERT_ACCOMMODATION,
  // DELETE_ACCOMMODATION,
  // CHANGE_ACCOMMODATION_STATUS,
  // DELETE_ACCOMMODATIONS_FOR_PROPERTY,

  // SET_CURRENT_ACCOMMODATIONS,
  // MODIFY_PROPERTY,
  // INSERT_PROPERTY,
  // DELETE_PROPERTY,
  // CHANGE_PROPERTY_STATUS,
  // SET_CURRENT_PROPERTIES,

  SET_STATUS_MESSAGE,
  CLEAR_STATUS_MESSAGE,

  MODIFY_VEHICLE,
  INSERT_VEHICLE,
  DELETE_VEHICLE,


  SET_CURRENT_VEHICLES_OWNER,
  SET_ALL_VEHICLES,
  SET_CURRENT_OWNERS,
  //CHANGE_VEHICLE_STATUS,
  CHANGE_VEHICLE_STATUS_DATE,
 // CHANGE_ADMIN_VEHICLE_STATUS,
  SET_DICTIONARY,
  SET_ALL_VIOLATIONS,

  INSERT_VIOLATION,
  DELETE_VIOLATION,
  SET_IS_LOADING,

  DELETE_OWNER,
  UPDATE_OWNER,

  INSERT_NOTIFICATION,
  DELETE_NOTIFICATION,
  SET_ALL_NOTIFICATIONS

  //SET_IS_LOADING
  // SET_CURRENT_DEALS,
  // SET_DOMAIN_DATA
} from "./parkingtypes";

const requestHandler = (request) => {
  let nd = new Date();
  let browserdate = nd.toString(); //'browser date';
  request.headers['browserdate'] = browserdate;
  return request
}

axios.interceptors.request.use(
  request => requestHandler(request)
)


export const clearStatusMessage = () => dispatch => {
  dispatch({ type: CLEAR_STATUS_MESSAGE });
};

export const setStatusMessage = (msg) => dispatch => {
  dispatch({
    type: SET_STATUS_MESSAGE,
    payload: { message: msg }
  });
}





//******************************************************
//******************************************************
// PARKING METHODS
//******************************************************
//******************************************************


const getCurrentDateString = () => {
  return getTodayAsString(); // dstr;
}


// OwnerVehicles
export const getVehiclesForOwner = (ownerid, carownertype) => dispatch => {
  //  console.log("getVehiclesForOwner in actions ", ownerid);

  let dstr = getCurrentDateString();
  //console.log('current date string is', dstr);


  let link = "/api/parking/get_vehicles_for_owner";
  let qobj = {};
  qobj['ownerid'] = ownerid;
  qobj['carownertype'] = carownertype;
  qobj['hoaid'] = sessionStorage.getItem('hoaid');
  let renter_free_parking = sessionStorage.getItem('renter_free_parking');
  
  qobj['dstr'] = dstr;
  dispatch({
    type: SET_IS_LOADING,
    payload: { isloading: true }
  });
  axios
    .post(link, qobj)
    .then(res => {
      dispatch({ type: CLEAR_ERRORS });
      dispatch({ type: SET_CURRENT_VEHICLES_OWNER, payload: {'data':res.data,'freespots':renter_free_parking} });
      dispatch({
        type: SET_IS_LOADING,
        payload: { isloading: false }
      });
      // history.push("/dashboard");
    })
    // thunk lets us do a dispatch
    // .then(res => console.log(res.data))
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
      dispatch({
        type: SET_IS_LOADING,
        payload: { isloading: false }
      });
    }
    );
};


// AdminVehicles
export const getAllVehicles = () => dispatch => {
  // console.log("getAllVehicles in actions ");
  let link = "/api/parking/get_all_vehicles";
  let qobj = {};
  qobj['hoaid'] = sessionStorage.getItem('hoaid');
  //console.log('we are in parking actions get all vehicles session is ', sessionStorage.getItem('hoaid'));
  // let qobj = {};
  // qobj['ownerid'] = ownerid;
  dispatch({
    type: SET_IS_LOADING,
    payload: { isloading: true }
  });
  axios
    .post(link, qobj)
    .then(res => {
      dispatch({ type: CLEAR_ERRORS });
      // dispatch({
      //   type: SET_STATUS_MESSAGE,
      //   payload: { message: "Accommodation Update Successful" }
      // });
      dispatch({ type: SET_ALL_VEHICLES, payload: res.data });
      dispatch({
        type: SET_IS_LOADING,
        payload: { isloading: false }
      });
      // history.push("/dashboard");
    })
    // thunk lets us do a dispatch
    // .then(res => console.log(res.data))
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
      dispatch({
        type: SET_IS_LOADING,
        payload: { isloading: false }
      });
    }
    );
};

// AdminActiveVehicles
export const getAllActiveVehicles = () => dispatch => {
  let link = "/api/parking/get_active_vehicles";

  let dstr = getCurrentDateString();
  let qobj = {};
  qobj['hoaid'] = sessionStorage.getItem('hoaid');
  // console.log('we are in parking actions getAllActiveVehicles session is ', sessionStorage.getItem('hoaid'));
  qobj['dstr'] = dstr;
  // let qobj = {};
  // qobj['ownerid'] = ownerid;
  dispatch({
    type: SET_IS_LOADING,
    payload: { isloading: true }
  });
  axios
    .post(link, qobj)
    .then(res => {
      dispatch({ type: CLEAR_ERRORS });
      // dispatch({
      //   type: SET_STATUS_MESSAGE,
      //   payload: { message: "Accommodation Update Successful" }
      // });
      dispatch({ type: SET_ALL_VEHICLES, payload: res.data });
      dispatch({
        type: SET_IS_LOADING,
        payload: { isloading: false }
      });
      // history.push("/dashboard");
    })
    // thunk lets us do a dispatch
    // .then(res => console.log(res.data))
    .catch(err => {

      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
      dispatch({
        type: SET_IS_LOADING,
        payload: { isloading: false }
      });

    }
    );
};

// VehicleFormOwner PayForParkingForm
export const insertVehicle = (vehicledata, history) => dispatch => {
  // console.log("insertVehicle  in actions ", vehicledata);
  vehicledata['hoaid'] = sessionStorage.getItem('hoaid');
  // dispatch({
  //   type: SET_IS_LOADING,
  //   payload: { isloading: true }
  // });
  let dstr = getCurrentDateString();
  vehicledata['startdate'] = dstr;
  vehicledata['dstr'] = dstr;

  axios
    .post("/api/parking/insert_vehicle", vehicledata)
    .then(res => {
      // console.log('in the action res.data is', res.data);
      dispatch({
        type: SET_IS_LOADING,
        payload: { isloading: false }
      });
      dispatch({ type: CLEAR_ERRORS });
      dispatch({
        type: SET_STATUS_MESSAGE,
        payload: { message: "Vehicle Insert Successful" }
      });
      dispatch({ type: INSERT_VEHICLE, payload: res.data });
    })
    .catch(err => {
      dispatch({
        type: SET_STATUS_MESSAGE,
        payload: { message: "Vehicle Insert Failed" }
      })
    });
};

// VehicleFormOwner PayForParkingForm
export const modifyVehicle = (cardata, history) => dispatch => {
  // console.log("modifyVehicle  in actions ", cardata);
  // for (var pair of cardata.entries()) {
  //   console.log('actions ', pair[0] + ', ' + pair[1]);
  // }
  let dstr = getCurrentDateString();

  cardata['dstr'] = dstr;
  axios
    .post("/api/parking/modify_vehicle", cardata)
    .then(res => {
      dispatch({ type: CLEAR_ERRORS });
      dispatch({
        type: SET_STATUS_MESSAGE,
        payload: { message: "Vehicle Update Successful" }
      });
      //let po = {};
      //console.log('actions update return data ', res.data);
      //  po['resdata'] = res.data;
      // po['updateobj'] = accomData;
      dispatch({ type: MODIFY_VEHICLE, payload: res.data });
      // history.push("/dashboard");
    })
    // thunk lets us do a dispatch
    // .then(res => console.log(res.data))
    .catch(err => {
      // console.log('WE HAVE A DISPATCH ERROR');
      // console.log(err);
      // dispatch({
      //   type: GET_ERRORS,
      //   payload: err.response.data
      // })
      dispatch({
        type: SET_STATUS_MESSAGE,
        payload: { message: "Vehicle Update Failed" }
      })
    }
    );
};

// AdminActiveVehicles AdminVehicles OwnerVehicles
/*
export const deleteVehicle = (vid, history) => dispatch => {
  // console.log("deleteVehicle  in actions ", vid);
  let qobj = {};
  qobj['vehicleid'] = vid;
  axios
    .post("/api/parking/delete_vehicle", qobj)
    .then(res => {
      dispatch({ type: CLEAR_ERRORS });
      dispatch({
        type: SET_STATUS_MESSAGE,
        payload: { message: "Vehicle Delete Successful" }
      });
      // let po = {};
      dispatch({ type: DELETE_VEHICLE, payload: res.data });
      // history.push("/dashboard");
    })
    // thunk lets us do a dispatch
    // .then(res => console.log(res.data))
    .catch(err => {
      // console.log('WE HAVE A DISPATCH ERROR');
      // console.log(err);
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    }
    );
};
*/
export const deleteVehicleModifyStatus = (vid, hoaid,unitnumber, history) => dispatch => {
 
  
  let qobj = {};
  qobj['vehicleid'] = vid;
  qobj['hoaid'] = hoaid;
  qobj['unitnumber'] = unitnumber;

// console.log('parkingActions deleteVehicleModifyStatus',qobj)
  axios
    .post("/api/parking/delete_vehicle_modify_status", qobj)
    .then(res => {
      dispatch({ type: CLEAR_ERRORS });
      dispatch({
        type: SET_STATUS_MESSAGE,
        payload: { message: "Vehicle Delete Successful" }
      });
      dispatch({ type: DELETE_VEHICLE, payload: res.data });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    }
    );
};

// HoaSettings
export const softDeleteVehicles = (submitdata) => dispatch => {
  // console.log("softDeleteVehicles  in actions ", vid);


  axios
    .post("/api/parking/soft_delete_vehicles", submitdata)
    .then(res => {
      dispatch({ type: CLEAR_ERRORS });
      dispatch({
        type: SET_STATUS_MESSAGE,
        payload: { message: "Vehicles Soft Delete Successful" }
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    }
    );
};

// HoaSettings
export const hardDeleteVehicles = (history) => dispatch => {
  // console.log("hardDeleteVehicles  in actions ", vid);
  let qobj = {};

  axios
    .post("/api/parking/hard_delete_vehicles", qobj)
    .then(res => {
      dispatch({ type: CLEAR_ERRORS });
      dispatch({
        type: SET_STATUS_MESSAGE,
        payload: { message: "Vehicles Delete Successful" }
      });
      // let po = {};
      // dispatch({ type: HARD_DELETE_VEHICLES, payload: res.data });
      // history.push("/dashboard");
    })
    // thunk lets us do a dispatch
    // .then(res => console.log(res.data))
    .catch(err => {
      // console.log('WE HAVE A DISPATCH ERROR');
      // console.log(err);
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    }
    );
};

// AdminOwners
export const getAllOwners = () => dispatch => {
  //console.log("modify advertisement in actions ", advertisementData);
  let link = "/api/parking/get_all_owners";
  //let qobj = {};
  // qobj['ownerid'] = ownerid;
  let qobj = {};
  qobj['hoaid'] = sessionStorage.getItem('hoaid');
  dispatch({
    type: SET_IS_LOADING,
    payload: { isloading: true }
  });
  axios
    .post(link, qobj)
    .then(res => {
      dispatch({ type: CLEAR_ERRORS });
      // dispatch({
      //   type: SET_STATUS_MESSAGE,
      //   payload: { message: "Accommodation Update Successful" }
      // });
      dispatch({ type: SET_CURRENT_OWNERS, payload: res.data });
      dispatch({
        type: SET_IS_LOADING,
        payload: { isloading: false }
      });
      // history.push("/dashboard");
    })
    // thunk lets us do a dispatch
    // .then(res => console.log(res.data))
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
      dispatch({
        type: SET_IS_LOADING,
        payload: { isloading: false }
      });
    }
    );
};

//AdminModifyOwner
export const updateOwner = (userData) => (dispatch) => {
  // console.log('in action update owner data in ', userData);
  axios
    .post("/api/parking/updateowner", userData)
    .then((res) => {
      dispatch({ type: UPDATE_OWNER, payload: userData });
      dispatch({
        type: SET_STATUS_MESSAGE,
        payload: { message: 'Update Owner Success' }
      });
    })
    .catch((err) =>
      dispatch({
        type: SET_STATUS_MESSAGE,
        payload: { message: "Update Owner Failure" }
      })
      // dispatch({
      //   type: GET_ERRORS,
      //   payload: err.response.data,
      // })
    );
};


// AdminOwners  OwnerProfile
export const deleteOwner = (oid) => (dispatch) => {
  let qobj = {};
  qobj['userid'] = oid;
  axios
    .post("/api/users/deleteowner", qobj)
    .then((res) => {
      dispatch({ type: DELETE_OWNER, payload: oid });
      dispatch({
        type: SET_STATUS_MESSAGE,
        payload: { message: 'Owner Deleted' }
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// export const loadDictionary = () => dispatch => {
//   //console.log("loadDictionary in actions ");
//   let link = "/api/parking/dictionary";
//   axios
//     .get(link)
//     .then(res => {
//       dispatch({ type: CLEAR_ERRORS });
//       // console.log(res.data);

//       dispatch({ type: SET_DICTIONARY, payload: res.data });
//       // history.push("/dashboard");
//     })
//     // thunk lets us do a dispatch
//     // .then(res => console.log(res.data))
//     .catch(err =>
//       dispatch({
//         type: GET_ERRORS,
//         payload: err.response.data
//       })
//     );
// };


/* */


export const showStatusMessage = (message) => dispatch => {

  dispatch({
    type: SET_STATUS_MESSAGE,
    payload: { message: message }
  });

};

/*
export const dispatchData = (which, data) => dispatch => {

  if (which === 'INSERT_VEHICLE') {
    dispatch({ type: INSERT_VEHICLE, payload: data });
    // dispatch({
    //   type: SET_STATUS_MESSAGE,
    //   payload: { message: message }
    // });
  }
  if (which === 'STATUS_MESSAGE') {
    dispatch({ type: SET_STATUS_MESSAGE, payload: { message: data } });
  }

};
*/

//VehicleOnsite
export const changeVehicleStatusDate = (vid, status, enddate) => dispatch => {
  // console.log("modify changeVehicleStatusDate in actions ", advertisementData);
  let dstr = getCurrentDateString();
  let link = `/api/parking/change-vehicle-status-date/${vid}/${status}/${enddate}/${dstr}`;
  // console.log("parkingactions changeVehicleStatusDate", link);
  let rdata = { vid: vid, status: status, enddate: enddate ,curdate: dstr};
  axios
    .get(link)
    .then(res => {
      dispatch({ type: CLEAR_ERRORS });
      dispatch({
        type: SET_STATUS_MESSAGE,
        payload: { message: "Vehicle Status Update Successful" }
      });
      dispatch({ type: CHANGE_VEHICLE_STATUS_DATE, payload: rdata });
    })
    // thunk lets us do a dispatch
    // .then(res => console.log(res.data))
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

/*
export const changeVehicleStatus = (vid, status) => dispatch => {
  //console.log("modify advertisement in actions ", advertisementData);
  let link = `/api/parking/change-vehicle-status/${vid}/${status}`;
  //console.log("adveractions changebusinesssttus", link);
  let rdata = { vid: vid, status: status };
  axios
    .get(link)
    .then(res => {
      dispatch({ type: CLEAR_ERRORS });
      dispatch({
        type: SET_STATUS_MESSAGE,
        payload: { message: "Vehicle Status Update Successful" }
      });
      dispatch({ type: CHANGE_VEHICLE_STATUS, payload: rdata });
    })
    // thunk lets us do a dispatch
    // .then(res => console.log(res.data))
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};
*/

/*
export const changeAdminVehicleStatus = (vid, status) => dispatch => {
  //console.log("modify advertisement in actions ", advertisementData);
  let link = `/api/parking/change-vehicle-status/${vid}/${status}`;
  //console.log("adveractions changebusinesssttus", link);
  let rdata = { vid: vid, status: status };
  axios
    .get(link)
    .then(res => {
      dispatch({ type: CLEAR_ERRORS });
      dispatch({
        type: SET_STATUS_MESSAGE,
        payload: { message: "Vehicle Status Update Successful" }
      });
      dispatch({ type: CHANGE_ADMIN_VEHICLE_STATUS, payload: rdata });
    })
    // thunk lets us do a dispatch
    // .then(res => console.log(res.data))
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

*/
// Violations
export const getAllViolations = () => dispatch => {
  let link = "/api/parking/get_all_violations";
  let qobj = {};
  qobj['hoaid'] = sessionStorage.getItem('hoaid');
  // console.log('we are in parking actions get all vehicles session is ', sessionStorage.getItem('hoaid'));
  // let qobj = {};
  // qobj['ownerid'] = ownerid;
  dispatch({
    type: SET_IS_LOADING,
    payload: { isloading: true }
  });
  axios
    .post(link, qobj)
    .then(res => {
      dispatch({ type: CLEAR_ERRORS });
      // dispatch({
      //   type: SET_STATUS_MESSAGE,
      //   payload: { message: "Accommodation Update Successful" }
      // });
      dispatch({ type: SET_ALL_VIOLATIONS, payload: res.data });
      dispatch({
        type: SET_IS_LOADING,
        payload: { isloading: false }
      });
      // history.push("/dashboard");
    })
    // thunk lets us do a dispatch
    // .then(res => console.log(res.data))
    .catch(err => {
      dispatch({
        type: SET_IS_LOADING,
        payload: { isloading: false }
      });

      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    }
    );
};

// RecordViolation
export const insertViolation = (vdata, history) => dispatch => {
  // console.log("insertAccommodation  in actions ", accomdata);
  // dispatch({
  //   type: SET_IS_LOADING,
  //   payload: { isloading: true }
  // });
  axios
    .post("/api/parking/insert_violation", vdata)
    .then(res => {
      // dispatch({
      //   type: SET_IS_LOADING,
      //   payload: { isloading: false }
      // });
      dispatch({ type: CLEAR_ERRORS });
      dispatch({
        type: SET_STATUS_MESSAGE,
        payload: { message: "Violation Insert Successful" }
      });
      dispatch({ type: INSERT_VIOLATION, payload: res.data });
    })
    .catch(err => {
      dispatch({
        type: SET_STATUS_MESSAGE,
        payload: { message: "Violation Insert Failed" }
      })
      // ;
      // dispatch({
      //   type: GET_ERRORS,
      //   payload: err.response.data
      // })
    }
    );
};


// Violations
export const deleteViolation = (vid, history) => dispatch => {
  // console.log("deleteViolation  in actions ", vid);
  let qobj = {};
  qobj['violationid'] = vid;


  axios
    .post("/api/parking/delete_violation", qobj)
    .then(res => {
      dispatch({ type: CLEAR_ERRORS });
      dispatch({
        type: SET_STATUS_MESSAGE,
        payload: { message: "Violation Delete Successful" }
      });
      // let po = {};
      dispatch({ type: DELETE_VIOLATION, payload: res.data });
      // history.push("/dashboard");
    })
    // thunk lets us do a dispatch
    // .then(res => console.log(res.data))
    .catch(err => {
      // console.log('WE HAVE A DISPATCH ERROR');
      // console.log(err);
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    }
    );
};

// AdminNotifications
export const getAllNotifications = () => dispatch => {
  let link = "/api/parking/get_all_notifications";
  let qobj = {};
  qobj['hoaid'] = sessionStorage.getItem('hoaid');
  // console.log('we are in parking actions get all vehicles session is ', sessionStorage.getItem('hoaid'));
  // let qobj = {};
  // qobj['ownerid'] = ownerid;
  dispatch({
    type: SET_IS_LOADING,
    payload: { isloading: true }
  });
  axios
    .post(link, qobj)
    .then(res => {
      dispatch({ type: CLEAR_ERRORS });
      // dispatch({
      //   type: SET_STATUS_MESSAGE,
      //   payload: { message: "Accommodation Update Successful" }
      // });
      dispatch({ type: SET_ALL_NOTIFICATIONS, payload: res.data });
      dispatch({
        type: SET_IS_LOADING,
        payload: { isloading: false }
      });
      // history.push("/dashboard");
    })
    // thunk lets us do a dispatch
    // .then(res => console.log(res.data))
    .catch(err => {
      dispatch({
        type: SET_IS_LOADING,
        payload: { isloading: false }
      });

      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    }
    );
};


// ContactAdmin
export const insertNotification = (vdata, history) => dispatch => {

  axios
    .post("/api/parking/insert_notification", vdata)
    .then(res => {

      dispatch({ type: CLEAR_ERRORS });
      dispatch({
        type: SET_STATUS_MESSAGE,
        payload: { message: "Notification Insert Successful" }
      });
      dispatch({ type: INSERT_NOTIFICATION, payload: res.data });
    })
    .catch(err => {
      dispatch({
        type: SET_STATUS_MESSAGE,
        payload: { message: "Notification Insert Failed" }
      })

    }
    );
};

// AdminNotifications
export const deleteNotification = (vid, history) => dispatch => {
  //console.log("deleteNotification  in actions ", vid);
  let qobj = {};
  qobj['notificationid'] = vid;


  axios
    .post("/api/parking/delete_notification", qobj)
    .then(res => {
      dispatch({ type: CLEAR_ERRORS });
      dispatch({
        type: SET_STATUS_MESSAGE,
        payload: { message: "Notification Delete Successful" }
      });
      // let po = {};
      dispatch({ type: DELETE_NOTIFICATION, payload: res.data });
      // history.push("/dashboard");
    })
    // thunk lets us do a dispatch
    // .then(res => console.log(res.data))
    .catch(err => {
      // console.log('WE HAVE A DISPATCH ERROR');
      // console.log(err);
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    }
    );
};

// AdminUserCsvUpload
export const uploadUserCSV = (csvfile) => (dispatch) => {
  // dispatch({ type: CLEAR_ERRORS });
  // console.log("we are in action about to upload file");
  // console.log(mimeFile);
  // console.log("end of we are in action about to upload file");

  dispatch({
    type: SET_STATUS_MESSAGE,
    payload: "",
  });

  axios
    .post("/api/parking/uploadcsv", csvfile)
    // .then(res => history.push("/login"))
    // thunk lets us do a dispatch
    .then((res) => {
      //console.log("we have uploaded the file");
      // console.log(res.data);
      dispatch({
        type: SET_STATUS_MESSAGE,
        payload: { message: "Upload CSV Records Successful" }

      });
    })
    .catch((err) => {
      // console.log("about to dispatch error");
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};












