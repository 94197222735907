//import React, { Fragment, useState, useEffect } from "react";
import React, { Component } from "react";
import axios from "axios";
//import { withRouter } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
import ErrorMessageConstantClose from "../common/ErrorMessageConstantClose";
import LoadingMessage from "../common/LoadingMessage";

import { isitEmpty } from '../../validation/isitempty';



//import isEmpty from "../../../validation/is-empty";


//import ReactDOM from "react-dom";
//import Spinner from "../../common/Spinner";

import TextFieldGroupPlain from "../common/TextFieldGroupPlain";
//import { formatErrorMessage } from '../../timescale/helpers/AjaxHelper';

class ForgotPasswordForm extends Component {
  constructor(props) {
    super(props);
    //  console.log('login props are ', props);
    this.state = {
      email: "",
      showmessage: false,
      isloading: false,
      errors: {}
    }
  }


  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  submitResetPWRequest = (e) => {
    e.preventDefault();
    let { email } = this.state;
    let errors = {};

    if (isitEmpty(email)) {
      errors.email = "Email Required";
      this.setState({ errors: errors });
      return;
    }
    let eregex = "^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$";
    let isvalid = email.match(eregex);
    if (isvalid === null) {
      errors.email = "Invalid Email Format";
      this.setState({ errors: errors });
      return;

    }
    //console.log('isvalid email', isvalid);
    // 
    // this.setState({ [e.target.name]: e.target.value });
    const userData = {
      email: this.state.email,
      hoaid: sessionStorage.getItem('hoaid')
    };

    this.setState({ isloading: true });




    axios
      .post("/api/users/forgot_password", userData)
      .then((res) => {
        // console.log('forgot password', res);
        // alert('thank you');
        this.setState({ showmessage: true, isloading: false });
      })
      .catch((err) => {
        this.setState({ errors: err.response.data, isloading: false });
        //   console.log(JSON.stringify(err.response.data))
      }
      );

  }

  closeForm = (e) => {
    e.preventDefault();
    // console.log('going to close form');
    this.props.closeform();
  }

  closemessage = () => {
    this.setState({ showmessage: false });
    this.props.closeform();
    //let link = "/" + sessionStorage.getItem('hoaid'); //this.state.hoaid;
    //console.log('go to logon link is ', link);
    // this.props.history.push(link);
  }

  continueToPage = () => {
    //e.preventDefault();
    // console.log('goint to close form');
    //this.props.closeform();
  }




  /*
  http://www.hoaparkingsolutions.com/userverification/password/60a7f68d1301c900156a2eeb/YV

localhost:3000/userverification/password/60a80413fdb372120a8021d3/YV/jimvelasco
localhost:3000/userverification/verify/60a80413fdb372120a8021d3/YV/jimvelasco
  */

  render() {

    let { errors } = this.state;
    const perrors = this.props.errors;
    let { isloading } = this.state;
    let { showmessage } = this.state;
    // showmessage = true;

    // console.log('errors', errors);
    const okelement = <FontAwesomeIcon icon={faCheckCircle} color="#65598a" size="2x" title="enter / submit" />
    const cancelelement = <FontAwesomeIcon icon={faTimesCircle} color="black" size="2x" title="cancel / close" />

    let message = 'You will receive an email.  Follow the link to reset your password. Thank You.';


    return (




      <div className="bordernoshadow">
        {showmessage ? (
          <ErrorMessageConstantClose message={message} closemessage={this.closemessage} />
        ) : (null)}

        <div style={{ display: "flex", gap: "20px", flexDirection: "column" }}>
          <div className="row">
            <div className="col-md-12">
              {(isloading) ? (<LoadingMessage />) : null}
              <p>Please enter your email address</p>

              <div className="form-group">
                <TextFieldGroupPlain
                  type="text"
                  label="Email"
                  placeholder="email"
                  name="email"
                  value={this.state.email}
                  onChange={this.onChange}
                  error={errors.email}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 ">
              <span className="iconbuttonspan">
                <Link to=""
                  onClick={(e) => this.submitResetPWRequest(e)} >
                  {okelement}
                </Link>
              </span>
              <span className="iconbuttonspan">
                <Link to=""
                  onClick={(e) => this.closeForm(e)} >
                  {cancelelement}
                </Link>
              </span>
              {errors && errors['message'] ? (<div className="error-display">{errors['message']}</div>) : (null)}
              {perrors && perrors['message'] ? (<h2 className="error-display">{perrors['message']}</h2>) : (null)}

            </div>
          </div>


        </div>

      </div>
    );
  }
}

export default ForgotPasswordForm;

/*
expiresTAI: "1609459236"
expiresUTC: "2020-12-31"
jjv: "cragzop"
nextLeapTAI: "N/A"
nextLeapUTC: "N/A"
previousLeapTAI: "1498867237"
previousLeapUTC: "2017-07-01"
*/


