import React, { Component } from "react";
//import PropTypes from "prop-types";
import { Link } from "react-router-dom";
//import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';



import { connect } from "react-redux";
//import isEmpty from "../../validation/is-empty";


//import Spinner from "../common/Spinner";
//import TermsAndConditions from "./TermsAndConditions";

//
//import TextFieldGroup from "../../common/TextFieldGroup";
import TextFieldGroupPlain from "../common/TextFieldGroupPlain";
import SelectNameValuePlain from "../common/SelectNameValuePlain";
//import CheckboxGroup from "../common/CheckboxGroup";

import { updateOwner, showStatusMessage } from "../../actions/parkingActions";
import { changePassword } from "../../actions/authActions";

import { validateModifyOwnerInput } from "../../validation/client_modifyowner";
import { getTodayAsString } from "../parking/DateHelper";






class AdminModifyOwner extends Component {
  constructor(props) {
    super(props);


    this.state = {
      owner_id: "",
      owner_email: "",
      owner_name: "",
      owner_fname: "",
      owner_lname: "",
      owner_phone: "",
      owner_pincode: "",
      owner_password: "",
      parking_allowed: 5,
      owner_free_parking: 0,
      parking_allowed_renter: 2,
      renter_free_parking: 0,
      status_flag: 1,
      hoaid: sessionStorage.getItem('hoaid'),
      owner: null,
      errors: {},
    };



    this.onChange = this.onChange.bind(this);
    // this.closeform = this.onChange.bind(this);


  }



  static getDerivedStateFromProps(props, state) {
    if (props.owner !== state.owner) {
      return {
        owner: props.owner
      }
    }
    return null;
  }

  componentDidMount() {
    // console.log('admin modify owner cdm');
    this.loadState(this.props);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.owner !== this.state.owner) {
      this.loadState(this.props);
    }
  }




  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  // handleChange(event) {
  //   this.setState({value: event.target.value});
  // }

  loadState = (props) => {
    // console.log('owner', props.owner);
    this.setState(
      {
        owner_id: props.owner._id,
        owner_email: props.owner.email,
        owner_name: props.owner.name,
        owner_fname: props.owner.first_name,
        owner_lname: props.owner.last_name,
        owner_phone: props.owner.phone,
        owner_pincode: props.owner.pincode,
        parking_allowed: props.owner.parking_allowed,
        parking_allowed_renter: props.owner.parking_allowed_renter,
        owner_free_parking: props.owner.owner_free_parking,
        renter_free_parking: props.owner.renter_free_parking,
        status_flag: parseInt(props.owner.status_flag)
      });
  }



  onSubmit(e) {
    e.preventDefault();
    let submitdata = {};
    submitdata['owner_id'] = this.state.owner_id;
    submitdata['owner_email'] = this.state.owner_email;
    submitdata['owner_name'] = this.state.owner_name;
    submitdata['owner_fname'] = this.state.owner_fname;
    submitdata['owner_lname'] = this.state.owner_lname;
    submitdata['owner_phone'] = this.state.owner_phone;
    submitdata['owner_pincode'] = this.state.owner_pincode;
    submitdata['parking_allowed'] = this.state.parking_allowed;
    submitdata['parking_allowed_renter'] = this.state.parking_allowed_renter;
    submitdata['owner_free_parking'] = this.state.owner_free_parking;
    submitdata['renter_free_parking'] = this.state.renter_free_parking;
    submitdata['status_flag'] = this.state.status_flag;
    submitdata['hoaid'] = this.state.hoaid;


    // console.log(submitdata);

    const { errors, isValid } = validateModifyOwnerInput(submitdata);
    if (!isValid) {
      this.setState({ errors: errors });
      return;
    }
    this.props.updateOwner(submitdata);
    setTimeout(() => {
      this.props.closeform();
    }, 3000);
  }

  onSubmitPW(e) {
    e.preventDefault();
    // console.log('submit password', this.state);
    const userdata = {
      userid: this.state.owner_id,
      password: this.state.owner_password,
      password2: this.state.owner_password
    };
    if (this.state.owner_password.length === 0) {
      let error = {};
      error['owner_password'] = 'Password cannot be empty';
      this.setState({ errors: error });
      return;
    }
    this.props.changePassword(userdata);
  }







  closeForm = (e) => {
    e.preventDefault();
    // console.log('goint to close form');
    this.props.closeform();
  }



  render() {
    // const { errors } = this.state;
    const perrors = this.props.errors;
    const errors = this.state.errors;
    const { datasubmitted } = this.state;

    // console.log('this.props', this.props);
    let isloading = this.props.parking.isloading;






    const okelement = <FontAwesomeIcon icon={faCheckCircle} color="#65598a" size="2x" title="enter / submit" />
    const cancelelement = <FontAwesomeIcon icon={faTimesCircle} color="black" size="2x" title="cancel / close" />

    let rentchoices = [{ name: "Available for rent", value: 1 }, { name: "NOT available for rent", value: 0 }];

    return (
      <div className="register" >
        <div style={{ marginBottom: "15px" }}>
          <p className=" text-center">Modify Owner</p>
          <form >
            <div className="row ">
              <div className="col-sm-5">
                Email
              </div>
              <div className="col-sm-7">
                <TextFieldGroupPlain
                  type="text"
                  placeholder="Email"
                  name="owner_email"
                  value={this.state.owner_email}
                  onChange={this.onChange}
                  error={errors.owner_email}
                />
              </div>
            </div>
            <div className="row ">
              <div className="col-sm-5">
                First Name
              </div>
              <div className="col-sm-7">
                <TextFieldGroupPlain
                  type="text"
                  placeholder="First Name"
                  name="owner_fname"
                  value={this.state.owner_fname}
                  onChange={this.onChange}
                  error={errors.owner_fname}
                />
              </div>
            </div>
            <div className="row ">
              <div className="col-sm-5">
                Last Name
              </div>
              <div className="col-sm-7">
                <TextFieldGroupPlain
                  type="text"
                  placeholder="Last Name"
                  name="owner_lname"
                  value={this.state.owner_lname}
                  onChange={this.onChange}
                  error={errors.owner_lname}
                />
              </div>
            </div>
            <div className="row ">
              <div className="col-sm-5">
                Phone
              </div>
              <div className="col-sm-7">
                <TextFieldGroupPlain
                  type="text"
                  placeholder="Phone"
                  name="owner_phone"
                  value={this.state.owner_phone}
                  onChange={this.onChange}
                  error={errors.owner_phone}
                />
              </div>
            </div>



            <div className="row ">
              <div className="col-sm-5">
                Pincode
              </div>
              <div className="col-sm-7">
                <TextFieldGroupPlain
                  type="text"
                  placeholder="Pincode"
                  name="owner_pincode"
                  value={this.state.owner_pincode}
                  onChange={this.onChange}
                  error={errors.owner_pincode}
                />
              </div>
            </div>

            <div className="row ">
              <div className="col-sm-5">
                Unit Parking
              </div>
              <div className="col-sm-7">
                <TextFieldGroupPlain
                  type="text"
                  placeholder="Parking"
                  name="parking_allowed"
                  value={this.state.parking_allowed}
                  onChange={this.onChange}
                  error={errors.parking_allowed}
                />
              </div>
            </div>

            <div className="row ">
              <div className="col-sm-5">
                Unit Free Parking
              </div>
              <div className="col-sm-7">
                <TextFieldGroupPlain
                  type="text"
                  placeholder="Free Parking"
                  name="owner_free_parking"
                  value={this.state.owner_free_parking}
                  onChange={this.onChange}
                  error={errors.owner_free_parking}
                />
              </div>
            </div>

            <div className="row ">
              <div className="col-sm-5">
                Renter Parking
              </div>
              <div className="col-sm-7">
                <TextFieldGroupPlain
                  type="text"
                  placeholder="GuestParking"
                  name="parking_allowed_renter"
                  value={this.state.parking_allowed_renter}
                  onChange={this.onChange}
                  error={errors.parking_allowed_renter}
                />
              </div>
            </div>

            <div className="row ">
              <div className="col-sm-5">
                Renter Free Parking
              </div>
              <div className="col-sm-7">
                <TextFieldGroupPlain
                  type="text"
                  placeholder="Free Parking"
                  name="renter_free_parking"
                  value={this.state.renter_free_parking}
                  onChange={this.onChange}
                  error={errors.renter_free_parking}
                />
              </div>
            </div>

            <div className="row ">
              <div className="col-sm-5">
                Availability
              </div>
              <div className="col-sm-7">
                <SelectNameValuePlain
                  label="Availability"
                  list={rentchoices}
                  name="status_flag"
                  value={this.state.status_flag}
                  onChange={this.onChange}
                  error={errors.status_flag}
                />
              </div>

            </div>


          </form>
          <div className="row">
            <div className="col-sm-12 ">
              <span className="iconbuttonspan">

                <Link to=""
                  onClick={(e) => this.onSubmit(e)} >{okelement}</Link></span>
              <span className="iconbuttonspan">

                <Link to=""
                  onClick={(e) => this.closeForm(e)} >{cancelelement}</Link>
              </span>
            </div>
          </div>

          {perrors && (
            <div className="error-display">{perrors.message}</div>
          )}

          <hr />

          <div style={{ marginBottom: "15px" }}>
            <p className=" text-center">Reset Password</p>
            <form >
              <div className="row ">
                <div className="col-sm-5">
                  Password
                </div>
                <div className="col-sm-7">
                  <TextFieldGroupPlain
                    type="password"
                    placeholder="Password"
                    name="owner_password"
                    value={this.state.owner_password}
                    onChange={this.onChange}
                    error={errors.owner_password}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 ">
                  <span className="iconbuttonspan">

                    <Link to=""
                      onClick={(e) => this.onSubmitPW(e)} >{okelement}</Link></span>

                </div>
              </div>
            </form>



          </div>

        </div>

      </div >



    );
  }





  // this is good practice because it will help debugging
  // it is not checked when in production mode.
  // Business.propTypes = {
  //   createBusiness: PropTypes.func.isRequired,
  //   modifyBusiness: PropTypes.func.isRequired,
  //   auth: PropTypes.object.isRequired,
  //   errors: PropTypes.object.isRequired
};

// map errors to this component
// then we can use this.props.auth.user etc
// since we mapped error, we can use componentWillReceiveProps method
const mapStateToProps = state => ({
  auth: state.auth,
  parking: state.parking,
  errors: state.errors,
});
// the state.auth above comes from rootReducer in index.js in reducers.

export default connect(
  mapStateToProps,
  { updateOwner, changePassword, showStatusMessage }
)((AdminModifyOwner));
// wrap the Register with withRouter so the authAction can use history to redirect
