import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'


import SectionParking from "./SectionParking";

function SectionContact(props) {

  //export default (props) => {



  let shoastr = sessionStorage.getItem('selectedhoa');

  if (!shoastr) {
    return (null)
  }

  //console.log('props', props);


  let ahoa = JSON.parse(shoastr);
  //console.log('section contact ahoa', ahoa);

  // let hoa_phone_cell = ahoa['hoa_phone_cell'];
  // let hoa_phone_office = ahoa['hoa_phone_office'];
  // let hoa_email = ahoa['hoa_email'];

  // let pm_phone_cell = ahoa['pm_phone_cell'];
  // let pm_phone_office = ahoa['pm_phone_office'];
  // let pm_email = ahoa['pm_email'];

  // let webmaster_email = ahoa['webmaster_email'];

  let pnumbers = ahoa['contact_information'];

  // pnumbers.sort(function (a, b) {
  //   if (a['sort_order'] < b['sort_order']) return 1;
  //   if (a['sort_order'] > b['sort_order']) return -1;
  //   if (a['sort_order'] === b['sort_order']) return 0;
  // })



  let rdata = [];

  // let nowdate = new Date();
  // console.log('nowdate is ', nowdate);

  // let bnowdate = new Date(0);
  // console.log('bnowdate is ', bnowdate);

  // let cnowdate = new Date(Date.UTC(1970, 0, 1));
  // console.log('cnowdate is ', cnowdate);

  let category = 'All';

  if (props.which === 'Renter') {
    category = 'Renter'
  }
  if (props.which === 'Owner') {
    category = 'Owner'
  }

  // console.log('section contact which category', props.which, category);

  pnumbers.forEach((pnum, index) => {

    let pn = pnum['phone_number'];
    let em = pnum['email'];
    let pndisplay = null;
    let emdisplay = null;
    if (pn.length > 0) pndisplay = (<div>{pn}</div>);
    if (em.length > 0) emdisplay = (<div>
      <a href={`mailto:${em}`}>{em}</a>
    </div>
    );


    if (((pnum['display_category'] === 'All') || (pnum['display_category'] === category))) {
      rdata.push(
        <li key={index + 1000} style={{ marginBottom: "10px" }} >
          <div ><b>{pnum['phone_description']}</b></div>
          {pndisplay}
          {emdisplay}
        </li>)
    }
  });

  const closeForm = (e) => {
    e.preventDefault();
    props.closeform('contact');
  }

  // const cancelicon = <FontAwesomeIcon icon={faTimesCircle} color="black" size="lg" />
  const cancelicon = <FontAwesomeIcon icon={faTimesCircle} color="black" size="lg" title="cancel / close" />


  return (
    <div style={{ fontSize: "10pt" }}>

      <div style={{ textAlign: "right" }}>
        <span className="iconbuttonspan">
          <Link to=""
            onClick={(e) => closeForm(e)} >
            {cancelicon}
          </Link>
        </span>
      </div>


      <h6><b>Contact Information</b></h6>
      <p>If you need assistance, please contact the appropriate resource.</p>


      <ul>
        {rdata}
      </ul>



      <SectionParking />

    </div>

  );
};

export default SectionContact;