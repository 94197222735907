
//const isEmpty = require("./is-empty");
//import { isEmpty } from './is-empty';

//const Validator = require("validator");
import { isitEmpty } from './isitempty';

export const validateRecordViolationInput = (data) => {
  let errors = {};
  // console.log('validateModifyVehicleInput data', data);
  // console.log('validateModifyVehicleInput data', data.carownername);
  // console.log('hard call ', isitEmpty('no'))

  data.violation_plate = !isitEmpty(data.violation_plate) ? data.violation_plate : "";
  data.violation_state = !isitEmpty(data.violation_state) ? data.violation_state : "";
  data.violation_location = !isitEmpty(data.violation_location) ? data.violation_location : "";


  if (isitEmpty(data.violation_plate)) {
    errors.violation_plate = "Plate field is required";
  }
  if (isitEmpty(data.violation_state)) {
    errors.violation_state = "State field is required";
  }
  if (isitEmpty(data.violation_location)) {
    errors.violation_location = "Location field is required";
  }


  return {
    errors,
    isValid: isitEmpty(errors)
  };
};




