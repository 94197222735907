import React from "react";

// import SectionGeneral from "./SectionGeneral";
// import SectionContact from "./SectionContact";
// import HelpNavigation from "./HelpNavigation";



//import ContactAdmin from "../ContactAdmin";



function HelpLanding() {





  // onCancelClick(e) {
  //   e.preventDefault();
  //   console.log('help app about to go back using history')
  //   this.props.history.goBack();
  //   //let url = "/" + sessionStorage.getItem('hoaid');
  //   // window.location.href = url;
  //   // this.props.history.push(url);
  //   //window.location = "/id/" + sessionStorage.getItem('hoaid');
  // }










  return (

    <div className="tenpoint bordernoshadow" >
      <h5 style={{ textAlign: "center" }}>
        HOA Parking Solutions
      </h5>
      <div style={{ textAlign: "center" }}>
        <p>Landing page about goes here</p>
        <p>Basic Contact Information</p>
      </div>







      {/* <h6><b>Registering Your Vehicles in the App</b></h6>
        <ul>

          <li>Owners must register every vehicle their guests or contractors have that are parked in the lots for more than 4 hours. </li>
          <li>Renters must register (under the owner’s unit number) every vehicle they have that may be parked in the lots.</li>
          <li>Unless permission is granted, no more than 2 vehicles registered under your unit number may be in the lots at any given time. </li>
          <li>Set the App’s ONSITE value to “Yes” or “No” as needed.</li>
          <li>Refer to the Help page to learn more about using the App.</li>
        </ul> */}



    </div>
  );
}


export default HelpLanding;
