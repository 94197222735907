import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";



import {
  GET_ERRORS,
  SET_CURRENT_USER,
  SET_STATUS_MESSAGE,
  CLEAR_ERRORS,
  SET_IS_LOADING,
  UPDATE_USER_NAME,
  UPDATE_USER_PIN,
  UPDATE_USER_PASSWORD,
  UPDATE_USER_AVAILABILITY,
  RULES_AND_REGS,
  INSERT_OWNER
} from "./parkingtypes";

// Register User
// we passed in the history for Register component so now we can redirect
export const emailverification_registerUser = (userData, history) => (dispatch) => {

  let link = "/" + userData.hoaid;
  console.log('go to logon link is ', link);

  axios
    .post("/api/users/register", userData)
    .then((res) => {

      dispatch({
        type: SET_STATUS_MESSAGE,
        // payload: { message: "Thank You.  You will receive an email.  Follow the link to verify your email address." }
        payload: { message: "Thank You.  User has been registered." }
      });



      history.push(link);
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// RegisterViaAdmin
export const registerUser = (userData, history) => (dispatch) => {

  let link = "/" + userData.hoaid;
  // console.log('go to logon link is ', link);

  axios
    .post("/api/users/register", userData)
    .then((res) => {

      dispatch({
        type: SET_STATUS_MESSAGE,
        // payload: { message: "Thank You.  You will receive an email.  Follow the link to verify your email address." }
        payload: { message: "Thank You.  User has been registered." }
      });
      dispatch({ type: INSERT_OWNER, payload: userData });

      // do not take them back to login screen.  only do this if we are email verifying
      //history.push(link);
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};


// Login - Get User Token

// Login
export const loginUser = (userData, navigate) => (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
  dispatch({
    type: SET_IS_LOADING,
    payload: { isloading: true }
  });
  //let token = '12345';
  //localStorage.setItem("jwtToken", token);
  //dispatch(setCurrentUser(userData));
  /*  */
  //  console.log('about to do login in authactions', userData);
  axios
    .post("/api/users/login", userData)
    .then((res) => {
      // Save to localStorage
      const { token } = res.data;
      const { role } = res.data;
      const { userid } = res.data;
      const { unitnumber } = res.data;
      const { name } = res.data;
      const { email } = res.data;
      // console.log('authjust logged in', role, userid, name);
      // console.log('in action auth actions resdata is ', res.data);
      // Set token to ls  The localStorage method is built into react framework.  no need to create our own
      localStorage.setItem("jwtToken", token);
      //console.log("user role is ", res.data.role);
      sessionStorage.setItem('primary_userid', userid);
      sessionStorage.setItem('alias_userid', userid);
      sessionStorage.setItem('userRole', role);
      sessionStorage.setItem('unitnumber', unitnumber);
      sessionStorage.setItem('username', name);
      sessionStorage.setItem('email', email);

      // console.log('userRole', role);

      // Set token to Auth header
      // token includes user information
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);
      // Set current user
      //console.log("decoded current user ", decoded);
      dispatch(setCurrentUser(decoded));
      dispatch({
        type: SET_IS_LOADING,
        payload: { isloading: false }
      });
      if (role === 'admin') {
        navigate("/admindashboard");
      }
      if (role === 'readonly') {
        navigate("/readonlyowners");
      }
      if (role === 'owner') {
        navigate("/dashboard");
      }
    })
    .catch((err) => {
      console.log('we have an error ', err);
      // console.log('error response login', err.response);
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
      dispatch({
        type: SET_IS_LOADING,
        payload: { isloading: false }
      });


      // dispatch({
      //   type: SET_STATUS_MESSAGE,
      //   payload: { message: 'Invalid Email and or Password' }
      // });
    }
    );

};

// RenterLogin
export const loginRenter = (userData, navigate) => (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
  dispatch({
    type: SET_IS_LOADING,
    payload: { isloading: true }
  });

  axios
    .post("/api/users/loginrenter", userData)
    .then((res) => {
      sessionStorage.setItem('userRole', 'renter');
      sessionStorage.setItem('primary_userid', res.data._id);
      sessionStorage.setItem('alias_userid', res.data._id);
      sessionStorage.setItem('unitnumber', res.data.unitnumber);
      sessionStorage.setItem('renter_free_parking', res.data.renter_free_parking);

     // console.log('renter login data', res.data)


      dispatch(setCurrentUser(res.data));
      dispatch({
        type: SET_IS_LOADING,
        payload: { isloading: false }
      });
      //  history.push("/renterdashboard");
      navigate("/renterdashboard");
    })
    .catch((err) => {
      console.log('we have an error', err.response.data);
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      dispatch({
        type: SET_IS_LOADING,
        payload: { isloading: false }
      });
      // dispatch({
      //   type: SET_STATUS_MESSAGE,
      //   payload: { message: err.response.data.message }
      // });

    }
    );

};

// OwnerProfile
export const changeName = (userData, history) => (dispatch) => {
  axios
    .post("/api/users/changename", userData)
    .then((res) => {
      dispatch({ type: UPDATE_USER_NAME, payload: userData });
      dispatch({
        type: SET_STATUS_MESSAGE,
        payload: { message: 'Change Name Success' }
      });
    })
    // thunk lets us do a dispatch
    // .then(res => console.log(res.data))
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

//AdminProfile
export const changeAdminName = (userData, history) => (dispatch) => {
  // console.log('actions user data', userData);
  axios
    .post("/api/users/changeadminname", userData)
    .then((res) => {
      dispatch({ type: UPDATE_USER_NAME, payload: res.data });
      dispatch({
        type: SET_STATUS_MESSAGE,
        payload: { message: 'Change Name Success' }
      });
    })
    // thunk lets us do a dispatch
    // .then(res => console.log(res.data))
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// OwnerProfile
export const changeAvailability = (userData, history) => (dispatch) => {
  // console.log('changeing ', userData);
  axios
    .post("/api/users/changeavailability", userData)
    .then((res) => {
      dispatch({ type: UPDATE_USER_AVAILABILITY, payload: res.data });
      dispatch({
        type: SET_STATUS_MESSAGE,
        payload: { message: 'Change Availability Success' }
      });
    })
    // thunk lets us do a dispatch
    // .then(res => console.log(res.data))
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// OwnerProfile
export const changePin = (userData) => (dispatch) => {
  // console.log('changing pin in actions', userData)
  axios
    .post("/api/users/changepin", userData)
    .then((res) => {
      dispatch({ type: UPDATE_USER_PIN, payload: res.data });
      dispatch({
        type: SET_STATUS_MESSAGE,
        payload: { message: 'Change PIN Success' }
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// AdminModifyOwner AdminProfile OwnerProfile
export const changePassword = (userData, history) => (dispatch) => {
  axios
    .post("/api/users/changepassword", userData)
    .then((res) => {
      dispatch({ type: UPDATE_USER_PASSWORD, payload: res.data });
      dispatch({
        type: SET_STATUS_MESSAGE,
        payload: { message: 'Change Password Success' }
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// OwnerDashboard OwnerProfile
export const changeRulesAndRegs = (userData, history) => (dispatch) => {
  axios
    .post("/api/users/changerulesandregs", userData)
    .then((res) => {
      dispatch({ type: RULES_AND_REGS, payload: userData['terms'] });
      dispatch({
        type: SET_STATUS_MESSAGE,
        payload: { message: 'Acknowledge Rules Success' }
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};










// Set logged in user
// this goes to reducer
// used internally
export const setCurrentUser = (decoded) => {
  //  console.log('setting current user ', decoded)
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

// Log user out
// Navbar
export const logoutUser = () => (dispatch) => {
  // Remove token from localStorage
  // console.log("logout user in auth actions");
  localStorage.removeItem("jwtToken");
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to {} which will set isAuthenticated to false
  //
  // looke at reducer
  //
  dispatch(setCurrentUser({}));
  // history.push("/");
};
