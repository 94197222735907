import React, { Component } from "react";
////import PropTypes from "prop-types";

import { Link } from "react-router-dom";
import LoadingMessage from "../common/LoadingMessage";
import ErrorMessageConstantClose from "../common/ErrorMessageConstantClose";
import { getTodayAsString,getOnsiteLabel, generateDateDisplay,getRowHighlightColor } from "../parking/DateHelper";


import { Button } from "react-bootstrap";

import SmartButtonHPS from "../common/SmartButtonHPS";





//import axios from "axios";


import { connect } from "react-redux";
import { getAllVehicles, showStatusMessage, deleteVehicleModifyStatus } from "../../actions/parkingActions";
//import { getActiveCounts } from './AjaxHelper';
//import msgHelper from '../../config/msgHelper';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faPlusCircle, faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";

import { confirmAlert } from 'react-confirm-alert';


import ParkingSummary from "../general/ParkingSummary";
import SelectNameValuePlain from "../common/SelectNameValuePlain";
import VehicleOnsite from "../general/VehicleOnsite";

//


//import axios from "axios";
//import classnames from "classnames";


//import TextFieldGroup from "../common/TextFieldGroupPlain";

class AdminVehicles extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      fname: "",
      lname: "",
      email: "",
      unitnumber: "",
      pincode: "",
      password: "",
      password2: "",
      selected_vehicle: null,
      showvehicleform: false,
      detailsdisplay: null,
      showoverlimit: false,
      showoverlimitmessage: "what",
      whichphoneview: 1,
      hoa: "Yampa View",


      vehicles_ary: [],
      whichsort: 'plate',
      sortdirection: 1,

      typefilter: 'any',
      unitfilter: 'any',
      onsitefilter: 'any',


      showonsiteform: false,
      onsite_vehicle: null,


      errors: {}
    };

    this.onChange = this.onChange.bind(this);
    this.onCBChange = this.onCBChange.bind(this);
    this.getData = this.getData.bind(this);

    // this.onSubmit = this.onSubmit.bind(this);
    // this.onSubmitPin = this.onSubmitPin.bind(this);

  }

  componentDidMount() {
    let viewnum = 1;
    if (window.innerWidth < 800) {
      viewnum = 2;
    }
    this.setState({ whichphoneview: viewnum });
    this.props.getAllVehicles();
  }

  // doGetVehicles = (oid) => {
  //   // let cid = this.props.auth.user.company_id;
  //   //   console.log('getting vehicles for ', oid);
  //   this.setState({ selected_vehicle: null, vehicles_ary: [], showvehicleform: false });
  //   this.props.getAllVehicles();
  // }


  // if we have errors this will run
  // componentWillReceiveProps(nextProps) {
  //   //console.log("register componentWillReceiveProps");
  //   if (nextProps.errors) {
  //     this.setState({ errors: nextProps.errors });
  //     // setState triggers a render
  //   }
  // }
  static getDerivedStateFromProps(props, state) {
    return {
      errors: props.errors
    };
  }

  //componentDidUpdate(prevProps) {
  // Typical usage (don't forget to compare props):
  // if (this.props.errors) {
  //   this.setState({ errors: this.props.errors });
  // }
  //}

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onCancelClick(e) {
    e.preventDefault();
    window.location = "/";
  }

  changeAdminVehicleStatus = (e, vehicle) => {
    //  console.log('user', this.props.auth.user);
    e.preventDefault();
    if (this.props.auth.user.crud === 'read') {
      alert('not authorized to change status');
    } else {
      this.setState({ showonsiteform: true, onsite_vehicle: vehicle });
    }
  };




  deleteTheVehicle = (vid,unitnumber) => {
    //  e.preventDefault();
    // console.log('delete vehicle', vid);
   //let unitnumber = sessionStorage.getItem('unitnumber');
    let hoaid = sessionStorage.getItem('hoaid');
   // console.log('admin vehicles unitnumber',unitnumber,'hoaid',hoaid);
    this.props.deleteVehicleModifyStatus(vid,hoaid,unitnumber);


  //  this.props.deleteVehicle(vid);
  }
  deleteVehicle = (e, v) => {
    e.preventDefault();
    let unitnumber = v['unitnumber'];

    if (this.props.auth.user.crud === 'read') {
      alert('not authorized to delete vehicle');
      return;
    }
    const okelement = <FontAwesomeIcon icon={faCheckCircle} color="black" size="2x" title="enter / submit" />
    const cancelelement = <FontAwesomeIcon icon={faTimesCircle} color="black" size="2x" title="cancel / close" />
    confirmAlert({
      customUI: ({ onClose }) => {
        let vid = v['_id'];
        let lbl = v['make'] + ' ' + v['plate'] + ' ' + v['plate_state'];
        //console.log('the vid is ', vid);
        return (
          <div className='confirmmessage' >
            <h6>Are you sure you want to delete?</h6>
            <p>{lbl}</p>
            <span className="iconbuttonspan"
              onClick={() => {
                this.deleteTheVehicle(vid,unitnumber);
                onClose();
              }
              }> {okelement}</span>
            <span className="iconbuttonspan"
              onClick={() => {
                onClose();
              }}
            >
              {cancelelement}
            </span>
          </div>
        );
      }
    });
  }

  doSort = (e, which) => {
    e.preventDefault();
    let sd = this.state.sortdirection;
    sd = sd * -1;
    this.setState({ whichsort: which, sortdirection: sd });
  }

  showOwnerDetails = (e, vehicle) => {
    e.preventDefault();
    let odetails = (
      <div style={{ marginTop: "10px" }}>
        <span className="mr-2">Name: <span style={{ fontSize: "10pt" }}>
          {vehicle['carowner_lname']}, {vehicle['carowner_fname']}</span></span>
        <span className="mr-2">Type: <span style={{ fontSize: "10pt" }}>{vehicle['carownertype']}</span></span>
        <span className="mr-2">Unit: <span style={{ fontSize: "10pt" }}>{vehicle['unitnumber']}</span></span>
        <span className="mr-2">Phone: <span style={{ fontSize: "10pt" }}>{vehicle['carownerphone']}</span></span>
        <br />
        <span className="mr-2">Make: <span style={{ fontSize: "10pt" }}>{vehicle['vehicle_type']} - {vehicle['make']}</span></span>
        <span className="mr-2">Model: <span style={{ fontSize: "10pt" }}>{vehicle['model']}</span></span>
        <span className="mr-2">Year: <span style={{ fontSize: "10pt" }}>{vehicle['year']}</span></span>
        <span className="mr-2">Checkout: <span style={{ fontSize: "10pt" }}>{vehicle['enddate']}</span></span>

      </div>
    );

    this.setState({ detailsdisplay: odetails });


  }


  togglePhoneView = (e, which) => {
    e.preventDefault();
    this.setState({ whichphoneview: which });
  }

  closemessage = () => {
    this.setState({ showoverlimit: false });
  }

  closeonsiteform = () => {
    // console.log('this is close form');
    this.setState({ showonsiteform: false, show: false });
  }


  onCBChange = (e) => {
    let tval = 0;
    let ischecked = e.target.checked;
    //   console.log('on cbchanged')
    if (ischecked) tval = 1;
    // this.setState({ termsaccepted: ischecked, terms: tval });
  }

  getData = (e) => {
    // console.log('getdata')
    let checkedels = [];
    let els = document.getElementsByClassName('checkboxclass');
    //console.log('ary', ary)
    Array.from(els).forEach(el => {
      //  console.log('ischecked', el.checked, ' id', el['id'])
      if (el.checked) {
        let parts = el['id'].split('_');
        checkedels.push(parts[1])
      }
    })
    // console.log('array positions checked is ', checkedels)
    let vehicles_ary = this.props.parking.current_vehicles_owner;
    checkedels.forEach(num => {
      let selitem = vehicles_ary[num]
      console.log('selitem', selitem)

    })

    this.sendSms()
  }

  sendSms = (nums) => {

    /* send to twillio */
    /*   bummer */

    // Download the helper library from https://www.twilio.com/docs/node/install
    // Set environment variables for your credentials
    // Read more at http://twil.io/secure
    /*
    const accountSid = "AC22559bf524ffb64e316de38cadeb82f2";
    const authToken = "d6ef484854536a17b6010914e355da7e";
    const client = require("twilio")(accountSid, authToken);

    client.messages
      .create({ body: "Hello from Twilio", from: "+13608034359", to: "+17209884142" })
      .then(message => console.log(message.sid));
*/

  }


  //  <input id={cbid} className="checkboxclass" type="checkbox" onChange={(e) => this.onCBChange(e)} />&nbsp;


  renderCarRecords = (vehicles_ary) => {
    let rdata = [];

    let dstr = getTodayAsString();
    vehicles_ary.forEach((vehicle, index) => {
      let edate = vehicle['enddate'];
      let sflag = vehicle['status_flag'].toString();
      let bcolor = getRowHighlightColor('warning',vehicle);
      // let onsite = 'No';
      // if (edate > dstr) {
      //   onsite = 'Yes';
      // }
      let onsite = getOnsiteLabel(edate, dstr,vehicle);
      let displaydate = generateDateDisplay(edate, dstr);
      let cbid = "arrayposition_" + index
      rdata.push(
        <div key={index + 1000} className="grid-container-10" style={{backgroundColor:bcolor}}>
          <div className="grid-item-normal">

            <Link to=""
              onClick={(e) => this.showOwnerDetails(e, vehicle)} >{vehicle.plate}</Link>
          </div>
          <div className="grid-item-normal">{vehicle.plate_state}</div>
          <div className="grid-item-normal">{vehicle.vehicle_type} - {vehicle.make}</div>
          <div className="grid-item-normal">{vehicle.model}</div>
          <div className="grid-item-normal">
            {vehicle['carowner_lname']}, {vehicle['carowner_fname']}
          </div>
          <div className="grid-item-normal">{vehicle.carownertype}</div>
          <div className="grid-item-normal">{vehicle.unitnumber}</div>
          <div className="grid-item-normal">
            {displaydate}
          </div>
          <div className="grid-item-normal">
            {vehicle.carownertype === 'renter' ? (<div>{onsite}</div>) : (
              <Link to=""
                onClick={e => {
                  this.changeAdminVehicleStatus(
                    e,
                    vehicle
                  );
                }}
              >
                {onsite}
              </Link>
            )}
          </div>
          <div className="grid-item-normal">
            <Link to=""
              onClick={(e) => this.deleteVehicle(e, vehicle)} >delete {sflag}</Link>
          </div>
        </div>
      )
    })

    let btn = (
      <SmartButtonHPS
        name="Notify Selected"
        buttonclass={"btn-basic"}
        onClick={(e) => this.getData(e)} />
    )
    return (<div>{rdata}</div>)
  }

  renderCarRecordsPhone = (vehicles_ary) => {
    let rdata = [];

    let dstr = getTodayAsString();

    vehicles_ary.forEach((vehicle, index) => {
      let bcolor = getRowHighlightColor('warning',vehicle);
      let edate = vehicle['enddate'];
      let displaydate = generateDateDisplay(edate, dstr);
      let onsite = getOnsiteLabel(edate, dstr,vehicle);
      rdata.push(


        <div className="bordershadow" key={index} style={{backgroundColor:bcolor}}>
          <div className="grid-item-bold">{index + 1}</div>
          <div className="grid-container-3" style={{ borderBottom: "0px solid black" }}>
            <div className="grid-item-bold"><b>Plate</b></div>
            <div className="grid-item-bold"><b>Name</b></div>
            <div className="grid-item-bold"><b>Onsite</b></div>

            <div className="grid-item-normal" style={{ fontWeight: "bold", fontSize: "10pt" }}>{vehicle.plate} - {vehicle.plate_state}</div>
            <div className="grid-item-normal">
              {vehicle['carowner_lname']}, {vehicle['carowner_fname']}
            </div>
            <div className="grid-item-normal">

              {vehicle.carownertype === 'renter' ? ("Yes") : (
                <Link to=""
                  onClick={e => {
                    this.changeAdminVehicleStatus(
                      e,
                      vehicle
                    );
                  }}
                >
                  {onsite}
                </Link>

              )}


            </div>


            <div className="grid-item-bold"><b>Make</b></div>
            <div className="grid-item-bold"><b>Model</b></div>
            <div className="grid-item-bold">Year</div>


            <div className="grid-item-normal"> {vehicle.vehicle_type} - {vehicle.make}</div>
            <div className="grid-item-normal"> {vehicle.model}</div>
            <div className="grid-item-normal">{vehicle.year}</div>






            <div className="grid-item-bold"><b>Phone</b></div>
            <div className="grid-item-bold"><b>Type</b></div>
            <div className="grid-item-bold"><b>Unit</b></div>


            <div className="grid-item-normal">{vehicle.carownerphone}</div>
            <div className="grid-item-normal"> {vehicle.carownertype}</div>
            <div className="grid-item-normal">{vehicle.unitnumber}</div>



            <div className="grid-item-bold"><b>Checkout</b></div>
            <div className="grid-item-bold">&nbsp;</div>

            <div className="grid-item-bold"><b>Action</b></div>




            <div className="grid-item-normal">{displaydate}</div>
            <div className="grid-item-normal">&nbsp;</div>


            <div className="grid-item-normal">
              <Link to=""
                onClick={(e) => this.deleteVehicle(e, vehicle)} >delete</Link>
            </div>
          </div>






        </div>
      )
    })
    return (<div>{rdata}</div>)
  }

  // hardDelete = (e) => {
  //   e.preventDefault();
  //   //console.log('hard delete vehicles');
  //   this.props.hardDeleteVehicles();
  // }


  render() {
    const { errors } = this.state;
    const { showoverlimit } = this.state;
    const { showoverlimitmessage } = this.state;
    let { onsite_vehicle } = this.state;
    let { whichphoneview } = this.state;
    let ptop = window.scrollY;
    let itop = 100 + ptop;
    let popupclass = 'popupdialog bordershadow';
    if (whichphoneview !== 1) popupclass = 'popupdialog_phone bordernoshadow';

    let modalclass = 'modal-main';
    if (whichphoneview !== 1) modalclass = 'modal-main-phone-absolute';

    //const { properties_ary } = this.state;
    //  const { showvehicleform } = this.state;
    // let { selected_vehicle } = this.state;
    let { detailsdisplay } = this.state;
    //  let { showphoneview } = this.state;
    let chbclass = 'normalbuttonwrapper';

    let dstr = getTodayAsString();



    //const { isadmin } = this.state;
    let vehicles_ary = this.props.parking.current_vehicles_owner;
    let { whichsort } = this.state;
    let { sortdirection } = this.state;
    vehicles_ary = vehicles_ary.sort(function (a, b) {
      if (a[whichsort] < b[whichsort]) return (1 * sortdirection);
      if (a[whichsort] > b[whichsort]) return (-1 * sortdirection);
      if (a[whichsort] === b[whichsort]) return 0;
    })


    if (this.state.typefilter !== 'any') {
      if (this.state.typefilter === 'renter') {
        vehicles_ary = vehicles_ary.filter(item => item.carownertype === 'renter');
      }
      if (this.state.typefilter !== 'renter') {
        vehicles_ary = vehicles_ary.filter(item => item.carownertype !== 'renter');
      }
    }

    if (this.state.unitfilter !== 'any') {
      vehicles_ary = vehicles_ary.filter(item => item.unitnumber === this.state.unitfilter);
    }
    if (this.state.onsitefilter !== 'any') {
      //console.log('dstr is', dstr, this.state.onsitefilter);
      let tary = [];
      vehicles_ary.forEach(el => {
        //console.log('el', el['enddate'], 'dstr', dstr);
        if (this.state.onsitefilter === 'Yes') {
          if (el['enddate'] >= dstr) {
            tary.push(el);
          }
        }
        if (this.state.onsitefilter === 'No') {
          if (el['enddate'] < dstr) {
            tary.push(el);
          }
        }

      });
      // console.log('tary', tary);
      vehicles_ary = tary;
    }


    let millis = Date.now();

    const unums = vehicles_ary.map(x => x.unitnumber);
    unums.sort();
    const uniqueUnits = [...new Set(unums)];
    let unitnumber_list = [{ name: 'any', value: 'any' }];
    uniqueUnits.forEach((item, index) => {
      let uobj = {};
      uobj['key'] = index;
      uobj['name'] = item;
      uobj['value'] = item;
      unitnumber_list.push(uobj);
    })

    let passtype_list = [{ name: 'any', value: 'any' }, { name: 'renter', value: 'renter' }, { name: 'owner', value: 'owner' }];
    let onsite_list = [{ name: 'any', value: 'any' }, { name: 'No', value: 'No' }, { name: 'Yes', value: 'Yes' }];
    //let unitnumber_list = [{name: '101', value: '101' }, {name: '206', value: '206' }, {name: '304', value: '304' }]

    let phonesorttable = (
      <div style={{ borderBottom: "0px solid black", marginBottom: "10px" }}>
        <table>
          <thead><tr><th colSpan="5">Sort</th></tr></thead>
          <tbody>
            <tr>
              <td style={{ paddingRight: "10px" }}>
                <Link to=""
                  onClick={(e) => this.doSort(e, 'plate')} >
                  <b>Plate</b>
                </Link>
              </td>
              <td style={{ paddingRight: "10px" }}>
                <Link to=""
                  onClick={(e) => this.doSort(e, 'carownertype')} >
                  <b>Type</b>
                </Link>
              </td>
              <td style={{ paddingRight: "10px" }}>
                <Link to=""
                  onClick={(e) => this.doSort(e, 'unitnumber')} >
                  <b>Unit</b>
                </Link>
              </td>
              <td style={{ paddingRight: "10px" }}>
                <Link to=""
                  onClick={(e) => this.doSort(e, 'carowner_lname')} >
                  <b>Name</b>
                </Link>
              </td>
              <td style={{ paddingRight: "10px" }}>
                <Link to=""
                  onClick={(e) => this.doSort(e, 'enddate')} >
                  <b>Onsite</b>
                </Link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>


    );

    return (
      <div style={{ marginTop: "10px" }}>



        <div className="inputbox">
          <div className="innerinputbox2">
            {(this.props.parking.isloading) ? (<LoadingMessage />) : null}
            {showoverlimit ? (
              <ErrorMessageConstantClose message={showoverlimitmessage} closemessage={this.closemessage} />
            ) : (null)}




            <div className="parking_label">


              <div style={{ display: "flex", justifyContent: "start" }}>
                <div>
                  Vehicles
                </div>
                {/* <div style={{ textAlign: "center" }}>
                  {this.state.hoa}
                </div> */}


                {/* <div >
                  <ParkingSummary millis={millis} />
                </div> */}
              </div>


              {/* <div className="row">
                <div className="col-sm-4 tacenter">Vehicles </div>
                <div className="col-sm-4 tacenter">
                  HOA: {this.state.hoa}

                </div>
                <div className="col-sm-4 tacenter">
                  <ParkingSummary millis={millis} />
                </div>
              </div> */}


            </div>


            <div style={{ fontSize: "10pt" }}>


              <table >

                <thead><tr>
                  <th colSpan="4">
                    Filter
                  </th>
                </tr>
                </thead>
                <tbody>

                  <tr>

                    <td style={{ paddingRight: "10px" }}>Type</td>
                    <td style={{ paddingRight: "10px" }}>

                      <div className="form-group">
                        <SelectNameValuePlain
                          label="Type"
                          list={passtype_list}
                          name="typefilter"
                          value={this.state.typefilter}
                          onChange={this.onChange}
                          error={errors.typefilter}
                        />
                      </div>
                    </td>
                    <td style={{ paddingRight: "10px" }}>Unit</td>
                    <td style={{ paddingRight: "10px" }}>
                      <div className="form-group">
                        <SelectNameValuePlain
                          label="Unit"
                          list={unitnumber_list}
                          name="unitfilter"
                          value={this.state.unitfilter}
                          onChange={this.onChange}
                          error={errors.unitfilter}
                        />
                      </div>
                    </td>
                    <td style={{ paddingRight: "10px" }}>Onsite</td>
                    <td style={{ paddingRight: "10px" }}>
                      <div className="form-group">
                        <SelectNameValuePlain
                          label="Onsite"
                          list={onsite_list}
                          name="onsitefilter"
                          value={this.state.onsitefilter}
                          onChange={this.onChange}
                          error={errors.onsitefilter}
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div style={{ fontSize: "10pt" }}>
                <div className="phoneview">
                  <div>{phonesorttable}</div>
                  <div>
                    {/* {vehicles_template_grid_phone} */}
                    {this.renderCarRecordsPhone(vehicles_ary)}</div>
                </div>



                <div className="tableview">
                  <div className="grid-container-10">
                    <div className="grid-item-bold">
                      <Link to=""
                        onClick={(e) => this.doSort(e, 'plate')} >
                        Plate
                      </Link>
                    </div>

                    <div className="grid-item-bold">
                      <Link to=""
                        onClick={(e) => this.doSort(e, 'plate_state')} >
                        State
                      </Link>
                    </div>
                    <div className="grid-item-bold">Make</div>
                    <div className="grid-item-bold">Model</div>
                    <div className="grid-item-bold">
                      <Link to=""
                        onClick={(e) => this.doSort(e, 'carowner_lname')} >
                        Name
                      </Link>
                    </div>
                    <div className="grid-item-bold">
                      <Link to=""
                        onClick={(e) => this.doSort(e, 'carownertype')} >
                        Type
                      </Link>
                    </div>
                    <div className="grid-item-bold">
                      <Link to=""
                        onClick={(e) => this.doSort(e, 'unitnumber')} >
                        Unit
                      </Link>
                    </div>
                    <div className="grid-item-bold">
                      <Link to=""
                        onClick={(e) => this.doSort(e, 'enddate')} >
                        Checkout
                      </Link>
                    </div>




                    <div className="grid-item-bold">
                      <Link to=""
                        onClick={(e) => this.doSort(e, 'enddate')} >
                        Onsite
                      </Link>
                    </div>
                    <div className="grid-item-bold">Action</div>
                  </div>
                  <div>
                    {/* {vehicles_template_grid} */}
                    {this.renderCarRecords(vehicles_ary)}
                  </div>
                </div>
              </div>



            </div>
            {detailsdisplay}
          </div>
        </div>

        {this.state.showonsiteform ? (

          <div className="modal">
            <div className={modalclass}>
              <VehicleOnsite
                onsite_vehicle={onsite_vehicle}
                sendclosetoparent={this.closeonsiteform} />
            </div>
          </div>
        ) : (null)}



      </div>

    );
  }
}


// this is good practice because it will help debugging
// it is not checked when in production mode.
//onClick={(e) => props.gotoBooking(e, obj.accommodations.booking_url)}
// Profile.propTypes = {
//   auth: PropTypes.object.isRequired,
//   errors: PropTypes.object.isRequired
// };

// map errors to this component
// then we can use this.props.auth.user etc
// since we mapped error, we can use componentWillReceiveProps method
const mapStateToProps = state => ({
  auth: state.auth,
  parking: state.parking,
  errors: state.errors
});
// the state.auth above comes from rootReducer in index.js in reducers.

export default connect(
  mapStateToProps,
  { getAllVehicles, showStatusMessage, deleteVehicleModifyStatus }
)((AdminVehicles));
// wrap the Register with withRouter so the authAction can use history to redirect
