import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router';

import { loginUser } from "../../actions/authActions";
import axios from "axios";

import { Link } from "react-router-dom";
import { connect } from "react-redux";
import TextFieldGroup from "../common/TextFieldGroup";
import TextFieldGroupPlain from "../common/TextFieldGroupPlain";
import ForgotPasswordForm from "./ForgotPasswordForm";
import LoadingMessage from "../common/LoadingMessage";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

//import Spinner from "../common/Spinner";
// import { connect } from "react-redux";
//import SpinnerSmall from "../common/SpinnerSmall";

//import "./Login.css";
// import airforceLogo from "../../img/airforce_logo.png";
// import loginImgBg from "../../img/login_img_bg.png";



function Login(props) {

  let usedemo = sessionStorage.getItem('use_demo_mode')
  // console.log('use demo', usedemo)
  let emailstr = '';
  let pwstr = ''
  if (usedemo === "1") {
   // emailstr = "johnny@retreatia.com";
    emailstr = "jim.velasco@gmail.com";
    // emailstr = "admin@retreatia.com";
     pwstr = "123456"
  }


  const [forgotpassword, setForgotpassword] = useState(false);

  const [errors, setErrors] = useState({ message: "heck" });
  const [perrors, setPErrors] = useState({ message: 'we have a problem' });
  const [logonInfo, setLogonInfo] = useState({
    email: emailstr,
    password: pwstr
  });
  const auth = useSelector(state => state.auth);
  const reduxerrors = useSelector(state => state.errors);
  // console.log('reduxerrors', reduxerrors)

  let dispatch = useDispatch();
  let navigate = useNavigate();

  const handleChange = (event) => {
    setLogonInfo({ ...logonInfo, [event.target.name]: event.target.value });
  };


  const closeform = () => {
    setForgotpassword(false)
  }

  const doLogin = () => {
    setErrors({})
    const userData = {
      email: logonInfo.email,
      password: logonInfo.password,
      hoaid: 'YV' //sessionStorage.getItem('hoaid')
    };

    let err = {};

    if (userData['email'] === '') {
      err['email'] = 'Please enter a valid email';
      setErrors(err);
      return;
    }
    if (userData['password'] === '') {
      err['password'] = 'Please enter a valid Password';
      setErrors(err);
      return;
    }
    //console.log('on doLogin', userData);
   // sessionStorage.setItem('loggedonuser',userData['email'])
    dispatch(loginUser(userData, navigate));
    //    this.props.loginUser(userData, this.props.history);

  }


  const onCancelClick = () => {
    // console.log('on onCancelClick using navigate');
    // let url = "/" + sessionStorage.getItem('hoaid');
    // window.location.href = url;
    navigate(-1);
  }
  const showForgotPassword = () => {
    console.log('on showForgotPassword');
    setForgotpassword(true)
  }

  // const getPostLogonData = () => {
  //   //  console.log('get logon data', auth)
  //   let role = 'role'

  //   if (auth) role = auth['user']['role'];
  //   return (
  //     <h2>{role}</h2>
  //   )

  // }
  const okelement = <FontAwesomeIcon icon={faCheckCircle} color="#65598a" size="2x" title="enter / submit" />
  const cancelelement = <FontAwesomeIcon icon={faTimesCircle} color="black" size="2x" title="cancel / close" />
  let bgimage1 = sessionStorage.getItem('background_image_url');
  // let errors = {};
  //  let perrors = {};

  // if (auth.user.role === 'owner') {
  //   return (<h2>we need to leave</h2>)
  // }

  return (
    <div className="landing" style={{
      backgroundImage: `url("${bgimage1}")`
    }}>


      <div className="inputbox" style={{ maxWidth: "700px", margin: "0px auto" }}>
        <div className="innerinputbox">

          <p className="text-center">{sessionStorage.getItem('hoaname')}</p>
          <h4 className="text-center mb-4">Owner Login</h4>

          <div className="grid-container-2-13">
            <div className="grid-item-bold-a">Email</div>
            <div className="grid-item-normal-a" style={{ marginBottom: "5px" }}>
              <TextFieldGroupPlain
                type="email"
                label="Email"
                placeholder="Email Address"
                name="email"
                value={logonInfo.email}
                onChange={handleChange}
                error={errors.email}
              />
            </div>
            <div className="grid-item-bold-a">Password</div>
            <div className="grid-item-normal-a" style={{ marginBottom: "5px" }}>
              <TextFieldGroupPlain
                type="password"
                label="Password"
                placeholder="Password"
                name="password"
                value={logonInfo.password}
                onChange={handleChange}
                error={errors.password}
              />
            </div>
            <div className="grid-item-normal-a">
              &nbsp;

            </div>
            <div className="grid-item-normal-a">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <span className="iconbuttonspan">
                    <Link to=""
                      onClick={(e) => doLogin(e)} >
                      {okelement}
                    </Link>
                  </span>
                  <span className="iconbuttonspan">
                    <Link to=""
                      onClick={(e) => onCancelClick(e)} >
                      {cancelelement}
                    </Link>
                  </span>

                </div>
                <div>
                  <Link to=""
                    onClick={(e) => showForgotPassword(e)} >
                    <div style={{ fontSize: "12pt" }}>
                      forgot password
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>


          {/* 
          <div style={{ display: "flex", gap: "20px", flexDirection: "column" }}>
            <div className="form-group">
              <TextFieldGroup
                type="email"
                label="Email"
                placeholder="Email Address"
                name="email"
                value={logonInfo.email}
                onChange={handleChange}
                error={errors.email}
              />
            </div>
            <div className="form-group">
              <TextFieldGroup
                type="password"
                label="Password"
                placeholder="Password"
                name="password"
                value={logonInfo.password}
                onChange={handleChange}
                error={errors.password}
              />
            </div>
            <div className="row">
              <div className="col-md-4 offset-md-4">
                <span className="iconbuttonspan">
                  <Link to=""
                    onClick={(e) => doLogin(e)} >
                    {okelement}
                  </Link>
                </span>
                <span className="iconbuttonspan">
                  <Link to=""
                    onClick={(e) => onCancelClick(e)} >
                    {cancelelement}
                  </Link>
                </span>
              </div>
              <div className="col-md-4">
                <Link to=""
                  onClick={(e) => showForgotPassword(e)} >
                  <div style={{ fontSize: "12pt" }}>
                    forgot password
                  </div>
                </Link>
              </div>
            </div>
          </div>
 */}



          {/* </form> */}

          {forgotpassword ? (
            <div className="row">
              <div className="col-md-8 offset-md-4">
                <ForgotPasswordForm closeform={closeform} />
              </div>
            </div>) : (null)}

          {reduxerrors && <div className="error-display">{reduxerrors.message}</div>}

          {/* {getPostLogonData()} */}

        </div>
      </div>


    </div>


  );

}

export default Login;

/*
expiresTAI: "1609459236"
expiresUTC: "2020-12-31"
jjv: "cragzop"
nextLeapTAI: "N/A"
nextLeapUTC: "N/A"
previousLeapTAI: "1498867237"
previousLeapUTC: "2017-07-01"

HTTP CA certificate SHA-256 fingerprint:
  67888ad0d60e34aaa289b7af5565e8aa763308b6c387f5e3a15340d5ff8d54a6

es token
eyJ2ZXIiOiI4LjIuMCIsImFkciI6WyIxOTIuMTY4LjEyOS4yMzI6OTIwMCJdLCJmZ3IiOiI2Nzg4OGFkMGQ2MGUzNGFhYTI4OWI3YWY1NTY1ZThhYTc2MzMwOGI2YzM4N2Y1ZTNhMTUzNDBkNWZmOGQ1NGE2Iiwia2V5IjoiQVVfODRvQUJFRy02NFdBT19DeTg6QkgteTY2cUtRX1NxYkxnM3hFUmZFdyJ9

Password for the elastic user (reset with `bin/elasticsearch-reset-password -u elastic`):
  FgA=*b6XI6PqiqQWleo7
*/
