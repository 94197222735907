import React from "react";

export default () => {

  let hoaname = sessionStorage.getItem('hoaname')
  let hoastr = sessionStorage.getItem('selectedhoa');
  let hoaobj = { help_text: { general: "<h2>Nothing general" } };

  if (hoastr) hoaobj = JSON.parse(hoastr);

  // console.log('hoaobj', hoaobj)

  // console.log('hoaobj text', hoaobj['help_text']['general'])

  // return (
  //   <div>
  //     <h6><b>General Parking Rules</b></h6>
  //     <div dangerouslySetInnerHTML={{ __html: hoaobj['help_text']['permissions'] }}>

  //     </div>
  //     {/* {hoaobj['help_text']['general']} */}




  //   </div >

  // );

  return (
    <div dangerouslySetInnerHTML={{ __html: hoaobj['help_text']['general'] }}></div>
  )


  // return (
  //   <div>

  //     <h6><b>General Parking Rules</b></h6>


  //     <ul>
  //       <li>Owners must register their own vehicles and their visitor’s vehicles, if parked in our lots overnight, in {hoaname}’s Parking App and set each vehicle’s ONSITE indicator to YES or NO accordingly.</li>

  //       <li>Renters must register their vehicles (under the unit number they’re renting from), if parked in our lots at any time, in the Parking App. It is the owner's responsibility to inform your renters that they must register their vehicles.</li>

  //       <li>Maximum 2 registered vehicles per unit in our parking lots at any time, unless temporary permission is granted by the Mgmt. Company.</li>

  //       <li>Park within marked parking spaces, using good judgment when snow is on the ground.</li>

  //       <li>Do not obstruct/block driveways, walkways, parking spaces, trash receptacles, shuttle bus turnaround, or snow storage area.</li>

  //       <li>No parking in driveway leading into upper parking lot.</li>

  //       <li>When new snow falls, move your vehicle daily to allow for snow plowing.</li>

  //       <li>No inoperative, immobile, stored, or expired tag vehicles.</li>

  //       <li>No vehicle major maintenance, including oil changes.</li>

  //       <li>No playing baseball, softball, or any other activity which may damage vehicles or property.</li>

  //       <li>Parking violations may be subject to immediate towing or booting, at owner expense.</li>

  //       <li>No trailers or oversized vehicles.  NOTE: Owners see Rules and Regulations.</li>

  //     </ul>

  //   </div>

  // );
};