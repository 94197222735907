
//const isEmpty = require("./is-empty");
//import { isEmpty } from './is-empty';

//const Validator = require("validator");
import { isitEmpty } from './isitempty';

export const validateNotifyHoaInformation = (data) => {
  let errors = {};
  // console.log('validateModifyVehicleInput data', data);
  // console.log('validateModifyVehicleInput data', data.carownername);
  // console.log('hard call ', isitEmpty('no'))

  // submitdata['notify_type'] = this.state.notify_type;
  // submitdata['notify_name'] = this.state.notify_name;
  // submitdata['notify_phone'] = this.state.notify_phone;
  // submitdata['notify_unit'] = this.state.notify_unit;
  // submitdata['notify_plate'] = this.state.notify_plate;
  // submitdata['notify_description'] = this.state.notify_description;

  data.notify_name = !isitEmpty(data.notify_name) ? data.notify_name : "";
  data.notify_description = !isitEmpty(data.notify_description) ? data.notify_description : "";



  if (isitEmpty(data.notify_name)) {
    errors.notify_name = "Name field is required";
  }
  if (isitEmpty(data.notify_description)) {
    errors.notify_description = "Comment field is required";
  }



  return {
    errors,
    isValid: isitEmpty(errors)
  };
};




