import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showStatusMessage } from "../../actions/parkingActions";
import { useNavigate } from 'react-router';
import SmartButtonHPS from "../common/SmartButtonHPS";
import axios from "axios";



import { Link } from "react-router-dom";

import { DefaultEditor } from 'react-simple-wysiwyg';
import { BtnBold, BtnItalic, Editor, Toolbar } from 'react-simple-wysiwyg';


function AdminHelpTest(props) {

  const [html, setHtml] = React.useState('');
  const [hoaobject, setHoaobject] = React.useState(null);
  const [selary, setSelary] = React.useState([0, 0, 0, 0, 0, 0, 0]);
  const [errors, setErrors] = React.useState({});

  const topicAry = ['general', 'permissions', 'owner', 'owner_tc', 'renter', 'renter_tc', 'admin'];
  const topicLblAry = ['General', 'Permissions', 'Owner', 'Owner TC', 'Renter', 'Renter TC', 'Admin'];

  const dispatch = useDispatch()


  const bclass = 'btn-basic';
  const bclasssel = 'btn-basic-selected';

  useEffect(() => {
    // console.log('aht use effect getting selectedhoa')
    let hoastr = sessionStorage.getItem('selectedhoa');
    let hoaobj = JSON.parse(hoastr);
    //  console.log('loaded help_text', hoaobj['help_text']);
    setHoaobject(hoaobj)
    // loadGeneralStart(hoaobj);
  }, []);

  function onChange(e) {
    setHtml(e.target.value);
  }

  // const loadGeneral = (e) => {
  //   e.preventDefault();
  //   //  loadGeneralComplete();
  //   // console.log('toggle general')
  //   let html = hoaobject['help_text']['general'];
  //   setHtml(html)
  //   setSelary([1, 0])
  // }

  // const loadGeneralStart = (hoaobj) => {
  //   // console.log('load general start called', hoaobj)
  //   let html = hoaobj['help_text']['general'];
  //   setHtml(html)
  //   setSelary([1, 0])
  //   setErrors({})

  // }
  // const loadPermissions = (e) => {
  //   e.preventDefault();
  //   let html = hoaobject['help_text']['permissions'];
  //   setHtml(html)
  //   setSelary([0, 1])
  //   setErrors({})
  // }

  const loadTheHelp = (e, which) => {
    // console.log('load the help', which)
    e.preventDefault();
    let i = topicAry.indexOf(which)
    let ary = [0, 0, 0, 0, 0, 0, 0]
    ary[i] = 1
    //let tobj = { ...hoaobject }
    let html = hoaobject['help_text'][which];
    setHtml(html)
    setSelary(ary)
    setErrors({})


  }

  const submitChanges = (e) => {
    e.preventDefault();

    let allok = true;
    const sum = selary.reduce((accumulator, value) => {
      return accumulator + value;
    }, 0);

    if (sum === 0) allok = false;

    if (!allok) {
      setErrors({ message: "Nothing to publish" })
      return;
    }

    let whichi = selary.indexOf(1);
    let which = topicAry[whichi]
    let whichlbl = topicLblAry[whichi]

    // const firstLetter = which.charAt(0)
    // const firstLetterCap = firstLetter.toUpperCase()
    // const remainingLetters = which.slice(1)
    // const capitalizedWord = firstLetterCap + remainingLetters
    // whichlbl = capitalizedWord;
    // console.log('whichl is', which)

    //let helpdata = { ...hoaobject['help_text'] }
    // let helpdata = {}; // ...hoaobject['help_text'] }
    // hoaobject['help_text'][which] = html
    // helpdata[which] = html

    // console.log('the data to be submitted is', helpdata)
    /*
    let which = 'general'
    let whichlbl = 'General'

    if (selary[1] === 1) which = 'permissions'

    if (which === 'general') {
      hoaobject['help_text']['general'] = html
      helpdata['general'] = html
    }

    if (which === 'permissions') {
      hoaobject['help_text']['permissions'] = html
      helpdata['permissions'] = html
      whichlbl = 'Permissions'
    }
    */

    // helpdata['other'] = '<h3>now is the time</h3>'

    let transobj = {}
    transobj['hoaid'] = sessionStorage.getItem('hoaid')
    transobj['which'] = which
    transobj['helpdata'] = html // helpdata; //helpdata

    // console.log('transobj helpdata', transobj['helpdata'])

    let url = "/api/parking/updatehoahelp";
    axios.post(url, transobj).then(rdata => {
      //alert('we are done')
      // console.log('help text update returned data', rdata.data);
      let modobj = rdata.data;
      sessionStorage.setItem('selectedhoa', JSON.stringify(modobj))
      dispatch(showStatusMessage(whichlbl + " Help Text Published"));
    }).catch(err => {
      console.log(err);
    });

  }



  const importHtml = (e) => {
    e.preventDefault();
    let data = document.getElementById('importhtml').value
    //console.log('data is', data)
    setHtml(data)

  }

  let topicselected = true;
  const sumofary = selary.reduce((accumulator, value) => {
    return accumulator + value;
  }, 0);

  if (sumofary === 0) topicselected = false;

  //  // onClick={(e) => loadGeneral(e)}

  return (

    <div style={{ border: "0px solid yellow", marginTop: "10px" }}>

      <div className="inputbox">
        <div className="innerinputbox2">

          <p className="parking_label">Help Text Editor</p>
          <div style={{ margin: "20px", textAlign: "center" }}>
            <SmartButtonHPS
              name="General"
              buttonclass={selary[0] === 0 ? bclass : bclasssel}

              onClick={(e) => loadTheHelp(e, 'general')}
            />
            &nbsp;
            <SmartButtonHPS
              name="Permissions"
              buttonclass={selary[1] === 0 ? bclass : bclasssel}
              onClick={(e) => loadTheHelp(e, 'permissions')}
            />
            &nbsp;
            <SmartButtonHPS
              name="Owner"
              buttonclass={selary[2] === 0 ? bclass : bclasssel}
              onClick={(e) => loadTheHelp(e, 'owner')}
            />
            &nbsp;
            <SmartButtonHPS
              name="Owner TC"
              buttonclass={selary[3] === 0 ? bclass : bclasssel}
              onClick={(e) => loadTheHelp(e, 'owner_tc')}
            />

            &nbsp;
            <SmartButtonHPS
              name="Renter"
              buttonclass={selary[4] === 0 ? bclass : bclasssel}
              onClick={(e) => loadTheHelp(e, 'renter')}
            />

            &nbsp;
            <SmartButtonHPS
              name="Renter TC"
              buttonclass={selary[5] === 0 ? bclass : bclasssel}
              onClick={(e) => loadTheHelp(e, 'renter_tc')}
            />

            &nbsp;
            <SmartButtonHPS
              name="Admin"
              buttonclass={selary[6] === 0 ? bclass : bclasssel}
              onClick={(e) => loadTheHelp(e, 'admin')}
            />
          </div>

          {topicselected ? (
            <div>



              <DefaultEditor
                placeholder="Help Text"
                value={html}
                onChange={onChange}
                title="help text editor"
              />
              <div style={{ margin: "20px", textAlign: "center" }}>
                <SmartButtonHPS
                  name="Submit"
                  buttonclass={"btn-basic"}
                  onClick={(e) => submitChanges(e)}
                />
              </div>




              {/* <h5>Import Html</h5>
              <div>
                <textarea id="importhtml" rows="10" cols="100"></textarea>
              </div>
              <div style={{ margin: "20px", textAlign: "center" }}>
                <SmartButtonHPS
                  name="Import"
                  buttonclass={"btn-basic"}
                  onClick={(e) => importHtml(e)}
                />
              </div> */}
            </div>
          ) : (
            <div style={{ textAlign: "center" }}>
              <p>Please select a topic</p>
            </div>
          )}


          {errors && errors['message'] ? (
            <div style={{ textAlign: "center" }}>
              <div className="error-display" style={{ fontSize: "10pt" }}>{errors['message']}</div>
            </div>) : (null)}

        </div>
      </div>
    </div>
  )
}

export default AdminHelpTest;

{/* <hr />
          <DefaultEditor
            value={html}
            onChange={onChange}
            title="ed2"
            placeholder="Test"
          />
          <hr /> */}
{/* <Editor value={value} onChange={onChange}>
            <Toolbar>
              <BtnBold />
              <BtnItalic />
            </Toolbar>
          </Editor> */}
{/* {html} */ }

/* default help */
/* general */
/*

<div><h6><b>General Parking Rules</b></h6><ul><li>Owners must register their own vehicles and visitor’s vehicles, if parked in our lots overnight, in Yampa Views’s Parking App and set each vehicle’s ONSITE indicator to YES or NO accordingly.</li><li>Renters must register their vehicles (under the unit number they’re renting from), if parked in our lots at any time, in the Parking App. It is the owner's responsibility to inform your renters that they must register their vehicles.</li><li>Maximum 2 registered vehicles per unit in our parking lots at any time, unless temporary permission is granted by the Mgmt. Company.</li><li>Park within marked parking spaces, using good judgment when snow is on the ground.</li><li>Do not obstruct/block driveways, walkways, parking spaces, trash receptacles, shuttle bus turnaround, or snow storage area.</li><li>No parking in driveway leading into upper parking lot.</li><li>When new snow falls, move your vehicle daily to allow for snow plowing.</li><li>No inoperative, immobile, stored, or expired tag vehicles.</li><li>No vehicle major maintenance, including oil changes.</li><li>No playing baseball, softball, or any other activity which may damage vehicles or property.</li><li>Parking violations may be subject to immediate towing or booting, at owner expense.</li><li>No trailers or oversized vehicles.  NOTE: Owners see Rules and Regulations.</li></ul></div>

*/

/* permissions */
/*

<div><h6><b>Permissions</b></h6><p>Contact Yampa View Property Management (PM) to request permission to temporarily park any vehicle not allowed per Yampa View regulations. You can call or send a message to the PM through the application once you have logged in as an owner or guest. </p><ul><li> Any additional vehicle over the assigned limit. NOTE: Limits can be changed during peak times.</li><li> For any oversized vehicle or trailer (wider or 4 feet longer than its parking space).</li><li> Contractor dumpster or storage trailer. NOTE: A miminum of one week notice must be submitted to Yampa View Property Management for approval. </li><li>If permission is granted, you must register the vehicle (if it hasn’t been previously registered). Oversized vehicles must be parked either in the lower lot or parallel to the upper lot cliff.</li></ul></div>

*/


/* owner */
/*
<div>
<b>No special rules</b>
</div>
*/

/* owner tc */
/*
<div>
<ol><li><h6>Prior to registering your vehicle(s), it is imperative that you read Yampa View’s Rules and Regulations, located in AppFolio, where the parking rules reside.</h6></li><li><h6>
As an owner, it is your responsibility to register your vehicles in Yampa View’s Parking App and set the ONSITE value to “Yes” or “No”</h6></li><li><h6>It is your responsibility to provide a pincode and to inform your vacation renters that they must register their vehicles.</h6></li><li><h6>VIOLATION OF THESE TERMS AND CONDITIONS MAY RESULT IN YOU BEING FINED, AND/OR YOU OR YOUR VACATION RENTER’S VEHICLE BEING TOWED OR BOOTED AT OWNER EXPENSE!!</h6></li></ol>
</div>
*/

/* renter */
/*
<div>
<b>No special rules</b>
</div>
*/

/* renter tc */
/*
<div>
 <ul><li> Renters must register their vehicles under the unit number they are renting from, if parked in our lots at any time.The Checkout Date entered cannot be exceeded. </li><li> Renters may park no more than 2 registered vehicles in our lots at any given time. Please only register a second vehicle if it is necessary, as parking is limited. </li><li> Park within marked parking spaces, using good judgment when snow is on the ground.</li><li> Do not obstruct or block driveways, walkways, parking spaces, trash receptacles, shuttle bus turnaround, or snow storage areas.</li><li> No parking in driveway leading into upper parking lot.</li>
<li> When new snow falls, move your vehicle daily to allow for snow plowing.</li><li> No inoperative, immobile, stored, or expired tag vehicles. </li><li> No vehicle major maintenance, including oil changes.</li><li> No playing baseball, softball, or any other activity which may damage vehicles or property.</li><li> Parking violations may be subject to immediate towing or booting, at owner expense.
</li><li> No trailers or oversized vehicles permitted. </li></ul><h6>VIOLATION OF THESE TERMS AND CONDITIONS MAY RESULT IN YOUR VEHICLE BEING TOWED OR BOOTED, AT YOUR EXPENSE!!
</h6></div>
*/




/*
router.post("/updatehoahelp", (req, res) => {

let hoaid = req.body.hoaid;
let query = { hoaid: hoaid };
let helpdata = req.body.helpdata;
*/

  // const renderGeneralToggle = () => {
  //   // let hoaname = sessionStorage.getItem('hoaname');
  //   // if (!hoaname) return null
  //   // if (hoaname.length === 0) {
  //   //   return null
  //   // } else {
  //   return (
  //     <SmartButtonHPS
  //       name="General"
  //       buttonclass={selary[0] === 0 ? bclass : bclasssel}
  //       onClick={(e) => loadGeneral(e)}
  //     />
  //   )
  //   // }
  // }

  // const renderPermissionsToggle = () => {
  //   // let hoaname = sessionStorage.getItem('hoaname');
  //   // if (!hoaname) return null
  //   // if (hoaname.length === 0) {
  //   //   return null
  //   // } else {
  //   return (
  //     <SmartButtonHPS
  //       name="Permissions"
  //       buttonclass={selary[1] === 0 ? bclass : bclasssel}
  //       onClick={(e) => loadPermissions(e)}
  //     />
  //   )
  //   // }
  // }




  // let hoastr = sessionStorage.getItem('selectedhoa');
  // let hoaobj = JSON.parse(hoastr);
  // console.log('the selected hoa is', hoaobj)

  // <div dangerouslySetInnerHTML={{ __html: hoaobj['help_text']['general'] }}></div>

  // let html6 = hoaobj['help_text']['general'];

  // const htmlstr = (
  //   <ol>
  //     <li>
  //       <b>Where can I find Yampa View’s Parking Rules?</b><br />
  //       <b>OWNERS</b> will find them in Yampa View’s Rules & Regulations, on AppFolio. <br /><b>RENTERS</b> will find them in the Yampa View’s Parking App, renter section.
  //     </li>
  //     <li>
  //       <b>Where do I start?</b><br />
  //       <b>OWNERS:</b> Once you LOGON using the Owner button, you will need to update/verify your profile information and provide/update a PIN number for your renters and for the security and parking enforcement within the complex. In addition, Owners must agree to the Parking App’s Terms and Condition. From the Dashboard, you will be able to add/edit/delete vehicles. <br />
  //       <b>RENTERS:</b> Once you LOGON using the Renter button, you’ll be required to select the unit you are staying in and provide the pin number.  In order to register your vehicle(s) for your vacation stay, you’ll be required to acknowledge that you have read and agree to abide by the Parking App’s Terms and Conditions for renters, provide your car information, and associate it to the unit number you are staying at. The Owner or Managing Agent is responsible for providing you with the Pin number. Failure to register your vehicle upon arrival risks having your vehicle tagged with a warning, booted and/or towed.
  //     </li>
  //   </ol >)

  // let html2 = `<div>${htmlstr}`;
  // let html3 = "<div><ul><li>now</li><li>is</li><li>the</li></ul></div>"
  // let html4 = hoaobj['help_text']['permissions']
  //console.log('html2', html2)

  //console.log('htmlstr', htmlstr.toString())


  // const [value, setValue] = React.useState('simple text');

