
//const isEmpty = require("./is-empty");
//import { isEmpty } from './is-empty';

//const Validator = require("validator");
import { isitEmpty } from './isitempty';

export const validateContactInformationInput = (data) => {
  let errors = {};
  // console.log('validateModifyVehicleInput data', data);
  // console.log('validateModifyVehicleInput data', data.carownername);
  // console.log('hard call ', isitEmpty('no'))

  data.contact_id = !isitEmpty(data.contact_id) ? data.contact_id : "";
  data.phone_description = !isitEmpty(data.phone_description) ? data.phone_description : "";
  data.phone_number = !isitEmpty(data.phone_number) ? data.phone_number : "";
  data.email = !isitEmpty(data.email) ? data.email : "";
  data.sort_order = !isitEmpty(data.sort_order) ? data.sort_order : "";






  if (isitEmpty(data.contact_id)) {
    errors.contact_id = "Id field is required";
  }
  if (isitEmpty(data.phone_description)) {
    errors.phone_description = "Description field is required";
  }
  if (isitEmpty(data.phone_number) && isitEmpty(data.email)) {
    errors.phone_number = "Phone number or email field is required";
  }
  if (isitEmpty(data.sort_order)) {
    errors.sort_order = "Sort order field is required";
  }


  return {
    errors,
    isValid: isitEmpty(errors)
  };
};




