import React, { Component } from "react";
import { connect } from "react-redux";


import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
//import TextFieldGroupPlain from "../common/TextFieldGroupPlain";
import SpinnerSmall from "../common/SpinnerSmall";

import { setStatusMessage } from "../../actions/parkingActions";

import './payments.css';

class PaymentForm extends React.Component {
  constructor(props) {
    console.log('credit card props', props);
    super(props);
    this.fattJs = null;
    this.state = {
      tokenizedPaymentMethod: null,
      completedTransaction: null,
      transactionError: null,
      isPayButtonDisabled: true,
      formValues: {},
      loading: false,
      fattjs: null,
      cardata: props.cardata,
      paymentcomplete: false,
      errors: {}
    };
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    const FattJs = window.FattJs;

    // tell fattJs to load in the card fields
    //HOA-Parking-Solutions-c721f3cd977c
    //  style:
    //"height:35px;width: 100%; font-size: 15px; font-family: Helvetica Neue, Helvetica; color: #31325f; font-weight: 300;"
    const fattjs = new FattJs("HOA-Parking-Solutions-c721f3cd977c", {
      number: {
        id: "card-number",
        placeholder: "0000 0000 0000 0000",
        type: 'text',
        style: "outline: 1px solid #ccc;margin:10px;padding:3px;",

        format: 'prettyFormat'
      },
      cvv: {
        id: "card-cvv",
        placeholder: "CVV",
        style: "outline: 1px solid #ccc;margin:10px;padding:3px;"
        // 35px is the height of my card-cvv div
      }
    });
    fattjs.showCardForm().then(handler => {
      // console.log("form loaded! :^)");

      // for testing!
      handler.setTestPan("4111111111111111");  // success
      //handler.setTestPan("4012888888881881");  // fail
      handler.setTestCvv("123");

      this.setState({
        formValues: {
          month: "11",
          year: "2022",
          firstname: this.props.cardata.carowner_fname,
          lastname: this.props.cardata.carowner_lname,
          phone: "+1111111111111111",
          zipcode: "80016"
        }
      });
    });

    //Visa	4111111111111111	4012888888881881

    fattjs.on("card_form_complete", message => {
      console.log("card_form_complete", message);
      // activate pay button
      this.setState({ isPayButtonDisabled: false });
    });

    fattjs.on("card_form_incomplete", message => {
      console.log("card_form_uncomplete", message);
      // deactivate pay button
      this.setState({ isPayButtonDisabled: true });
    });
    this.setState({ fattjs: fattjs });
  }



  handleFieldChange = event => {
    const { formValues } = this.state;
    const { name, value } = event.target;
    let which = false;
    if (value.length === 0) which = true;
    this.setState({ formValues: { ...formValues, [name]: value }, isPayButtonDisabled: which });
  };

  onChange(e) {
    let elname = e.target.name;
    let elval = e.target.value;
    //    console.log('elname is', elname, elval, this.state.vehiclespotsobj);

    this.setState({ [e.target.name]: e.target.value, errors: {} });

  }

  // if email is supplied, a reciept will be sent to customer
  handlePay = (e) => {
    e.preventDefault();
    this.setState({
      tokenizedPaymentMethod: null,
      completedTransaction: null,
      isPayButtonDisabled: true,
      loading: true
    });
    let fv = this.state.formValues;
    let err = {};
    let msg = '';
    if (fv.firstname.length === 0) msg = 'firstname must not be empty';
    if (fv.lastname.length === 0) msg = 'lastname must not be empty';
    if (fv.month.length === 0) msg = 'month must not be empty';
    if (fv.year.length === 0) msg = 'year must not be empty';

    if (msg.length > 0) {
      err = { message: msg };
      this.setState({ errors: err, loading: false, transactionError: err });
      return;
    }




    const { formValues } = this.state;
    const extraDetails = {
      ...formValues,
      total: this.props.cardata.total_price,
      address_1: "100 S Orange Ave",
      address_2: "",
      address_city: "Orlando",
      address_state: "FL",
      address_zip: this.state.formValues.zipcode,
      address_country: "USA",
      url: "https://omni.fattmerchant.com/#/bill/",
      method: 'card',
      // validate is optional and can be true or false. 
      // determines whether or not fattmerchant.js does client-side validation.
      // the validation follows the sames rules as the api.
      // check the api documentation for more info:
      // https://fattmerchant.com/api-documentation/
      validate: false,
      // meta is optional and each field within the POJO is optional also
      meta: {
        // reference: 'invoice-reference-num',// optional - will show up in emailed receipts
        reference: `parking at unit: ${this.state.cardata.unitnumber} from: ${this.state.cardata.parking_startdate} to: ${this.state.cardata.parking_enddate}`, //'invoice-reference-num',
        memo: `make: ${this.state.cardata.make} model: ${this.state.cardata.model} year: ${this.state.cardata.year} plate: ${this.state.cardata.plate} state: ${this.state.cardata.plate_state}`
        //   memo: 'notes about this transaction',// optional - will show up in emailed receipts
        //   otherField1: 'other-value-1', // optional - we don't care
        //   otherField2: 'other-value-2', // optional - we don't care
        //   subtotal: 1, // optional - will show up in emailed receipts
        //   tax: 0, // optional - will show up in emailed receipts
        //   lineItems: [ // optional - will show up in emailed receipts
        //     { "id": "optional-fm-catalog-item-id", "item": "Demo Item", "details": "this is a regular, demo item", "quantity": 10, "price": .1 }
        //  ]
      }
    };

    // console.log('extra details', extraDetails);
    /*
    dispatch({
        type: SET_STATUS_MESSAGE,
        // payload: { message: "Thank You.  You will receive an email.  Follow the link to verify your email address." }
        payload: { message: "Thank You.  User has been registered." }
      });
      */

    this.state.fattjs.pay(extraDetails).then(completedTransaction => {
      console.log(
        "successfully paid. here's the transaction: ",
        completedTransaction
      );
      this.setState({
        completedTransaction,
        isPayButtonDisabled: false,
        loading: false,
        paymentcomplete: true
      });
      let { cardata } = this.state;
      cardata['parking_transaction_id'] = completedTransaction.id;
      this.props.setStatusMessage('Transaction Complete');

      //   setTimeout(() => {
      // this.props.closeallforms();
      this.props.handlePaymentFromStax('success', cardata);
      //  }, 3000);
      this.setState({ paymentcomplete: true });

    }).catch(err => {
      // console.log('error returned from pay is', err);
      // console.log('paf', err['payment_attempt_failed']);
      // console.log('pam', err['payment_attempt_message']);
      let msg = 'Problem with transaction';
      if (err['message']) {
        msg = err['message'];
      }
      if (err['payment_attempt_message']) {
        msg = err['payment_attempt_message'];
      }
      this.setState({
        // transactionError: { message: err['message'] },
        transactionError: { message: msg },
        isPayButtonDisabled: false,
        loading: false,
        paymentcomplete: false
      });
    });

  };

  /*
  payment_attempt_failed: true
payment_attempt_message: "Unable to process the purchase transaction."
*/

  transactionSuccess = (cardata) => {

    this.props.handlePaymentFromStax('success', cardata);
  }





  renderCustomerDetails = () => {
    const { formValues } = this.state;

    return (
      <div className="bordershadowcc2">
        <p style={{ textAlign: "center" }}>Customer Information</p>

        <div className="grid-container-2-cc2">
          <div className="grid-item-bold-cc">
            First
          </div>
          <div className="grid-item-normal-cc">
            <input
              name="firstname"
              className="field"
              placeholder="First Name"
              onChange={this.handleFieldChange}
              value={formValues.firstname || ""}
            />
          </div>
          <div className="grid-item-bold-cc">
            Last
          </div>
          <div className="grid-item-normal-cc">
            <input
              name="lastname"
              className="field"
              placeholder="Last Name"
              onChange={this.handleFieldChange}
              value={formValues.lastname || ""}
            />
          </div>
          <div className="grid-item-bold-cc">
            Zip
          </div>
          <div className="grid-item-normal-cc">
            <input
              name="zipcode"
              className="field"
              placeholder="80305"
              onChange={this.handleFieldChange}
              value={formValues.zipcode || ""}
            />
          </div>

        </div >
      </div>
    )
  };

  renderCardDetails = () => {
    const { formValues } = this.state;
    const okicon = <FontAwesomeIcon icon={faCheckCircle} color="#65598a" size="2x" title="enter / submit" />
    const okicondisable = <FontAwesomeIcon icon={faCheckCircle} color="#ddd" size="2x" />
    const cancelicon = <FontAwesomeIcon icon={faTimesCircle} color="black" size="2x" title="cancel / close" />
    const { isPayButtonDisabled, loading, errors, zipcode } = this.state;
    return (
      <div className="bordershadowcc2" >
        <p style={{ textAlign: "center" }}>Card Information</p>
        <div className="grid-container-2-cc2">
          <div className="grid-item-bold-cc">
            Card
          </div>
          <div className="grid-item-normal-cc">
            <div id="card-number" className="card-number" />
          </div>
          <div className="grid-item-bold-cc">
            CCV
          </div>
          <div className="grid-item-normal-cc">
            <div id="card-cvv" className="card-cvv" />
          </div>
          <div className="grid-item-bold-cc">
            MM
          </div>
          <div className="grid-item-normal-cc">
            <input
              className="field"
              name="month"
              maxLength="2"
              placeholder="MM"
              onChange={this.handleFieldChange}
              value={formValues.month || ""}
            />
          </div>
          <div className="grid-item-bold-cc">
            YYYY
          </div>
          <div className="grid-item-normal-cc">
            <input
              className="field"
              name="year"
              maxLength="4"
              placeholder="YYYY"
              onChange={this.handleFieldChange}
              value={formValues.year || ""}
            />
          </div>
        </div>



      </div>
    );
  };

  renderMessage = () => {
    const { completedTransaction, tokenizedPaymentMethod, transactionError, } = this.state;
    const success = completedTransaction || tokenizedPaymentMethod;

    return (
      <div className="success">
        {completedTransaction
          ? `Successfully paid $1! The ID for this transaction is ${completedTransaction.id
          }`
          : ""}
        {tokenizedPaymentMethod
          ? `Successfully tokenized the card! The ID for the payment method is ${tokenizedPaymentMethod.id
          }`
          : ""}
        {transactionError
          ? `Error: ${transactionError.message}`
          : ""}
      </div>
    );
  };

  closeform = (e) => {
    //  console.log('closing form fatt merchant', this.props.cardata);
    // console.log('we hit close from the credit card form.  callinng this.props.sendclosetoparent(');
    if (e) e.preventDefault();
    this.props.sendclosetoparent();
  }

  render() {
    let { isPayButtonDisabled, loading, errors, zipcode } = this.state;

    const okicon = <FontAwesomeIcon icon={faCheckCircle} color="#65598a" size="2x" title="enter / submit" />
    const okicondisable = <FontAwesomeIcon icon={faCheckCircle} color="#f0f0f0" size="2x" />
    const cancelicon = <FontAwesomeIcon icon={faTimesCircle} color="black" size="2x" title="cancel / close" />

    // console.log('render isPayButtonDisabled', isPayButtonDisabled);
    // isPayButtonDisabled = false;

    return (
      <div style={{ border: "0px solid black" }}>
        {/* <p style={{ textAlign: "center" }}>Customer Information</p> */}

        <form onSubmit={e => e.preventDefault()} >
          {this.renderCustomerDetails()}
          {/* <p style={{ textAlign: "center", marginTop: "20px" }}>Card Information</p> */}
          {this.renderCardDetails()}


          <div style={{ textAlign: "left", marginTop: "20px" }}>

            {this.state.paymentcomplete ? (
              <div>
                <span className="iconbuttonspan">
                  {okicondisable}
                </span>
                <span className="iconbuttonspan">
                  <Link to=""
                    onClick={(e) => this.props.closeallforms()}  >
                    {cancelicon}
                  </Link>
                </span>
              </div>

            ) : (
              <div>
                <span className="iconbuttonspan">
                  <Link to=""
                    onClick={(e) => this.handlePay(e)}  >
                    {okicon}
                  </Link>

                </span>
                <span className="iconbuttonspan">
                  <Link to=""
                    onClick={(e) => this.closeform(e)}  >
                    {cancelicon}
                  </Link>
                </span>
              </div>

            )}

          </div>


          <div className="outcome">
            {this.renderMessage()}
            {/* {loading && <div className="loader" />} */}
            {loading && <SpinnerSmall align={"center"} />}


          </div>


        </form>


      </div>


    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  parking: state.parking,
  errors: state.errors,
});

export default connect(mapStateToProps, { setStatusMessage })(PaymentForm);

//export default withRouter(PaymentForm);

// React.render(<PaymentForm />, document.body);
