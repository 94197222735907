import React from "react";

export default () => {

  let hoaname = sessionStorage.getItem('hoaname')
  let hoastr = sessionStorage.getItem('selectedhoa');
  let hoaobj = { help_text: { permissions: "<h2>No special permissions" } };
  if (hoastr) hoaobj = JSON.parse(hoastr);



  return (
    <div>
      {/* <p>from db</p> */}
      <div dangerouslySetInnerHTML={{ __html: hoaobj['help_text']['permissions'] }}></div>
    </div>
  )


  // return (
  //   <div>
  //     <h6><b>Permissions</b></h6>
  //     <p>
  //       Contact {hoaname} Property Management (PM) to request permission to temporarily park any vehicle not allowed per {hoaname} regulations. You can call or send a message to the PM through the application once you have logged in as an owner or guest. </p>
  //     <ul>

  //       <li> Any additional vehicle over the assigned limit. NOTE: Limits can be changed during peak times.</li>
  //       <li> For any oversized vehicle or trailer (wider or 4 feet longer than its parking space).</li>
  //       <li> Contractor dumpster or storage trailer. NOTE: A miminum of one week notice must be submitted to {hoaname} Property Management for approval. </li>
  //       <li>If permission is granted, you must register the vehicle (if it hasn’t been previously registered). Oversized vehicles must be parked either in the lower lot or parallel to the upper lot cliff.</li>
  //     </ul>

  //   </div>

  // );
};