import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';


export default (align) => {
  return (
    <div style={{ textAlign: { align }, padding: "10px" }}>
      <FontAwesomeIcon icon={faSpinner} spin size="2x" color="#007bff" />

    </div>
  );
};
