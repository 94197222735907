import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'


//import { connect } from "react-redux";
import { loginRenter } from "../../actions/authActions";
import TextFieldGroupPlain from "../common/TextFieldGroupPlain";
import LoadingMessage from "../common/LoadingMessage";
import { getUnitNumbers } from '../parking/AjaxHelper';
//import SelectNameValue from "../common/SelectNameValue";
import SelectNameValuePlain from "../common/SelectNameValuePlain";



//class RenterLogin extends Component {
function RenterLogin(props) {
  const [unitnumber, setUnitnumber] = useState('1234');
  const [pincode, setPincode] = useState('4321');
  const [unitnumber_list, setUnitnumber_list] = useState([]);
  const [errors, setErrors] = useState({ message: "heck errors" });
  //const [reduxerrors, setReduxerrors] = useState({ message: "heck redux" });

  const reduxerrors = useSelector(state => state.errors);

  let dispatch = useDispatch();
  let navigate = useNavigate();

  let usedemo = sessionStorage.getItem('use_demo_mode')
  //console.log('use demo', usedemo)
  let unitnumberstr = '';
  let pincodestr = ''
  if (usedemo === "1") {
    //unitnumberstr = "1234";
    unitnumberstr = "204";
    pincodestr = "4321"
  }

  const [logonInfo, setLogonInfo] = useState({
    unitnumber: unitnumberstr,
    pincode: pincodestr
  });

  const handleChange = (event) => {
    setLogonInfo({ ...logonInfo, [event.target.name]: event.target.value });
  };


  // constructor() {
  //   super();
  //   let unum = '';
  //   let pc = '';
  //   if (parseInt(sessionStorage.getItem('use_demo_mode')) === 1) {
  //     unum = '1234';
  //     pc = '4321';
  //   }
  //   // this.state = {
  //   //   // jim

  //   //   unitnumber: unum,
  //   //   pincode: pc,
  //   //   // unitnumber: "",
  //   //   // pincode: "",
  //   //   unitnumber_list: [],
  //   //   errors: {},
  //   // };

  //   this.onChange = this.onChange.bind(this);
  //   this.onSubmit = this.onSubmit.bind(this);
  // }

  // this will make the user go to shuttles if they use localhost:3000/login or real url
  // componentDidMount() {
  //   let navigate = useNavigate();
  //   //console.log()
  //   if (this.props.auth.isAuthenticated) {
  //     //  this.props.history.push("/renterdashboard");
  //     return;
  //   }
  // console.log("guest login component did mount");

  useEffect(() => {
    getTheUnitNumbers()
  }, []);

  const getTheUnitNumbers = () => {


    getUnitNumbers().then(res => {
      // console.log('getunitnumbers return data ', res);
      let uary = res.units;
      let vary = res.vehicles;
      let parking_allowed_unit = res.parking_allowed_unit;
      let parking_allowed_renter = res.parking_allowed_renter;
      let season_adjust_unit = res.season_adjust_unit;
      let season_adjust_renter = res.season_adjust_renter;

      let tary = [];
      let goodary = [];

      // console.log('pau par', parking_allowed_unit, parking_allowed_renter);
      // console.log('sau sar', season_adjust_unit, season_adjust_renter);

      let fary = uary.filter(el => (el.status_flag === 1));
      goodary = fary;
      goodary.sort(function (a, b) {
        if (a['unitnumber'] < b['unitnumber']) return -1;
        if (a['unitnumber'] > b['unitnumber']) return 1;
        if (a['unitnumber'] === b['unitnumber']) return 0;
      });

      let emptyoption = { name: "Select", value: "" };
      tary.push(emptyoption);

      goodary.forEach(el => {
        let nv = {};
        nv['name'] = 'Unit: ' + el['unitnumber'];
        nv['value'] = el['unitnumber'];
        tary.push(nv);
      });
      setUnitnumber_list(tary);
    }).catch(error => {
      console.log('error', error);
    });
  }


  // componentDidUpdate(prevProps) {
  //   //console.log('renter login componend did update');
  //   if (this.props.auth.isAuthenticated) {
  //     // this.props.history.push("/renterdashboard");
  //   }

  // }



  const onSubmit = (e) => {
    e.preventDefault();

    // if (this.state.unitnumber === "") {
    //   alert('please select a unit');
    //   return;
    // }

    const userData = {
      unitnumber: logonInfo.unitnumber,
      pincode: logonInfo.pincode,
      hoaid: sessionStorage.getItem('hoaid')
    };

    let err = {};

    if (userData['unitnumber'] === '') {
      err['unitnumber'] = 'Please enter a valid unitnumber';
      setErrors(err);
      return;
    }
    if (userData['pincode'] === '') {
      err['pincode'] = 'Please enter a valid pincode';
      setErrors(err);
      return;
    }
    // console.log('submit renter login', userData);
    //, this.props.history
    dispatch(loginRenter(userData, navigate));
    // this.props.loginRenter(userData, null);
  }

  const onCancelClick = (e) => {
    e.preventDefault();
    // window.location = "/";
    // this.props.history.goBack();
    let url = "/" + sessionStorage.getItem('hoaid');
    // window.location.href = url;
    ///this.props.history.push(url);
    //window.location = "/id/" + sessionStorage.getItem('hoaid');
    navigate(-1)
  }

  const okelement = <FontAwesomeIcon icon={faCheckCircle} color="#65598a" size="2x" title="enter / submit" />
  const cancelelement = <FontAwesomeIcon icon={faTimesCircle} color="black" size="2x" title="cancel / close" />

  // console.log('errors are', errors);
  let bgimage1 = sessionStorage.getItem('background_image_url');

  return (
    <div className="landing" style={{
      backgroundImage: `url("${bgimage1}")`
    }}>


      <div className="inputbox" style={{ maxWidth: "700px", margin: "0px auto" }}>
        <div className="innerinputbox">
          {/* {(this.props.parking.isloading) ? (<LoadingMessage />) : null} */}
          {/* {(this.props.deals.isloading) ? (<LoadingMessage />) : null} */}


          <p className="text-center">{sessionStorage.getItem('hoaname')}</p>
          <h4 className="text-center mb-4">Renter Checkin</h4>


          <div className="grid-container-2-13">
            <div className="grid-item-bold-a">Unit #</div>
            <div className="grid-item-normal-a" style={{ marginBottom: "5px" }}>
              <SelectNameValuePlain
                label="Unit Number"
                list={unitnumber_list}
                name="unitnumber"
                value={logonInfo.unitnumber}
                onChange={handleChange}
                error={errors.unitnumber}
              />
            </div>
            <div className="grid-item-bold-a">Pin Code</div>
            <div className="grid-item-normal-a" style={{ marginBottom: "5px" }}>
              <TextFieldGroupPlain
                type="password"
                label="Pin Code"
                placeholder="Pin Code"
                name="pincode"
                value={logonInfo.pincode}
                onChange={handleChange}
                error={errors.pincode}
              />
            </div>
            <div className="grid-item-normal-a">
              &nbsp;

            </div>
            <div className="grid-item-normal-a">



              <span className="iconbuttonspan">
                <Link to=""
                  onClick={(e) => onSubmit(e)} >
                  {okelement}
                </Link>
              </span>
              <span className="iconbuttonspan">
                <Link to=""
                  onClick={(e) => onCancelClick(e)} >
                  {cancelelement}
                </Link>
              </span>



            </div>
          </div>

          {/* <div style={{ display: "flex", gap: "20px", flexDirection: "column" }}>
            <div className="form-group">
              <SelectNameValue
                label="Unit Number"
                list={unitnumber_list}
                name="unitnumber"
                value={logonInfo.unitnumber}
                onChange={handleChange}
                error={errors.unitnumber}
              />
            </div>

            <div className="form-group">
              <TextFieldGroup
                type="password"
                label="Pin Code"
                placeholder="Pin Code"
                name="pincode"
                value={logonInfo.pincode}
                onChange={handleChange}
                error={errors.pincode}
              />
            </div>
            <div className="row">
              <div className="col-md-8 offset-md-4">

                <span className="iconbuttonspan">
                  <Link to=""
                    onClick={(e) => onSubmit(e)} >
                    {okelement}
                  </Link>
                </span>
                <span className="iconbuttonspan">
                  <Link to=""
                    onClick={(e) => onCancelClick(e)} >
                    {cancelelement}
                  </Link>
                </span>



              </div>
            </div>
          </div> */}


          {reduxerrors && (
            <div className="error-display">{reduxerrors.message}</div>
          )}
        </div>

      </div>

    </div>


  );
  // }
}

// const mapStateToProps = (state) => ({
//   auth: state.auth,
//   parking: state.parking,
//   errors: state.errors,
// });
export default RenterLogin;

//export default connect(mapStateToProps, { loginRenter })(RenterLogin);
