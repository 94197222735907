import React, { Component } from "react";
import { Link } from "react-router-dom";


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import SectionGeneral from "./SectionGeneral";
import SectionPermissions from "./SectionPermissions";
import SectionContact from "./SectionContact";
import HelpNavigation from "./HelpNavigation";



//import ContactAdmin from "../ContactAdmin";



class HelpApp extends Component {

  constructor(props) {
    super(props);
    // console.log('HelpApp props', props)
    this.state = {
      // hoa: props.hoa,
      whichhelp: '',
      showcontactadmin: false,
      hoa_phone_cell: '',
      hoa_phone_office: '',
      hoa_email: '',
      // hoa_general_help: '',
      errors: {}
    };


  }

  componentDidMount() {
    let shoastr = sessionStorage.getItem('selectedhoa');
    if (shoastr) {
      let ahoa = JSON.parse(shoastr);

      let cphone = ahoa['hoa_phone_cell'];
      let ophone = ahoa['hoa_phone_office'];
      let oemail = ahoa['hoa_email'];
      // let generalhelp = ahoa['help_text']['general']
      this.setState({
        hoa_phone_cell: cphone, hoa_phone_office: ophone,
        hoa_email: oemail
      })
    }
    //console.log('admin help phones', cphone, ophone);
  }



  // onCancelClick(e) {
  //   e.preventDefault();
  //   console.log('help app about to go back using history')
  //   this.props.history.goBack();
  //   //let url = "/" + sessionStorage.getItem('hoaid');
  //   // window.location.href = url;
  //   // this.props.history.push(url);
  //   //window.location = "/id/" + sessionStorage.getItem('hoaid');
  // }


  showContactAdmin = (e) => {
    e.preventDefault();
    //let showcontactadmin = !this.state.showcontactadmin;
    this.setState({ showcontactadmin: true });
  }
  closeform = () => {
    // console.log('this is close form');
    this.setState({ showcontactadmin: false });
  }




  render() {

    let hoaname = sessionStorage.getItem('hoaname')

    return (

      <div className="tenpoint bordernoshadow" >
        <h5 style={{ textAlign: "center" }}>
          {hoaname} Parking
        </h5>

        <SectionGeneral />

        {/* <HelpNavigation /> */}

        <SectionPermissions />

        {/* <SectionContact which={"App"} /> */}

        {/* <ContactAdmin /> */}


      </div>
    );
  }
}


export default ((HelpApp));
