import React, { Component } from "react";
//import PropTypes from "prop-types";
//import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
//import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'

import SectionContact from "../parking/help/SectionContact";

import { getTodayAsString } from "../parking/DateHelper";




import { connect } from "react-redux";




//
//import TextFieldGroup from "../../common/TextFieldGroup";
import TextFieldGroupPlain from "../common/TextFieldGroupPlain";
import SelectNameValuePlain from "../common/SelectNameValuePlain";
import TextAreaGroup from "../common/TextAreaGroup";

import { validateNotifyHoaInformation } from "../../validation/client_notifymanagement";

//import ParkingHelper from "../../config/parkingHelper";

import { insertNotification, showStatusMessage } from "../../actions/parkingActions";

//import { validateInsertModifyVehicleInput } from "../../validation/client_insertvehiclevalidation";
//import { getParkingSpots } from '../parking/AjaxHelper';





class ContactAdmin extends Component {
  constructor(props) {
    super(props);




    let d = new Date();

    let dstr = getTodayAsString();

    let hrs = d.getHours();
    let mins = d.getMinutes();
    let secs = d.getSeconds();
    if (hrs < 10) hrs = "0" + hrs;
    if (mins < 10) mins = "0" + mins;
    if (secs < 10) secs = "0" + secs;

    let tstr = hrs + ':' + mins + ':' + secs;

    this.state = {
      notify_type: "APPLICATION",
      notify_name: "",
      notify_phone: "",
      notify_unit: "",
      notify_plate: "",
      notify_description: "",
      notify_date: dstr,
      notify_time: tstr,
      hoaid: sessionStorage.getItem('hoaid'),
      whichphoneview: 1,

      errors: {},
    };





    this.onChange = this.onChange.bind(this);
    // this.onCBChange = this.onCBChange.bind(this);
    // this.onSubmit = this.onSubmit.bind(this);
    // this.onFileInputChange = this.onFileInputChange.bind(this);
    // this.handleMapClick = this.handleMapClick.bind(this);
  }



  static getDerivedStateFromProps(props, state) {
    if (props.vehicle !== state.vehicle) {
      return {
        vehicle: props.vehicle
      }
    }
    return null;
  }

  componentDidMount() {

    let viewnum = 1;
    if (window.innerWidth < 800) {
      viewnum = 2;
    }
    this.setState({

      whichphoneview: viewnum
    });


  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.vehicle !== this.state.vehicle) {
      // this.loadState(this.props, null);
    }
  }
  onChange(e) {

    this.setState({ [e.target.name]: e.target.value, errors: {} });

  }





  onSubmit(e) {
    e.preventDefault();



    let submitdata = {};
    submitdata['notify_type'] = this.state.notify_type;
    submitdata['notify_name'] = this.state.notify_name;
    submitdata['notify_phone'] = this.state.notify_phone;
    submitdata['notify_unit'] = this.state.notify_unit;
    submitdata['notify_plate'] = this.state.notify_plate;
    submitdata['notify_description'] = this.state.notify_description;
    submitdata['notify_date'] = this.state.notify_date;
    submitdata['notify_time'] = this.state.notify_time;
    submitdata['hoaid'] = this.state.hoaid;

    const { errors, isValid } = validateNotifyHoaInformation(submitdata);
    if (!isValid) {
      this.setState({ errors: errors });
      return;
    }




    // console.log('submit data is', submitdata);
    this.props.insertNotification(submitdata, this.props.history);



    // const { errors, isValid } = validateInsertModifyVehicleInput(submitdata);
    // if (!isValid) {
    //   this.setState({ errors: errors });
    //   return;
    // }

    // // this.props.closeform();


    // if (this.state.which === 'modify') {
    //   this.props.modifyVehicle(submitdata, this.props.history);
    // } else {
    //   this.props.insertVehicle(submitdata, this.props.history);
    // }

    // setTimeout(() => {
    //   this.props.closeform();
    // }, 3000);




  }


  handleChange = name => {
    this.setState({ name });
    console.log("handleChange", name);
  };


  handleSelect = name => {
    //console.log("handleSelect", lookup);
    let rary = name.split(",");
    let tname = rary[0];
    this.setState({
      name: tname
      // lookup: tname
    });
  }
  closeForm = (e) => {
    e.preventDefault();
    // console.log('goint to close form');
    this.props.closeform();
  }



  render() {
    // const { errors } = this.state;
    // const errors = this.props.errors;
    const errors = this.state.errors;
    const perrors = this.props.errors;
    // const { which } = this.state;
    // const { isguest } = this.state;
    // // const { unitnumber } = this.state;
    // const { ownercount } = this.state;
    // const { showterms } = this.state;
    // const { termsaccepted } = this.state;
    // const { vehicletypelist } = this.state;

    let helpquestions = [
      { value: "APPLICATION", name: "Application Feedback" },
      { value: "PARKING", name: "Parking Issue" },
      { value: "TRASH", name: "Trash Issue" },
      { value: "NOISE", name: "Excessive Noise" },
      { value: "CRITTER", name: "Wildlife Issue" },
      { value: "OTHER", name: "Other" }
    ];


    // console.log('perrors', perrors);

    // if (vehicletypelist.length === 0) {
    //   return null;
    // }






    //const searchOptions = {};


    const okicon = <FontAwesomeIcon icon={faCheckCircle} color="#65598a" size="2x" title="enter / submit" />
    const okicondisable = <FontAwesomeIcon icon={faCheckCircle} color="#ddd" size="2x" />
    const cancelicon = <FontAwesomeIcon icon={faTimesCircle} color="black" size="2x" title="cancel / close" />

    // const okicon = <FontAwesomeIcon icon="check-circle" color="#65598a" size="2x" />
    // const okicondisable = <FontAwesomeIcon icon="check-circle" color="#ddd" size="2x" />
    // const cancelicon = <FontAwesomeIcon icon="times-circle" color="black" size="2x" />





    return (
      <div className="register" style={{ backgroundColor: "white" }} >
        <div style={{ marginBottom: "15px" }}>

          <div className="row" style={{ marginTop: "10px" }}>
            <div className="col-md-12 offset-md-0">
              <SectionContact which={"App"} />
            </div>
          </div>




          {/* <p className=" text-center">Notify Administator</p> */}

          <div className="row" style={{ marginTop: "10px" }}>

            <div className="col-md-12 offset-md-0">
              <h6><b>Comments</b></h6>
            </div>
          </div>
          <div className="row" style={{ marginTop: "0px" }}>
            <div className="col-md-10 offset-md-1" style={{ fontSize: "10pt" }}>

              <form noValidate >
                <div className="row mb-2">
                  <div className="col-md-3">
                    Topic
                  </div>
                  <div className="col-md-9">
                    <SelectNameValuePlain
                      label="Topic"
                      list={helpquestions}
                      name="notify_type"
                      value={this.state.notify_type}
                      onChange={this.onChange}
                      error={errors.notify_type}
                    />


                  </div>
                </div>



                <div className="row mb-2">
                  <div className="col-md-3">
                    Name</div>
                  <div className="col-md-9">
                    <TextFieldGroupPlain
                      type="text"
                      label="Name"
                      placeholder="Name"
                      name="notify_name"
                      value={this.state.notify_name}
                      onChange={this.onChange}
                      error={errors.notify_name}
                    />
                  </div>
                </div>

                <div className="row mb-2">
                  <div className="col-md-3">
                    Phone</div>
                  <div className="col-md-9">
                    <TextFieldGroupPlain
                      type="text"
                      label="Phone"
                      placeholder="Phone"
                      name="notify_phone"
                      value={this.state.notify_phone}
                      onChange={this.onChange}
                      error={errors.notify_phone}
                    />
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-md-3">
                    Unit #</div>
                  <div className="col-md-9">
                    <TextFieldGroupPlain
                      type="text"
                      label="Unit"
                      placeholder="Unit"
                      name="notify_unit"
                      value={this.state.notify_unit}
                      onChange={this.onChange}
                      error={errors.notify_unit}
                    />
                  </div>
                </div>


                <div className="row mb-2">
                  <div className="col-md-3">
                    Comment</div>
                  <div className="col-md-9">
                    <TextAreaGroup
                      type="text"
                      label="Comment"
                      placeholder="Comment"
                      name="notify_description"
                      value={this.state.notify_description}
                      onChange={this.onChange}
                      error={errors.notify_description}
                    />
                  </div>
                </div>

              </form>



              <div className="row" style={{ marginTop: "10px" }}>


                <div className="col-md-12">
                  <span className="iconbuttonspan">
                    <Link to=""
                      onClick={(e) => this.onSubmit(e)} >
                      {okicon}
                    </Link>
                  </span>

                  <span className="iconbuttonspan">
                    <Link to=""
                      onClick={(e) => this.closeForm(e)} >
                      {cancelicon}
                    </Link>
                  </span>
                </div>

              </div>

            </div>

          </div>


          {perrors && (
            <div className="error-display">{perrors.message}</div>
          )}

        </div>
      </div>



    );
  }





  // this is good practice because it will help debugging
  // it is not checked when in production mode.
  // Business.propTypes = {
  //   createBusiness: PropTypes.func.isRequired,
  //   modifyBusiness: PropTypes.func.isRequired,
  //   auth: PropTypes.object.isRequired,
  //   errors: PropTypes.object.isRequired
};

// map errors to this component
// then we can use this.props.auth.user etc
// since we mapped error, we can use componentWillReceiveProps method
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
});
// the state.auth above comes from rootReducer in index.js in reducers.

export default connect(
  mapStateToProps,
  { insertNotification }
)((ContactAdmin));
// wrap the Register with withRouter so the authAction can use history to redirect
