import axios from "axios";
//import { session } from "passport";
import { getTodayAsString } from "./DateHelper";



export const getActiveCounts = (oid) => {
  return new Promise((resolve, reject) => {
    let url = "/api/parking/get_active_count";

    let dstr = getTodayAsString();


    let shoastr = sessionStorage.getItem('selectedhoa');
    let ahoa = JSON.parse(shoastr);

    //console.log('ahoa', ahoa);
    let maxunitactive = parseInt(ahoa['parking_allowed_unit']);
    let maxrenteractive = parseInt(ahoa['parking_allowed_renter']);
    let maxallactive = parseInt(ahoa['parking_allowed_hoa']);
    let maxowneractive = maxunitactive - maxrenteractive;
    let seasonaladjust = 0;
    let renter_free_parking_spots = parseInt(ahoa['renter_free_parking_spots']);
    let owner_free_parking_spots = parseInt(ahoa['owner_free_parking_spots']);
    let season_adjust_unit = parseInt(ahoa['season_adjust_unit']);
    let season_adjust_renter = parseInt(ahoa['season_adjust_renter']);
    let season_parking_fee_factor = parseFloat(ahoa['season_parking_fee_factor']);
    let use_availability_factor = ahoa['use_availability_factor'];

    let qobj = {};
    qobj['hoaid'] = sessionStorage.getItem('hoaid');
    qobj['ownerid'] = oid;
    qobj['dstr'] = dstr;
    axios.post(url, qobj).then(data => {
      //  console.log('ajax helper data is ', data.data);
      let rdata = {};
      let allcount = data.data.allcount;
      let unitcount = data.data.unitcount;
      let rentercount = data.data.rentercount;
      let ownercount = unitcount - rentercount;
      let usr = data.data.user;
      maxunitactive = usr['parking_allowed'] - season_adjust_unit; //seasonaladjust;
      maxrenteractive = usr['parking_allowed_renter'] - season_adjust_renter;
      maxowneractive = maxunitactive - rentercount; //maxguestactive;

      // console.log('get counts user maxowneractive', maxowneractive);

      rdata['allcountok'] = true;
      rdata['ownercountok'] = true;
      rdata['rentercountok'] = true;
      rdata['allcount'] = allcount;
      rdata['unitcount'] = unitcount;
      rdata['ownercount'] = ownercount;
      rdata['rentercount'] = rentercount;
      rdata['maxunitactive'] = maxunitactive;
      rdata['maxrenteractive'] = maxrenteractive;
      rdata['maxallactive'] = maxallactive;
      rdata['renter_free_parking_spots'] = renter_free_parking_spots;
      rdata['owner_free_parking_spots'] = owner_free_parking_spots;
      rdata['season_adjust_unit'] = season_adjust_unit;
      rdata['season_adjust_renter'] = season_adjust_renter;
      rdata['season_parking_fee_factor'] = season_parking_fee_factor;
      rdata['use_availability_factor'] = use_availability_factor;




      if (allcount >= maxallactive) {
        rdata['allcountok'] = false;
      }
      if (ownercount >= maxowneractive) {
        rdata['ownercountok'] = false;
      }
      if (rentercount >= maxrenteractive) {
        rdata['rentercountok'] = false;
      }
      sessionStorage.setItem('activecountdata', JSON.stringify(rdata));
      //  console.log('activecountdata', rdata);
      resolve(rdata);
    }).catch(err => {
      reject(err);
    });
  });
}


// export const bothgetUnitNumbers = () => {
//   return new Promise((resolve, reject) => {
//     let url1 = "/api/parking/get_unit_numbers";
//     let url2 = "/api/parking/get_active_vehicles";
//     let rdata = {};
//     axios.get(url1).then(units => {
//       //   console.log('units numbers data', units.data);
//       rdata['units'] = units.data;
//       axios.get(url2).then(vehicles => {
//         console.log('vehicles numbers data', vehicles.data);
//         rdata['vehicles'] = vehicles.data;
//         resolve(rdata);
//       }).catch(err => {
//         // console.log("in getActive4380Devices response");
//         // console.log(err);
//         reject(err);
//       });


//     }).catch(err => {
//       // console.log("in getActive4380Devices response");
//       // console.log(err);
//       reject(err);
//     });
//   });
// }


export const getUnitNumbers = () => {
  return new Promise((resolve, reject) => {

    // let hoaid = sessionStorage.getItem('hoaid');
    // let hoastr = sessionStorage.getItem('hoa_data');
    // let hoaary = JSON.parse(hoastr);

    // let thehoa = hoaary.filter(el => el['hoaid'] = hoaid);
    // // console.log(thehoa);
    // let ahoa = thehoa[0];

    let dstr = getTodayAsString();

    let shoastr = sessionStorage.getItem('selectedhoa');
    let ahoa = JSON.parse(shoastr);

    // console.log('getUnitNumbers the hoa is ', hoaid, ahoa);
    let parking_allowed_unit = ahoa['parking_allowed_unit'];
    let parking_allowed_renter = ahoa['parking_allowed_renter'];
    let season_adjust_unit = ahoa['season_adjust_unit'];
    let season_adjust_renter = ahoa['season_adjust_renter'];

    let url1 = "/api/parking/get_unit_numbers";  // this gets all units that are not admin
    //  let url2 = "/api/parking/get_active_vehicles";
    let rdata = {};
    let qobj = {};
    qobj['hoaid'] = sessionStorage.getItem('hoaid');
    qobj['dstr'] = dstr;
    //  axios.post(url3, qobj).then(data => {
    axios.post(url1, qobj).then(units => {
      // console.log('units numbers data', units.data);
      rdata['parking_allowed_unit'] = parking_allowed_unit;
      rdata['parking_allowed_renter'] = parking_allowed_renter;
      rdata['season_adjust_unit'] = season_adjust_unit;
      rdata['season_adjust_renter'] = season_adjust_renter;
      rdata['units'] = units.data;
      rdata['vehicles'] = [];
      resolve(rdata);
      // axios.post(url2, qobj).then(vehicles => {
      //   // console.log('vehicles numbers data', vehicles.data);
      //   rdata['vehicles'] = vehicles.data;
      //   resolve(rdata);
      // }).catch(err => {
      //   // console.log(err);
      //   reject(err);
      // });


    }).catch(err => {
      // console.log(err);
      reject(err);
    });
  });
}

export const getParkingSummary = () => {
  return new Promise((resolve, reject) => {

    let shoastr = sessionStorage.getItem('selectedhoa');
    let ahoa = JSON.parse(shoastr);
    let hoaid = ahoa['hoaid'];

    let maxallactive = parseInt(ahoa['parking_allowed_hoa']);

    // console.log('dict data', maxowneractive, maxallactive);
    // console.log('hoa data', amaxowneractive, amaxallactive);


    let dstr = getTodayAsString();

    let url3 = "/api/parking/get_parking_summary"; // + oid;
    let qobj = {};
    qobj['hoaid'] = hoaid;
    qobj['dstr'] = dstr;
    axios.post(url3, qobj).then(results => {
      let rdata = {};
      let allcount = results.data.allcount;
      // let ownercount = data.data.ownercount;
      rdata['allcount'] = allcount;
      // rdata['ownercount'] = ownercount;
      rdata['maxallactive'] = maxallactive;
      // rdata['maxowneractive'] = maxowneractive;
      //console.log('getParkingSummary before resolve');
      resolve(rdata);
    }).catch(err => {
      // console.log("in getActive4380Devices response");
      // console.log(err);
      reject(err);
    });
  });
}


export const getParkingSpots = () => {
  return new Promise((resolve, reject) => {
    let hoaid = sessionStorage.getItem('hoaid');
    let url3 = "/api/parking/parking_spot_data"; // + oid;
    let qobj = {};
    qobj['hoaid'] = hoaid;
    axios.post(url3, qobj).then(data => {
      let rdata = {};
      data.data.forEach(el => {
        let key = el['spot_type'];
        rdata[key] = el;
      })
      //let rdata = {};
      resolve(rdata);
    }).catch(err => {
      // console.log("in getActive4380Devices response");
      // console.log(err);
      reject(err);
    });
  });
}
export const getParkingSpotsForHoaid = (hoaid) => {
  return new Promise((resolve, reject) => {
    let url3 = "/api/parking/parking_spot_data"; // + oid;
    let qobj = {};
    qobj['hoaid'] = hoaid;
    axios.post(url3, qobj).then(data => {
      let rdata = {};
      // data.data.forEach(el => {
      //   let key = el['spot_type'];
      //   rdata[key] = el;
      // })
      //let rdata = {};
      // console.log('gps', data);
      resolve(data.data);
    }).catch(err => {
      // console.log("in getActive4380Devices response");
      // console.log(err);
      reject(err);
    });
  });
}

