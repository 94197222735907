import React, { Fragment, useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import SmartButtonHPS from "../common/SmartButtonHPS";

//import { Button } from "react-bootstrap";
import { useNavigate } from 'react-router';

import axios from "axios";


import { getParkingSpotsForHoaid } from '../parking/AjaxHelper';


function LandingForHoa(props) {

  //console.log('landing page props', props);

  const [hoaname, setHoaname] = useState('Yampa');
  const [validhoa, setValidhoa] = React.useState(0);
  const [hoadata, setHoadata] = React.useState();
  const [hoaid, setHoaid] = React.useState('YV');
  const [bgimg, setBgimg] = React.useState('http://hoaparking.s3.amazonaws.com/steamboat-ski-resort.jpg')

  let navigate = useNavigate();

  useEffect(() => {
    //   console.log('landing for hoa use effect props', props)
    if (props.hoaid) {
      // console.log('we have a props id', props)
      // document.getElementById("breadcrumbdiv").innerHTML = ''
      hoapicked(props.hoaid)
    } else {
      console.log('we have a NO props so get list')
      //  getHoaSelectlist();
    }
  }, [props.hoaid]);




  const isAuthenticated = 0;


  // let bgimage1 = 'http://hoaparking.s3.amazonaws.com/steamboat-ski-resort.jpg';
  /* <SmartButtonHPS
  name="hoaid"
  onClick={buttonclick()}

/>
*/

  const gotologin = () => {
    navigate("/login")
  }
  const gotorenter = () => {
    navigate("/renterlogin")
  }

  const guestLinks = (


    <div className="flex_row_center">
      {/* <div className="flex_item">
        <SmartButtonHPS
          name="Owner"
          onClick={gotologin}

        />
      </div>
      <div className="flex_item">
        <SmartButtonHPS
          name="Renter"
          onClick={gotorenter}

        />
      </div> */}
      <div className="flex_item">
        <Link to="/login" style={{ width: "110px", margin: "5px" }} className="btn btn-md btn-info  btnparking">
          Owner
        </Link>
      </div>
      <div className="flex_item">
        <Link to="/renterlogin" style={{ width: "110px", margin: "5px" }} className="btn btn-md btn-info btnparking">
          Renter
        </Link>
      </div>
    </div>

  );

  const authLinks = (
    <div>
      Logout
    </div>
  );

  // const {isAuthenticated} = this.props.auth

  // const hoaname = 'yampa view condos'


  const hoapicked = (hoaid) => {
    //  console.log('hoapicked from widget is ', hoaid, hoaname);
    getParkingSpotsForHoaid(hoaid).then(spots => {
      sessionStorage.setItem('hoa_parking_spots', JSON.stringify(spots));
      //  console.log('spots', spots)
      getHoaData(hoaid)
      // this.setState({ hoaid: hoaid, hoaname: hoaname, validhoa: true, notready: false })
    }).catch(error => {
      console.log('error', error);
    });

  }

  const getHoaData = (hoaid) => {
    let url = "/api/parking/data_for_hoa/" + hoaid;
    //console.log('getHoaData url is ', url);
    axios.get(url).then(rdata => {
      let thedata = rdata.data;
    // console.log('data_for_hoa ', thedata);
      let hoaname = thedata['name'];
      setHoaid(hoaid);
      setHoaname(hoaname);
      sessionStorage.setItem('hoaid', hoaid);
      sessionStorage.setItem('hoaname', hoaname);
      sessionStorage.setItem('selectedhoa', JSON.stringify(thedata));
      sessionStorage.setItem('use_owner_creditcard', thedata['use_owner_creditcard']);
      sessionStorage.setItem('use_renter_creditcard', thedata['use_renter_creditcard']);
      sessionStorage.setItem('use_owner_ppp', thedata['use_owner_ppp']);
      sessionStorage.setItem('use_renter_ppp', thedata['use_renter_ppp']);
      sessionStorage.setItem('use_warning_status', thedata['use_warning_status']);
      sessionStorage.setItem('use_demo_mode', thedata['use_demo_mode']);
      sessionStorage.setItem('parking_allowed_hoa',thedata['parking_allowed_hoa']);
     // sessionStorage.setItem('availability_warning_buffer',thedata['availability_warning_buffer']);
      sessionStorage.setItem('availability_warning_buffer',thedata['availability_warning_buffer']);

      
    // sessionStorage.setItem('renter_free_parking_spots', thedata['renter_free_parking_spots']);
      sessionStorage.setItem('background_image_url', thedata['background_image_url']);
      setBgimg(thedata['background_image_url'])
      setHoadata(JSON.stringify(thedata, null, 2))
      // bgimage1 = thedata['background_image_url'];
      // this.setState({validhoa: true, notready: false, hoaname: hoaname, background_image_url: thedata['background_image_url'] })

      // this.hoapicked(hoaid, hoaname);

      //sessionStorage.setItem('use_renter_creditcard', 1);
      //sessionStorage.setItem('use_owner_ppp', 1);
      // sessionStorage.setItem('use_renter_ppp', 1);
      //sessionStorage.setItem('use_demo_mode', 1);
      // console.log('validhoa flag is  ', validhoa)
      let rte = "/" + hoaid
      //  navigate(rte)
      props.onBreadCrumb(hoaname)

      //console.log('should update div')

      // document.getElementById("breadcrumbdiv").innerHTML = hoaname
      // document.getElementById("breadcrumbdivphone").innerHTML = hoaname




    }).catch(err => {
      // console.log('WE HAVE AN ERROR');
      let msg = "No Data for HOA ID " + hoaid
      navigate("/")
      //this.setState({errors: {message: msg } })
      // this.props.history.push("/");
      // console.log(err);
    });

    // const {onNavigation} = useAuth();


  }

  const buildHoaSummary = () => {
    if (!hoadata) {
      return (null)
    }
    let data = JSON.parse(hoadata);
    // console.log('data is', data)

    let parking_allowed_hoa = data['parking_allowed_hoa'];
    let name = data['name'];
    let address = data['address'];
    let city = data['city'];
    let state = data['state'];
    let zip = data['zip'];
    let company = data['company'];

    // 65598a

    return (
      <div className="bordernoshadow" style={{
        color: "black", maxWidth: "400px",
        backgroundColor: "#f0f0f0", margin: "40px auto"
      }}>

        <div className="grid-container-2-a">
          <div className="grid-item-bold-a">Manager</div>
          <div className="grid-item-normal-a">{company}</div>
          {/* <div className="grid-item-bold-a">Name</div>
          <div className="grid-item-normal-a">{name}</div> */}
          <div className="grid-item-bold-a">Address</div>
          <div className="grid-item-normal-a">{address}</div>
          <div className="grid-item-bold-a">&nbsp;</div>
          <div className="grid-item-normal-a">{city} {state} {zip}</div>
          <div className="grid-item-bold-a">Parking Spaces</div>
          <div className="grid-item-normal-a">{parking_allowed_hoa}</div>
        </div>



      </div>
    )
  }


  return (
    <div style={{ textAlign: "center" }}>
      <div className="landing container-fluid" style={{
        border: "1px solid black",
        backgroundImage: `url("${bgimg}")`,
        paddingTop: "40px"
      }}>
        {/* <div className="landing-page-label2" style={{
          textAlign: "center",
          maxWidth: "300px", margin: "0px auto"

        }}>
          <h5 style={{ textAlign: "center", color: "white", padding: "10px" }} >Welcome</h5>
        </div> */}




        <div className="landing-page-label" style={{ maxWidth: "400px", margin: "20px auto" }}>
          <div style={{ margin: "20px auto" }}>
            <h5 style={{ marginTop: "10px", marginBottom: "15px", color: "white" }}>
              {hoaname}
            </h5>
            {guestLinks}
          </div>
          <p style={{ fontSize: "9pt", marginTop: "-10px", color: "#fff" }}>Parking Simplified!</p>
        </div>


        <div style={{ marginTop: "100px" }}>
          {buildHoaSummary()}
        </div>

        {/* <div style={{
          display: "block",
          margin: "20px auto", color: "black", height: "140px", padding: "20px",
          overflowY: "auto", maxWidth: "600px", backgroundColor: "#f0f0f0", textAlign: "left",
        }} className="bordernoshadow">

          <p style={{ textAlign: "center", color: "#000000", paddingTop: "0px" }} >
            Other HOA details displayed somewhere?
          </p>
          <pre style={{ fontSize: "9pt" }}>
            {hoadata}
          </pre>
        </div> */}


      </div>
    </div >

  );

}


export default LandingForHoa

