import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import AdminUserCsvUpload from "../admin/AdminUserCsvUpload";
import TextFieldGroupLabel from "../common/TextFieldGroup";




import { connect } from "react-redux";
import { showStatusMessage, softDeleteVehicles, hardDeleteVehicles } from "../../actions/parkingActions";
//import VehicleForm from "./VehicleForm";



//import axios from "axios";
//import classnames from "classnames"; 


import TextFieldGroup from "../common/TextFieldGroupPlain";

class HoaSettings extends Component {
  constructor() {
    super();
    let dstr = this.getDateString();
    this.state = {
      // name: "",
      // phone: "",
      email: "",
      // unitnumber: "",
      // pincode: "",
      // password: "",
      // password2: "",
      // userid: "",
      adddays: 0,
      parking_allowed_unit: 1,
      parking_allowed_renter: 0,
      parking_allowed_hoa: 0,
      renter_free_parking_spots: "0",
      owner_free_parking_spots: 0,
      season_adjust_unit: 0,
      season_adjust_renter: 0,
      season_parking_fee_factor: 1,
      use_availability_factor: false,
      use_warning_status: 0,
      availability_warning_buffer: 0,
      enddate: dstr,
      // hoa_phone_office: "",
      // hoa_phone_cell: "",



      errors: {}
    };

    this.onChange = this.onChange.bind(this);
    this.onCBChange = this.onCBChange.bind(this);
    // this.onSubmitName = this.onSubmitName.bind(this);
    // this.onSubmitPin = this.onSubmitPin.bind(this);
    // this.onSubmitPassword = this.onSubmitPassword.bind(this);
    this.onSubmitChangeDays = this.onSubmitChangeDays.bind(this);
    this.onSubmitLoadHoa = this.onSubmitLoadHoa.bind(this);


  }
  getDateString() {
    let dt = new Date();
    // dt.setMonth(dt.getMonth() + 1);
    dt.setDate(dt.getDate() - 7);
    let yr = dt.getFullYear();
    let mo = (dt.getMonth() + 1).toString();
    let day = dt.getDate().toString();
    if (mo.length === 1) mo = "0" + mo;
    if (day.length === 1) day = "0" + day;
    let rstr = yr + "-" + mo + "-" + day;
    return rstr;
  }

  componentDidMount() {
    // console.log('owner profile mounted');

    // console.log('season_adjust_renter', activecountobj['season_adjust_renter']);

    // let hoaid = sessionStorage.getItem('hoaid');
    // let hoastr = sessionStorage.getItem('hoa_data');
    // let hoaary = JSON.parse(hoastr);
    // let thehoa = hoaary.filter(el => el['hoaid'] = hoaid);
    // let activecountobj = thehoa[0];

    let shoastr = sessionStorage.getItem('selectedhoa');
    let activecountobj = JSON.parse(shoastr);



  //  console.log('season_adjust_renter activecountobj', activecountobj);

    if (this.props.auth.isAuthenticated) {
      // this.props.history.push("/dashboard");
      let role = this.props.auth.user.role;
      let uid = this.props.auth.user._id;
      let isadmin = false;
      if (role === 'admin') isadmin = true;
      this.setState({
        name: this.props.auth.user.name,
        phone: this.props.auth.user.phone,
        email: this.props.auth.user.email,
        unitnumber: this.props.auth.user.unitnumber,
        pincode: this.props.auth.user.pincode,

        userid: uid,
        isadmin: isadmin,
        adddays: 5,


        parking_allowed_unit: activecountobj['parking_allowed_unit'].toString(),
        parking_allowed_renter: activecountobj['parking_allowed_renter'].toString(),
        parking_allowed_hoa: activecountobj['parking_allowed_hoa'].toString(),
        renter_free_parking_spots: activecountobj['renter_free_parking_spots'].toString(),
        owner_free_parking_spots: activecountobj['renter_free_parking_spots'].toString(),

        // unit_free_parking_spots: activecountobj['unit_free_parking_spots'].toString(),
        season_adjust_unit: activecountobj['season_adjust_unit'].toString(),
        season_adjust_renter: activecountobj['season_adjust_renter'].toString(),
        season_parking_fee_factor: activecountobj['season_parking_fee_factor'].toString(),
        use_availability_factor: activecountobj['use_availability_factor'],
        use_warning_status: activecountobj['use_warning_status'],
        availability_warning_buffer: activecountobj['availability_warning_buffer'],

        // hoa_phone_office: activecountobj['hoa_phone_office'],
        // hoa_phone_cell: activecountobj['hoa_phone_cell'],
        // hoa_email: activecountobj['hoa_email'],

        // pm_phone_office: activecountobj['pm_phone_office'],
        // pm_phone_cell: activecountobj['pm_phone_cell'],
        // pm_email: activecountobj['pm_email'],

        // webmaster_email: activecountobj['webmaster_email'],
        parking_information_url: activecountobj['parking_information_url'],
        hoa_name: activecountobj['name'],






        //.toString()



      })

    }
  }



  // if we have errors this will run
  // componentWillReceiveProps(nextProps) {
  //   //console.log("register componentWillReceiveProps");
  //   if (nextProps.errors) {
  //     this.setState({ errors: nextProps.errors });
  //     // setState triggers a render
  //   }
  // }
  static getDerivedStateFromProps(props, state) {
    return {
      errors: props.errors
    };
  }

  //componentDidUpdate(prevProps) {
  // Typical usage (don't forget to compare props):
  // if (this.props.errors) {
  //   this.setState({ errors: this.props.errors });
  // }
  //}

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  // onCBChange(e) {
  //   let tval = 'false';
  //   let ischecked = e.target.checked;
  //   if (ischecked) tval = 'true';
  //   // console.log(e.target.name, ischecked);
  //   this.setState({ use_availability_factor: tval });
  //   //  termsaccepted
  // }


  onCBChange(e) {
    let tval = 0;
    let ischecked = e.target.checked;
    if (ischecked) tval = 1;
  //  console.log('tval ' + tval, 'ischecked',ischecked)
    this.setState({ use_warning_status: tval });
  }

  submitHoaChanges = (e) => {
    e.preventDefault();
    let hoaid = sessionStorage.getItem('hoaid');
    let sobj = {};
    sobj['hoaid'] = hoaid;
    sobj['parking_allowed_unit'] = this.state.parking_allowed_unit;
    sobj['parking_allowed_renter'] = this.state.parking_allowed_renter;
    sobj['parking_allowed_hoa'] = this.state.parking_allowed_hoa;
    sobj['renter_free_parking_spots'] = this.state.renter_free_parking_spots;
    sobj['owner_free_parking_spots'] = this.state.owner_free_parking_spots;
    sobj['season_adjust_unit'] = this.state.season_adjust_unit;
    sobj['season_adjust_renter'] = this.state.season_adjust_renter;
    sobj['season_parking_fee_factor'] = this.state.season_parking_fee_factor;
    sobj['use_availability_factor'] = this.state.use_availability_factor;
    sobj['use_warning_status'] = this.state.use_warning_status;
    sobj['availability_warning_buffer'] = this.state.availability_warning_buffer;

    // sobj['hoa_phone_office'] = this.state.hoa_phone_office;
    // sobj['hoa_phone_cell'] = this.state.hoa_phone_cell;
    // sobj['hoa_email'] = this.state.hoa_email;

    // sobj['pm_phone_office'] = this.state.pm_phone_office;
    // sobj['pm_phone_cell'] = this.state.pm_phone_cell;
    // sobj['pm_email'] = this.state.pm_email;

    // sobj['webmaster_email'] = this.state.webmaster_email;

    sobj['parking_information_url'] = this.state.parking_information_url;




  //   console.log('submit obj is ',sobj);
    let url = "/api/parking/updatehoa";
    let link2 = "/api/parking/hoa_data";

    axios.post(url, sobj).then(data => {
      axios
        .get(link2)
        .then(res => {
          let hoadata = res.data;
          // console.log('app did mount', hoadata);
          sessionStorage.setItem('hoa_data', JSON.stringify(hoadata));
          this.props.showStatusMessage("HOA Update Success");
        })
        .catch(err =>
          console.log(err)
        );
    }).catch(err => {
      // console.log("in getActive4380Devices response");
      console.log(err);
    });
  }







  onSubmitChangeDays(e) {
    e.preventDefault();
    let url = "/api/parking/adjust_dates";
    let qobj = {};
    qobj['numdays'] = this.state.adddays;
    axios.post(url, qobj).then(data => {
      alert(this.state.adddays + ' days added');
      // console.log(data)
    }).catch(err => {
      // console.log("in getActive4380Devices response");
      console.log(err);
    });
    // console.log('submit change days', this.state.changedays);
  }
  onSubmitLoadHoa(e) {
    e.preventDefault();
    let url = "/api/parking/insert_hoa";
    let qobj = {};

    axios.post(url, qobj).then(data => {
      alert('hoa loaded');
      // console.log(data)
    }).catch(err => {
      // console.log("in getActive4380Devices response");
      console.log(err);
    });
    // console.log('submit change days', this.state.changedays);
  }

  onSubmitLoadSpot(e) {
    e.preventDefault();
    let url = "/api/parking/insert_spot";
    let qobj = {};

    axios.post(url, qobj).then(data => {
      alert('spot loaded');
      // console.log(data)
    }).catch(err => {
      // console.log("in getActive4380Devices response");
      console.log(err);
    });
    // console.log('submit change days', this.state.changedays);
  }

  onSubmitChargeCard(e) {
    e.preventDefault();
    let url = "/api/parking/charge_credit_card";
    let qobj = {};

    axios.post(url, qobj).then(data => {
      alert('charge card success');
      console.log(data)
    }).catch(err => {
      // console.log("in getActive4380Devices response");
      console.log(err);
    });
    // console.log('submit change days', this.state.changedays);
  }

  onSubmitRemoveVehicles(e) {
    e.preventDefault();
    let url = "/api/parking/remove_vehicles";
    let qobj = {};

    alert('remove vehicle');

    // axios.post(url, qobj).then(data => {
    //   alert('hoa loaded');
    // }).catch(err => {
    //   console.log(err);
    // });

  }


  softDelete = (e) => {
    let { enddate } = this.state;
    let submitdata = { "enddate": enddate };
    e.preventDefault();
    //console.log('hard delete vehicles');
    this.props.softDeleteVehicles(submitdata);
  }


  hardDelete = (e) => {
    e.preventDefault();
    //console.log('hard delete vehicles');
    this.props.hardDeleteVehicles();
  }

  /*
  let maxunitactive = parseInt(ahoa['parking_allowed_unit']);
      let maxrenteractive = parseInt(ahoa['parking_allowed_renter']);
      let maxallactive = parseInt(ahoa['parking_allowed_hoa']);
      let renter_free_parking_spots = parseInt(ahoa['renter_free_parking_spots']);
      let unit_free_parking_spots = parseInt(ahoa['unit_free_parking_spots']);
      let season_adjust_unit = parseInt(ahoa['season_adjust_unit']);
      let season_adjust_renter = parseInt(ahoa['season_adjust_renter']);
      let season_parking_fee_factor = parseInt(ahoa['season_parking_fee_factor']);
      let use_availability_factor = ahoa['use_availability_factor'];
      */



  render() {
    const { errors } = this.state;
    //const { properties_ary } = this.state;

    let { use_availability_factor } = this.state;

    let {use_warning_status} = this.state;

    let struws = use_warning_status.toString();
   // console.log('use_warning_status',use_warning_status)

    let ischecked = 'checked';
    if (use_availability_factor === 'false') ischecked = '';



    const okelement = <FontAwesomeIcon icon={faCheckCircle} color="#65598a" size="2x" title="enter / submit" />
    const cancelelement = <FontAwesomeIcon icon={faTimesCircle} color="black" size="2x" title="cancel / close" />


    return (
      <div className="container-fluid">
        <h4 style={{ textAlign: "center", color: "white", marginTop: "10px" }} >HOA Settings</h4>








        <div className="inputbox">
          <div className="innerinputbox2">


            <p className="parking_label">Administrator
              <span style={{ marginLeft: "20px" }}>{this.state.email}</span>
            </p>


            <h6 style={{ backgroundColor: "#f0f0f0", padding: "5px" }}>HOA: {this.state.hoa_name}</h6>

            <div className="row mb-2">
              <div className="col-md-3">
                Total Parking Spots
              </div>
              <div className="col-md-3">
                <TextFieldGroup
                  type="text"
                  label="Days"
                  placeholder="parking_allowed_hoa"
                  name="parking_allowed_hoa"
                  value={this.state.parking_allowed_hoa}
                  onChange={this.onChange}
                  error={errors.parking_allowed_hoa}
                />
              </div>

              <div className="col-md-3">
                Renter Free Parking
              </div>
              <div className="col-md-3">
                <TextFieldGroup
                  type="text"
                  label="Days"
                  placeholder="renter_free_parking_spots"
                  name="renter_free_parking_spots"
                  value={this.state.renter_free_parking_spots}
                  onChange={this.onChange}
                  error={errors.renter_free_parking_spots}
                />
              </div>
            </div>




            <div className="row mb-2">
              <div className="col-md-3">
                Season Adjust Unit
              </div>
              <div className="col-md-3">
                <TextFieldGroup
                  type="text"
                  label="Days"
                  placeholder="season_adjust_unit"
                  name="season_adjust_unit"
                  value={this.state.season_adjust_unit}
                  onChange={this.onChange}
                  error={errors.season_adjust_unit}
                />
              </div>
              <div className="col-md-3">
                Season Adjust Renter
              </div>
              <div className="col-md-3">
                <TextFieldGroup
                  type="text"
                  label="Days"
                  placeholder="season_adjust_renter"
                  name="season_adjust_renter"
                  value={this.state.season_adjust_renter}
                  onChange={this.onChange}
                  error={errors.season_adjust_renter}
                />
              </div>
            </div>








            <div className="row mb-2">
              <div className="col-md-3">
                Season Parking Fee Factor
              </div>
              <div className="col-md-3">
                <TextFieldGroup
                  type="text"
                  label="Days"
                  placeholder="season_parking_fee_factor"
                  name="season_parking_fee_factor"
                  value={this.state.season_parking_fee_factor}
                  onChange={this.onChange}
                  error={errors.season_parking_fee_factor}
                />
              </div>

              <div className="col-md-3">
                Parking Information
              </div>

              <div className="col-md-3">
                <TextFieldGroup
                  type="text"
                  label="Parking Info"
                  placeholder="Parking Info"
                  name="parking_information_url"
                  value={this.state.parking_information_url}
                  onChange={this.onChange}
                  error={errors.parking_information_url}
                />
              </div>




            </div>

            <div className="row mb-2">
              <div className="col-md-3">
                Use Warning Status
              </div>
              <div className="col-md-3">
                {/* <TextFieldGroup
                  type="text"
                  label="uws"
                  placeholder="use_warning_status"
                  name="use_warning_status"
                  value={struws}
                  onChange={this.onChange}
                  error={errors.use_warning_status}
                /> */}

                <input
                  type="checkbox"
                  id="checkbox"
                  checked={use_warning_status}
                  onChange={this.onCBChange}
                />
              </div>
              <div className="col-md-3">
                Availability warning buffer
              </div>

              <div className="col-md-3">
                <TextFieldGroup
                  type="text"
                  label="Availability Buffer"
                  placeholder="Availability Buffer"
                  name="availability_warning_buffer"
                  value={this.state.availability_warning_buffer}
                  onChange={this.onChange}
                  error={errors.availability_warning_buffer}
                />
              </div>
            </div>


            <Link to=""
              onClick={(e) => this.submitHoaChanges(e)} >
              {okelement}
            </Link>
            <br /><br />
            <div style={{ fontSize: "10pt" }}>
              <div>Parking space limits for the unit and renters are set at the unit owner level.  <br />The default values are 3 maximum for the unit and 2 maximum for a renter.  <br />These can be overridden by the admin to allow for more spaces for the unit.</div>
              <br />
              <div className="row">
                <div className="col-md-2">
                  Max Parking HOA
                </div>
                <div className="col-md-10">
                  Number of spaces available for the HOA.  Adjust if spots are needed for snow storage
                </div>
              </div>
              <div className="row">
                <div className="col-md-2">
                  Renter Free Parking
                </div>
                <div className="col-md-10">
                  Number of spaces free for the Renter
                </div>
              </div>
              <div className="row">
                <div className="col-md-2">
                  Season Adjust Unit
                </div>
                <div className="col-md-10">
                  Subtract this number from the spaces available for the unit (Owner + Renter).  This can be a negative number.
                </div>
              </div>
              <div className="row">
                <div className="col-md-2">
                  Season Adjust Renter
                </div>
                <div className="col-md-10">
                  Substract this number from the spaces available for the Renter. This can be a negative number.
                </div>
              </div>
              <div className="row">
                <div className="col-md-2">
                  Season Parking Fee Factor
                </div>
                <div className="col-md-10">
                  Bump up the parking fee by this factor
                </div>
              </div>
              <div className="row">
                <div className="col-md-2">
                 Use Warning Status
                </div>
                <div className="col-md-10">
                  1 or 0 (true or false).  Show warning colored row when over limit instead of not letting them add vehicle
                </div>
              </div>
              <div className="row">
                <div className="col-md-2">
                 Availability warning buffer
                </div>
                <div className="col-md-10">
                 Issue a warning if number of available parking spots is less than or equal to the availability buffer value
                </div>
              </div>


              {/* <div className="row">
                <div className="col-md-2">
                  Use Availability Factor
                </div>

                <div className="col-md-10">
                  Bump up the parking fee according to availability.  Do not use
                </div>
              </div> */}
              <br />

            </div>




            <div className="row">
              <div className="col-md-6 offset-md-3">
                <AdminUserCsvUpload />
              </div>
            </div>

            <div className="row" style={{ marginTop: "20px" }}>
              <div className="col-md-6 offset-md-3" >
                <div className="inputbox">
                  <div className="innerinputbox2">
                    <p className="parking_label">Soft Delete Vehicles</p>
                    <TextFieldGroup
                      type="date"
                      placeholder="End Date"
                      name="enddate"
                      value={this.state.enddate}
                      onChange={this.onChange}
                      error={errors.enddate}
                    />
                    <p>This will soft delete the vehicle by changing the status flag to 9.  The status will be changed for all vehicles
                      who's checkout data is before the indicated date above.</p>

                    <Link to="" className="btn btn-info btn-block mt-4"
                      onClick={(e) => this.softDelete(e)} >
                      Submit
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="row" style={{ marginTop: "20px" }}>
              <div className="col-md-6 offset-md-3" >
                <div className="inputbox">
                  <div className="innerinputbox2">
                    <p className="parking_label">Hard Delete Vehicles</p>
                    <p>This will permanently remove vehicles from the database.  Use with caution.</p>

                    <Link to="" className="btn btn-info btn-block mt-4"
                      onClick={(e) => this.hardDelete(e)} >
                      Submit
                    </Link>
                  </div>
                </div>
              </div>
            </div>





          </div >
        </div >







      </div >

    );
  }
}


// this is good practice because it will help debugging
// it is not checked when in production mode.
//onClick={(e) => props.gotoBooking(e, obj.accommodations.booking_url)}
HoaSettings.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

// map errors to this component
// then we can use this.props.auth.user etc
// since we mapped error, we can use componentWillReceiveProps method
const mapStateToProps = state => ({
  auth: state.auth,
  parking: state.parking,
  errors: state.errors
});
// the state.auth above comes from rootReducer in index.js in reducers.

export default connect(
  mapStateToProps,
  { showStatusMessage, softDeleteVehicles, hardDeleteVehicles }
)((HoaSettings));
// wrap the Register with withRouter so the authAction can use history to redirect
