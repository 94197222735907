import React, { Component } from "react";
// we need to get into git
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import LoadingMessage from "../common/LoadingMessage";



import { getAllNotifications, deleteNotification } from "../../actions/parkingActions";
import { getTodayAsString } from "../parking/DateHelper";
import axios from "axios";




class AdminNotifications extends Component {
  constructor(props) {
    super(props);

    this.state = {
      whichphoneview: 1,
      hoaid: sessionStorage.getItem('hoaid'),
      notifications: [],
      show: false,
      sortdirection: 1,
      whichsort: 'violation_plate',

      errors: {}
    }; //shuttles: ["one", "two", "three"] };
    // console.log("Dashboard props", props);

  }

  componentDidMount() {
    let viewnum = 1;
    if (window.innerWidth < 800) {
      viewnum = 2;
    }
    // console.log('window inner width is ', window.innerWidth);
    this.setState({ whichphoneview: viewnum });
    this.props.getAllNotifications();
    //this.doGetVehicles(oid);
  }



  deleteTheNotification = (e, o) => {
    e.preventDefault();
    let oid = o['_id'];
    // alert(oid);
    // console.log('about to delete notification for ', oid)
    this.props.deleteNotification(oid);
  }


  togglePhoneView = (e, which) => {
    e.preventDefault();
    this.setState({ whichphoneview: which });
  }

  closeform = () => {
    // console.log('this is close form');
    this.setState({ show: false });
  }





  doSort = (e, which) => {
    e.preventDefault();
    let sd = this.state.sortdirection;
    sd = sd * -1;
    this.setState({ whichsort: which, sortdirection: sd });
  }

  togglePhoneView = (e, which) => {
    e.preventDefault();
    this.setState({ whichphoneview: which });
  }

  closenotifications = (e) => {
    e.preventDefault();
    this.props.closenotifications();
  }


  render() {



    let notifications = this.props.parking.current_notifications;
    let numnotifications = notifications.length;
    // let { whichsort } = this.state;
    // let { sortdirection } = this.state;
    // notifications = notifications.sort(function (a, b) {
    //   if (a[whichsort] < b[whichsort]) return (1 * sortdirection);
    //   if (a[whichsort] > b[whichsort]) return (-1 * sortdirection);
    //   if (a[whichsort] === b[whichsort]) return 0;
    // })

    const role = sessionStorage.getItem('userRole');


    let dstr = getTodayAsString();



    // email	name	phone	role	hoaid unit	pincode
    let notifications_template_grid = notifications.map((n, index) => (
      <div key={index} className="grid-container-9n">
        <div className="grid-item-normal">{n.notify_type}</div>
        <div className="grid-item-normal">{n.notify_plate}</div>

        <div className="grid-item-normal">{n.notify_name}</div>
        <div className="grid-item-normal">{n.notify_phone}</div>

        <div className="grid-item-normal">{n.notify_description}</div>
        <div className="grid-item-normal">{n.notify_date}</div>
        <div className="grid-item-normal">{n.notify_time}</div>
        <div className="grid-item-normal">{n.notify_unit}</div>
        <div className="grid-item-normal">
          <Link to=""
            onClick={(e) => this.deleteTheNotification(e, n)} >delete</Link>
        </div>
      </div>
    ));

    /*
    submitdata['notify_type'] = this.state.notify_type;
    submitdata['notify_name'] = this.state.notify_name;
    submitdata['notify_phone'] = this.state.notify_phone;
    submitdata['notify_unit'] = this.state.notify_unit;
    submitdata['notify_plate'] = this.state.notify_plate;
    submitdata['notify_description'] = this.state.notify_description;
    submitdata['notify_date'] = this.state.notify_date;
    submitdata['notify_time'] = this.state.notify_time;
    */



    let notifications_template_grid_phone = notifications.map((notification, index) => (
      <div className="bordershadow" key={index}>
        <div className="grid-item-bold">{index + 1}</div>
        <div key={index} className="grid-container-3" >
          <div className="grid-item-bold">Type</div>
          <div className="grid-item-bold">Plate</div>
          <div className="grid-item-bold">Name</div>
          <div className="grid-item-normal">{notification.notify_type}</div>
          <div className="grid-item-normal" >
            {notification.notify_plate}
          </div>
          <div className="grid-item-normal">{notification.notify_name}</div>
          <div className="grid-item-bold">Description</div>
          <div className="grid-item-bold">Phone</div>
          <div className="grid-item-bold">Unit</div>
          <div className="grid-item-normal">{notification.notify_description}</div>
          <div className="grid-item-normal">{notification.notify_phone}</div>
          <div className="grid-item-normal">{notification.notify_unit}</div>

          <div className="grid-item-bold">Date</div>
          <div className="grid-item-bold">Time</div>
          <div className="grid-item-bold">&nbsp;</div>
          <div className="grid-item-normal">{notification.notify_date}</div>
          <div className="grid-item-normal">{notification.notify_time}</div>
          <div className="grid-item-normal">
            <Link to=""
              onClick={(e) => this.deleteTheNotification(e, notification)} >delete</Link>
          </div>
        </div>
      </div>
    ));



    let phonesorttable = (
      <div style={{ borderBottom: "0px solid black", marginBottom: "10px" }}>
        <table>
          <thead><tr><th colSpan="5">Sort</th></tr></thead>
          <tbody>
            <tr>
              <td style={{ paddingRight: "10px" }}>
                <Link to=""
                  onClick={(e) => this.doSort(e, 'notify_plate')} >
                  <b>Plate</b>
                </Link>
              </td>
              <td style={{ paddingRight: "10px" }}>
                <Link to=""
                  onClick={(e) => this.doSort(e, 'notify_date')} >
                  <b>Date</b>
                </Link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );






    return (
      <div style={{ border: "0px solid yellow", marginTop: "10px" }}>


        <div className="inputbox">
          <div className="innerinputbox2">
            {(this.props.parking.isloading) ? (<LoadingMessage />) : null}

            <p className="parking_label">Feedback</p>


            {(numnotifications === 0) ? (<h3>No Feedback</h3>) : (



              <div style={{ fontSize: "10pt" }}>

                <div className="phoneview" style={{ fontSize: "10pt", border: "0px solid black" }}>
                  <div>{phonesorttable}</div>
                  <div>{notifications_template_grid_phone}</div>

                </div>
                <div className="tableview" style={{ fontSize: "10pt", border: "0px solid black", maxHeight: "400px", overflowY: "scroll", overflowX: "hidden" }} >
                  <div>
                    <div className="grid-container-9n">
                      <div className="grid-item-bold">Type</div>
                      <div className="grid-item-bold">
                        <Link to=""
                          onClick={(e) => this.doSort(e, 'notify_plate')} >
                          <b>Plate</b>
                        </Link>
                      </div>

                      <div className="grid-item-bold">
                        <Link to=""
                          onClick={(e) => this.doSort(e, 'notify_name')} >
                          <b>Name</b>
                        </Link>
                      </div>



                      <div className="grid-item-bold">Phone</div>

                      <div className="grid-item-bold">Description</div>
                      <div className="grid-item-bold">

                        <Link to=""
                          onClick={(e) => this.doSort(e, 'notify_date')} >
                          <b>Date</b>
                        </Link>
                      </div>
                      <div className="grid-item-bold">Time</div>
                      <div className="grid-item-bold">Unit</div>

                    </div>
                    <div>
                      {notifications_template_grid}
                    </div>
                  </div>





                </div>
              </div>
            )}


          </div >
        </div >



      </div >
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  parking: state.parking,
  errors: state.errors,
});

export default connect(mapStateToProps, { getAllNotifications, deleteNotification })(AdminNotifications);

//export default Dashboard;
