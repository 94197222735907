import React, { Component } from "react";
// we need to get into git
import { connect } from "react-redux";

import CreditCardForm from "./CreditCardForm.js";
import { Table } from "react-bootstrap";


import { Link } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { insertVehicle, modifyVehicle } from "../../actions/parkingActions";
import { getActiveCounts } from '../parking/AjaxHelper';
import LoadingMessage from "../common/LoadingMessage.js";
import { getTodayAsString } from "../parking/DateHelper";






class PayForParkingForm extends Component {
  constructor(props) {
    super(props);
    // console.log('pfpform props', props);
    let userrolestr = sessionStorage.getItem('userRole');


    //  console.log('enddate', props.cardata.enddate, 'seldate', props.selecteddate);




    this.state = {

      cardata: props.cardata,
      selecteddate: props.selecteddate,
      use_renter_creditcard: parseInt(sessionStorage.getItem('use_renter_creditcard')),
      use_owner_creditcard: parseInt(sessionStorage.getItem('use_owner_creditcard')),
      use_renter_ppp: parseInt(sessionStorage.getItem('use_renter_ppp')),
      use_owner_ppp: parseInt(sessionStorage.getItem('use_owner_ppp')),
      availability_warning_buffer: parseInt(sessionStorage.getItem('availability_warning_buffer')),

      maxunitactive: 0,


      userrole: userrolestr,

      isloading: true,
      rentercount: 0,
      renter_free_parking_spots: 0,
      numownercars: 0,
      ownerfree: 0,
      which: props.which,

      errors: {}
    }; //shuttles: ["one", "two", "three"] };
    // console.log("Dashboard props", props);
    this.onChange = this.onChange.bind(this);

  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  // static getDerivedStateFromProps(props, state) {
  //   if (props.millis !== state.millis) {
  //     return {
  //       millis: props.millis
  //     };
  //   }
  //   return null;
  // }

  componentDidUpdate(prevProps) {
    if ((this.props.selecteddate) !== (prevProps.selecteddate) || (this.props.vehicle_type) !== (prevProps.vehicle_type)) {
      // console.log('we have a change', this.props.selecteddate);
      // this.setStateValues();
    }

  }

  componentDidMount() {

    // console.log('cdm state car data enddate', this.state.cardata.enddate);
    //  console.log('cdm state selected date', this.state.selecteddate);

    let oid = this.props.auth.user['_id'];
    getActiveCounts(oid).then(res => {
      //  console.log('getActiveCounts res is ', res);
      this.setState({
        isloading: false,
        rentercount: res['rentercount'],
        ownercount: res['ownercount'],
        maxunitactive: res['maxunitactive'],
        allcount: res['allcount'],
        // renter_free_parking_spots: res['renter_free_parking_spots'],
        // owner_free_parking_spots: res['owner_free_parking_spots']

        renter_free_parking_spots: this.props.auth.user['renter_free_parking'],
        owner_free_parking_spots: this.props.auth.user['owner_free_parking']
      })
    }).catch(error => {
      console.log('error', error);
    });
  }



  // this is called by the credit card form
  closetheform = (e) => {
    if (e) e.preventDefault();
    //  console.log('close form ppp');
    this.props.sendclosetoparent();
  }

  closeallforms = (e) => {
    if (e) e.preventDefault();
    //  console.log('close from xxxx')
    this.props.closeallforms();
    // this.props.sendclosetoparent();
  }

  renderCarData = () => {
    const { cardata } = this.state;

    return (
      <div className="pppform" >
        <Table size="sm" style={{ width: "100%", fontSize: "10pt", textAlign: "left" }}>
          <thead>
            <tr >
              <td ><b>Make</b><br />{cardata['make']}</td>
              <td><b>Model</b><br />{cardata['model']}</td>
              <td><b>Year</b><br />{cardata['year']}</td>
            </tr>
          </thead>
          <tbody>

            <tr>
              <td><b>Plate</b><br />{cardata['plate']}</td>
              <td><b>State</b><br />{cardata['plate_state']}</td>
              <td><b>Type</b><br />{cardata['vehicle_type']}</td>
            </tr>

          </tbody>
        </Table>
      </div>
    )
  }



  renderFeeDatesNight = (transobj) => {
    return (
      <div>
        <div className="pppform" style={{ display: "flex", justifyContent: "space-between", textAlign: "left", fontSize: "10pt", padding: "10px" }}>
          <div ><b>Checkin</b><br />{transobj.parking_startdate}</div>
          <div ><b>Checkout</b><br />{transobj.parking_enddate}</div>
          <div ><b>Nights</b><br />{transobj.parking_nights}</div>
        </div>
      </div>
    )
  }

  renderFeeDatesAndPrices = (transobj) => {

    return (
      <div className="pppform">
        <div style={{ display: "flex", justifyContent: "space-evenly", fontSize: "10pt" }}>
          <div ><b>Checkin:</b>&nbsp;{transobj.parking_startdate}</div>
          <div ><b>Checkout:</b>&nbsp;{transobj.parking_enddate}</div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-evenly", fontSize: "10pt", marginTop: "10px" }}>
          <div ><b>Nights:</b>&nbsp;{transobj.parking_nights}</div>
          <div ><b>$/Night:</b>&nbsp;{transobj.dayprice}</div>
          <div ><b>Total:</b>&nbsp;{transobj.total_price}</div>
        </div>
      </div>
    )
  }

  // this is called by the main view
  renderFeeDataObj = (transobj) => {
    const { cardata } = this.state;
    let { ownercount } = this.state;
    let { owner_free_parking_spots } = this.state;
    let { rentercount } = this.state;
    let { renter_free_parking_spots } = this.state;
    // console.log('rentercount', rentercount, 'renter_free_parking_spots', renter_free_parking_spots)
    let which = cardata['which'];
    let userrole = sessionStorage.getItem('userRole');
    // console.log('userrole', userrole);
    if (userrole === 'owner') {
      if (this.state.use_owner_ppp) {
        if (this.state.use_owner_creditcard) {
          return (
            <div>
              {ownercount >= owner_free_parking_spots ? (
                this.renderFeeDatesAndPrices(transobj)
              ) : (
                this.renderFeeDatesNight(transobj)
              )}
            </div>
          )
        } else {
          return (
            <div >
              {this.renderFeeDatesNight(transobj)}
            </div>
          )
        }
      } else {
        return (<p>we have no ower ppp</p>)
      }
    }

    if (userrole === 'renter') {
      //  console.log('rendering renter fees ', this.state.use_renter_ppp, this.state.use_renter_creditcard);
      if (this.state.use_renter_ppp) {
        if (this.state.use_renter_creditcard) {
          return (
            <div >
              {rentercount >= renter_free_parking_spots ? (
                this.renderFeeDatesAndPrices(transobj)
              ) : (
                this.renderFeeDatesNight(transobj)
              )}
              {/* {this.renderFeeDatesAndPrices(transobj)} */}

            </div>
          )
        } else {
          return (
            <div  >
              {this.renderFeeDatesNight(transobj)}
            </div>
          )
        }


      } else {
        return (<p>we have no renter ppp</p>)
      }
    }
  }

  handlePaymentFromStax = (which, transobj) => {

    // console.log('we will submit the vehicle data  ', cardata);
   // console.log('we will submit the vehicle trans data  ', transobj);
    if (this.state.cardata.which === 'modify') {
      this.props.modifyVehicle(transobj, this.props.history);
    } else {
      this.props.insertVehicle(transobj, this.props.history);
    }

    // setTimeout(() => {
    //   this.props.closeallforms();
    // }, 3000);

  }




  performInsertVehicle = (e) => {
    e.preventDefault();
    let { cardata } = this.state;
    let { rentercount } = this.state;
    let { ownercount } = this.state;

    let { allcount } = this.state; //total number of vehicles
    let { availability_warning_buffer } = this.state;
    let parking_allowed_hoa = sessionStorage.getItem('parking_allowed_hoa')

    let use_warning_status = sessionStorage['use_warning_status'];

    cardata['enddate'] = this.state.selecteddate;
   //console.log('parking_allowed_hoa',parking_allowed_hoa,'allcount',allcount,'availability_warning_buffer',availability_warning_buffer)
  //  console.log('rentercount',rentercount,'renter free parking',this.state.renter_free_parking_spots)
 // console.log('performInsertVehicle rentercount',rentercount,'ownercount',ownercount)
  //  cardata['status_flag'] = 1;
  // cardata comes in with status of 1
    if ((rentercount >= this.state.renter_free_parking_spots)) {
      // console.log('ppp we need to set the status to 2')
      if (use_warning_status == 1) {
        cardata['status_flag'] = 2;
      }
    }

   // if ((ownercount + (rentercount+1)) == 3) {
    if ((ownercount + (rentercount+1) ==  parseInt(this.state.maxunitactive)) ){
      if (use_warning_status == 1) {
        cardata['status_flag'] = 2;
      }
     
    }

   

   // console.log('we will submit the vehicle', cardata,this.state.cardata.which);
    if (this.state.cardata.which === 'modify') {
      cardata['active_flag'] = 1
     // console.log('we will submit the vehicle', cardata,cardata.which);
      this.props.modifyVehicle(cardata, this.props.history);
    } else {
      this.props.insertVehicle(cardata, this.props.history);
    }

    setTimeout(() => {
      this.props.closeallforms();
    }, 3000);
  }



  createTransObj = () => {
    let { cardata } = this.state;
    let { selecteddate } = this.state;
    //  let transobj = cardata;
    let transobj = Object.assign({}, cardata);

    let dstr = getTodayAsString();

    let caroriginaldate = cardata['enddate'];

    let curveh = cardata['vehicle_type'];
    let carenddate = cardata['enddate'];
    if ((carenddate === '1970-01-01') || (carenddate < dstr)) {
      carenddate = dstr;
    }
    let which = cardata['which'];

    // console.log('which sd', which, selecteddate);
    // console.log('cur sel', currentdate, this.state.selecteddate);
    let vehiclespotsobj = JSON.parse(sessionStorage.getItem('hoa_parking_spots'));
    //  console.log('vehiclespotsobj', vehiclespotsobj);
    // let shoastr = sessionStorage.getItem('selectedhoa');
    // let ahoa = JSON.parse(shoastr);


    let activecountdata = sessionStorage.getItem('activecountdata');
    //  console.log('vehicle form cdm activecountdata', activecountdata);
    let activecountobj = JSON.parse(activecountdata);
    let allcount = activecountobj['allcount'];
    let maxallactive = activecountobj['maxallactive'];
    let season_parking_fee_factor = activecountobj['season_parking_fee_factor'];
    let use_availability_factor = activecountobj['use_availability_factor'];


    let parkingobjecta = vehiclespotsobj.filter(item => item['spot_type'] === curveh);
    let parkingobject = parkingobjecta[0];
    let ratio = allcount / maxallactive + 1;
    if (use_availability_factor === 'false') ratio = 1;
    let stime = new Date(dstr).getTime();
    let etime = new Date(selecteddate).getTime();
    let carenddatetime = new Date(carenddate).getTime();

    let begin = dstr;
    let checkout = selecteddate;


    let diff = etime - stime;
    if (which === 'modify') {
      begin = carenddate;
      diff = etime - carenddatetime;
    }
    let nights = (diff / (1000 * 60 * 60 * 24));
    let tprice = (parkingobject['spot_price'] * nights * ratio * season_parking_fee_factor).toFixed(2);
    let dayprice = (parkingobject['spot_price'] * ratio * season_parking_fee_factor).toFixed(2);
    // let deltadays = (etime - carenddatetime) / (1000 * 60 * 60 * 24);

    transobj['parking_nights'] = nights;
    transobj['dayprice'] = dayprice;
    transobj['total_price'] = tprice;
    transobj['parking_startdate'] = begin;
    transobj['parking_enddate'] = checkout;
    transobj['enddate'] = checkout;
    transobj['caroriginaldate'] = caroriginaldate;


    // console.log('transobj', transobj);
    return transobj;

    // cardata['enddate'] = this.state.selecteddate;
    // cardata['parking_startdate'] = carcurdate;
    // cardata['parking_enddate'] = this.state.selecteddate;
    // cardata['parking_nights'] = nights;

  }




  render() {
    let { errors } = this.state;
    let { cardata } = this.state;
    let { isloading } = this.state;
    let { userrole } = this.state;
    let { rentercount } = this.state;
    let { renter_free_parking_spots } = this.state;
    let { ownercount } = this.state;
    let { owner_free_parking_spots } = this.state;
    let {maxunitactive} = this.state;


    let { allcount } = this.state; //total number of vehicles
    let { availability_warning_buffer } = this.state;
    let parking_allowed_hoa = sessionStorage.getItem('parking_allowed_hoa')

    //allcount = 38;

   // console.log('rentercount',rentercount);


    let dstr = getTodayAsString();

    //owner_free_parking_spots = 2;

    // let { cardata } = this.state;
    let { selecteddate } = this.state;


    let which = this.state.cardata.which;

    // console.log('ppp which', which);

    if (isloading) {
      return <LoadingMessage />
    }

    let showthecard = false; //(this.state.use_owner_creditcard || this.state.use_renter_creditcard);

    let rtobj = this.createTransObj();
    // console.log('the transobj is', rtobj);

   // let msg = 'Parking is free for the first vehicle.';
    let msg = 'Parking is free for vehicle number ' + (rentercount + 1) + '.';

    let spotsremaining = parking_allowed_hoa - allcount;

    let rmsg = [];

   // console.log('the owner count is ',ownercount)


    if (userrole === 'owner') {
      let cardate = cardata['enddate'];
      //  console.log('cardate', cardate, 'selecteddate', selecteddate);
      if (which === 'modify') {
        // msg = 'Modify existing ' + this.state.userrole + ' vehicle.'
        if (cardate === '1970-01-01') {
          // msg = 'Putting ' + this.state.userrole + ' vehicle Onsite.'
          msg = (<div>Putting &nbsp;{this.state.userrole}&nbsp;vehicle onsite<br /> {dstr}&nbsp;to&nbsp;{selecteddate}</div>);
        } else {
          msg = (<div>Changing &nbsp;{this.state.userrole}&nbsp;vehicle period<br /> {cardate}&nbsp;to&nbsp;{selecteddate}</div>);
        }
        if (this.state.use_owner_creditcard && (ownercount >= owner_free_parking_spots)) {
          showthecard = true;
        }
      }
      // showthecard = true;


    }
    if (userrole === 'renter') {
      if (which === 'modify') {
        msg = 'Modify exising ' + this.state.userrole + ' vehicle.'
        if (this.state.use_renter_creditcard && rtobj['parking_nights'] > 0) {
          showthecard = true;
        }
      }
      //  console.log('which ', which);
      //  console.log('rentercount ', rentercount);
      //  console.log('renter_free_parking_spots ', renter_free_parking_spots);

       // console.log('rendering parking_allowed_hoa',parking_allowed_hoa,'allcount',allcount,'availability_warning_buffer',availability_warning_buffer)
       
      //  console.log('spotsremaining',spotsremaining,'availability_warning_buffer',availability_warning_buffer)
      
        // console.log('maxunitactive',maxunitactive);
      if (which === 'create') {
        if (rentercount >= renter_free_parking_spots) {
          // msg = 'The maximum vehicles for the renter(' + renter_free_parking_spots + ') will be exceeded.  ';
          // msg += 'We will allow this until May 1 2024. '
          // msg += 'Until then the car record will be flagged as a warning. '
         
          msg = (<div><div><b>Renters are only allowed {renter_free_parking_spots} car.</b></div>
          {spotsremaining > availability_warning_buffer ? (
            <div>We will allow a second vehicle because there are {spotsremaining} parking spots available.</div> 
          ) : (
            <div>
            <div>There are {availability_warning_buffer} or less parking spots available.</div>
            <div>Please remove your vehicle within 24 hours.</div></div>
          )}
          

          <div><b>Unregister vehicles are in violation.</b></div> 

            {/* <div>Please remove your second car within 48 hours.</div>        */}
          </div>
          )
         
        // msg += 'Until then the car record will be flagged as a warning. '
      //    msg += 'Please only park if absolutely necessary.';
        } else {
          if ((ownercount + (rentercount + 1)) == parseInt(maxunitactive)) {
            if (sessionStorage['use_warning_status'] == 1) {
              msg = (
                <div>
                  <div><b>The maximum vehicles for the unit({maxunitactive}) will be exceeded.</b></div>
                  <div>We will allow another vehicle until May 1, 2024.</div> 
                  <div>Unregister vehicles are in violation.</div> 
                  {/* <div>Please remove your second car within 48 hours.</div>     */}
                 
                </div>
              )
             
            } else {
              msg = 'Parking is available.  Number of spots remaining will be ' + (parking_allowed_hoa - allcount - 1);
            }
          }
        }
       
        // console.log('use this.state.use_renter_creditcard ', this.state.use_renter_creditcard);
        if (this.state.use_renter_creditcard) {
          msg = 'Please pay to park.  Please only park if it is absolutely necessary.';
          showthecard = true;
        //  showthecard = false;
        }

      }
    }

    //showthecard = false;




    const okicon = <FontAwesomeIcon icon={faCheckCircle} color="#65598a" size="2x" title="enter / submit" />
    const okicondisable = <FontAwesomeIcon icon={faCheckCircle} color="#ddd" size="2x" />
    const cancelicon = <FontAwesomeIcon icon={faTimesCircle} color="black" size="2x" title="cancel / close" />




    // console.log('cardata is', cardata);

    //style={{ height: "650px", backgroundColor: "#ffffff" }}
    return (
      <div className="pppform" >

        <h5>Parking for {cardata['carowner_lname']}, {cardata['carowner_fname']}</h5>
        {this.renderCarData()}

        {/* <hr /> */}
        {/* {this.renderFeeData()} */}
        {this.renderFeeDataObj(rtobj)}


        <div style={{ backgroundColor: "#bdb8cd", padding: "5px", textAlign: "center", marginTop: "10px", fontSize: "10pt" }}>
         
          {msg}
        </div>

        <hr />

        {showthecard ? (
          <div style={{ paddingBottom: "30px" }}>
            <CreditCardForm handlePaymentFromStax={this.handlePaymentFromStax} cardata={rtobj} sendclosetoparent={this.closetheform}
              closeallforms={this.closeallforms} />
          </div>

        ) : (

          <div style={{ marginTop: "20px", textAlign: "left" }}>
            <h5 style={{ textAlign: "center" }}>Submit Vehicle Information</h5>
            <span className="iconbuttonspan">
              <Link to=""
                onClick={(e) => this.performInsertVehicle(e)}  >
                {okicon}
              </Link>
            </span>
            <span className="iconbuttonspan">
              <Link to=""
                onClick={(e) => this.closetheform(e)}  >
                {cancelicon}
              </Link>
            </span>
          </div>

        )}




      </div>


    );


  }
}


const mapStateToProps = (state) => ({
  auth: state.auth,
  parking: state.parking,
  errors: state.errors,
});

export default connect(mapStateToProps, { insertVehicle, modifyVehicle })(PayForParkingForm);

//export default Dashboard;

/* <div style={{ backgroundColor: "white", height: "400px" }}>
         <PaymentForm />
       </div> */
