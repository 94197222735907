import React from "react";
//import classnames from "classnames";
//import PropTypes from "prop-types";
//import {OverlayTrigger,Tooltip } from "react-bootstrap";
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';



const SelectNameValuePlain = ({ label, name, list, value, error, disabled, onChange }) => {
  //console.log("here is the list", list);
  //value = "amazing";
  //console.log("list is", list)
  //   let tdisabled = false;
  //   if (disabled === true) {
  //     tdisabled = true;
  //   }


  let tdisabled = false;
  if (disabled === true) {
    tdisabled = true;
  }

  let optionTemplate = null;
  if (list) {
    optionTemplate = list.map((v, index) => (
      <option key={index} value={v['value']} >
        {v['name']}
      </option >
    ));
  } else {
    optionTemplate = <option value="">Select</option>;
  }

  //   let tlabel = label;
  //   if (tooltip) {
  //     tlabel = (<OverlayTrigger placement="auto" overlay={<Tooltip className="ts_tooltip" id="tooltip-id">{tooltip}</Tooltip>}>
  //     <span className="d-inline-block">
  //       {label}&nbsp;
  //     <FontAwesomeIcon icon="question-circle" />
  //     </span>
  //   </OverlayTrigger>)
  //   }
  //   let col1class = "col-md-3";
  //   let col2class = "col-md-9";
  //   if (ratio) {
  //     let parts = ratio.split("-");
  //     col1class = `col-md-${parts[0]}`;
  //     col2class = `col-md-${parts[1]}`;
  //   }

  //form-select form-select-lg

  // console.log("disabled is ", disabled);


  // return (
  //   <div className="form-group">
  //     <div className="row">
  //       <div className="col-md-12" style={{ border: "0px solid black" }}>
  //         <select className="form-control form-control-sm" disabled={tdisabled} 
  //         name={name} value={value} onChange={onChange}
  //         >
  //           {optionTemplate}
  //         </select>
  //         {error && <div className="hw_invalid_feedback">{error}</div>}
  //       </div>
  //     </div>
  //   </div>
  // );
  return (
    <div className="form-group">
      <div className="row">
        <div className="col-md-12" style={{ border: "0px solid black" }}>
          <select className="form-select form-select-sm" disabled={tdisabled}
            name={name} value={value} onChange={onChange}
          >
            {optionTemplate}
          </select>
          {error && <div className="hw_invalid_feedback">{error}</div>}
        </div>
      </div>
    </div>
  );
  // return (

  //   <div className="col-md-12x" style={{ border: "1px solid black" }}>
  //     <select disabled={tdisabled} name={name} value={value} onChange={onChange}
  //     >
  //       {optionTemplate}
  //     </select>
  //     {error && <div className="hw_invalid_feedback">{error}</div>}
  //   </div>
  // );
};

export default SelectNameValuePlain;
