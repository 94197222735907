import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
//import {OverlayTrigger,Tooltip } from "react-bootstrap";
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//import RBAC from "../../utils/rbac";



const TextFieldGroupPlain = ({
  name,
  placeholder,
  value,
  label,
  error,
  info,
  type,
  onChange,
  disabled,
  tooltip,
  acl,
  ratio
}) => {
  //console.log(placeholder + " " + value);
  // let tlabel = placeholder; //label //
  // if (label) tlabel = label;
  let tdisabled = false;
  if (disabled === true) {
    tdisabled = true;
  }
  if (!value) value = '';



  // let col1class = "col-md-3";
  // let col2class = "col-md-9";
  // if (ratio) {
  //   let parts = ratio.split("-");
  //   col1class = `col-md-${parts[0]}`;
  //   col2class = `col-md-${parts[1]}`;
  // }

  return (
    <div className="form-group">


      <input
        type={type}
        className={classnames("form-control form-control-sm", {
          "is-invalid": error
        })}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        disabled={tdisabled}
        step="1"
      />
      {info && <small className="form-text text-muted">{info}</small>}
      {error && <div className="invalid-feedback">{error}</div>}
    </div>
  );
};

// className={classnames("form-control form-control-sm", {
//   "is-invalid": error
// })}

// is-invalid depends if error.name exists.  see validation code

TextFieldGroupPlain.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  // value: PropTypes.string.isRequired,
  info: PropTypes.string,
  error: PropTypes.string,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  //disabled: PropTypes.string
  disabled: PropTypes.bool
};

TextFieldGroupPlain.defaultProps = {
  type: "text"
};

export default TextFieldGroupPlain;
