import React, { useState, useEffect } from 'react';
import { getParkingSummary } from '../parking/AjaxHelper';


function ParkingSummary(props) {
  // console.log('props', props);

  const [allcount, setAllCount] = useState(0);
  const [maxcount, setMaxCount] = useState(0);

  let allok = true;


  const fetchData = () => {
    getParkingSummary().then(rdata => {
      //console.log('we have data back from promise oktogo is ', oktogo);
      if (allok) {
        setAllCount(rdata.allcount);
        setMaxCount(rdata.maxallactive);
      }
    }).catch(error => {
      console.log('error', error);
    });

  }


  useEffect(() => {
    fetchData();
    // }
    return () => {
      allok = false;
    }
  }, [props.millis]);

  return (
    <span style={{ fontSize: "9pt", marginTop: "3px" }}>
      <span>Spaces: {maxcount}</span>&nbsp;&nbsp;&nbsp;
      <span>Used: {allcount}</span>&nbsp;&nbsp;&nbsp;
      <span>Available:  {maxcount - allcount} </span>
    </span>
  );

}

export default ParkingSummary;
