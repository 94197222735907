import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'



import { connect } from "react-redux";
import { changeAdminName, changePassword } from "../../actions/authActions";
//import VehicleForm from "./VehicleForm";
//


//import axios from "axios";
//import classnames from "classnames"; 


import TextFieldGroup from "../common/TextFieldGroupPlain";

class AdminProfile extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      phone: "",
      email: "",
      unitnumber: "",
      pincode: "",
      password: "",
      password2: "",
      userid: "",
      adddays: 0,


      errors: {}
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmitName = this.onSubmitName.bind(this);
    // this.onSubmitPin = this.onSubmitPin.bind(this);
    this.onSubmitPassword = this.onSubmitPassword.bind(this);
    // this.onSubmitChangeDays = this.onSubmitChangeDays.bind(this);
    // this.onSubmitLoadHoa = this.onSubmitLoadHoa.bind(this);


  }

  componentDidMount() {
    // console.log('owner profile mounted');
    if (this.props.auth.isAuthenticated) {
      // this.props.history.push("/dashboard");
      let role = this.props.auth.user.role;
      let uid = this.props.auth.user._id;
      let isadmin = false;
      if (role === 'admin') isadmin = true;
      this.setState({
        name: this.props.auth.user.name,
        phone: this.props.auth.user.phone,
        email: this.props.auth.user.email,
        unitnumber: this.props.auth.user.unitnumber,
        pincode: this.props.auth.user.pincode,
        userid: uid,
        isadmin: isadmin,
        adddays: 5

      })

    }
  }



  // if we have errors this will run
  // componentWillReceiveProps(nextProps) {
  //   //console.log("register componentWillReceiveProps");
  //   if (nextProps.errors) {
  //     this.setState({ errors: nextProps.errors });
  //     // setState triggers a render
  //   }
  // }
  static getDerivedStateFromProps(props, state) {
    return {
      errors: props.errors
    };
  }

  //componentDidUpdate(prevProps) {
  // Typical usage (don't forget to compare props):
  // if (this.props.errors) {
  //   this.setState({ errors: this.props.errors });
  // }
  //}

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }



  onSubmitPassword(e) {
    e.preventDefault();
    // console.log('submit password', this.state);
    const userdata = {
      userid: this.state.userid,
      password: this.state.password,
      password2: this.state.password2
    };
    this.props.changePassword(userdata);
  }
  onSubmitPin(e) {
    e.preventDefault();
    // console.log('submit pin', this.state);
    const userdata = {
      userid: this.state.userid,
      pincode: this.state.pincode,
    };
    this.props.changePin(userdata);
  }
  onSubmitName(e) {
    e.preventDefault();

    const userdata = {
      which: 'admin',
      userid: this.state.userid,
      name: this.state.name,
      phone: this.state.phone,
    };

    // console.log('submit name', userdata);
    this.props.changeAdminName(userdata);
  }









  render() {
    const { errors } = this.state;
    //const { properties_ary } = this.state;



    const okelement = <FontAwesomeIcon icon={faCheckCircle} color="#65598a" size="2x" title="enter / submit" />
    const cancelelement = <FontAwesomeIcon icon={faTimesCircle} color="white" size="1x" title="cancel / close" />


    return (
      <div className="container-fluid">
        <h4 style={{ textAlign: "center", color: "white", marginTop: "10px" }} >Profile</h4>








        <div className="inputbox">
          <div className="innerinputbox2">

            <p className="parking_label">Administrator
              <span style={{ marginLeft: "20px" }}>{this.state.email}</span>
              <span style={{ float: "right" }}>
                <Link to="/admindashboard">
                  {cancelelement}
                </Link>
              </span>
            </p>




            <form noValidate onSubmit={this.onSubmitName}>
              <div className="row mb-2">
                <div className="col-md-2">
                  <b>Name</b>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <TextFieldGroup
                      type="text"
                      label="Name"
                      placeholder="Name"
                      name="name"
                      value={this.state.name}
                      onChange={this.onChange}
                      error={errors.name}
                    />
                  </div>
                </div>
                <div className="col-md-2">
                  <b>Phone</b>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <TextFieldGroup
                      type="text"
                      label="Phone"
                      placeholder="Phone"
                      name="phone"
                      value={this.state.phone}
                      onChange={this.onChange}
                      error={errors.phone}
                    />
                  </div>
                </div>



                <div className="col-md-1">
                  <Link to=""
                    onClick={(e) => this.onSubmitName(e)} >
                    {okelement}
                  </Link>
                  {/* <input type="submit" className="btn btn-sm btn-info btn-block" /> */}
                </div>

              </div>

            </form>
            <hr />



            <form noValidate onSubmit={this.onSubmitPassword} >

              <div className="row mb-2">

                <div className="col-md-2">
                  <b>Password</b>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <TextFieldGroup
                      type="password"
                      label="Password"
                      placeholder="Password"
                      name="password"
                      value={this.state.password}
                      onChange={this.onChange}
                      error={errors.password}
                    />
                  </div>
                </div>
                <div className="col-md-2">
                  <b>Confirm</b>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <TextFieldGroup
                      type="password"
                      label="Confirm"
                      placeholder="Confirm Password"
                      name="password2"
                      value={this.state.password2}
                      onChange={this.onChange}
                      error={errors.password2}
                    />
                  </div>
                </div>

                <div className="col-md-1">
                  <Link to=""
                    onClick={(e) => this.onSubmitPassword(e)} >
                    {okelement}
                  </Link>
                  {/* <input type="submit" className="btn btn-sm btn-info btn-block" /> */}
                </div>

              </div>

            </form >












          </div >
        </div >







      </div >

    );
  }
}


// this is good practice because it will help debugging
// it is not checked when in production mode.
//onClick={(e) => props.gotoBooking(e, obj.accommodations.booking_url)}
AdminProfile.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

// map errors to this component
// then we can use this.props.auth.user etc
// since we mapped error, we can use componentWillReceiveProps method
const mapStateToProps = state => ({
  auth: state.auth,
  parking: state.parking,
  errors: state.errors
});
// the state.auth above comes from rootReducer in index.js in reducers.

export default connect(
  mapStateToProps,
  { changeAdminName, changePassword }
)((AdminProfile));
// wrap the Register with withRouter so the authAction can use history to redirect
