import React, { Component } from "react";

import SectionGeneral from "./SectionGeneral";
import SectionPermissions from "./SectionPermissions";
import SectionContact from "./SectionContact";
import HelpNavigation from "./HelpNavigation";





class HelpRenter extends Component {

  constructor(props) {
    super(props);
    this.state = {

      errors: {}
    };


  }

  componentDidMount() {


  }







  render() {

    let hoaname = sessionStorage.getItem('hoaname')

    return (

      <div className="tenpoint bordernoshadow" >
        <h5 style={{ textAlign: "center" }}>
          {hoaname} Parking<br />
          Renter Help
        </h5>



        <SectionGeneral />



        <h6><b>Renter Guide and Instructions</b></h6>
        <p><b>GETTING STARTED</b> </p>
        <ul>
          <li>After logging in on the<b> Renter Login </b>page, you are now on the <b>Renter Dashboard </b>page. </li>
          <li>Confirm that you are associated with the correct unit number. It is important that you register your vehicle upon arrival.  </li>
        </ul>

        {/* <HelpNavigation /> */}

        <p><b>REGISTERING VEHICLES</b> </p>
        <ul>
          <li>The Renter Dashboard page  lists all the vehicles associated to your rental unit. To add a vehicle, click the + button on the left hand side. You will now see a pop-up window: Register Vehicles.The following data is required:</li>
          <li><b>Vehicle</b>: 	Car	(includes all cars, SUV’s, crossovers) <br />
            Truck/Van	(includes pickup trucks, small truck/van, mini-van) <br />
            Motorcycle  </li>

          <li><b>Name</b>: First & Last		(name of vehicle owner)</li>
          <li> <b>Phone</b>:	Enter a number where you can be reached during your stay.   </li>
          <li> <b>Make, Model, Year, Plate, State</b>	(Vehicle & license plate information)</li>
          <li> Note that there is a maximum of 2 vehicles/unit that can be ONSITE on the premises at any point in time per {hoaname} rules.</li>
          <li> <b>Checkout Date</b>: Your vehicle must be removed on this date. </li>
          <li> <b>Terms & Conditions</b>: It is important to read, understand, and accept the terms & conditions. Unlawful parked vehicles will be tagged and given 12 hours to move or be booted or towed. </li>
        </ul>



        {/* <SectionContact which={"Renter"} /> */}






      </div>
    );
  }
}


export default HelpRenter;
