import React from "react";
import spinner from "./716.gif";


const LoadingMessage = () => {


  return (
    <div className="loadingmessage" >

      <img
        src={spinner}
        style={{ width: "200px", height: "10px", margin: "auto", display: "block" }}
        alt="Loading..."
      />
    </div>
  );
};

// style={{ width: "200px", margin: "auto", display: "block" }}



export default LoadingMessage;
