export const TEST_DISPATCH = "TEST_DISPATCH";

export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const SET_STATUS_MESSAGE = "SET_STATUS_MESSAGE";
export const CLEAR_STATUS_MESSAGE = "CLEAR_STATUS_MESSAGE";

export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_IS_LOADING = "SET_IS_LOADING";

export const UPDATE_USER_NAME = "UPDATE_USER_NAME";
export const UPDATE_USER_PIN = "UPDATE_USER_PIN";
export const UPDATE_USER_PASSWORD = "UPDATE_USER_PASSWORD";


export const UPDATE_USER_AVAILABILITY = "UPDATE_USER_AVAILABILITY";






export const SET_CURRENT_ACCOMMODATIONS = "SET_CURRENT_ACCOMMODATIONS";

export const MODIFY_VEHICLE = "MODIFY_VEHICLE";
export const INSERT_VEHICLE = "INSERT_VEHICLE";
export const DELETE_VEHICLE = "DELETE_VEHICLE";





export const SET_CURRENT_VEHICLES_OWNER = "SET_CURRENT_VEHICLES_OWNER";

export const SET_ALL_VEHICLES = "SET_ALL_VEHICLES";



export const SET_CURRENT_OWNERS = "SET_CURRENT_OWNERS";

//export const CHANGE_VEHICLE_STATUS = "CHANGE_VEHICLE_STATUS";
export const CHANGE_VEHICLE_STATUS_DATE = "CHANGE_VEHICLE_STATUS_DATE";

//export const CHANGE_ADMIN_VEHICLE_STATUS = "CHANGE_ADMIN_VEHICLE_STATUS";
export const SET_DICTIONARY = "SET_DICTIONARY";


export const SET_ALL_VIOLATIONS = "SET_ALL_VIOLATIONS";
export const INSERT_VIOLATION = "INSERT_VIOLATION";

export const DELETE_VIOLATION = "DELETE_VIOLATION";


export const SET_ALL_NOTIFICATIONS = "SET_ALL_NOTIFICATIONS";
export const INSERT_NOTIFICATION = "INSERT_NOTIFICATION";
export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";

export const DELETE_OWNER = "DELETE_OWNER";
export const UPDATE_OWNER = "UPDATE_OWNER";
export const INSERT_OWNER = "INSERT_OWNER";

export const RULES_AND_REGS = "RULES_AND_REGS";
























/*
export const SET_CURRENT_ADVERTISEMENTS = "SET_CURRENT_ADVERTISEMENTS";
export const SET_CURRENT_ADVERTISEMENT = "SET_CURRENT_ADVERTISEMENT";
export const CREATE_ADVERTISEMENT = "CREATE_ADVERTISEMENT";
export const MODIFY_ADVERTISEMENT = "MODIFY_ADVERTISEMENT";
export const REMOVE_ADVERTISEMENT = "REMOVE_ADVERTISEMENT";
export const CHANGE_ADVERTISEMENT_STATUS = "CHANGE_ADVERTISEMENT_STATUS";

export const SET_BUSINESSES = "SET_BUSINESSES";
export const SET_IMAGES = "SET_IMAGES";
export const SET_NEW_IMAGE = "SET_NEW_IMAGE";
export const REMOVE_IMAGE = "REMOVE_IMAGE";

export const CHANGE_BUSINESS_STATUS = "CHANGE_BUSINESS_STATUS";
export const REMOVE_BUSINESS = "REMOVE_BUSINESS";
export const CREATE_BUSINESS = "CREATE_BUSINESS";
export const MODIFY_BUSINESS = "MODIFY_BUSINESS";
export const SET_BUSINESS = "SET_BUSINESS";

export const SET_ADVERTISERS = "SET_ADVERTISERS";
export const CHANGE_ADVERTISER_STATUS = "CHANGE_ADVERTISER_STATUS";
export const REMOVE_ADVERTISER = "REMOVE_ADVERTISER";
*/


// export const GET_PROFILE = "GET_PROFILE";
// export const PROFILE_LOADING = "PROFILE_LOADING";
// export const PROFILE_NOT_FOUND = "PROFILE_NOT_FOUND";
// export const CLEAR_CURRENT_PROFILE = "CLEAR_CURRENT_PROFILE";
// export const GET_PROFILES = "GET_PROFILES";
// export const POST_LOADING = "POST_LOADING";
// export const GET_POSTS = "GET_POSTS";
// export const GET_POST = "GET_POST";
// export const ADD_POST = "ADD_POST";
// export const DELETE_POST = "DELETE_POST";





