import isEmpty from "../validation/is-empty";

import { SET_CURRENT_USER, RULES_AND_REGS, UPDATE_USER_NAME } from "../actions/parkingtypes";

// import { TEST_DISPATCH } from "../actions/types";

const initialState = {
  isAuthenticated: false,
  user: {}
};

// payload is the decoded user.  if it is empty means we are not authenticated
export default function (state = initialState, action) {
  let currentuser = {};
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload
      };

    case UPDATE_USER_NAME:
      currentuser = state.user;
      console.log('reducer action payload', action.payload);
      currentuser['fname'] = action.payload['fname'];
      currentuser['lname'] = action.payload['lname'];
      currentuser['phone'] = action.payload['phone'];
      return {
        ...state,
        user: currentuser
      };




    case RULES_AND_REGS:
      currentuser = state.user;
      currentuser['has_read_terms'] = action.payload
      return {
        ...state,
        user: currentuser
      };
    // case TEST_DISPATCH:
    //   console.log("we are in authreducer " + action.type);
    //   return {
    //     ...state,
    //     user: action.payload
    //   };
    default:
      return state;
  }
}
