import React, { Component } from "react";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";


class ErrorMessageConstantClose extends Component {
  constructor(props) {
    super(props);
    // console.log("Businesses props", props);
    this.state = {
      statusmessage: null
    }; //shuttles: ["one", "two", "three"] };
    // this.getAdvertisements = this.getAdvertisements.bind(this);
    // this.showMessage = this.showMessage.bind(this);
    // this.clearMessage = this.clearMessage.bind(this);
    // let messageTimer = null;
  }

  componentDidMount() {
    //console.log("status message did mount component did mount ", this.props);
    this.setState({ statusmessage: this.props.message });

  }



  closemessage = (e) => {
    e.preventDefault();
    this.props.closemessage();
  }

  render() {
    //  const { errors } = this.props;
    const statusmessage = this.state.statusmessage;
    const closeicon = <FontAwesomeIcon icon={faTimesCircle} color="#000" size="1x" title="cancel / close" />


    return (
      <div>



        <div className="infomessage" >
          <div className="flex_row_sb">
            <div className="flex_item_left">&nbsp;</div>
            <div className="flex_item_center"><span style={{ color: "black", fontWeight: "bold" }}>Information</span></div>
            <div className="flex_item_right">
              <Link to=""
                onClick={(e) => this.closemessage(e)} >{closeicon}</Link>
            </div>
          </div>
          <span>{statusmessage}</span>
        </div>
      </div>
    );
  }
};



export default ErrorMessageConstantClose;
