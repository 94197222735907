import React, { Component } from "react";
// we need to get into git
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//import axios from "axios";

//import { useRanger } from "react-ranger";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
//
//import { getDomainData, getDealSpecials } from "../../actions/dealsActions";
//import styled, { createGlobalStyle } from "styled-components";

import SmartButtonHPS from "../common/SmartButtonHPS";

import ParkingSummary from "../general/ParkingSummary";


import TermsAndConditions_Owner from "./TermsAndConditions_Owner";
import OwnerProfile from "./OwnerProfile";
import OwnerVehicles from "./OwnerVehicles";
import AdminActiveVehicles from "../admin/AdminActiveVehicles";
import Violations from "../admin/Violations";

import { changeRulesAndRegs } from "../../actions/authActions";





/*
const SliderDateDisplay = styled("div")`
  display: inline-block;
  height: 12px;
  width: 90%;
  margin: 0 5%;
  color:white;
  font-size:10pt;
`;
*/

class OwnerDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showprofile: false,
      showvehicles: true,
      showallvehicles: false,
      showviolations: false,
      selary: [1, 0, 0],
      errors: {}
    }; //shuttles: ["one", "two", "three"] };
    // console.log("Dashboard props", props);

  }

  componentDidMount() {
    // this.props.getDomainData();
  }

  showProfile = (e) => {
    e.preventDefault();
    // this.props.history.push("/profile");
    let ary = [0, 0, 0]
    this.setState({ showprofile: true, showvehicles: false, showallvehicles: false, showviolations: false, selary: ary });
  }
  showVehicles = (e) => {
    e.preventDefault();
    let ary = [1, 0, 0]
    //setLogonInfo({ ...logonInfo, [event.target.name]: event.target.value });
    this.setState({ showprofile: false, showvehicles: true, showallvehicles: false, showviolations: false, selary: ary });
  }
  showAllVehicles = (e) => {
    let ary = [0, 1, 0]
    e.preventDefault();
    this.setState({ showprofile: false, showvehicles: false, showallvehicles: true, showviolations: false, selary: ary });
  }

  showViolations(e) {
    e.preventDefault();
    let ary = [0, 0, 1]
    if (this.state.showallvehicles) {
      ary = [0, 1, 1]
    }
    // let which = true; //!this.state.showviolations;

    // this.setState({
    //   showviolations: true,
    //   showallvehicles: false,
    //   showvehicles: false,
    //   selary: ary
    // });

    this.setState({
      showvehicles: false, showviolations: true, selary: ary
    });
  }


  submitRulesAndRegs(e) {
    e.preventDefault();
    const user = this.props.auth.user;
    const userdata = {
      userid: user._id,
      terms: 1,
      termsaccepted: true
    };
    // console.log(userdata)
    this.props.changeRulesAndRegs(userdata);
  }




  closeViolations = () => {
    let ary = [0, 1, 0]
    this.setState({
      showviolations: false,
      showallvehicles: true,
      showvehicles: false,
      selary: ary
    });
  }

  closeactive = () => {
    let ary = [0, 0, 0]
    let showvio = false
    if (this.state.showviolations) {
      ary = [0, 0, 1]
      showvio = true
    }
    this.setState({
      showviolations: showvio,
      showallvehicles: false,
      showvehicles: false,
      selary: ary
    });
  }








  render() {
    //  console.log('DOING A DASBOARD RENDER');

    // console.log('perrors are', perrors);

    //let valary = [100, 400];
    // console.log('dashboard render dd ', dd);
    // minmax[0] = parseInt(dd['ppn']);
    /*
    backgroundColor: "#231259",
          color: "white",
          */

    const { showprofile } = this.state;
    const { showvehicles } = this.state;
    const { showallvehicles } = this.state;
    let { showviolations } = this.state;


    const user = this.props.auth.user;
    const has_read_terms = user['has_read_terms'];
    // console.log('has read terms ', user['has_read_terms']);

    if (!has_read_terms) {
      return (
        <div>
          <h5 style={{ textAlign: "center", color: "white", marginTop: "15px" }} >Owner Dashboard</h5>
          {/* <h5 style={{ textAlign: "center", color: "white", marginTop: "15px" }} >
            Before proceeding</h5> */}


          <TermsAndConditions_Owner />

          {/* <h6 style={{ textAlign: "center", color: "white", margin: "15px" }} >
            Please go to your Profile and acknowledge you have read the rules and regulations.</h6> */}
          <h4 style={{ textAlign: "center", marginTop: "30px", color: "white", margin: "15px" }} >
            Please acknoledge you have read and accept the rules and regulations.<br />
            <Link to="" className="btn btn-sm btn-info btnparking"
              onClick={(e) => this.submitRulesAndRegs(e)} >Accept</Link>

          </h4>


        </div>

      )
    }


    let bclass = 'btn-basic button_14';
    let bclasssel = 'btn-basic-selected';
    //   bclass = 'btn-basic-selected';

    let selary = this.state.selary;

    let millis = Date.now();


    return (
      <div className="container-fluid">

        <div className="row" style={{ marginTop: "10px", marginBottom: "10px" }} >

          <div className="col-md-4 offset-md-4">
            <h5 style={{ textAlign: "center", color: "white" }} >Owner Dashboard</h5>
            <div style={{ textAlign: "center", color: "white" }}>{sessionStorage.getItem('hoaname')}</div>
          </div>
          <div className="col-md-4">
            <div style={{ textAlign: "center", color: "white", paddingRight: "20px" }}><ParkingSummary millis={millis} /></div>
          </div>
        </div>
        {/* <div style={{ position: "absolute", left: "10px", color: "white" }}>{sessionStorage.getItem('hoaname')}</div> */}
        {/* <h5 style={{ textAlign: "center", color: "white", marginTop: "10px" }} >Owner Dashboard</h5>
        <div style={{ textAlign: "center", color: "white" }}>{sessionStorage.getItem('hoaname')}</div>
        <div style={{ textAlign: "center", color: "white", marginBottom: "20px" }}><ParkingSummary millis={millis} /></div> */}




        <div className="flex_row_center">
          <div className="flex_item">
            <SmartButtonHPS
              name="My Vehicles"
              buttonclass={selary[0] === 0 ? bclass : bclasssel}
              onClick={(e) => this.showVehicles(e)}

            />
          </div>
          <div className="flex_item">
            <SmartButtonHPS
              name="Onsite Vehicles"
              buttonclass={selary[1] === 0 ? bclass : bclasssel}

              onClick={(e) => this.showAllVehicles(e)}

            />
          </div>
          <div className="flex_item">
            <SmartButtonHPS
              name="Violations"
              buttonclass={selary[2] === 0 ? bclass : bclasssel}
              onClick={(e) => this.showViolations(e)}
            />
          </div>
          {/* <div className="flex_item">
            <Link to="" className="btn btn-sm btn-info btnparking"
              onClick={(e) => this.showVehicles(e)} >My Vehicles</Link>
          </div> */}
          {/* <div className="flex_item">
            <Link to="" className="btn btn-sm btn-info btnparking"
              onClick={(e) => this.showAllVehicles(e)} >Onsite Vehicles</Link>
          </div> */}
          {/* <div className="flex_item">
            <Link to="" className="btn btn-sm btn-info btnparking"
              onClick={(e) => this.showViolations(e)} >Show Violations</Link>
          </div> */}


        </div>




        <div>
          {showprofile ? (<OwnerProfile />) : (null)}
          {showvehicles ? (<OwnerVehicles />) : (null)}
          {showallvehicles ? (<AdminActiveVehicles closeactive={this.closeactive} />) : (null)}
          {showviolations ? (
            <Violations closeviolations={this.closeViolations} />
          ) : (null)}
        </div>








      </div >
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  parking: state.parking,
  errors: state.errors,
});

export default connect(mapStateToProps, { changeRulesAndRegs })(OwnerDashboard);

//export default Dashboard;
