import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import SmartButtonHPS from "../../common/SmartButtonHPS";


import Feedback from "./Feedback";


import HelpLanding from "./HelpLanding";
import HelpApp from "./HelpApp";
import HelpOwner from "./HelpOwner";
import HelpRenter from "./HelpRenter";
import HelpAdmin from "./HelpAdmin";
import HelpNavigation from "./HelpNavigation";
import SectionContact from "./SectionContact";






class HelpMain extends Component {

  constructor(props) {
    super(props);
    this.state = {
      whichhelp: '',
      activekey: "help",
      shownav: false,
      showcontact: false,
      showfeedback: false
    };
  }



  componentDidMount() {
    // console.log("we are in admin tabs which is ", this.props.match.params.which)
    let { which } = this.props;
    this.setState({ whichhelp: which });
  }



  onCancelClick(e) {
    e.preventDefault();
    // this.props.history.goBack();
    this.props.onNavigation(-1)
  }

  toggleNav(e) {
    e.preventDefault();
    let setshow = true;
    let st = this.state.shownav;
    if (st) setshow = false;
    this.setState({ shownav: setshow });
  }

  toggleContact(e) {
    e.preventDefault();
    let setshow = true;
    let st = this.state.showcontact;
    if (st) setshow = false;
    this.setState({ showcontact: setshow });
  }

  toggleFeedback(e) {
    e.preventDefault();
    let setshow = true;
    let st = this.state.showfeedback;
    if (st) setshow = false;
    this.setState({ showfeedback: setshow });
  }
  /*
   <SmartButtonHPS
                  name="Navigation"
                  buttonclass={"btn-basic"}
                  onClick={(e) => this.renderNavToggle(e)}
  
                />
  //               */
  // zzrenderNavToggle() {
  //   let hoaname = sessionStorage.getItem('hoaname');
  //   if (hoaname && hoaname.length === 0) {
  //     return null
  //   } else {

  //     return (
  //       <Link to=""
  //         onClick={(e) => this.toggleNav(e)} >
  //         {"Navigation"}
  //       </Link>
  //     )
  //   }
  // }

  renderNavToggle() {
    let hoaname = sessionStorage.getItem('hoaname');
    if (!hoaname) return null
    if (hoaname.length === 0) {
      return null
    } else {

      return (
        <SmartButtonHPS
          name="Navigation"
          buttonclass={"btn-basic button_12 button_w80"}
          onClick={(e) => this.toggleNav(e)}

        />
      )
    }
  }

  renderContactToggle() {
    let hoaname = sessionStorage.getItem('hoaname');
    if (!hoaname) return null
    if (hoaname.length === 0) {
      return null
    } else {
      return (
        <SmartButtonHPS
          name="Contact"
          buttonclass={"btn-basic button_12 button_w80"}
          onClick={(e) => this.toggleContact(e)}
        />
      )
    }
  }

  renderFeedbackToggle() {
    let hoaname = sessionStorage.getItem('hoaname');
    // console.log('rf hoaname', hoaname)
    if (!hoaname) return null
    if (hoaname.length === 0) {
      return null
    } else {
      return (
        <SmartButtonHPS
          name="Feedback"
          buttonclass={"btn-basic button_12 button_w80"}
          onClick={(e) => this.toggleFeedback(e)}
        />
      )
    }
  }


  closeform = (which) => {
    //  console.log('this is close form', which);
    if (which === 'feedback') {
      this.setState({ showfeedback: false });
    }
    if (which === 'contact') {
      this.setState({ showcontact: false });
    }
    if (which === 'navigation') {
      this.setState({ shownav: false });
    }
    // this.setState({ show: false });
  }

  render() {

    //const { errors } = this.state;
    const { activekey } = this.state;
    const { whichhelp } = this.state;
    const { shownav } = this.state;
    const { showcontact } = this.state;
    const { showfeedback } = this.state;
    // paths could be settings or smb_settings or dd_settings depending on where called
    const cancelelement = <FontAwesomeIcon icon={faTimesCircle} color="white" size="1x" />


    let hoadata = sessionStorage.getItem('selectedhoa');
    let hoaname = sessionStorage.getItem('hoaname');
    let userrole = sessionStorage.getItem('userRole');
    //  console.log('render help main hoaname userrole', hoaname, userrole)
    let whichlevel = 'App';
    if (userrole === 'owner') whichlevel = 'Owner';
    if (userrole === 'renter') whichlevel = 'Renter';
    if (userrole === 'admin') whichlevel = 'Owner';

    // height: "300px", maxWidth: "400px"

    return (
      <div className="container-fluid">


        <div className="inputbox">
          <div className="innerinputbox2">



            <p className="parking_label">Application Information


              <span style={{ float: "right" }}>
                <Link to=""
                  onClick={(e) => this.onCancelClick(e)} >
                  {cancelelement}
                </Link>
              </span>
            </p>
            <div style={{ textAlign: "center", fontSize: "10pt" }}>

              {this.renderNavToggle()}&nbsp;

              {this.renderContactToggle()}&nbsp;

              {this.renderFeedbackToggle()}
            </div>

            {shownav ? (
              <div className="bordernoshadow" style={{
                backgroundColor: "white", padding: "10px", margin: "10px auto"

              }}>
                <HelpNavigation closeform={this.closeform} />
              </div>
            ) : (
              null
            )}

            {showcontact ? (
              <div className="bordernoshadow" style={{
                backgroundColor: "white", padding: "10px", margin: "10px auto",
                maxWidth: "400px"
              }}>
                <SectionContact closeform={this.closeform} which={whichlevel} />
              </div>
            ) : (
              null
            )}

            {showfeedback ? (
              <div className="bordernoshadow" style={{
                backgroundColor: "white", padding: "10px", margin: "10px auto",
                maxWidth: "500px"
              }}>
                <Feedback closeform={this.closeform} />
              </div>
            ) : (
              null
            )}


            <div>
              {whichhelp === 'landing' ? (<HelpLanding />) : (null)}
              {whichhelp === 'app' ? (<HelpApp />) : (null)}
              {whichhelp === 'owner' ? (<HelpOwner />) : (null)}
              {whichhelp === 'renter' ? (<HelpRenter />) : (null)}
              {whichhelp === 'admin' ? (<HelpAdmin />) : (null)}

            </div>

          </div>
        </div>

      </div>
    );
  }
}



const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors

});

export default connect(
  mapStateToProps,
  {}
)((HelpMain));

// export default Measurements;
