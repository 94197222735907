import React, { Component } from "react";
//import PropTypes from "prop-types";
//import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { Button } from 'react-bootstrap';


import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'


import { connect } from "react-redux";

// import TextFieldGroupPlain from "../common/TextFieldGroupPlain";
// import ErrorMessageConstantClose from "../common/ErrorMessageConstantClose";
// import msgHelper from '../../config/msgHelper';

// import { changeVehicleStatusDate } from "../../actions/parkingActions";
// import { getActiveCounts } from '../parking/AjaxHelper';

// import PayForParkingForm from "../payments/PayForParkingForm";

import { getTodayAsString, formatReadableDate } from "../parking/DateHelper";


// import { validateInsertModifyVehicleInput } from "../../validation/client_insertvehiclevalidation";
// import { getParkingSpots } from '../parking/AjaxHelper';





class ChangeHistory extends Component {
  constructor(props) {
    super(props);
     let selected_vid = this.props.selected_vid;
     let which = this.props.which;
     let owneremail = this.props.owneremail;
     let ownerphone = this.props.ownerphone;
     let ownername = this.props.ownername;
   
    this.state = {
       selected_vid: selected_vid,
       which: which,
       owneremail: owneremail,
       ownerphone: ownerphone,
       ownername: ownername,
       the_vehicle: null,
      errors: {}
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.selected_vid !== state.selected_vid) {
      return {
        selected_vid: props.selected_vid,
        which: props.which,
        owneremail: props.owneremail,
        ownerphone: props.ownerphone,
        ownername: props.ownername
      }
    }
    return null;
  }

  componentDidMount() {
  //  console.log('cdm vid',this.state.selected_vid)
    this.getVehicle(this.state.selected_vid);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selected_vid !== this.state.selected_vid) {
     // console.log('the passed vid is',this.state.selected_vid)
      this.getVehicle(this.state.selected_vid);
    }
  }

  getVehicle = (vid) => {
   // console.log('getting the vehicle information',vid);
    let url = "/api/parking/get_vehicles_for_vehid/" + vid
    axios.get(url).then(veh => {
     // console.log('vehicle info did update', veh.data);
      this.setState({the_vehicle:veh.data})
    }).catch(err => {
      console.log(err);
    });

  }
 
  renderOwnerUsageSummary = (vehdata) => {
    if (vehdata !== null) {
      // console.log('render veh', vehdata)
      let chistory = vehdata['change_history'];
      //  console.log('render chistory', chistory)
      let chisdata = [];
      chistory.forEach((el, index) => {
        let str = el['startdate'] + ' ' + el['enddate']
        let sdate = new Date(el['startdate']);
        let edate = new Date(el['enddate']);
        let stime = sdate.getTime();
        let etime = edate.getTime();
        let diff = etime - stime;
        let days = diff / (1000 * 60 * 60 * 24);
        str = str + ' * ' + days;
        if (el['enddate'] == '1970-01-01') {
          days = '-'
        }
      //  let ele = (<div key={index}>{str}</div>);
      //  let row = (<tr><td>{sdate}</td><td>{edate}</td><td>{days}</td></tr>);
        let dstr = formatReadableDate(el['change_date'])
        chisdata.push(
          <tr key={index + 1000} >
            <td>{dstr}</td>
            <td>{el['startdate']}</td>
            <td>{el['enddate']}</td>
            <td>{days}</td></tr>
        );

      })
      return (
        <div style={{ margin: '15px' }}>
          <div className="grid-container-2-13" style={{ textAlign: "left" }}>
            <div><b>Type</b>  </div><div><b>{vehdata['carownertype']} </b></div>
            <div><b>Name</b> </div><div>{vehdata['carowner_fname']} {vehdata['carowner_lname']}</div>
            <div><b>Make</b>  </div><div>{vehdata['make']} {vehdata['model']}</div>
            <div><b>Plate</b>  </div><div>{vehdata['plate']} {vehdata['year']}</div>
            <div><b>Unit</b>  </div><div>{vehdata['unitnumber']} </div>
            <div><b>Phone</b>  </div><div>{vehdata['carownerphone']} </div>
          </div>
          <table style={{ width: '100%', marginTop: '10px' ,fontSize: "10pt"}}><thead><tr><th>Change</th><th>Start</th><th>End</th><th>Days</th></tr></thead>
            <tbody>{chisdata}</tbody>
          </table>
        </div>
      )
    } else {
      return (<div />)
    }
  }

  renderRenterUsageSummary = (vehdata) => {
    if (vehdata !== null) {
      // console.log('render veh', vehdata)
      let chistory = vehdata['change_history'];
      //  console.log('render chistory', chistory)
      let chisdata = [];
      chistory.forEach((el, index) => {
        let str = el['startdate'] + ' ' + el['enddate']
        let sdate = new Date(el['startdate']);
        let edate = new Date(el['enddate']);
        let stime = sdate.getTime();
        let etime = edate.getTime();
        let diff = etime - stime;
        let days = diff / (1000 * 60 * 60 * 24);
        str = str + ' * ' + days;
        if (el['enddate'] == '1970-01-01') {
          days = '-'
        }
        let ele = (<div key={index}>{str}</div>);
        let row = (<tr><td>{sdate}</td><td>{edate}</td><td>{days}</td></tr>);
        let dstr = formatReadableDate(el['change_date'])
        chisdata.push(
          <tr key={index + 1000} >
            <td>{dstr}</td>
            <td>{el['startdate']}</td>
            <td>{el['enddate']}</td>
            <td>{days}</td></tr>
        );

      })
      return (
        <div style={{ margin: '15px' }}>
          <div className="grid-container-2-13" style={{ textAlign: "left" }}>
            <div><b>Type</b>  </div><div><b>{vehdata['carownertype']} </b></div>
            <div><b>Name</b> </div><div>{vehdata['carowner_fname']} {vehdata['carowner_lname']}</div>

            <div><b>Make</b>  </div><div>{vehdata['make']} {vehdata['model']}</div>
            <div><b>Plate</b>  </div><div>{vehdata['plate']} {vehdata['year']}</div>
            <div><b>Unit</b>  </div><div>{vehdata['unitnumber']} </div>
            <div><b>Phone</b>  </div><div>{vehdata['carownerphone']} </div>
          </div>
          {this.state.which == 'renter'} {
            <div className="grid-container-2-13" style={{ textAlign: "left" }}>
              <div><hr /></div><div><hr /></div>
              <div><b>Type</b>  </div><div><b>owner </b></div>
              <div><b>Name</b>  </div><div>{this.state.ownername}</div>
              <div><b>Phone</b>  </div><div>{this.state.ownerphone} </div>
              <div><b>Email</b>  </div><div>{this.state.owneremail} </div>
            </div>

          }

          <table style={{ width: '100%', marginTop: '10px' , fontSize: "10pt"}}><thead><tr><th>Change</th><th>Start</th><th>End</th><th>Days</th></tr></thead>
            <tbody>{chisdata}</tbody>
          </table>
        </div>
      )
    } else {
      return (<div />)
    }
  }

  closethepopup = (e) => {
    if (e) e.preventDefault();
    this.props.sendclosetoparent();
  }

  render() {
    // const { errors } = this.state;
    // const errors = this.props.errors;
    const errors = this.state.errors;
    const perrors = this.props.errors;
    const cancelicon = <FontAwesomeIcon icon={faTimesCircle} color="black" size="2x" title="cancel / close" />

    let dstr = getTodayAsString();

    return (
        <div style={{ width: "100%", margin: "0px auto" }}>
            <div style={{ marginBottom: "15px", textAlign: "center" }}>
                <h5>Vehicle Id</h5>
                <div> {this.state.selected_vid}</div>
                <div> {dstr}</div>
                {this.state.which == 'owner' ? (
                  this.renderOwnerUsageSummary(this.state.the_vehicle)
                ) : (
                  this.renderRenterUsageSummary(this.state.the_vehicle)

                
                )}
               
                <div style={{ marginTop: "10px", textAlign: "left" }}>
                    <span className="iconbuttonspan">
                        <Link to=""
                            onClick={(e) => this.closethepopup(e)} >
                            {cancelicon}
                        </Link>
                    </span>
                </div>
            </div>
        </div>
    )
  }
}



const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
});
// the state.auth above comes from rootReducer in index.js in reducers.

export default connect(
  mapStateToProps,
  { }
)((ChangeHistory));
// wrap the Register with withRouter so the authAction can use history to redirect
