import React, { Component } from "react";
//import { withRouter } from "react-router";
import axios from "axios";
import { connect } from "react-redux";



//import { Route } from 'react-router-dom';

import { Tab } from 'react-bootstrap';
import { Tabs } from 'react-bootstrap';

import OwnerUsage from "./OwnerUsage";
import RenterUsageOwnerData from "./RenterUsageOwnerData";






class ReportTabs extends Component {


  constructor(props) {
    super(props);
    // console.log("Status original props",props);


    this.state = {
      activekey: "rentercarsowner",

      errors: {}
    };

  }



  componentDidMount() {
    //store.dispatch(setTransTimestamp());
    //  this.props.setTransTimestamp();

    //  this.getDirectory();

  }

  componentWillUnmount() {
    // console.log("Tabs componentWillUnmount");

  }










  tabselected(key) {
    this.setState({ activekey: key, report_data: '' });
  }








  render() {

    const { errors } = this.state;
    const { activekey } = this.state;




    // style={{ textAlign: "center", marginBottom: "10px", width:"300px"}}


    return (
      <div>
        <div className="inputbox">
          <div className="innerinputbox2">


            <p className="parking_label">Application Reports


            </p>



            <Tabs variant="tabs" defaultActiveKey="rentercarsowner" activeKey={activekey} transition={false}
              onSelect={(key) => {
                this.tabselected(key);
              }}
              id="noanim-tab-example">
              <Tab key={'1'} eventKey={'rentercarsowner'} title={'Renter Cars'}>
                <RenterUsageOwnerData />
              </Tab>

              <Tab key={'2'} eventKey={'ownercars'} title={'Owner Cars'}>
                <OwnerUsage />
              </Tab>

              {/* <Tab key={'3'} eventKey={'tbdtab'} title={'TBD'}>
                <h3>TBD</h3>
              </Tab> */}


            </Tabs>






          </div>
        </div>





      </div>
    );
  }
}


const mapDispatchToProps = {

};

export default connect(null, mapDispatchToProps)(ReportTabs);


//export default ReportTabs;

// export default Measurements;
