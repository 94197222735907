import React, { Component } from "react";
// we need to get into git
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import LoadingMessage from "../common/LoadingMessage";
import SmartButtonHPS from "../common/SmartButtonHPS";




import { getAllViolations, deleteViolation } from "../../actions/parkingActions";
import { getTodayAsString } from "../parking/DateHelper";

import axios from "axios";

import RecordViolation from "./RecordViolation";


class Violations extends Component {
  constructor(props) {
    // console.log('violation props', props)
    super(props);

    this.state = {
      whichphoneview: 1,
      hoaid: sessionStorage.getItem('hoaid'),
      violations: [],
      show: false,
      sortdirection: 1,
      whichsort: 'violation_plate',
      showplatesonly: false,

      errors: {}
    }; //shuttles: ["one", "two", "three"] };
    // console.log("Dashboard props", props);

  }

  componentDidMount() {
    let viewnum = 1;
    if (window.innerWidth < 800) {
      viewnum = 2;
    }
    // console.log('window inner width is ', window.innerWidth);
    //console.log('violatons cdm');
    this.setState({ whichphoneview: viewnum });
    this.props.getAllViolations();
    //this.getAllViolations();
    //this.doGetVehicles(oid);
  }

  // getAllViolations = () => {
  //   let submitdata = {};
  //   submitdata['hoaid'] = this.state.hoaid;
  //   let url = "/api/parking/get_all_violations";
  //   axios.post(url, submitdata).then(data => {
  //     // console.log(data);
  //     this.setState({ violations: data.data });
  //     // this.props.showStatusMessage("Violation has been recorded");
  //     // this.props.closeform();
  //   }).catch(err => {
  //     // console.log("in getActive4380Devices response");
  //     console.log(err);
  //   });

  // }



  deleteViolation = (e, o) => {
    e.preventDefault();
    let oid = o['_id'];
    this.props.deleteViolation(oid);
  }


  togglePhoneView = (e, which) => {
    e.preventDefault();
    this.setState({ whichphoneview: which });
  }

  closeform = () => {
    // console.log('this is close form');
    this.setState({ show: false });
  }



  showRecordViolation(e) {
    e.preventDefault();
    this.setState({
      show: true
    });
  }

  doSort = (e, which) => {
    e.preventDefault();
    let sd = this.state.sortdirection;
    sd = sd * -1;
    this.setState({ whichsort: which, sortdirection: sd });
  }

  togglePhoneView = (e, which) => {
    e.preventDefault();
    this.setState({ whichphoneview: which });
  }

  closeviolations = (e) => {
    e.preventDefault();
    this.props.closeviolations();
  }


  showPlatesOnlyToggle = (e) => {
    // this.doSort(e, 'plate');
    e.preventDefault();
    let setflag = true;
    let cur = this.state.showplatesonly;
    if (cur) {
      setflag = false;
    }
    this.setState({ showplatesonly: setflag });
  }


  render() {



    let violations = this.props.parking.current_violations;
    // let { violations } = this.state;
    let numviolations = violations.length;
    // if (numviolations === 0) {
    //   return <h3>No violations to report</h3>
    // }
    let { whichsort } = this.state;
    let { sortdirection } = this.state;
    violations = violations.sort(function (a, b) {
      if (a[whichsort] < b[whichsort]) return (1 * sortdirection);
      if (a[whichsort] > b[whichsort]) return (-1 * sortdirection);
      if (a[whichsort] === b[whichsort]) return 0;
    })

    const role = sessionStorage.getItem('userRole');


    let dstr = getTodayAsString();



    // email	name	phone	role	hoaid unit	pincode
    let violations_template_grid = violations.map((v, index) => (
      <div key={index} className="grid-container-7a">

        <div className="grid-item-normal">{v.violation_plate} - {v.violation_state}</div>
        <div className="grid-item-normal">{v.violation_reporter}</div>
        <div className="grid-item-normal">{v.violation_location}</div>
        <div className="grid-item-normal">{v.violation_description}</div>
        <div className="grid-item-normal">{v.violation_date}</div>
        <div className="grid-item-normal">{v.violation_time}</div>
        <div className="grid-item-normal">
          <Link to=""
            onClick={(e) => this.deleteViolation(e, v)} >delete</Link>
          {/* {role !== 'admin' ? (null) : (
            <Link to=""
              onClick={(e) => this.deleteViolation(e, v)} >delete</Link>
          )} */}
        </div>
      </div>
    ));



    let violations_template_grid_phone = violations.map((violation, index) => (
      <div className="bordershadow" key={index}>
        <div className="grid-item-bold">{index + 1}</div>
        <div key={index} className="grid-container-2" >
          <div className="grid-item-bold">Plate</div>
          <div className="grid-item-bold">Location</div>
          <div className="grid-item-bold" style={{ backgroundColor: "#ddd" }}>
            {violation.violation_plate} - {violation.violation_state}
          </div>
          <div className="grid-item-normal">{violation.violation_location}</div>
          <div className="grid-item-bold">Description</div>
          <div className="grid-item-bold">&nbsp;</div>
          <div className="grid-item-normal">{violation.violation_description}</div>
          <div className="grid-item-normal">&nbsp;</div>

          <div className="grid-item-bold">Date</div>
          <div className="grid-item-bold">Time</div>
          <div className="grid-item-normal">{violation.violation_date}</div>
          <div className="grid-item-normal">{violation.violation_time}</div>
        </div>
      </div>
    ));

    let violations_template_grid_phone_admin = violations.map((violation, index) => (
      <div className="bordershadow" key={index}>
        <div className="grid-item-bold">{index + 1}</div>
        <div key={index} className="grid-container-3" >
          <div className="grid-item-bold">Plate</div>
          <div className="grid-item-bold">Location</div>
          <div className="grid-item-bold">Description</div>
          <div className="grid-item-bold" style={{ backgroundColor: "#ddd" }}>
            {violation.violation_plate} - {violation.violation_state}
          </div>
          <div className="grid-item-normal">{violation.violation_location}</div>
          <div className="grid-item-normal">{violation.violation_description}</div>

          <div className="grid-item-bold">Date</div>
          <div className="grid-item-bold">Time</div>
          <div className="grid-item-bold">&nbsp;</div>

          <div className="grid-item-normal">{violation.violation_date}</div>
          <div className="grid-item-normal">{violation.violation_time}</div>
          <div className="grid-item-normal">
            <Link to=""
              onClick={(e) => this.deleteViolation(e, violation)} >delete</Link>
          </div>
        </div>
      </div>
    ));

    let phonesorttable = (
      <div style={{ borderBottom: "0px solid black", marginBottom: "10px" }}>
        <table>
          <thead><tr><th colSpan="5">Sort</th></tr></thead>
          <tbody>
            <tr>
              <td style={{ paddingRight: "10px" }}>
                <Link to=""
                  onClick={(e) => this.doSort(e, 'violation_plate')} >
                  <b>Plate</b>
                </Link>
              </td>
              <td style={{ paddingRight: "10px" }}>
                <Link to=""
                  onClick={(e) => this.doSort(e, 'violation_date')} >
                  <b>Date</b>
                </Link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );

    let violations_plates_only = violations.map((violation, index) => (
      <div key={index} className="bordershadowsm" style={{ fontSize: "14pt", width: "250px", textAlign: "center" }}>

        <div>
          {violation.violation_plate} - {violation.violation_state}
        </div>
        <div style={{ fontSize: "12pt" }}>
          {violation.violation_location}
        </div>
        <div style={{ fontSize: "12pt" }}>
          {violation.violation_description}
        </div>
        <div style={{ fontSize: "10pt" }}>
          {violation.violation_date} - {violation.violation_time}
        </div>



      </div >
    ));




    let { whichphoneview } = this.state;
    let { showplatesonly } = this.state;

    let popupclass = 'popupdialog bordernoshadow';
    if (whichphoneview !== 1) popupclass = 'popupdialog_phone bordernoshadow';
    let chbclass = 'normalbuttonwrapper';
    let ptop = window.scrollY;
    let itop = 80 + ptop;
    const addicon = <FontAwesomeIcon icon={faPlusCircle} color="#65598a" size="2x" title="add" />
    const closeicon = <FontAwesomeIcon icon={faTimesCircle} color="#fff" size="1x" title="cancel / close" />

    return (
      <div style={{ border: "0px solid yellow", marginTop: "10px" }}>
        <div style={{ textAlign: "center", marginBottom: "5px" }}>
          <SmartButtonHPS
            name={showplatesonly ? <span>Records</span> : <span>Plates</span>}
            buttonclass="btn-basic button_12"
            onClick={(e) => this.showPlatesOnlyToggle(e)}
          />

          {/* <span className={chbclass}>
            <Link to="" className="btn btn-xs btn-info btnparking"
              onClick={(e) => this.showPlatesOnlyToggle(e)} >
              {showplatesonly ? (
                <span>Records</span>
              ) : (
                <span>Plates</span>
              )}

            </Link>
          </span> */}
        </div>


        <div className="inputbox">
          <div className="innerinputbox2">
            {(this.props.parking.isloading) ? (<LoadingMessage />) : null}

            {/* <p className="parking_label">Violations</p> */}

            <div className="parking_label">Violations

              {/* {this.props.isadmin ? ( */}
              <span style={{ float: "right" }}>
                <Link to=""
                  onClick={(e) => this.closeviolations(e)} >
                  {closeicon}
                </Link>
              </span>
              {/* ) : (null)
              } */}


            </div>



            <div className="flex_row_sb">
              <div className="flex_item_left">
                {this.props.isadmin ? (<Link to=""
                  onClick={(e) => this.showRecordViolation(e)} >{addicon}</Link>) : (
                  <Link to=""
                    onClick={(e) => this.showRecordViolation(e)} >{addicon}</Link>
                )}
              </div>
              <div className="flex_item_center">
                <h5 style={{ textAlign: "center", color: "black" }}>Today: {dstr}</h5>
              </div>
              <div className="flex_item_right">
                {/* <Link to=""
                  onClick={(e) => this.closeviolations(e)} >{closeicon}</Link> */}
              </div>
            </div>
            <hr />







            {this.state.show ? (
              <div className={popupclass}>
                <RecordViolation closeform={this.closeform} />
              </div>
            ) : (null)}

            {(numviolations === 0) ? (<h3>No Violations</h3>) : (

              <div>
                {showplatesonly ? (
                  <div style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    width: "100%",
                  }}>
                    {violations_plates_only}

                  </div>


                ) : (




                  <div style={{ fontSize: "10pt" }}>
                    <div className="phoneview" style={{ fontSize: "10pt", border: "0px solid black" }}>
                      <div>{phonesorttable}</div>
                      {/* <div>
                        {role === 'admin' ? (
                          violations_template_grid_phone_admin
                        ) : (
                          violations_template_grid_phone
                        )}
                      </div> */}
                      <div>
                        {violations_template_grid_phone_admin}
                      </div>
                    </div>
                    <div className="tableview" style={{ fontSize: "10pt", border: "0px solid black", maxHeight: "400px", overflowY: "scroll", overflowX: "hidden" }} >
                      <div>
                        <div className="grid-container-7a">
                          <div className="grid-item-bold">
                            <Link to=""
                              onClick={(e) => this.doSort(e, 'violation_plate')} >
                              <b>Plate</b>
                            </Link>
                          </div>

                          <div className="grid-item-bold">
                            <Link to=""
                              onClick={(e) => this.doSort(e, 'violation_reporter')} >
                              <b>Reporter</b>
                            </Link>
                          </div>



                          <div className="grid-item-bold">Location</div>
                          <div className="grid-item-bold">Description</div>
                          <div className="grid-item-bold">

                            <Link to=""
                              onClick={(e) => this.doSort(e, 'violation_date')} >
                              <b>Date</b>
                            </Link>
                          </div>
                          <div className="grid-item-bold">Time</div>

                        </div>
                        <div>
                          {violations_template_grid}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}




          </div >
        </div >



        {/* <Modal backdrop="static" size="md" dialogClassName="bordershadow_modal" show={this.state.show} onHide={this.handleClose} >
          <Modal.Body style={{ backgroundColor: "#f0f0f0" }} >
            <RecordViolation closeform={this.closeform} />
          </Modal.Body>
        </Modal> */}



      </div >
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  parking: state.parking,
  errors: state.errors,
});

export default connect(mapStateToProps, { getAllViolations, deleteViolation })(Violations);

//export default Dashboard;
