import React, { Component } from "react";
//import PropTypes from "prop-types";
//import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { Button } from 'react-bootstrap';


//import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'


import { connect } from "react-redux";

import TextFieldGroupPlain from "../common/TextFieldGroupPlain";
import ErrorMessageConstantClose from "../common/ErrorMessageConstantClose";
import msgHelper from '../../config/msgHelper';

import { changeVehicleStatusDate } from "../../actions/parkingActions";
import { getActiveCounts } from '../parking/AjaxHelper';

import PayForParkingForm from "../payments/PayForParkingForm";

import { getTodayAsString, getTomorrowAsString } from "../parking/DateHelper";


// import { validateInsertModifyVehicleInput } from "../../validation/client_insertvehiclevalidation";
// import { getParkingSpots } from '../parking/AjaxHelper';





class VehicleOnsite extends Component {
  constructor(props) {




    super(props);

    let isadminstr = sessionStorage.getItem('userRole');
    // console.log('isadminstr', isadminstr);
    let isadmin = false;
    if (isadminstr === 'admin') {
      isadmin = true;
    }


    let dstr = getTomorrowAsString();

    let veh = this.props.onsite_vehicle;
    veh['which'] = 'modify';
    veh['vehicleid'] = veh['_id'];
    let ved = veh['enddate'];
    if ((ved === '1970-01-01') || (ved < dstr)) {
      ved = dstr;
    }

    /*
    useccform: parseInt(sessionStorage.getItem('use_owner_creditcard')),
      usepppform: parseInt(sessionStorage.getItem('use_owner_ppp')),
    */


    this.state = {
      hoaid: sessionStorage.getItem('hoaid'),
      use_owner_ppp: parseInt(sessionStorage.getItem('use_owner_ppp')),
      use_owner_creditcard: parseInt(sessionStorage.getItem('use_owner_creditcard')),
      enddate: ved,
      onsite_vehicle: veh,
      whichphoneview: 1,
      selectedOption: 'offsite',
      showoverlimit: false,
      showoverlimitmessage: "what",
      showpppform: false,
      isadmin: isadmin,
      disable_controls: false,
      errors: {},
    };

    this.onChange = this.onChange.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);
    this.onValueChange = this.onValueChange.bind(this);

  }



  static getDerivedStateFromProps(props, state) {
    if (props.vehicle !== state.vehicle) {
      return {
        vehicle: props.vehicle
      }
    }
    return null;
  }

  componentDidMount() {

    let viewnum = 1;
    if (window.innerWidth < 800) {
      viewnum = 2;
    }
    this.setState({
      whichphoneview: viewnum
    });

    let statusmode = 'offsite'

    if (this.state.onsite_vehicle.active_flag === 1) {
      statusmode = 'changedate';
    }
    this.setState({ selectedOption: statusmode })


  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.vehicle !== this.state.vehicle) {
      // this.loadState(this.props, null);
    }
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value, errors: {} });
  }

  onChangeDate(e) {
    let ed = e.target.value;

    let dstr = getTomorrowAsString();
    //console.log('ed dstr', ed, dstr);
    if (ed < dstr) {
      this.setState({ errors: { enddate: 'Date must be in the future.' } });
      return;
    }

    this.setState({ [e.target.name]: e.target.value, errors: {} });
  }


  onValueChange(event) {
    this.setState({
      selectedOption: event.target.value
    });
  }




  performUpdate = (status, enddate) => {
    let vehicle = this.state.onsite_vehicle;
    let vid = vehicle._id;
  // console.log('performUpdate',vid, status, enddate);
    this.props.changeVehicleStatusDate(vid, status, enddate);
    this.props.sendclosetoparent();

  }


  closeallforms = (e) => {
    if (e) e.preventDefault();

    this.props.sendclosetoparent();
  }



  closeformfromchild = () => {
    //  e.preventDefault();
    // console.log('closeformfromchild goint to close form');
    //console.log('we enable all components in onsite')
    this.setState({ showpppform: false, disable_controls: false });
  }

  closeOnsiteForm = (e) => {
    e.preventDefault();
    // console.log('goint to close form');
    this.props.sendclosetoparent();
  }

  closemessage = () => {
    this.setState({ showoverlimit: false });
  }

  handleOffsite = (e) => {
    // alert('handle offsite');
    e.preventDefault()
    this.performUpdate(0, '1970-01-01');


  }

  handleSubmitChangeDate = (e) => {
    // alert('handle change date');
    e.preventDefault();

    //  console.log('uss', this.state.use_owner_creditcard);

    let { onsite_vehicle } = this.state;

    let ovdate = onsite_vehicle['enddate'];
    let oid = onsite_vehicle['ownerid'];

    let eq00 = false;
    if (ovdate === '1970-01-01') {
      eq00 = true;
    }
    getActiveCounts(oid).then(res => {
      // console.log('getActiveCounts res is ', ovdate, res);
      if (eq00 && !res.ownercountok) {
        let msg = "The maximum number of active parking spaces (" + res.maxunitactive + ") for this unit has been reached.";
        msg = msg + ' Owner: ' + res.ownercount + ' Renter: ' + res.rentercount;
        // this.setState({ showoverlimit: true, showoverlimitmessage: msgHelper.over_owner_parking_limit + ' ' + res.maxunitactive })
        this.setState({ showoverlimit: true, showoverlimitmessage: msg });
        return;
      }
      if (eq00 && !res.allcountok) {
        this.setState({ showoverlimit: true, showoverlimitmessage: msgHelper.over_total_parking_limit });
        return;
      }
      let ed = this.state.enddate;
      if ((this.state.use_owner_ppp) && (!this.state.isadmin)) {
        this.setState({ showpppform: true, disable_controls: true });
       // console.log('we disable all components in onsite')
      } else {
        //jjv
        //this.performUpdate(0, ed);
        this.performUpdate(1, ed);
      }
    }).catch(error => {
      console.log('error', error);
    });




  }



  render() {
    // const { errors } = this.state;
    // const errors = this.props.errors;
    const errors = this.state.errors;
    const perrors = this.props.errors;

    const { showoverlimit } = this.state;
    const { showoverlimitmessage } = this.state;
    const { showpppform } = this.state;
    const { disable_controls } = this.state;

    let { whichphoneview } = this.state;


    let { onsite_vehicle } = this.state;

    const okicon = <FontAwesomeIcon icon={faCheckCircle} color="#65598a" size="2x" title="enter / submit" />
    const okicondisable = <FontAwesomeIcon icon={faCheckCircle} color="#ddd" size="2x" />
    const cancelicon = <FontAwesomeIcon icon={faTimesCircle} color="black" size="2x" title="cancel / close" />

    let modalclass = 'modal-main-inline';
    if (whichphoneview !== 1) modalclass = 'modal-main-phone';

    // const okicon = <FontAwesomeIcon icon="check-circle" color="#65598a" size="2x" />
    // const okicondisable = <FontAwesomeIcon icon="check-circle" color="#ddd" size="2x" />
    // const cancelicon = <FontAwesomeIcon icon="times-circle" color="black" size="2x" />



    let dstr = getTodayAsString();



    return (
      <div className="xxbordershadownm" style={{ width: "100%", margin: "0px auto" }}>
        <div style={{ marginBottom: "15px", textAlign: "center" }}>

          {showoverlimit ? (
            <ErrorMessageConstantClose message={showoverlimitmessage} closemessage={this.closemessage} />
          ) : (null)}

          <p className="text-center"><b>Owner Vehicle Status</b></p>
          <p className="text-center">
            Plate: {onsite_vehicle.plate}<br />
            Make: {onsite_vehicle.make}<br />
            Type:  {onsite_vehicle.carownertype}</p>
          <p style={{ backgroundColor: "#f0f0f0" }}>
            Current Vehicle Status <br />
            {onsite_vehicle.enddate > dstr ? (<b>Onsite</b>) : (<b>Offsite</b>)}
          </p>


          {onsite_vehicle.enddate >= dstr ? (
            <div>
              <hr />
              <b>Change status to offsite</b>

              <div style={{ marginTop: "10px" }}>
                <Link to=""
                  onClick={(e) => this.handleOffsite(e)} >
                  {okicon}
                </Link>
              </div>
            </div>
          ) : (
            null
          )}


          {disable_controls ? (null) : (
            <div>

              <hr />
              <b>

                Change vehicle checkout date
              </b>


              <div style={{ width: "200px", margin: "10px auto" }}>
                <TextFieldGroupPlain
                  type="date"
                  label="End Date"
                  placeholder="End Date"
                  name="enddate"
                  value={this.state.enddate}
                  onChange={this.onChangeDate}
                  error={errors.enddate}
                />
              </div>

              <div style={{ marginTop: "0px" }}>
                {errors.enddate ? (
                  <div>{okicondisable}</div>
                ) : (
                  <Link to=""
                    onClick={(e) => this.handleSubmitChangeDate(e)} >
                    {okicon}
                  </Link>
                )}
              </div>
              <hr />
              <div style={{ marginTop: "10px", textAlign: "left" }}>

                <span className="iconbuttonspan">
                  <Link to=""
                    onClick={(e) => this.closeOnsiteForm(e)} >
                    {cancelicon}
                  </Link>
                </span>
              </div>
            </div>

          )}

          {showpppform ? (
            <div className="xbordershadow">
              <div className={modalclass}>
                <PayForParkingForm selecteddate={this.state.enddate} cardata={onsite_vehicle} sendclosetoparent={this.closeformfromchild}
                  closeallforms={this.closeallforms} />
              </div>
            </div>
          ) : (
            null
          )}




          {perrors && (
            <div className="error-display">{perrors.message}</div>
          )}

        </div>
      </div>


    );
  }




  // this is good practice because it will help debugging
  // it is not checked when in production mode.
  // Business.propTypes = {
  //   createBusiness: PropTypes.func.isRequired,
  //   modifyBusiness: PropTypes.func.isRequired,
  //   auth: PropTypes.object.isRequired,
  //   errors: PropTypes.object.isRequired
};

// map errors to this component
// then we can use this.props.auth.user etc
// since we mapped error, we can use componentWillReceiveProps method
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
});
// the state.auth above comes from rootReducer in index.js in reducers.

export default connect(
  mapStateToProps,
  { changeVehicleStatusDate }
)((VehicleOnsite));
// wrap the Register with withRouter so the authAction can use history to redirect
