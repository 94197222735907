import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { Table } from "react-bootstrap";

import { Link } from "react-router-dom";
import { getTodayAsString, getOnsiteLabel, generateDateDisplay,getRowHighlightColor } from "../parking/DateHelper";

import ChangeHistory from "./ChangeHistory";



import { Button } from 'react-bootstrap';

import TextFieldGroup from "../common/TextFieldGroup";
import SelectNameValuePlain from "../common/SelectNameValuePlain";


//import axios from "axios";
//import classnames from "classnames";

class RenterUsage extends Component {

  constructor(props) {
    super(props);
    // console.log("Status original props",props);
    let dstr = this.getDateString();
    let estr = this.getEndDateString();


    this.state = {
      activekey: "rentercars",
      vehicles: [],
      begindate: dstr,
      enddate: estr,
      which: 'owner',
      hoaid: sessionStorage.getItem('hoaid'),
      showpopup: false,
      selected_vid: null,
      errors: {}
    };

    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    let shoastr = sessionStorage.getItem('selectedhoa');
    let ahoa = JSON.parse(shoastr);
    // console.log('ahoa', ahoa);
    //store.dispatch(setTransTimestamp());
    //  this.props.setTransTimestamp();

    //  this.getDirectory();
    //this.getReportData()

  }

  getDateString() {
    let dt = new Date();
    // dt.setMonth(dt.getMonth() + 1);
    dt.setDate(dt.getDate() - 14);
    let yr = dt.getFullYear();
    let mo = (dt.getMonth() + 1).toString();
    let day = dt.getDate().toString();
    if (mo.length === 1) mo = "0" + mo;
    if (day.length === 1) day = "0" + day;
    let rstr = yr + "-" + mo + "-" + day;
    return rstr;
  }
  getEndDateString() {
    let dt = new Date();
    // dt.setMonth(dt.getMonth() + 1);
    let yr = dt.getFullYear();
    let mo = (dt.getMonth() + 1).toString();
    let day = dt.getDate().toString();
    if (mo.length === 1) mo = "0" + mo;
    if (day.length === 1) day = "0" + day;
    let rstr = yr + "-" + mo + "-" + day;
    return rstr;
  }

  onChange(e) {
    //  console.log(e.target.name);
    //  console.log(e.target.value);
    this.setState({ [e.target.name]: e.target.value });
    // if (e.target.name === 'begindate') {
    //   this._globalsdate = (e.target.value);
    // }
    // if (e.target.name === 'enddate') {
    //   this._globaledate = (e.target.value);
    // }

    // this.getDirectory();
  }

  getReportData = (e) => {
    e.preventDefault();
    let { begindate } = this.state;
    let { enddate } = this.state;
    let { which } = this.state;

    let submitdata = { "which": which, "begindate": begindate, "enddate": enddate };
    submitdata['hoaid'] = this.state.hoaid;

    //let url = "/api/parking/get_car_usage";
    let url = "/api/parking/get_car_usage_renter_warning";
    // console.log(url, submitdata);
    axios.post(url, submitdata).then(vehs => {
      // console.log(vehs.data);
      this.setState({ vehicles: vehs.data });
    }).catch(err => {
      console.log(err);
    });


  }



  // renderOwnerUsageSummary = (veh) => {
  //   let chistory = veh['change_history'];
  //   let chisdata = [];
  //   chistory.forEach((el, index) => {
  //     let str = el['startdate'] + ' ' + el['enddate']
  //     let sdate = new Date(el['startdate']);
  //     let edate = new Date(el['enddate']);
  //     let stime = sdate.getTime();
  //     let etime = edate.getTime();
  //     let diff = etime - stime;
  //     let days = diff / (1000 * 60 * 60 * 24);
  //     str = str + ' * ' + days;
  //     let ele = (<div key={index}>{str}</div>);
  //     if (el['enddate'] !== '1970-01-01') {
  //       if (index > 0) {
  //         chisdata.push(ele);
  //       }
  //     }
  //   })
  //   return (<div>{chisdata}</div>)
  // }

  renderOwnerCarRecords = (vehicles_ary) => {
   // console.log(vehicles_ary);
    let rdata = [];
    let curunum = '0';
    let dstr = getTodayAsString();
    vehicles_ary.forEach((vehicle, index) => {
      let edate = vehicle['enddate'];
      let bcolor = getRowHighlightColor('warning',vehicle);
      let chistory = vehicle['change_history'];
      let chisdata = [];
      chistory.forEach(el => {
        let str = el['startdate'] + ' ' + el['enddate']
        chisdata.push(str);
      })    
      let onsite = getOnsiteLabel(edate, dstr,vehicle);
      let displaydate = edate; //generateDateDisplay(edate, dstr);
      let tcurunum = vehicle.unitnumber;
      // console.log(curunum, tcurunum);
      let rowclassname = '';
      if (tcurunum !== curunum) {
        rowclassname = 'boldrowtop';
        curunum = tcurunum;
      } else {
        rowclassname = '';
      }
      let stdate = new Date(vehicle['startdate']);
      let enddate = new Date(vehicle['enddate']);
      let stime = stdate.getTime();
      let etime = enddate.getTime();
      let diff = etime - stime;
      let days = diff / (1000 * 60 * 60 * 24);
      if (vehicle['enddate'] == '1970-01-01') {
        days = ''
      }
      rdata.push(
        <tr key={index + 1000} className={rowclassname} style={{ backgroundColor: bcolor }}>
          <td>
            {vehicle.plate} - {vehicle.plate_state}
          </td>
          <td>{vehicle.vehicle_type}</td>
          <td>{vehicle.make} - {vehicle.model}</td>
          <td>{vehicle.unitnumber}</td>
          <td>
            {vehicle['carowner_lname']}, {vehicle['carowner_fname']}
          </td>
         
          <td>{vehicle['carownertype']}</td>
          <td>
            {vehicle.startdate}
          </td>
          <td>
            {displaydate}
          </td>
          <td>
            {days}
            </td>
          <td>
          <Link to=""
                onClick={(e) => this.showChangeHistory(e, vehicle)} >
                   {vehicle.number_of_changes}
                  </Link>
          </td>
          <td>
            {onsite} -
            {vehicle['status_flag']}
          </td>
        </tr>
      )
    })
    return (<tbody>{rdata}</tbody>)
  }
  


  showChangeHistory = (e,veh) => {

    this.setState({selected_vid: veh['_id'],showpopup:true});

  }

  closepopup = () => {
    // console.log('this is close form');
    this.setState({ showpopup: false });
  }


  render() {
    // const { errors } = this.state;
    //const advertiser = this.state.selectedAdvertiser;
    // same as const errors = this.state.errors

    // this was used to show user from props
    // const { user } = this.props.auth; // const user = this.props.auth.user
    // this shows user {user ? user.name : null}

    const { errors } = this.state;
    const { vehicles } = this.state;
    let ptop = window.scrollY;
    let itop = 100 + ptop;

    let { showpopup } = this.state;
    let { selected_vid } = this.state;

    let popupclass = 'popupdialog bordershadow';

    let vehid = '123'




      return (
          <div>
              <div className="xrow" style={{ backgroundColor: '#f0f0f0', padding: "15px", margin: "10px" }}>
                  <Button key={1} id="submitdates" onClick={(e) => { this.getReportData(e) }}
                      variant="dark" size="sm">Submit</Button>
              </div>

              {vehicles.length > 0 ? (
                  <Table size={"sm"} style={{ fontSize: "10pt" }}>
                      <thead>

                          <tr>
                              <th>Plate</th>
                              <th>Type</th>
                              <th>Make</th>
                              <th>Unit</th>
                              <th>Owner</th>
                              <th>Owner Type</th>
                              <th>Start</th>
                              <th>End</th>
                              <th>Days</th>
                              <th>History</th>
                              <th>Onsite s</th>
                          </tr>

                      </thead>

                      {this.renderOwnerCarRecords(vehicles)}

                  </Table>

              ) : (<p>Please run query showing owner</p>)
              }

              {
                  showpopup ? (
                      <div className={popupclass} style={{ top: itop,width:"500px" }}>
                          <ChangeHistory
                              selected_vid={selected_vid}
                              which={'owner'}
                              sendclosetoparent={this.closepopup} />
                      </div>
                  ) : (null)
              }

          </div>
            
           
      )

    



  }
    
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
});
// the state.auth above comes from rootReducer in index.js in reducers.

export default connect(
  mapStateToProps,
  null
)(RenterUsage);
// wrap the Register with withRouter so the authAction can use history to redirect
