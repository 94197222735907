
//const isEmpty = require("./is-empty");
//import { isEmpty } from './is-empty';

//const Validator = require("validator");
import { isitEmpty } from './isitempty';

export const validateModifyOwnerInput = (data) => {
  let errors = {};
  // console.log('validateModifyVehicleInput data', data);
  // console.log('validateModifyVehicleInput data', data.carownername);
  // console.log('hard call ', isitEmpty('no'))

  data.owner_email = !isitEmpty(data.owner_email) ? data.owner_email : "";
  data.owner_phone = !isitEmpty(data.owner_phone) ? data.owner_phone : "";
  data.owner_pincode = !isitEmpty(data.owner_pincode) ? data.owner_pincode : "";


  if (isitEmpty(data.owner_email)) {
    errors.owner_email = "Email field is required";
  }
  if (isitEmpty(data.owner_phone)) {
    errors.owner_phone = "Phone field is required";
  }
  if (isitEmpty(data.owner_pincode)) {
    errors.owner_pincode = "Pin Code field is required";
  }


  return {
    errors,
    isValid: isitEmpty(errors)
  };
};




