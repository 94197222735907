import React, { Component } from "react";

import SectionGeneral from "./SectionGeneral";
//import SectionPermissions from "./SectionPermissions";
import SectionContact from "./SectionContact";
import HelpNavigation from "./HelpNavigation";







class HelpOwner extends Component {

  constructor(props) {
    super(props);
    this.state = {

      errors: {}
    };


  }

  componentDidMount() {


  }

  onCancelClick(e) {
    e.preventDefault();
    this.props.history.goBack();
    //let url = "/" + sessionStorage.getItem('hoaid');
    // window.location.href = url;
    // this.props.history.push(url);
    //window.location = "/id/" + sessionStorage.getItem('hoaid');
  }







  render() {

    let hoaname = sessionStorage.getItem('hoaname')

    return (

      <div className="tenpoint bordernoshadow" >
        <h5 style={{ textAlign: "center" }}>
          {hoaname} Parking<br />
          Owner Help
        </h5>




        <SectionGeneral />





        <h6><b>Owner Guide and Instructions</b></h6>
        <p><b>GETTING STARTED</b> </p>
        <ul>
          <li>After logging in on the<b> Unit Owner Login </b>page, you are now on the <b>Owner Dashboard </b>page. </li>
          <li>NOTE: Every owner is associated to a unit number. Each unit number has 1 authorized login assigned. The user ID is the owner email address. Where multiple emails existed for a unit, only one was assigned. </li>
        </ul>
        {/* <div >
          <HelpNavigation />
        </div> */}
        <p><b>PROFILE</b> </p>
        <ul>
          <li>	On the next one-time Terms and Conditions screen, you’ll be asked to acknowledge that you have read and accept {hoaname}’s Rules and Regulations.
          </li>
          <li> Click on the Owner Profile button (in the upper right corner). If this is your first time there, you will see your default profile. It is important that you verify/update your phone number.  </li>
          <li> Change your Password (to something you’ll remember).</li>
          <li> Update your PIN code. This Pin is required for the Renter to register their vehicle. Changing your PIN periodically or for each rental reduces the chance of renters abusing the system. </li>
          <li>The Availability Flag has 2 choices. By selecting "Available to rent", it automatically displays this unit on the Renter's page. Selecting "Not Available" removes the unit from Renter page. </li>
          <li>If you rent your unit, it is YOUR responsibility to let your renters and/or your Rental company know the rules before they arrive. Provide link and/or QC Code, PIN code, and registration instructions. We recommend posting the instructions in your unit and sharing it with your vacation rental property manager to include in welcome emails to renters.
          </li>
          <li> Be sure to click the Enter/Submit button for any field that is updated or modified. You will receive a confirmation that the change was updated successfully.</li>


        </ul>



        <p><b>REGISTERING & MAINTAINING VEHICLES</b> </p>


        <ul>
          <li>The Owner Dashboard page  lists all the vehicles associated to your unit. To add a vehicle, click the "+" button on the left hand side. You will now see a pop-up window: Register Vehicles.The following data is required:</li>
          <li><b>Vehicle</b>: 	<br />Car	(includes all cars, SUV’s, crossovers)<br />
            Truck/Van	(includes pickup trucks, small trucks, small vans)<br />
            Motorcycle<br />
            Trailer*<br />
            Camper/RV*<br />
            Oversized* (includes construction vehicles, large pickups, etc)<br />
            Dumpster*<br />

            *Note: You must obtain temporary permission to park a trailer, camper/RV, oversized vehicle, or dumpster. See Rules & Regs. These are provided for convenience when applying for a work permit, construction project, move, or other authorized activity.</li>
          <li> <b>Belongs To</b>:	<br />
            Owner <br />
            Family <br />
            Friend  <br />
            Long Term <br />
            Visitor   	(routine visitor such as caretaker, cleaner, etc.) <br />
            Contractor	(business and work related activities; associated with a vehicle with a *)   </li>
          <li><b>Name</b>: First & Last		(name of vehicle owner)</li>
          <li> <b>Make</b>, <b>Model</b>, <b>Year</b>, <b>Plate</b>, <b>State</b>	(Vehicle & license plate information)</li>
          <li> Notice that you can add as many vehicles as you want BUT only 2 vehicles/unit can be ONSITE on the premises at any point in time per {hoaname} rules.</li>
          <li> <b>ONSITE Indicator:</b> Set each vehicle’s ONSITE indicator to YES or NO to reflect its onsite status. Set to YES if that vehicle is parked in our lots overnight; NO if it isn’t. When you register a vehicle, the ONSITE indicator will default to NO with a 1970-01-01 date. Click it, and a pop-up window will appear asking you to “Change vehicle check-out date”, the date you will be leaving. Entering a future date causes the ONSITE indicator to change to YES. A few examples:<br />

            1. Your primary residence is {hoaname} – You’ll change your vehicles’ ONSITE indicators to YES (with some arbitrary date way out in the future) when you register each vehicle. If one or more vehicles is offsite for several nights, you’ll change the ONSITE indicator to NO, but change it back to YES when it’s back onsite.<br />
            2. Your primary residence is NOT {hoaname} – You’ll change your registered vehicle’s ONSITE indicator to YES (with your expected check-out date) when it is onsite. Or, if you’re renting a car, you’ll register that vehicle and set the ONSITE indicator to YES; just be sure to delete the rental vehicle when you depart.
          </li>
        </ul>

        <p><b>MONITORING and REPORTING</b> </p>
        <ul>
          <li>From the Dashboard, click on the <b>ONSITE VEHICLES</b> button. This will give you a report of all the vehicles currently parked with information on the unit associated to it.</li>
          <li> Clicking the Plates Only button gives you an abbreviated plate listing to located the owner of a car that is blocking you, illegally parked, or hasn’t moved in the required 48 hours. If the plate is not on the list at all, it is an unregistered vehicle, illegally parked. Click the X to close the window and go back to the Onsite Vehicles screen.</li>
          <li> From the Onsite Vehicles screen, you can post a violation by clicking on the Show Violations button. This will let Administration know there is a problem to check. During Peak Times, you can also call the Mgmt Company to report the problem. Unlawful parked vehicles will be tagged and given 12 hours to move or be booted or towed. </li>
        </ul>


        {/* <SectionPermissions /> */}

        {/* <SectionContact which={"Owner"} /> */}








      </div >
    );
  }
}


export default ((HelpOwner));
