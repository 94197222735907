import React from "react";

export default () => {

  let shoastr = sessionStorage.getItem('selectedhoa');

  let ahoa = JSON.parse(shoastr);



  let parkingurl = ahoa['parking_information_url'];

  return (
    <div style={{ fontSize: "10pt" }}>
      <h6><b>Offsite Parking Information</b></h6>

      {/* <p>For your overflow parking needs, we understand that you may park free at Howelsen for
        48 hours or at the Greyhound Transit Center for 1 week.</p> */}
      <p>Offsite overnight parking rules change by the season. Visit&nbsp;
        <a href={parkingurl} target="_blank">{parkingurl}</a> for current information.</p>


    </div>

  );
};