import React, { Component } from "react";

import SectionGeneral from "./SectionGeneral";
import SectionPermissions from "./SectionPermissions";


class HelpAdmin extends Component {

  constructor(props) {
    super(props);
    this.state = {
      errors: {}
    };


  }

  componentDidMount() {

    // console.log('this is help for admin')


  }


  render() {

    let hoaname = sessionStorage.getItem('hoaname')
    let hoastr = sessionStorage.getItem('selectedhoa');
    let hoaobj = { help_text: { general: "<h2>No Admin Help" } };

    if (hoastr) hoaobj = JSON.parse(hoastr);

    return (

      <div className="tenpoint bordernoshadow" >
        <h5 style={{ textAlign: "center" }}>
          {hoaname} Parking
          Administrator Help
        </h5>



        <SectionGeneral />

        <SectionPermissions />

        {/* <div dangerouslySetInnerHTML={{ __html: hoaobj['help_text']['admin'] }}></div> */}

      </div>
    );
  }
}


export default HelpAdmin;
