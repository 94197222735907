import './App.css';

import React from "react";

import { useDispatch, useSelector } from "react-redux";



import { useParams } from 'react-router-dom'


import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import { Provider } from "react-redux";
import StatusMessage from "./components/common/StatusMessage";
import Login from "./components/auth/Login";
import OwnerProfile from "./components/owner/OwnerProfile";
import AdminProfile from "./components/admin/AdminProfile";
import RenterLogin from "./components/auth/RenterLogin";
import Landing from "./components/layout/Landing";

import LandingForHoa from "./components/layout/LandingForHoa";
import Navbar from "./components/layout/Navbar";
import OwnerDashboard from "./components/owner/OwnerDashboard";
import AdminDashboard from "./components/admin/AdminDashboard";
import MaintenanceDashboard from "./components/admin/MaintenanceDashboard";
//import HelpTabs from "./components/parking/help/HelpTabs";
import HelpTabs from "./components/parking/help/HelpMain";

import RenterDashboard from "./components/renter/RenterDashboard";

import UserVerification from "./components/auth/UserVerification";


//import { logoutUser } from "./actions/authActions";





//import Login from "./components/auth/Login";
//import UserVerification from "./components/auth/UserVerification";
//import RenterLogin from "./components/auth/RenterLogin";
import store from "./store";


const AuthContext = React.createContext(null);

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  //let dispatch = useDispatch();
  //const location = useLocation();

  //const [data, setData] = React.useState('what');
  const [token, setToken] = React.useState(sessionStorage.getItem('token'));
  const [crumbs, setCrumbs] = React.useState('');



  React.useEffect(() => {


  }, []);

  const handleNavigation = (link) => {
    // console.log('app hook handleNavigation', link)
    // const origin = '/dashboard';

    if (link === '/') {
      // console.log('handleNavigation link passed in is /')
      setCrumbs('');
      // dispatch(logoutUser())
      sessionStorage.setItem('hoaname', '')
      sessionStorage.setItem('userRole', '')
      //  sessionStorage.setItem('hoaname', '')
      sessionStorage.removeItem('hoaid')
    }
    navigate(link);
  };

  const handleHelpClick = (link) => {

    // const reduxauth = useSelector(state => state.auth);

    //console.log('we are in protected route rbac', reduxauth);
    // const user = reduxauth.user;
    let hoaid = sessionStorage.getItem('hoaid');
    if (hoaid === null) {
      hoaid = '';
    }
    let userrole = sessionStorage.getItem('userRole');
    if (userrole === null) {
      userrole = '';
    }

    let hlink = '/helptabs/landing';

    if (userrole.length > 0) {
      hlink = '/helptabs/' + userrole

    } else {
      if (hoaid.length > 0) {
        hlink = '/helptabs/app';
      }

    }


    // console.log('app hook handleHelpClick session hoaid', hoaid)
    // console.log('app hook handleHelpClick userrole', userrole)

    //console.log('app hook handleHelpClick', link)

    // console.log('app hook handleHelpClick user', user)

    // if (sessionStorage.getItem('hoaid') && )
    // const origin = '/dashboard';

    navigate(hlink);
  };

  const handleBreadcrumb = (incrumb) => {
    // console.log('app hook handleNavigation', link)
    // const origin = '/dashboard';
    setCrumbs(incrumb);
  };

  const value = {
    token,
    // rbac,
    crumbs,
    // showSidebar,
    // onLogin: handleLogin,
    onNavigation: handleNavigation,
    onBreadcrumb: handleBreadcrumb,
    onHelpClick: handleHelpClick
    // onLogout: handleLogout,
    // onHamburger: handleHamburger,
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );

}


const useAuth = () => {
  return React.useContext(AuthContext);
};




// const Logon = () => {
//   const { onLogin } = useAuth();
//   return (
//     <Login onLogin={onLogin} />
//   );
// };

/* good */
const ProtectedRoute = ({ children }) => {
  // const { token, rbac } = useAuth();
  const reduxauth = useSelector(state => state.auth);

  //console.log('we are in protected route rbac', reduxauth);
  const isauth = reduxauth.isAuthenticated;
  // console.log('we are in protected route children',children);
  const location = useLocation();
  // console.log('token is',token)
  if (!isauth) {
    let link = "/" + sessionStorage.getItem('hoaid');
    //console.log('we should navigate to home')
    return <Navigate to={link} replace state={{ from: location }} />;
    // return <Navigate to="/home" replace = {true}  />;
  } else {
    //console.log('we are authorized in ProtectedRoute')
  }
  // if (JSON.parse(rbac).indexOf('worker') > -1) {
  //   return <Navigate to="/blankpage" replace state={{ from: location }} />;
  // }
  return children;
};

//  <Route index element={<Landing />} />
const Root = () => {
  let hoaid = 'YV';
  // let crumb = '123'
  return (
    <AuthProvider>
      {/* <BuildCrumb /> */}
      <StatusMessage />
      <TopNavigation />
      <Routes>

        {/* this will go to the multitenant landing page} */}
        {/* <Route index element={<Landing />} /> */}

        {/* this will force a load of the individual hoa via the hoaid} */}
        <Route index element={<LandingForHoa hoaid={"YV"} />} />

        <Route path="/:hoaid" element={<BuildLandingElement />} />




        <Route path="/login" element={<Login />} />

        <Route exact path="/profile" element={
          <ProtectedRoute>
            <OwnerProfile />
          </ProtectedRoute>
        } />
        <Route exact path="/adminprofile" element={
          <ProtectedRoute>
            <AdminProfile />
          </ProtectedRoute>
        } />
        <Route path="/dashboard" element={
          <ProtectedRoute> <OwnerDashboard /></ProtectedRoute>

        } />

        <Route path="/renterlogin" element={
          <RenterLogin />
        } />
        <Route path="/renterdashboard" element={
          <ProtectedRoute>
            <RenterDashboard />
          </ProtectedRoute>
        } />
        <Route path="/admindashboard" element={
          <ProtectedRoute>
            <AdminDashboard />
          </ProtectedRoute>
        } />
        <Route path="/maintenancedashboard" element={
          <ProtectedRoute>
            <MaintenanceDashboard />
          </ProtectedRoute>
        } />

        <Route path="/helptabs/:which" element={
          <BuildHelpTabs />
        } />

        <Route path="/showsessiondata" element={<ShowSessionData />} />

        {/* <Route exact path="/userverification/:which/:userid/:hoaid/:email"
                component={(which, userid, hoaid, email) =>
                  <UserVerification which={which} userid={userid} hoaid={hoaid} email={email} />} /> 
                  /userverification/password/userid/YV/email_address

                  
                  */}
        <Route exact path="/userverification/:which/:userid/:hoaid/:email"
          element={<BuildUserVerifyElement />} />



      </Routes>
    </AuthProvider>

  )
}

const BuildUserVerifyElement = () => {
  let params = useParams();
  let which = params.which;
  let userid = params.userid;
  let hoaid = params.hoaid;
  let email = params.email;
  console.log('BuildUserVerifyElement', which, userid, hoaid, email)
  return <UserVerification which={which} userid={userid} hoaid={hoaid} email={email} />;

}

const TopNavigation = () => {


  const { onNavigation, onHelpClick } = useAuth();
  let hoaid = sessionStorage.getItem('hoaid');
  //console.log('building top navigation')
  return (
    <Navbar hoaid={hoaid} onNavigation={onNavigation} onHelpClick={onHelpClick} />
  );
};

function BuildCrumb() {
  const { crumbs } = useAuth();
  let winwid = window.innerWidth;
  console.log('winwid', winwid)
  // return (
  //   <div style={{ display: "block", position: "absolute", top: "50px", left: "20px", zIndex: "10", color: "black" }}>
  //     {crumbs}
  //   </div>
  // )
  if (winwid < 600) {
    return (
      <div style={{ display: "block", position: "absolute", top: "45px", left: "20px", zIndex: "10", color: "black" }}>
        {crumbs}
      </div>
    )
  } else {
    return (
      <div style={{ display: "block", position: "absolute", top: "10px", left: "320px", zIndex: "10", color: "white" }}>
        {crumbs}
      </div>
    )

  }
}

function BuildLandingElement() {
  let params = useParams();
  const { onBreadcrumb } = useAuth();
  // const { onNavigation } = useAuth();
  let hoaid = null;
  //console.log('params are BuildLandingElement', params)
  if (params.hoaid) {
    //console.log('we have parms')
    // hoaid = params.hoaid.match();
    hoaid = params.hoaid;
  } else {
    // console.log('we have NO parms')
  }

  //console.log('the hoaid is ', hoaid, params.hoaid)

  return <LandingForHoa onBreadCrumb={onBreadcrumb} hoaid={params.hoaid} />;
}

function BuildHelpTabs() {
  let params = useParams();
  const { onNavigation } = useAuth();
  // console.log('params are', params)
  let which = params.which.match();
  if (!which) {
    return <h2>NotFound</h2>;
  } else {
    // console.log(params.which)
  }
  return <HelpTabs onNavigation={onNavigation} which={params.which} />;
}

function ShowSessionData() {
  return (
    <div style={{ color: "white" }}>
      <p>{sessionStorage.getItem('hoaid')}</p>
      <p>{sessionStorage.getItem('hoaname')}</p>
      <p>{sessionStorage.getItem('background_image_url')}</p>
      <p>{sessionStorage.getItem('selectedhoa')}</p>
      {/* <p>{sessionStorage.getItem('use_owner_creditcard')}</p>
      <p>{sessionStorage.getItem('use_renter_creditcard')}</p>
      <p>{sessionStorage.getItem('use_owner_ppp')}</p>
      <p>{sessionStorage.getItem('use_renter_ppp')}</p>
      <p>{sessionStorage.getItem('use_demo_mode')}</p>
      */}
    </div>

  )
}

const App = () => {


  return (
    <Provider store={store}>
      <BrowserRouter>
        <Root />
      </BrowserRouter>
    </Provider>
  );

  // return (
  //   <div className="App">
  //     <BrowserRouter>
  //       <Root />
  //     </BrowserRouter>
  //   </div>
  // );
}

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React

//         </a>
//       </header>


//     </div>
//   );
// }

export default App;
