import {
  // SET_ADVERTISERS,
  // CHANGE_ADVERTISER_STATUS,
  // REMOVE_ADVERTISER,
  // SET_BUSINESS,
  // SET_BUSINESSES,
  // CHANGE_BUSINESS_STATUS,
  // REMOVE_BUSINESS,
  // CREATE_BUSINESS,
  // SET_CURRENT_ADVERTISEMENTS,
  // SET_CURRENT_ADVERTISEMENT,
  // REMOVE_ADVERTISEMENT,
  // CREATE_ADVERTISEMENT,
  // MODIFY_ADVERTISEMENT,
  // CHANGE_ADVERTISEMENT_STATUS,
  // SET_IMAGES,
  // SET_NEW_IMAGE,
  // REMOVE_IMAGE,
  // GET_ERRORS,
  // CLEAR_ERRORS,
  // SET_STATUS_MESSAGE,
  // CLEAR_STATUS_MESSAGE,

  // SET_CURRENT_USER,
  SET_IS_LOADING,



  MODIFY_VEHICLE,
  INSERT_VEHICLE,
  DELETE_VEHICLE,
  //UPDATE_USER_NAME,
  //UPDATE_USER_PIN,
  //UPDATE_USER_PASSWORD,


  SET_CURRENT_VEHICLES_OWNER,
  SET_ALL_VEHICLES,
  SET_CURRENT_OWNERS,
  //CHANGE_VEHICLE_STATUS,
  CHANGE_VEHICLE_STATUS_DATE,
  //CHANGE_ADMIN_VEHICLE_STATUS,
  SET_DICTIONARY,
  SET_ALL_VIOLATIONS,
  INSERT_VIOLATION,
  DELETE_VIOLATION,
  DELETE_OWNER,
  UPDATE_OWNER,
  INSERT_OWNER,
  SET_ALL_NOTIFICATIONS,
  DELETE_NOTIFICATION



} from "../actions/parkingtypes";

// import { TEST_DISPATCH } from "../actions/types";

const initialState = {

  current_vehicles_owner: [],
  current_violations: [],
  current_notifications: [],
  all_owners: [],
  all_vehicles: [],
  dictionary: [],
  isloading: false
};
// let bizid = "";
// let workid = "";
// let workobj = {};
export default function (state = initialState, action) {
  // console.log("adverreducer ", action.type);
  // console.log("adverreducer ", action.payload);
  // let ownerid = "";
  // let workcollection = [];
  let workpayload = {};
  let workary = [];
  let tempary = [];
  let recid = "";
  let workid = "";
  let status = 0;
  let updatestatus = 0;


  switch (action.type) {
    // *************************
    // ADVERTISER
    // *************************

    case SET_IS_LOADING:
      return {
        ...state,
        isloading: action.payload.isloading,
      };

    case SET_CURRENT_VEHICLES_OWNER:
      workary = action.payload.data;
      let freespots = action.payload.freespots;
    // console.log('length of workary',workary.length,workary,freespots)
      tempary = []; // state.current_vehicles_owner;
      // this logic looks at number of free parking spots and resets status to 1 because it could be a 2
      workary.forEach(el => {
        if (workary.length === parseInt(freespots)) {
         // console.log('we have a match')
         // el['status_flag'] = 1
        }
      //  console.log('reducer enddate',el.enddate);
        tempary.push(el)
      })
      return {
        ...state,
        current_vehicles_owner: tempary
      };
    case SET_ALL_VEHICLES:
      workary = action.payload;
      return {
        ...state,
        //all_vehicles: workary
        current_vehicles_owner: workary
      };

    case SET_DICTIONARY:
      workary = action.payload;
      return {
        ...state,
        dictionary: workary
      };




    case MODIFY_VEHICLE:
      workpayload = action.payload;
      recid = workpayload['_id'];

      workary = state.current_vehicles_owner;
      tempary = [];
      workary.forEach(el => {
        if (el['_id'] === recid) {
          // el = updateobj;
          tempary.push(workpayload);
         // console.log('modify reducer payload',workpayload)
        } else {
          tempary.push(el);
        }
      })

      return {
        ...state,
        current_vehicles_owner: tempary
      };

    case INSERT_VEHICLE:
      workpayload = action.payload;

      workary = state.current_vehicles_owner;
      workary.push(workpayload)
      // let pnewary = [];
      // curprops.forEach(el => {
      //     pnewary.push(el);
      // });
      // pnewary.push(workpayload)

      return {
        ...state,
        current_vehicles_owner: workary
      };
    // case DELETE_VEHICLE:
    //   recid = action.payload.vid;
    //   console.log('vehicle to delete is ', recid);
    //   console.log('vehicle payload ', action.payload);
    //   return {
    //     ...state,
    //     current_vehicles_owner: state.current_vehicles_owner.filter(item => item._id !== recid),
    //     all_vehicles: state.all_vehicles.filter(item => item.ownerid !== recid)
    //   };

      case DELETE_VEHICLE:
        recid = action.payload.vid;
        let hoaid = action.payload.hoaid;
        let unum = action.payload.unitnumber;
       // console.log('vehicle to delete is ', recid);
      //  console.log('vehicle payload ', action.payload);
      
      let workary8 = state.current_vehicles_owner;
      let tempary2 = [];
      workary8.forEach(el => {
       // console.log('reducer',el)
        if (el['_id'] !== recid) {
          if (el['hoaid'] === hoaid && el['unitnumber'] === unum) {
            if (el['status_flag'] === 2) {
              el['status_flag'] = 1
            }
            tempary2.push(el)
          }
        }
      })

      workary = state.all_vehicles;
      let tempary1 = [];
      workary.forEach(el => {
        if (el['_id'] !== recid) {
          if (el['hoaid'] === hoaid && el['unitnumber'] === unum) {
            if (el['status_flag'] === 2) {
              el['status_flag'] = 1
            }
            tempary1.push(el)
          }
        }
      })

        return {
          ...state,
          all_vehicles: tempary1,
          current_vehicles_owner: tempary2
        };

        // return {
        //   ...state,
        //   current_vehicles_owner: state.current_vehicles_owner.filter(item => item._id !== recid),
        //   all_vehicles: state.all_vehicles.filter(item => item.ownerid !== recid)
        // };

    case DELETE_OWNER:
      recid = action.payload;
      //console.log('vehicle to delete is ', recid);
      return {
        ...state,
        all_owners: state.all_owners.filter(item => item._id !== recid)
      };

    case INSERT_OWNER:
      workpayload = action.payload;
      //console.log('vehicle to delete is ', recid);
      workary = state.all_owners;
      workary.push(workpayload)
      return {
        ...state,
        all_owners: workary
      };



    case SET_CURRENT_OWNERS:
      workary = action.payload;
      return {
        ...state,
        all_owners: workary
      };

    case UPDATE_OWNER:
      workpayload = action.payload;
      recid = workpayload['owner_id'];
      //  console.log('reducer update owner ', workpayload);

      workary = state.all_owners;
      tempary = [];
      workary.forEach(el => {
        if (el['_id'] === recid) {
          // el = updateobj;
          el['email'] = workpayload['owner_email'];
          el['name'] = workpayload['owner_name'];
          el['first_name'] = workpayload['owner_fname'];
          el['last_name'] = workpayload['owner_lname'];
          el['phone'] = workpayload['owner_phone'];
          el['pincode'] = workpayload['owner_pincode'];
          el['parking_allowed'] = workpayload['parking_allowed'];
          el['parking_allowed_renter'] = workpayload['parking_allowed_renter'];
          el['owner_free_parking'] = workpayload['owner_free_parking'];
          el['renter_free_parking'] = workpayload['renter_free_parking'];
          el['status_flag'] = workpayload['status_flag'];
        }
        tempary.push(el);
      })

      return {
        ...state,
        all_owners: tempary
      };

    // case CHANGE_VEHICLE_STATUS:
    //   workary = action.payload;
    //   return {
    //     ...state,
    //     all_owners: workary
    //   };
/*
    case CHANGE_VEHICLE_STATUS:
      workary = state.current_vehicles_owner;
      workid = action.payload.vid;
      status = action.payload.status;
      //upstatus = 0;
      // let updatestatus = 0;
      if (status === 0) {
        updatestatus = 1;
      }
      workary.map((biz, index) => {
        if (biz._id === workid) {
          biz.active_flag = updatestatus;
        }
      });
      return {
        ...state,
        current_vehicles_owner: workary
      };
*/
    case CHANGE_VEHICLE_STATUS_DATE:
      workary = state.current_vehicles_owner;
      workid = action.payload.vid;
      status = action.payload.status;
      let enddate = action.payload.enddate;
      let startdate = action.payload.curdate;
     // console.log('the passed status is',status)
      //upstatus = 0;
      // let updatestatus = 0;
      // if (parseInt(status) === 0) {
      //   updatestatus = 1;
      // }
      workary.map((biz, index) => {
        if (biz._id === workid) {
          biz.active_flag = status; //updatestatus;
          biz.enddate = enddate;
          biz.number_of_changes = biz.number_of_changes + 1;
          if (enddate !== '1970-01-01') {
            biz.startdate = startdate;
          }
        }
      });
      return {
        ...state,
        current_vehicles_owner: workary
      };

    // case CHANGE_ADMIN_VEHICLE_STATUS:
    //   //workary = state.all_vehicles;
    //   workary = state.current_vehicles_owner;

    //   workid = action.payload.vid;
    //   status = action.payload.status;
    //   //upstatus = 0;
    //   if (status === 0) {
    //     updatestatus = 1;
    //   }
    //   workary.map((biz, index) => {
    //     if (biz._id === workid) {
    //       biz.active_flag = updatestatus;
    //     }
    //   });
    //   return {
    //     ...state,
    //     current_vehicles_owner: workary
    //   };

    case SET_ALL_VIOLATIONS:
      workary = action.payload;
      return {
        ...state,
        //all_vehicles: workary
        current_violations: workary
      };

    case INSERT_VIOLATION:
      workpayload = action.payload;

      workary = state.current_violations;
      workary.push(workpayload)
      return {
        ...state,
        current_violations: workary
      };




    case DELETE_VIOLATION:
      recid = action.payload;
      // console.log('vehicle to delete is ', recid);
      return {
        ...state,
        current_violations: state.current_violations.filter(item => item._id !== recid)
      };

    case DELETE_NOTIFICATION:
      recid = action.payload;
      // console.log('NOTIFICATION to delete is ', recid);
      return {
        ...state,
        current_notifications: state.current_notifications.filter(item => item._id !== recid)
      };


    case SET_ALL_NOTIFICATIONS:
      workary = action.payload;
      return {
        ...state,
        //all_vehicles: workary
        current_notifications: workary
      };




    // *************************
    // ADVERTISEMENTS
    // *************************






    // *************************
    // IMAGES
    // *************************

    // return state;

    // case TEST_DISPATCH:
    //   console.log("we are in authreducer " + action.type);
    //   return {
    //     ...state,
    //     user: action.payload
    //   };
    default:
      return state;
  }
}
