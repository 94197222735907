import React, { Component } from "react";
//import PropTypes from "prop-types";
import { Link } from "react-router-dom";
//import { Modal, Button } from 'react-bootstrap';
//import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'

import { getTodayAsString, getTomorrowAsString, geFutureAsString } from "../parking/DateHelper";



import { connect } from "react-redux";


//import Spinner from "../common/Spinner";
import PayForParkingForm from "../payments/PayForParkingForm";
import TermsAndConditions_Renter from "./TermsAndConditions_Renter";
import LoadingMessage from "../common/LoadingMessage";
import TextFieldGroupPlain from "../common/TextFieldGroupPlain";
import SelectNameValuePlain from "../common/SelectNameValuePlain";
import { insertVehicle, modifyVehicle, showStatusMessage } from "../../actions/parkingActions";

import { validateInsertModifyVehicleInput } from "../../validation/client_insertvehiclevalidation";

class VehicleFormRenter extends Component {
  constructor(props) {
    // console.log('vfr props', props)
    super(props);



    let dstr = getTodayAsString();

    this.state = {
      vehicleid: "",
      ownerid: this.props.auth.user._id,//"",
      carowner_fname: "d",
      carowner_lname: "d",
      carownerphone: "d",
      unitnumber: this.props.auth.user.unitnumber,
      make: "d",
      model: "d",
      year: "d",
      plate: "d",
      plate_state: "d",
      which: '',
      carownertype: this.props.carownertype,

      vehicle: null, //this.props.vehicle,
      enddate: dstr,
      selecteddate: dstr,
      vehicle_type: "CAR",
      vehicletypelist: [], //vary,
      //vehiclespotsobj: JSON.parse(sessionStorage.getItem('hoa_parking_spots')),
      terms: 0,
      showterms: false,
      termsaccepted: false,
      errors: {},
      cardata: {},
      whichphoneview: 1,

      showpaymentform: false,

      vehicledata: {},
      isloading: false,
      disableform: false
    };
    // console.log('just sets state parking spots v form', JSON.parse(sessionStorage.getItem('hoa_parking_spots')));



    this.onChange = this.onChange.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);
    this.onCBChange = this.onCBChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    // this.onFileInputChange = this.onFileInputChange.bind(this);
    // this.handleMapClick = this.handleMapClick.bind(this);
  }



  static getDerivedStateFromProps(props, state) {
    if (props.vehicle !== state.vehicle) {
      return {
        vehicle: props.vehicle
      }
    }
    return null;
  }

  componentDidMount() {

    //  console.log('rvf cdm props', this.props);

    let shoastr = sessionStorage.getItem('selectedhoa');
    let ahoa = JSON.parse(shoastr);

    let stateobj = {};

    this.loadState(this.props);

    let viewnum = 1;
    if (window.innerWidth < 800) {
      viewnum = 2;
    }
    this.setState({ whichphoneview: viewnum });

  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.vehicle && (prevState.vehicle !== this.state.vehicle)) {
      // console.log('cdidupdate props', this.props);
      this.loadUpdateState(this.props);
    }
  }

  loadState = (props) => {
    let spots = JSON.parse(sessionStorage.getItem('hoa_parking_spots')); //this.state.vehiclespotsobj;
    let spotslist = []; //this.state.vehicletypelist;
    // let vehiclespotsobj = []; //this.state.vehiclespotsobj;
    if (spots !== null) {
      let vary = [];

      spots.forEach(el => {
        let nv = {};
        nv['name'] = el['spot_display'];
        nv['value'] = el['spot_type'];
        vary.push(nv);
      });

      // console.log('in load state vehicle array', vary);
      spotslist = vary;
      // vehiclespotsobj = spots;
    } else {

    }

    let dstr = getTomorrowAsString();

    let enddate = dstr;

    // let ownerid = this.props.auth.user._id; //'';
    let which = 'create';

    let vehicleid = '';

    let fname = '';
    let lname = '';
    let phone = '';
    let make = '';
    let model = '';
    let year = '';
    let plate = '';
    let plate_state = '';

    if (parseInt(sessionStorage.getItem('use_demo_mode')) === 1) {
      fname = 'Joe';
      lname = 'Tester';
      phone = '123-456-7890';
      make = 'Chevy';
      model = 'Blazer';
      year = '2015';
      plate = 'JJ-4040';
      plate_state = 'CO';
    }



    let vehicle_type = 'CAR';
    let terms = 0;
    let termsaccepted = false;

    if (props.vehicle) {
      which = 'modify';
      vehicleid = props.vehicle._id;
      make = props.vehicle.make;
      model = props.vehicle.model;
      year = props.vehicle.year;
      plate = props.vehicle.plate;
      plate_state = props.vehicle.plate_state;
      //  active_flag = props.vehicle.active_flag;
      vehicle_type = props.vehicle.vehicle_type;
      fname = props.vehicle.carowner_fname;
      lname = props.vehicle.carowner_lname;
      // otype = props.vehicle.carownertype;
      phone = props.vehicle.carownerphone;
      enddate = props.vehicle.enddate;
    }



    this.setState(
      {
        which: which,
        // ownerid: ownerid,
        vehicleid: vehicleid,
        vehicle_type: vehicle_type,
        carowner_fname: fname,
        carowner_lname: lname,
        carownerphone: phone,
        make: make,
        model: model,
        year: year,
        plate: plate,
        plate_state: plate_state,
        enddate: enddate,
        selecteddate: enddate,
        terms: terms,
        termsaccepted: termsaccepted,
        vehicletypelist: spotslist,

      }
    )
    // console.log('vehicle form  state is', this.state);
  }

  loadUpdateState = (props) => {


    let fname = props.vehicle.carowner_fname;
    let lname = props.vehicle.carowner_lname;
    //  let ounitnumber = this.props.auth.user.unitnumber;
    let enddate = props.vehicle.enddate;
    let phone = props.vehicle.carownerphone;
    // let ownerid = '';
    let which = 'modify';
    let vehicleid = props.vehicle._id;
    let make = props.vehicle.make;
    let model = props.vehicle.model;
    let year = props.vehicle.year;
    let plate = props.vehicle.plate;
    let plate_state = props.vehicle.plate_state;
    let vehicle_type = props.vehicle.vehicle_type;
    let terms = 0;
    let termsaccepted = false;


    this.setState(
      {
        which: which,
        // ownerid: ownerid,
        vehicleid: vehicleid,
        vehicle_type: vehicle_type,
        carowner_fname: fname,
        carowner_lname: lname,
        enddate: enddate,
        selecteddate: enddate,
        carownerphone: phone,
        make: make,
        model: model,
        year: year,
        plate: plate,
        plate_state: plate_state

      }
    )
    //console.log('vehicle form  state is', this.state);
  }

  onChange(e) {
    let elname = e.target.name;
    let elval = e.target.value;
    //    console.log('elname is', elname, elval, this.state.vehiclespotsobj);

    this.setState({ [e.target.name]: e.target.value, errors: {} });

  }

  onChangeDate(e) {
    let ed = e.target.value;

    let dstr = getTomorrowAsString();
    let fdstr = geFutureAsString(60);
    // console.log(ed, dstr, fdstr);

    if (ed < dstr) {
      this.setState({ errors: { selecteddate: 'Date must be in the future.' } });
      return;
    }
    if (ed > fdstr) {
      this.setState({ errors: { selecteddate: 'Checkout date must be within 60 days of today.' } });
      return;
    }

    this.setState({ selecteddate: e.target.value, errors: {} });
  }



  onCBChange(e) {
    let tval = 0;
    let ischecked = e.target.checked;
    if (ischecked) tval = 1;
    this.setState({ termsaccepted: ischecked, terms: tval });
  }




  onSubmit(e) {
    e.preventDefault();
    let active_flag = 1;
    let { carownertype } = this.state;
    let { vehicle_type } = this.state;
    let { plate } = this.state;
    let { make } = this.state;
    let { plate_state } = this.state;

    if (vehicle_type === 'DUMPSTER') {
      plate = 'DUMPSTER';
      make = 'n/a';
      plate_state = 'n/a';
    }


    let submitdata = {};
    submitdata['hoaid'] = sessionStorage.getItem('hoaid');
    submitdata['which'] = this.state.which;
    submitdata['vehicleid'] = this.state.vehicleid;
    submitdata['ownerid'] = this.state.ownerid;
    submitdata['carowner_fname'] = this.state.carowner_fname;
    submitdata['carowner_lname'] = this.state.carowner_lname;
    submitdata['carownerphone'] = this.state.carownerphone;
    submitdata['unitnumber'] = this.state.unitnumber;
    submitdata['make'] = make;
    submitdata['model'] = this.state.model;
    submitdata['year'] = this.state.year;
    submitdata['plate'] = plate;
    submitdata['plate_state'] = plate_state;
    submitdata['carownertype'] = 'renter'; //this.state.carownertype;
    submitdata['vehicle_type'] = this.state.vehicle_type;
    submitdata['enddate'] = this.state.enddate;
    submitdata['terms'] = this.state.terms;
    submitdata['active_flag'] = active_flag;

    const { errors, isValid } = validateInsertModifyVehicleInput('renter', submitdata);
    if (!isValid) {
      this.setState({ errors: errors });
      return;
      //   return res.status(400).json(errors);
    }

    //  console.log('submitdata', submitdata);
    //  console.log('ph', this.props.history);

    // this.props.history.push('payforparking');

    // console.log('we are opening payment summary form');
    // let el = document.getElementById('vehicleform');
    // console.log('vehicle form element', el);

    // let ewidth = el.offsetWidth;
    // let eheight = el.offsetHeight;

    // let etop = el.offsetTop;
    // let eleft = el.offsetLeft;

    // let xPos = (el.offsetLeft - el.scrollLeft + el.clientLeft);
    // let yPos = (el.offsetTop - el.scrollTop + el.clientTop);

    // console.log('vehicle form element w x h', ewidth, eheight);
    // console.log('vehicle form element t x l', etop, eleft);
    // console.log('real vehicle form element x x y', xPos, yPos);


    // let idiv = document.createElement("div");
    // // var text = document.createTextNode('now is the time');
    // // idiv.append(text);
    // idiv.style.width = ewidth + "px";
    // idiv.style.height = eheight + "px";
    // idiv.style.position = "absolute";
    // idiv.style.background = "rgba(0, 0, 0, 0.5)";
    // idiv.style.color = "white";
    // idiv.style.zIndex = 10;
    // // idiv.style.top = "100px";
    // // el.append(idiv);
    // //paymentformdiv

    // let parentDiv = document.getElementById("vehicleform").parentNode;
    // let sp2 = document.getElementById("vehicleform")
    //parentDiv.insertBefore(idiv, sp2)


    this.setState({ cardata: submitdata, showpaymentform: true, disableform: true });


  }



  closeForm = (e) => {
    e.preventDefault();
    // console.log('goint to close form');
    this.props.closeform();
  }

  showTerms = (e) => {
    e.preventDefault();
    let terms = !this.state.showterms;
    this.setState({ showterms: terms });
  }


  closeform = () => {
    this.setState({ showpaymentform: false, disableform: false });
  }

  closevehicleform = (e) => {

    if (e) e.preventDefault();
    this.props.closevehicleform();
  }

  closeallforms = (e) => {
    if (e) e.preventDefault();
    this.setState({ showpaymentform: false });
    this.props.closevehicleform();
  }






  render() {

    const errors = this.state.errors;
    const perrors = this.props.errors;
    const { which } = this.state;
    const isguest = true;

    const { showterms } = this.state;
    let { termsaccepted } = this.state;
    let { vehicletypelist } = this.state;
    const { renter_free_parking_spots } = this.state;

    let { showpaymentform } = this.state;
    let { whichphoneview } = this.state;

    const { vehicledata } = this.state;

    let { disableform } = this.state;


    vehicletypelist = vehicletypelist.filter(el => (el.value !== 'DUMPSTER'
      && el.value !== 'RV'
      && el.value !== 'TRAILER'
      && el.value !== 'OVERSIZE'));











    // console.log('perrors', perrors);

    if (vehicletypelist.length === 0) {
      return null;
    }

    //const searchOptions = {};

    let title = "Register";
    if (which === 'modify') {
      title = "Modify";
    }
    // let terms_list = [{ name: 'No, I do not accept the terms', value: 0 }, { name: 'Yes, I do accept the terms', value: 1 }];
    let disablesubmit = true;
    if (termsaccepted) {
      disablesubmit = false;
    }
    if (!isguest) {
      termsaccepted = true;
    }


    //  console.log('disablesubmit', disablesubmit); 65598a 17a2b8
    const okicon = <FontAwesomeIcon icon={faCheckCircle} color="#65598a" size="2x" title="enter / submit" />
    const okicondisable = <FontAwesomeIcon icon={faCheckCircle} color="#ddd" size="2x" />
    const cancelicon = <FontAwesomeIcon icon={faTimesCircle} color="black" size="2x" title="cancel / close" />
    const cancelicondisable = <FontAwesomeIcon icon={faTimesCircle} color="#ddd" size="2x" />

    // Owner, Long-Term Renter, Visitor, Caretender, Contractor.


    let cotypelist = [
      { name: "Owner", value: "owner" },
      { name: "Friend", value: "friend" },
      { name: "Long Term", value: "longterm" },
      { name: "Visitor", value: "visitor" },
      { name: "Caretender", value: "caretender" },
      { name: "Contractor", value: "contractor" }
    ];

    // let popupclass = 'popupdialog bordershadow';
    // let itop = 160; // + ptop;

    // let modalclass = 'modal-main';
    let modalclass = 'modal-main-inline';
    if (whichphoneview !== 1) modalclass = 'modal-main-phone';

    let ptop = window.scrollY;
    let itop = 10 + ptop;


    return (
      <div id="vehicleform" className="xregister" style={{ width: "100%", margin: "0px auto", zIndex: 5 }}>
        <div style={{ marginBottom: "15px", zIndex: 15 }} id="vehicleform2">

          {/* <div style={{ textAlign: "right" }}>
            <Link to=""
              onClick={(e) => this.closevehicleform(e)} >
              {"close"}
            </Link>
          </div> */}

          <p className=" text-center"><b>{title} Vehicle</b></p>
          {(this.state.isloading) ? (<LoadingMessage />) : null}

          {disableform ? (null) : (

            <div>

              <form noValidate >

                <div style={{ display: "flex", gap: "5px", flexDirection: "column" }}>

                  <div className="row">
                    <div className="col-4 font10ptb">
                      Vehicle
                    </div>
                    <div className="col-8">
                      <SelectNameValuePlain
                        label="Availability"
                        list={vehicletypelist}
                        name="vehicle_type"
                        disabled={disableform}
                        value={this.state.vehicle_type}
                        onChange={this.onChange}
                        error={errors.vehicle_type}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-4 font10ptb">
                      First</div>
                    <div className="col-8">
                      <TextFieldGroupPlain
                        type="text"
                        label="Name"
                        placeholder="First Name"
                        disabled={disableform}
                        name="carowner_fname"
                        value={this.state.carowner_fname}
                        onChange={this.onChange}
                        error={errors.carowner_fname}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4 font10ptb">
                      Last</div>
                    <div className="col-8">
                      <TextFieldGroupPlain
                        type="text"
                        label="Last Name"
                        placeholder="Last Name"
                        disabled={disableform}
                        name="carowner_lname"
                        value={this.state.carowner_lname}
                        onChange={this.onChange}
                        error={errors.carowner_lname}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-4 font10ptb">
                      Phone</div>
                    <div className="col-8">
                      <TextFieldGroupPlain
                        type="text"
                        label="Phone"
                        placeholder="Phone"
                        disabled={disableform}
                        name="carownerphone"
                        value={this.state.carownerphone}
                        onChange={this.onChange}
                        error={errors.carownerphone}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-4 font10ptb">
                      Plate
                    </div>
                    <div className="col-8">
                      <TextFieldGroupPlain
                        type="text"
                        label="plate"
                        placeholder="Plate"
                        disabled={disableform}
                        name="plate"
                        value={this.state.plate}
                        onChange={this.onChange}
                        error={errors.plate}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4 font10ptb">
                      State
                    </div>
                    <div className="col-8">
                      <TextFieldGroupPlain
                        type="text"
                        label="plate_state"
                        placeholder="State"
                        disabled={disableform}
                        name="plate_state"
                        value={this.state.plate_state}
                        onChange={this.onChange}
                        error={errors.plate_state}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-4 font10ptb">
                      Make</div>
                    <div className="col-8">
                      <TextFieldGroupPlain
                        type="text"
                        label="Make"
                        placeholder="Make"
                        disabled={disableform}
                        name="make"
                        value={this.state.make}
                        onChange={this.onChange}
                        error={errors.make}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-4 font10ptb">
                      Model
                    </div>
                    <div className="col-8">
                      <TextFieldGroupPlain
                        type="text"
                        label="Model"
                        placeholder="Model"
                        disabled={disableform}
                        name="model"
                        value={this.state.model}
                        onChange={this.onChange}
                        error={errors.model}
                      />

                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4 font10ptb">
                      Year
                    </div>
                    <div className="col-8">
                      <TextFieldGroupPlain
                        type="text"
                        label="Year"
                        placeholder="Year"
                        disabled={disableform}
                        name="year"
                        value={this.state.year}
                        onChange={this.onChange}
                        error={errors.year}
                      />
                    </div>
                  </div>



                  <div className="row">
                    <div className="col-4 font10ptb">
                      Checkout
                    </div>
                    <div className="col-8">
                      <TextFieldGroupPlain
                        type="date"
                        label="end date"
                        placeholder="End Date"
                        disabled={disableform}
                        name="selecteddate"
                        value={this.state.selecteddate}
                        onChange={this.onChangeDate}
                        error={errors.selecteddate}
                      />
                    </div>
                  </div>
                </div>
                {showterms ? (<TermsAndConditions_Renter />) : (null)}

                <div style={{ marginTop: "20px" }}>
                  <div className="row">

                    <div className="col-4 font10ptb">
                      Accept
                    </div>
                    <div className="col-8">
                      {disableform ? (
                        <div>
                          Terms and Conditions
                          &nbsp;
                          <input type="checkbox" disabled onChange={this.onCBChange} />
                        </div>

                      ) : (
                        <div>
                          <Link to=""
                            onClick={(e) => this.showTerms(e)} >Terms and Conditions</Link>
                          &nbsp;
                          <input type="checkbox" onChange={this.onCBChange} />
                        </div>

                      )}

                    </div>
                  </div>
                </div>


              </form>



              <div className="row" style={{ marginTop: "10px" }}>
                <div className="col-12">
                  {termsaccepted && !disableform ? (
                    <span className="iconbuttonspan">
                      <Link to=""
                        onClick={(e) => this.onSubmit(e)} >
                        {okicon}
                      </Link>
                    </span>
                  ) : (<span className="iconbuttonspan">
                    {okicondisable}
                  </span>
                  )}
                  {disableform ? (
                    <span className="iconbuttonspan">
                      {cancelicondisable}
                    </span>
                  ) : (
                    <span className="iconbuttonspan">
                      <Link to=""
                        onClick={(e) => this.closevehicleform(e)} >
                        {cancelicon}
                      </Link>
                    </span>
                  )}

                </div>
              </div>
            </div>
          )}
          {/* end of disable all form fields */}
          {showpaymentform ? (
            <div className="xmodal" id="paymentformdiv">
              <div className={modalclass}>
                <PayForParkingForm selecteddate={this.state.selecteddate} cardata={this.state.cardata} sendclosetoparent={this.closeform}
                  closeallforms={this.closeallforms} />
              </div>
            </div>
          ) : (
            null
          )}



          {errors && errors['message'] ? (<div className="error-display">{errors['message']}</div>) : (null)}
          {perrors && (
            <div className="error-display">{perrors.message}</div>
          )}



        </div>
      </div >


    );
  }

  //<div className={popupclass} style={{ top: itop }}></div>
  //  style={{ top: itop }}


  // this is good practice because it will help debugging
  // it is not checked when in production mode.
  // Business.propTypes = {
  //   createBusiness: PropTypes.func.isRequired,
  //   modifyBusiness: PropTypes.func.isRequired,
  //   auth: PropTypes.object.isRequired,
  //   errors: PropTypes.object.isRequired
};

// map errors to this component
// then we can use this.props.auth.user etc
// since we mapped error, we can use componentWillReceiveProps method
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
});
// the state.auth above comes from rootReducer in index.js in reducers.

export default connect(
  mapStateToProps,
  { insertVehicle, modifyVehicle, showStatusMessage }
)((VehicleFormRenter));
// wrap the Register with withRouter so the authAction can use history to redirect

//Change “Vehicle” picklist to (in this order): 
//Car, Truck, Van, Motorcycle, Trailer, Oversized Vehicle, Dumpster, Other.

