import React, { Component } from "react";
//import PropTypes from "prop-types";
//import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
//import { Modal, Button } from 'react-bootstrap';
//import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'

import { getTodayAsString } from "../parking/DateHelper";



import { connect } from "react-redux";
//import isEmpty from "../../validation/is-empty";


//import Spinner from "../common/Spinner";
//import TermsAndConditions_Owner from "./TermsAndConditions_Owner";
//import TermsAndConditions_Renter from "./TermsAndConditions_Renter";
import LoadingMessage from "../common/LoadingMessage";
//import FattMerchantPaymentForm from "../payments/FattMerchantPaymentForm.js";

// import 'react-responsive-modal/styles.css';
// import { Modal } from 'react-responsive-modal';







//
//import TextFieldGroup from "../../common/TextFieldGroup";
import TextFieldGroupPlain from "../common/TextFieldGroupPlain";
//import TextFieldGroup from "../common/TextFieldGroup";
import SelectNameValuePlain from "../common/SelectNameValuePlain";
//import ParkingHelper from "../../config/parkingHelper";

//import PayFeeCalculator from "./PayFeeCalculator";
import { insertVehicle, modifyVehicle, showStatusMessage } from "../../actions/parkingActions";

import { validateInsertModifyVehicleInput } from "../../validation/client_insertvehiclevalidation";

//import { getParkingSpots } from './AjaxHelper';

//import { Redirect } from 'react-router'



class VehicleFormOwner extends Component {
  constructor(props) {
    super(props);


    let dstr = getTodayAsString();

    this.state = {
      vehicleid: "",
      ownerid: this.props.auth.user._id,//"",
      carowner_fname: this.props.auth.user.fname,
      carowner_lname: this.props.auth.user.lname,
      carownerphone: this.props.auth.user.phone,
      unitnumber: this.props.auth.user.unitnumber,
      make: "",
      model: "",
      year: "",
      plate: "",
      plate_state: "",
      which: '',
      carownertype: 'owner', //this.props.carownertype,

      vehicle: this.props.vehicle,
      enddate: '',
      selecteddate: dstr,
      todaydate: '', //dstr,
      vehicle_type: "CAR",
      vehicletypelist: [], //vary,
      //vehiclespotsobj: JSON.parse(sessionStorage.getItem('hoa_parking_spots')),
      terms: 0,
      showterms: false,
      termsaccepted: true,
      errors: {},
      cardata: {},
      whichphoneview: 1,

      showpaymentform: false,

      vehicledata: {},
      isloading: false
    };
    // console.log('just sets state parking spots v form', JSON.parse(sessionStorage.getItem('hoa_parking_spots')));



    this.onChange = this.onChange.bind(this);
    this.onCBChange = this.onCBChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    // this.onFileInputChange = this.onFileInputChange.bind(this);
    // this.handleMapClick = this.handleMapClick.bind(this);
  }



  static getDerivedStateFromProps(props, state) {
    if (props.vehicle && (props.vehicle !== state.vehicle)) {
      return {
        vehicle: props.vehicle
      }
    }
    return null;
  }

  componentDidMount() {

    let shoastr = sessionStorage.getItem('selectedhoa');
    let ahoa = JSON.parse(shoastr);

    let stateobj = {};

    this.loadState(this.props);

    let viewnum = 1;
    if (window.innerWidth < 800) {
      viewnum = 2;
    }
    this.setState({ whichphoneview: viewnum });

  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.vehicle && (prevState.vehicle.plate !== this.state.vehicle.plate)) {
      // console.log('cdidupdate props', this.props);
      this.loadUpdateState(this.props);
    }
  }

  loadState = (props) => {
    let spots = JSON.parse(sessionStorage.getItem('hoa_parking_spots')); //this.state.vehiclespotsobj;
    let spotslist = []; //this.state.vehicletypelist;
    // let vehiclespotsobj = []; //this.state.vehiclespotsobj;
    if (spots !== null) {
      let vary = [];

      spots.forEach(el => {
        let nv = {};
        nv['name'] = el['spot_display'];
        nv['value'] = el['spot_type'];
        vary.push(nv);
      });

      // console.log('in load state vehicle array', vary);
      spotslist = vary;
      // vehiclespotsobj = spots;
    } else {

    }


    let fname = this.props.auth.user.fname;
    let lname = this.props.auth.user.lname;

    //let ounitnumber = this.props.auth.user.unitnumber;
    let enddate = '1970-01-01'; //dstr;
    //let enddate = '1970-01-01';
    let phone = this.props.auth.user.phone;
    // let ownerid = this.props.auth.user._id; //'';
    let otype = 'owner';
    let which = 'create';
    let vehicleid = '';
    let make = '';
    let model = '';
    let year = '';
    let plate = '';
    let plate_state = '';

    if (parseInt(sessionStorage.getItem('use_demo_mode')) === 1) {
      phone = '123-456-7890';
      make = 'Chevy';
      model = 'Blazer';
      year = '2015';
      plate = 'JJ-4040';
      plate_state = 'CO';
    }

    let vehicle_type = 'CAR';
    let terms = 0;
    let termsaccepted = true;

    if (props.vehicle) {
      which = 'modify';
      vehicleid = props.vehicle._id;
      make = props.vehicle.make;
      model = props.vehicle.model;
      year = props.vehicle.year;
      plate = props.vehicle.plate;
      plate_state = props.vehicle.plate_state;
      //  active_flag = props.vehicle.active_flag;
      vehicle_type = props.vehicle.vehicle_type;
      fname = props.vehicle.carowner_fname;
      lname = props.vehicle.carowner_lname;
      otype = props.vehicle.carownertype;
      phone = props.vehicle.carownerphone;
      enddate = props.vehicle.enddate;
    }




    this.setState(
      {
        which: which,
        // ownerid: ownerid,
        vehicleid: vehicleid,
        vehicle_type: vehicle_type,
        carownertype: otype,
        carowner_fname: fname,
        carowner_lname: lname,
        carownerphone: phone,
        make: make,
        model: model,
        year: year,
        plate: plate,
        plate_state: plate_state,
        enddate: enddate,
        terms: terms,
        termsaccepted: termsaccepted,
        vehicletypelist: spotslist,

      }
    )
    //console.log('vehicle form  state is', this.state);
  }

  loadUpdateState = (props) => {


    let otype = props.vehicle.carownertype;
    let fname = props.vehicle.carowner_fname;
    let lname = props.vehicle.carowner_lname;
    //  let ounitnumber = this.props.auth.user.unitnumber;
    let enddate = props.vehicle.enddate;
    let phone = props.vehicle.carownerphone;
    // let ownerid = '';
    let which = 'modify';
    let vehicleid = props.vehicle._id;
    let make = props.vehicle.make;
    let model = props.vehicle.model;
    let year = props.vehicle.year;
    let plate = props.vehicle.plate;
    let plate_state = props.vehicle.plate_state;
    let vehicle_type = props.vehicle.vehicle_type;
    let terms = 0;
    let termsaccepted = true;


    this.setState(
      {
        which: which,
        // ownerid: ownerid,
        vehicleid: vehicleid,
        vehicle_type: vehicle_type,
        carownertype: otype,
        carowner_fname: fname,
        carowner_lname: lname,
        enddate: enddate,
        carownerphone: phone,
        make: make,
        model: model,
        year: year,
        plate: plate,
        plate_state: plate_state

      }
    )
    //console.log('vehicle form  state is', this.state);
  }

  onChange(e) {
    let elname = e.target.name;
    let elval = e.target.value;
    //    console.log('elname is', elname, elval, this.state.vehiclespotsobj);

    this.setState({ [e.target.name]: e.target.value, errors: {} });

  }



  onCBChange(e) {
    let tval = 0;
    let ischecked = e.target.checked;
    if (ischecked) tval = 1;
    this.setState({ termsaccepted: ischecked, terms: tval });
  }




  onSubmit(e) {

   // console.log('submit car form');
    e.preventDefault();
    let active_flag = 0;
    let { carownertype } = this.state;
    let { vehicle_type } = this.state;
    let { plate } = this.state;
    let { make } = this.state;
    let { plate_state } = this.state;
    // let { todaydate } = this.state;

    if (vehicle_type === 'DUMPSTER') {
      plate = 'DUMPSTER';
      make = 'n/a';
      plate_state = 'n/a';
    }


    let submitdata = {};
    //  submitdata['hoaname'] = sessionStorage.getItem('hoaname');
    submitdata['hoaid'] = sessionStorage.getItem('hoaid');
    submitdata['which'] = this.state.which;
    //  submitdata['showpaymentform'] = this.state.showpaymentform;
    submitdata['vehicleid'] = this.state.vehicleid;
    submitdata['ownerid'] = this.state.ownerid;
    submitdata['carowner_fname'] = this.state.carowner_fname;
    submitdata['carowner_lname'] = this.state.carowner_lname;
    submitdata['carownerphone'] = this.state.carownerphone;
    submitdata['unitnumber'] = this.state.unitnumber;
    submitdata['make'] = make;
    submitdata['model'] = this.state.model;
    submitdata['year'] = this.state.year;
    submitdata['plate'] = plate;
    submitdata['plate_state'] = plate_state;
    submitdata['carownertype'] = this.state.carownertype;
    submitdata['vehicle_type'] = this.state.vehicle_type;
    //submitdata['todaydate'] = this.state.todaydate;
    submitdata['enddate'] = this.state.enddate;
    submitdata['terms'] = this.state.terms;
    submitdata['active_flag'] = active_flag;


    const { errors, isValid } = validateInsertModifyVehicleInput('owner', submitdata);

    // console.log('isvalid', isValid);
    if (!isValid) {
      this.setState({ errors: errors });

      return;
      //   return res.status(400).json(errors);
    }



    // this goes down the card payment route
    //this.setState({ cardata: submitdata, showpaymentform: true });


   //  console.log('we will submit insert vof the vehicle', submitdata);

    if (this.state.which === 'modify') {
      this.props.modifyVehicle(submitdata, this.props.history);
    } else {
      this.props.insertVehicle(submitdata, this.props.history);
    }

    setTimeout(() => {
      this.props.sendclosetoparent();
    }, 3000)


  }



  closeVehicleForm = (e) => {
    e.preventDefault();
    //console.log('going to close closeVehicleForm');
    this.props.sendclosetoparent();
  }

  showTerms = (e) => {
    e.preventDefault();
    let terms = !this.state.showterms;
    this.setState({ showterms: terms });
  }


  xxxxxcloseform = () => {
    this.setState({ showpaymentform: false });
  }






  render() {

    const errors = this.state.errors;
    const perrors = this.props.errors;
    const { which } = this.state;
    const isguest = true;

    const { showterms } = this.state;
    let { termsaccepted } = this.state;
    let { vehicletypelist } = this.state;
    const { renter_free_parking_spots } = this.state;

    let { showpaymentform } = this.state;
    let { whichphoneview } = this.state;

    const { vehicledata } = this.state;

    const { carownertype } = this.state;



    if (vehicletypelist.length === 0) {
      return null;
    }

    //const searchOptions = {};

    let title = "Register";
    if (which === 'modify') {
      title = "Modify";
    }
    // let terms_list = [{ name: 'No, I do not accept the terms', value: 0 }, { name: 'Yes, I do accept the terms', value: 1 }];
    // let disablesubmit = true;
    // if (termsaccepted) {
    //   disablesubmit = false;
    // }
    // if (!isguest) {
    //   termsaccepted = true;
    // }

    //showpaymentform = true;

    //  console.log('disablesubmit', disablesubmit); 65598a 17a2b8
    const okicon = <FontAwesomeIcon icon={faCheckCircle} color="#65598a" size="2x" title="enter / submit" />
    const okicondisable = <FontAwesomeIcon icon={faCheckCircle} color="#ddd" size="2x" />
    const cancelicon = <FontAwesomeIcon icon={faTimesCircle} color="black" size="2x" title="cancel / close" />

    // Owner, Long-Term Renter, Visitor, Caretender, Contractor.


    let cotypelist = [
      { name: "Owner", value: "owner" },
      { name: "Family", value: "family" },
      { name: "Friend", value: "friend" },
      { name: "Long Term", value: "longterm" },
      { name: "Visitor", value: "visitor" },
      { name: "Contractor", value: "contractor" }
    ];

    // let popupclass = 'popupdialog bordershadow';
    // let itop = 160; // + ptop;

    let popupclass = 'popupdialog bordershadow';
    if (whichphoneview !== 1) popupclass = 'popupdialog_phone bordernoshadow';
    let ptop = window.scrollY;
    let itop = 10 + ptop;

    let openmodal = true;

    return (
      <div className="xregister" style={{ width: "95%" }}>
        <div style={{ marginBottom: "15px" }}>

          {/* <div style={{ textAlign: "right" }}>
            <Link to=""
              onClick={(e) => this.closeVehicleForm(e)} >
              {"close"}
            </Link>
          </div> */}

          <p className="text-center"><b>{title} Vehicle</b></p>
          {(this.state.isloading) ? (<LoadingMessage />) : null}

          <form noValidate >
            <div style={{ display: "flex", gap: "5px", flexDirection: "column" }}>

              <div className="row">
                <div className="col-4 font10ptb">
                  Vehicle
                </div>
                <div className="col-8">
                  <SelectNameValuePlain
                    label="Availability"
                    list={vehicletypelist}
                    name="vehicle_type"
                    value={this.state.vehicle_type}
                    onChange={this.onChange}
                    error={errors.vehicle_type}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-4 font10ptb">
                  First Name</div>
                <div className="col-8">
                  <TextFieldGroupPlain
                    type="text"
                    label="Name"
                    placeholder="First Name"
                    name="carowner_fname"
                    value={this.state.carowner_fname}
                    onChange={this.onChange}
                    error={errors.carowner_fname}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-4 font10ptb">
                  Last Name</div>
                <div className="col-8">
                  <TextFieldGroupPlain
                    type="text"
                    label="Last Name"
                    placeholder="Last Name"
                    name="carowner_lname"
                    value={this.state.carowner_lname}
                    onChange={this.onChange}
                    error={errors.carowner_lname}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-4 font10ptb">
                  Belongs To
                </div>
                <div className="col-8">
                  {carownertype === 'renter' ? (<div style={{ marginBottom: "10px" }}>Renter</div>) : (
                    <SelectNameValuePlain
                      label="ownertype"
                      list={cotypelist}
                      name="carownertype"
                      value={this.state.carownertype}
                      onChange={this.onChange}
                      error={errors.carownertype}
                    />
                  )}
                </div>
              </div>


              <div className="row">
                <div className="col-4 font10ptb">
                  Phone</div>
                <div className="col-8">
                  <TextFieldGroupPlain
                    type="text"
                    label="Phone"
                    placeholder="Phone"
                    name="carownerphone"
                    value={this.state.carownerphone}
                    onChange={this.onChange}
                    error={errors.carownerphone}
                  />
                </div>
              </div>





              <div className="row">
                <div className="col-4 font10ptb">
                  Make</div>
                <div className="col-8">
                  <TextFieldGroupPlain
                    type="text"
                    label="Make"
                    placeholder="Make"
                    name="make"
                    value={this.state.make}
                    onChange={this.onChange}
                    error={errors.make}
                  />
                </div>
              </div>



              <div className="row">
                <div className="col-4 font10ptb">
                  Model
                </div>
                <div className="col-8">
                  <TextFieldGroupPlain
                    type="text"
                    label="Model"
                    placeholder="Model"
                    name="model"
                    value={this.state.model}
                    onChange={this.onChange}
                    error={errors.model}
                  />

                </div>
              </div>
              <div className="row">
                <div className="col-4 font10ptb">
                  Year
                </div>
                <div className="col-8">
                  <TextFieldGroupPlain
                    type="text"
                    label="Year"
                    placeholder="Year"
                    name="year"
                    value={this.state.year}
                    onChange={this.onChange}
                    error={errors.year}
                  />


                </div>
              </div>

              <div className="row">
                <div className="col-4 font10ptb">
                  Plate
                </div>
                <div className="col-8">
                  <TextFieldGroupPlain
                    type="text"
                    label="plate"
                    placeholder="Plate"
                    name="plate"
                    value={this.state.plate}
                    onChange={this.onChange}
                    error={errors.plate}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-4 font10ptb">
                  State
                </div>
                <div className="col-8">
                  <TextFieldGroupPlain
                    type="text"
                    label="plate_state"
                    placeholder="State"
                    name="plate_state"
                    value={this.state.plate_state}
                    onChange={this.onChange}
                    error={errors.plate_state}
                  />
                </div>
              </div>









              <div className="row">

                <div className="col-12">
                  <div style={{ fontSize: "9pt" }}>
                    * You need Management permission
                    for Trailer, Camper/RV, Oversized Vehicle or Dumpsters.
                  </div>
                </div>
              </div>

            </div>
          </form>

          <div className="row" style={{ marginTop: "10px" }}>


            <div className="col-12">

              <span className="iconbuttonspan">
                <Link to=""
                  onClick={(e) => this.onSubmit(e)} >
                  {okicon}
                </Link>
              </span>
              <span className="iconbuttonspan">
                <Link to=""
                  onClick={(e) => this.closeVehicleForm(e)} >
                  {cancelicon}
                </Link>
              </span>
            </div>
          </div>





          {errors && errors['message'] ? (<div className="error-display">{errors['message']}</div>) : (null)}
          {perrors && (
            <div className="error-display">{perrors.message}</div>
          )}



        </div>
      </div >


    );
  }


  //  style={{ top: itop }}


  // this is good practice because it will help debugging
  // it is not checked when in production mode.
  // Business.propTypes = {
  //   createBusiness: PropTypes.func.isRequired,
  //   modifyBusiness: PropTypes.func.isRequired,
  //   auth: PropTypes.object.isRequired,
  //   errors: PropTypes.object.isRequired
};

// map errors to this component
// then we can use this.props.auth.user etc
// since we mapped error, we can use componentWillReceiveProps method
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
});
// the state.auth above comes from rootReducer in index.js in reducers.

export default connect(
  mapStateToProps,
  { insertVehicle, modifyVehicle, showStatusMessage }
)((VehicleFormOwner));
// wrap the Register with withRouter so the authAction can use history to redirect

//Change “Vehicle” picklist to (in this order): 
//Car, Truck, Van, Motorcycle, Trailer, Oversized Vehicle, Dumpster, Other.

