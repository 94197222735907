export const isitEmpty = (value) => {



  if ((value === undefined) ||
    (value === 'undefined') ||
    (typeof value === "object" && Object.keys(value).length === 0) ||
    (typeof value === "string" && value.trim().length === 0)) {
    return true
  } else {
    return false;
  }

}

/*
 value === undefined ||
  value === 'undefined' ||
  value === null ||
  (typeof value === "object" && Object.keys(value).length === 0) ||
  (typeof value === "string" && value.trim().length === 0);
  */

