import React, { Component } from "react";
// we need to get into git
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import LoadingMessage from "../common/LoadingMessage";


import AdminModifyOwner from "./AdminModifyOwner";

import RegisterViaAdmin from "./RegisterViaAdmin";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";

import { confirmAlert } from 'react-confirm-alert';



import { getAllOwners } from "../../actions/parkingActions";
import { deleteOwner } from "../../actions/parkingActions";
//import axios from "axios";

//import { useRanger } from "react-ranger";
//import { confirmAlert } from 'react-confirm-alert';
//import 'react-confirm-alert/src/react-confirm-alert.css';
//
//import { getDomainData, getDealSpecials } from "../../actions/dealsActions";
//import styled, { createGlobalStyle } from "styled-components";



/*
const SliderDateDisplay = styled("div")`
  display: inline-block;
  height: 12px;
  width: 90%;
  margin: 0 5%;
  color:white;
  font-size:10pt;
`;
*/

class AdminOwners extends Component {
  constructor(props) {
    super(props);

    this.state = {
      whichphoneview: 1,
      showmodifyowner: false,
      selectedowner: null,
      whichsort: 'last_name',
      sortdirection: -1,
      showaddowner: false,
      readonly: props.which,

      errors: {}
    }; //shuttles: ["one", "two", "three"] };
    // console.log("Dashboard props", props);

  }

  componentDidMount() {
    let viewnum = 1;
    if (window.innerWidth < 800) {
      viewnum = 2;
    }
    this.setState({ whichphoneview: viewnum });
    this.props.getAllOwners();
    //this.doGetVehicles(oid);
  }

  goToProfile = (e) => {
    e.preventDefault();
    this.props.history.push("/profile");
  }



  deleteTheOwner = (oid) => {
    //  e.preventDefault();
    this.props.deleteOwner(oid);
  }
  deleteOwner = (e, o) => {
    e.preventDefault();
    if (this.props.auth.user.crud === 'read') {
      alert('not authorized to delete owner');
      return;
    }
    const okelement = <FontAwesomeIcon icon={faCheckCircle} color="black" size="2x" title="enter / submit" />
    const cancelelement = <FontAwesomeIcon icon={faTimesCircle} color="black" size="2x" title="cancel / close" />
    confirmAlert({
      customUI: ({ onClose }) => {
        let oid = o['_id'];
        let lbl = o['last_name'] + ', ' + o['first_name'];
        return (
          <div className='confirmmessage' >
            <h6>Are you sure you want to delete?</h6>
            <p>{lbl}</p>
            <span className="iconbuttonspan"
              onClick={() => {
                this.deleteTheOwner(oid);
                onClose();
              }
              }> {okelement}</span>
            <span className="iconbuttonspan"
              onClick={() => {
                onClose();
              }}
            >
              {cancelelement}
            </span>
          </div>
        );
      }
    });
  }


  togglePhoneView = (e, which) => {
    e.preventDefault();
    this.setState({ whichphoneview: which });
  }

  showModifyOwner(e, owner) {
    e.preventDefault();
    if (this.props.auth.user.crud === 'read') {
      alert('not authorized to change owner');
      return;
    }
    let which = true; //!this.state.showviolations;
    this.setState({
      showmodifyowner: which,
      selectedowner: owner
    });
  }

  closeform = () => {
    // console.log('this is close form');
    this.setState({ showmodifyowner: false });
  }

  doSort = (e, which) => {
    e.preventDefault();
    let sd = this.state.sortdirection;
    sd = sd * -1;
    this.setState({ whichsort: which, sortdirection: sd });
  }


  showAddOwner = (e => {
    e.preventDefault();
    if (this.props.auth.user.crud === 'read') {
      alert('not authorized to change owner');
      return;
    }
    this.setState({ showaddowner: true });
  });



  closeownerform = () => {
    // console.log('this is close form');
    this.setState({ showaddowner: false });
  }







  render() {

    let { whichphoneview } = this.state;
    let { selectedowner } = this.state;

    let { readonly } = this.state;

    let isreadonly = false;
    if (readonly === 'readonly') {
      isreadonly = true;
    }

    //console.log('read only stuff', readonly, isreadonly);


    let chbclass = 'normalbuttonwrapper';

    const { showmodifyowner } = this.state;
    let popupclass = 'popupdialog bordershadow';
    if (whichphoneview !== 1) popupclass = 'popupdialog_phone bordershadow';
    let ptop = window.scrollY;
    let itop = 80 + ptop;

    let owners_ary = this.props.parking.all_owners;

    let { whichsort } = this.state;
    let { sortdirection } = this.state;
    owners_ary = owners_ary.sort(function (a, b) {
      if (a[whichsort] < b[whichsort]) return (1 * sortdirection);
      if (a[whichsort] > b[whichsort]) return (-1 * sortdirection);
      if (a[whichsort] === b[whichsort]) return 0;
    })


    let owner_template_grid_phone = owners_ary.map((owner, index) => (
      <div className="bordershadow" key={index}>
        <div className="grid-item-bold">{index + 1}</div>
        <div key={index} className="grid-container-3" >
          <div className="grid-item-bold">Name</div>
          <div className="grid-item-bold">Phone</div>
          <div className="grid-item-bold">Role</div>
          <div className="grid-item-normal" >
            {(owner.role === 'admin' || isreadonly) ? (<span>{owner.last_name}, {owner.first_name}</span>) : (
              <Link to=""
                onClick={(e) => this.showModifyOwner(e, owner)} >{owner.last_name}, {owner.first_name}</Link>

            )}
          </div>
          <div className="grid-item-normal">{owner.phone}</div>
          <div className="grid-item-normal">{owner.role}</div>


          <div className="grid-item-bold">Email</div>
          <div className="grid-item-bold">Unit</div>
          <div className="grid-item-bold">Pin</div>



          <div className="grid-item-normal">{owner.email}</div>
          <div className="grid-item-normal">{owner.unitnumber}</div>
          <div className="grid-item-normal">{owner.pincode}</div>


          <div className="grid-item-bold">Parking</div>




          <div className="grid-item-bold">Renter</div>
          <div className="grid-item-bold">Action</div>
          <div className="grid-item-normal">{owner.parking_allowed}</div>


          <div className="grid-item-normal">{owner.parking_allowed_renter}</div>


          <div className="grid-item-normal">
            {(owner.role === 'admin' || isreadonly) ? (null) : (
              <Link to=""
                onClick={(e) => this.deleteOwner(e, owner)} >delete</Link>
            )}
          </div>
        </div>
      </div>
    ));

    // email	name	phone	role	hoaid unit	pincode
    let owners_template_grid = owners_ary.map((owner, index) => (
      <div key={index} className="grid-container-9c">
        <div className="grid-item-normal">
          {(owner.role === 'admin' || isreadonly) ? (<span>{owner.last_name}, {owner.first_name}</span>) : (
            <Link to=""
              onClick={(e) => this.showModifyOwner(e, owner)} >{owner.last_name}, {owner.first_name}</Link>

          )}
          &nbsp;{owner.has_read_terms}
        </div>
        <div className="grid-item-normal">{owner.email}</div>
        <div className="grid-item-normal">{owner.phone}</div>
        <div className="grid-item-normal">{owner.role}</div>
        <div className="grid-item-normal">{owner.unitnumber}</div>
        <div className="grid-item-normal">{owner.pincode}</div>
        <div className="grid-item-normal">{owner.parking_allowed}</div>
        <div className="grid-item-normal">{owner.parking_allowed_renter}&nbsp;-&nbsp;{owner.renter_free_parking}</div>
        <div className="grid-item-normal">
          {(owner.role === 'admin' || isreadonly) ? (null) : (
            <Link to=""
              onClick={(e) => this.deleteOwner(e, owner)} >delete</Link>
          )}
        </div>
      </div>
    ));

    let phonesorttable = (
      <div style={{ borderBottom: "0px solid black", marginBottom: "10px" }}>
        <table>
          <thead><tr><th colSpan="5">Sort</th></tr></thead>
          <tbody>
            <tr>
              <td style={{ paddingRight: "10px" }}>
                <Link to=""
                  onClick={(e) => this.doSort(e, 'last_name')} >
                  <b>Name</b>
                </Link>
              </td>
              <td style={{ paddingRight: "10px" }}>
                <Link to=""
                  onClick={(e) => this.doSort(e, 'unitnumber')} >
                  <b>Unit</b>
                </Link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>


    );


    const addicon = <FontAwesomeIcon icon={faPlusCircle} color="#65598a" size="2x" />

    const { showaddowner } = this.state;


    return (
      <div style={{ marginTop: "10px" }}>

        <div className="inputbox">
          <div className="innerinputbox2">
            {(this.props.parking.isloading) ? (<LoadingMessage />) : null}

            <p className="parking_label">Owners</p>

            {isreadonly ? (null) : (
              <div style={{ marginTop: "15px" }}>
                <Link to=""
                  onClick={(e) => this.showAddOwner(e)} >{addicon}</Link>


              </div>

            )}

            {showaddowner ? (
              <div className="popupdialog" >
                <RegisterViaAdmin closeform={this.closeownerform} />
              </div>
            ) : (
              null
            )}



            {showmodifyowner ? (
              <div className={popupclass} style={{ top: itop }}>
                <AdminModifyOwner owner={selectedowner} closeform={() => this.closeform()} />
              </div>
            ) : (null)}
            <div style={{ fontSize: "10pt" }}>
              <div style={{ fontSize: "10pt", border: "0px solid black" }}>
                <div className="phoneview">
                  <div>{phonesorttable}</div>

                  {owner_template_grid_phone}

                </div>
              </div>
              <div className="tableview" style={{ fontSize: "10pt", border: "0px solid black", maxHeight: "400px", overflowY: "scroll", overflowX: "hidden" }} >
                <div className="grid-container-9c">
                  <div className="grid-item-bold">
                    <Link to=""
                      onClick={(e) => this.doSort(e, 'last_name')} >
                      Name
                    </Link>

                  </div>
                  <div className="grid-item-bold">Email</div>

                  <div className="grid-item-bold">Phone</div>
                  <div className="grid-item-bold">Role</div>
                  <div className="grid-item-bold">
                    <Link to=""
                      onClick={(e) => this.doSort(e, 'unitnumber')} >
                      Unit
                    </Link>

                  </div>
                  <div className="grid-item-bold">Pin</div>
                  <div className="grid-item-bold" title="Total spaces allowed (space permitting)">Parking</div>
                  <div className="grid-item-bold" title="Renter spaces - Renter free parking">Renter</div>
                  <div className="grid-item-bold">Action</div>
                </div>
                <div>
                  {owners_template_grid}
                </div>
              </div>
            </div>











          </div >
        </div >

      </div >
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  parking: state.parking,
  errors: state.errors,
});

export default connect(mapStateToProps, { getAllOwners, deleteOwner })(AdminOwners);

//export default Dashboard;
