import React, { Component } from "react";
// we need to get into git
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//import axios from "axios";

//import { useRanger } from "react-ranger";
//import { confirmAlert } from 'react-confirm-alert';
//import 'react-confirm-alert/src/react-confirm-alert.css';
//
//import { getDomainData, getDealSpecials } from "../../actions/dealsActions";
//import styled, { createGlobalStyle } from "styled-components";
import HoaSettings from "./HoaSettings";
import AdminNotifications from "./AdminNotifications";
import AdminContactInformation from "./AdminContactInformation";
import AdminHelpText from "./AdminHelpText";
//import AdminUserCsvUpload from "./AdminUserCsvUpload";
//import TestArea from "./TestArea";





class MaintenanceDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showreports: false,
      showhoa: true,
      showplay: false,
      shownotifications: false,
      showcontactinfo: false,
      showhelptext: false,


      errors: {}
    }; //shuttles: ["one", "two", "three"] };
    // console.log("Dashboard props", props);

  }

  componentDidMount() {
    // this.props.getDomainData();
  }

  showReport = (e) => {
    e.preventDefault();
    // this.props.history.push("/profile");
    this.setState({ showreports: true, showhoa: false, showplay: false, shownotifications: false, showcontactinfo: false, showhelptext: false });
  }
  showHoa = (e) => {
    e.preventDefault();
    this.setState({ showreports: false, showhoa: true, showplay: false, shownotifications: false, showcontactinfo: false, showhelptext: false });
  }

  showContactInfo = (e) => {
    e.preventDefault();
    this.setState({ showreports: false, showhoa: false, showplay: false, shownotifications: false, showcontactinfo: true, showhelptext: false });
  }

  showNotifications = (e) => {
    e.preventDefault();
    this.setState({ showreports: false, showhoa: false, showplay: false, shownotifications: true, showcontactinfo: false, showhelptext: false });
  }

  showHelpText = (e) => {
    e.preventDefault();
    this.setState({ showreports: false, showhoa: false, showplay: false, shownotifications: false, showcontactinfo: false, showhelptext: true });
  }
  showTest = (e) => {
    e.preventDefault();
    this.setState({ showreports: false, showhoa: false, showplay: true, shownotifications: false, showcontactinfo: false, showhelptext: false });
  }


  downloadVehicles = (e) => {
    e.preventDefault();
    console.log('do the download');
    alert('download to csv file');
  }

  render() {
    //  console.log('DOING A DASBOARD RENDER');

    // console.log('perrors are', perrors);

    //let valary = [100, 400];
    // console.log('dashboard render dd ', dd);
    // minmax[0] = parseInt(dd['ppn']);
    /*
    backgroundColor: "#231259",
          color: "white",
          */
    const { showreports } = this.state;
    const { showhoa } = this.state;
    const { showplay } = this.state;
    const { shownotifications } = this.state;
    const { showcontactinfo } = this.state;
    const { showhelptext } = this.state;

    let chbclass = 'normalbuttonwrapper';

    let millis = Date.now();

    return (
      <div className="container-fluid">


        <h4 style={{ textAlign: "center", color: "white", marginTop: "15px", border: "0px solid white" }} >Maintenance Dashboard</h4>



        <div className="flex_row_center">
          <div className="flex_item"><Link to="" className="btn btn-sm btn-info btnparking"
            onClick={(e) => this.showHoa(e)} >HOA</Link></div>


          <div className="flex_item"><Link to="" className="btn btn-sm btn-info btnparking"
            onClick={(e) => this.showContactInfo(e)} >HOA Contact</Link></div>

          <div className="flex_item"><Link to="" className="btn btn-sm btn-info btnparking"
            onClick={(e) => this.showHelpText(e)} >Help Text</Link></div>

          <div className="flex_item"><Link to="" className="btn btn-sm btn-info btnparking"
            onClick={(e) => this.showNotifications(e)} >Feedback</Link></div>


        </div>


        {showhoa ? (<HoaSettings />) : (null)}
        {shownotifications ? (<AdminNotifications />) : (null)}
        {showcontactinfo ? (<AdminContactInformation />) : (null)}
        {showhelptext ? (<AdminHelpText millis={millis} />) : (null)}
        {showreports ? (
          <div style={{ textAlign: "center" }}>
            <h4 style={{ textAlign: "center", color: "white", marginTop: "10px" }}>Reports</h4>
            <span className={chbclass}>
              <Link to="" className="btn btn-sm btn-info btnparking" style={{ width: "200px" }}
                onClick={(e) => this.downloadVehicles(e)} >Download Vehicle Report</Link>
            </span>
          </div>
        ) : (null)}
        {/* {showplay ? (<TestArea />) : (null)} */}

        {/* <div className="row">
          <div className="col-md-6 offset-md-3">
            <AdminUserCsvUpload />
          </div>
        </div> */}




      </div >
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  parking: state.parking,
  errors: state.errors,
});

export default connect(mapStateToProps, {})(MaintenanceDashboard);

//export default Dashboard;
