import { combineReducers } from "redux";
import authReducer from "./authReducer";
import parkingReducer from "./parkingReducer";
import errorReducer from "./errorReducer";
import statusReducer from "./statusReducer";
//import adverReducer from "./adverReducer";

export default combineReducers({
  auth: authReducer,
  parking: parkingReducer,
  errors: errorReducer,
  statusMsg: statusReducer
  // advertise: adverReducer,
});
