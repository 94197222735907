import React from "react";
import { Button } from 'react-bootstrap';
//import RBAC from "../../utils/rbac";




const SmartButtonHPS = ({ name, buttonclass, variant, disabled, size, onClick }) => {
  let bdisabled = false;
  let errors = {};
  if (disabled) {
    bdisabled = disabled;
  }


  // return (
  //   <Button variant={variant} className={className} size={size} disabled={bdisabled} onClick={onClick}>{name}</Button>
  // );

  // return (
  //   <input className={errors.id ? "error-input" : "login-input"}
  //     placeholder="Sign-In UserID"
  //     name="id"
  //     type="text"
  //     // ratio="5-7"
  //     value={name}
  //     onChange={onClick}
  //     required
  //   />
  // )

  return (
    <button className={buttonclass} onClick={onClick} >
      {name}
    </button >
  )
};

// export default SmartButton;
// const mapStateToProps = state => ({
//   auth: state.auth
// });

// // call loginUser in actions file
// export default connect(
//   mapStateToProps,
//   {}
// )(SmartButton);

export default SmartButtonHPS;
