import React, { Component } from "react";
// we need to get into git
import { connect } from "react-redux";
//import axios from "axios";

//import { useRanger } from "react-ranger";
//import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
//
//import { getDomainData, getDealSpecials } from "../../actions/dealsActions";
//import styled, { createGlobalStyle } from "styled-components";
// import AdminOwners from "./AdminOwners";
// import AdminVehicles from "./AdminVehicles";
// import AdminActiveVehicles from "./AdminActiveVehicles";
// import VehicleForm from "./VehicleForm";
//import RenterVehicles from "./RenterVehicles";
import ParkingSummary from "../general/ParkingSummary";
import RenterVehicles from "./RenterVehicles";
//import ParkingSummary from "./ParkingSummary";




class RenterDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // showowners: false,
      // showvehicles: false,
      // showactivevehicles: false,

      errors: {}
    }; //shuttles: ["one", "two", "three"] };
    // console.log("Dashboard props", props);

  }

  componentDidMount() {
    // this.props.getDomainData();
  }









  render() {

    let millis = Date.now();


    return (
      <div className="container-fluid">

        <div className="row" style={{ marginTop: "10px", marginBottom: "10px" }} >

          <div className="col-md-4 offset-md-4">
            <h5 style={{ textAlign: "center", color: "white" }} >Renter Dashboard</h5>
            <div style={{ textAlign: "center", color: "white" }}>{sessionStorage.getItem('hoaname')}

            </div>
            <div style={{ textAlign: "center", color: "white", fontSize: "10pt" }}>Unit: {sessionStorage.getItem('unitnumber')}</div>
          </div>
          <div className="col-md-4">
            <div style={{ textAlign: "center", color: "white", paddingRight: "20px" }}><ParkingSummary millis={millis} /></div>
          </div>
        </div>

        {/* <h5 style={{ textAlign: "center", color: "white", marginTop: "15px" }} >Renter Dashboard</h5> */}



        <RenterVehicles />




      </div >
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  parking: state.parking,
  errors: state.errors,
});

export default connect(mapStateToProps, {})(RenterDashboard);

//export default Dashboard;
