import React, { Component } from "react";
//import PropTypes from "prop-types";
//import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import LoadingMessage from "../common/LoadingMessage";
import ErrorMessageConstantClose from "../common/ErrorMessageConstantClose";
//import Spinner from "../common/Spinner";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";

import { confirmAlert } from 'react-confirm-alert';

import ChangeHistory from "../reports/ChangeHistory";


//import axios from "axios";


import { connect } from "react-redux";
import { getVehiclesForOwner, showStatusMessage, deleteVehicleModifyStatus} from "../../actions/parkingActions";
import VehicleFormOwner from "./VehicleFormOwner";
import VehicleOnsite from "../general/VehicleOnsite";
//import VehicleOnsiteOwner from "./VehicleOnsiteOwner";
import ParkingSummary from "../general/ParkingSummary";
//import { getActiveCounts } from './AjaxHelper';
//import msgHelper from '../../config/msgHelper';
import { getTodayAsString, getOnsiteLabel, generateDateDisplay ,getRowHighlightColor} from "../parking/DateHelper";


//import 
//


//import axios from "axios";
//import classnames from "classnames";


//import TextFieldGroup from "../common/TextFieldGroupPlain";

class OwnerVehicles extends Component {
  constructor(props) {
    super(props);

    // console.log('owner vehicles occ', sessionStorage.getItem('use_owner_creditcard'))

    let viewnum = 1;
    if (window.innerWidth < 800) {
      viewnum = 2;
    }

    this.state = {
      // useccform: parseInt(sessionStorage.getItem('use_owner_creditcard')),
      usepppform: parseInt(sessionStorage.getItem('use_owner_ppp')),
      selected_vehicle: null,
      showvehicleform: false,
      showonsiteform: false,
      ownercount: 0,
      rentercount: 0,
      name: this.props.auth.user.name,
      fname: this.props.auth.user.fname,
      lname: this.props.auth.user.lname,
      email: this.props.auth.user.email,
      unitnumber: this.props.auth.user.unitnumber,
      showphoneview: false,
      showoverlimit: false,
      showoverlimitmessage: "what",
      whichphoneview: viewnum,
      windowWidth: 0,
      show: false,
      onsite_vehicle: null,
      showpopup: false,
      selected_vid: null,

      errors: {}
    };

    this.onChange = this.onChange.bind(this);
    // this.onSubmit = this.onSubmit.bind(this);
    // this.onSubmitPin = this.onSubmitPin.bind(this);

  }

  componentDidMount() {
    // console.log("owner vehicles cdm");
    // console.log('userRole', sessionStorage.getItem('userRole'));
    // console.log('primary_userid', sessionStorage.getItem('primary_userid'));
    // console.log('alias_userid', sessionStorage.getItem('alias_userid'));
    // console.log('unitnumber', sessionStorage.getItem('unitnumber'));
    // this.state = { windowWidth: window.innerWidth };
    // console.log('window.innerWidth', window.innerWidth)

    if (this.props.auth.isAuthenticated) {
      // console.log('we are authenticated')
      // this.props.history.push("/dashboard");
      // let showphone = false;
      // if (window.innerWidth < 800) {
      //   showphone = true;
      // }
      let viewnum = 1;
      if (window.innerWidth < 800) {
        viewnum = 2;
      }
      // let role = this.props.auth.user.role;
      let oid = sessionStorage.getItem('alias_userid'); //this.props.auth.user._id;
      // let isadmin = false;
      // if (role === 'admin') isadmin = true;
      // this.state = { windowWidth: window.innerWidth };
      // this.setState({
      //   name: this.props.auth.user.name,
      //   email: this.props.auth.user.email,
      //   unitnumber: this.props.auth.user.unitnumber,
      //   whichphoneview: viewnum,
      // })
      this.doGetVehicles(oid);
    } else {
      //console.log('we are NOT authenticated')
      window.location.href = "/" + sessionStorage.getItem('hoaid');
    }
  }

  doGetVehicles = (oid) => {
    // console.log('getting vehicles for ', oid);
    // this.setState({ selected_vehicle: null, vehicles_ary: [], showvehicleform: false });
    this.props.getVehiclesForOwner(oid, 'owner');
  }

  changeVehicleStatus = (e, vehicle) => {
    e.preventDefault();
    this.setState({ showonsiteform: true, onsite_vehicle: vehicle });
  };




  // if we have errors this will run
  // componentWillReceiveProps(nextProps) {
  //   //console.log("register componentWillReceiveProps");
  //   if (nextProps.errors) {
  //     this.setState({ errors: nextProps.errors });
  //     // setState triggers a render
  //   }
  // }
  static getDerivedStateFromProps(props, state) {
    return {
      errors: props.errors
    };
  }

  //componentDidUpdate(prevProps) {
  // Typical usage (don't forget to compare props):
  // if (this.props.errors) {
  //   this.setState({ errors: this.props.errors });
  // }
  //}

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onCancelClick(e) {
    e.preventDefault();
    window.location = "/";
  }

  showAddVehicleForm = (e, v) => {
    e.preventDefault();
    let oid = sessionStorage.getItem('alias_userid');


    this.setState({ selected_vehicle: v, showvehicleform: true, show: true });

  }


  deleteTheVehicle = (vid) => {
    let unitnumber = sessionStorage.getItem('unitnumber');
    let hoaid = sessionStorage.getItem('hoaid');
  //  console.log('owner vehicles delete vehicle ','vid',vid,'unitnumber',unitnumber,'hoaid',hoaid);
    this.props.deleteVehicleModifyStatus(vid,hoaid,unitnumber);
   
  }
  deleteVehicle = (e, v) => {
    e.preventDefault();
    const okelement = <FontAwesomeIcon icon={faCheckCircle} color="black" size="2x" title="enter / submit" />
    const cancelelement = <FontAwesomeIcon icon={faTimesCircle} color="black" size="2x" title="cancel / close" />
    confirmAlert({
      customUI: ({ onClose }) => {
        let vid = v['_id'];
        let lbl = v['make'] + ' ' + v['plate'] + ' ' + v['plate_state'];
        // console.log('the vid is', vid);
        return (
          <div className='confirmmessage' >
            <h6>Are you sure you want to delete?</h6>
            <p>{lbl}</p>
            <span className="iconbuttonspan"
              onClick={() => {
                this.deleteTheVehicle(vid);
                onClose();
              }
              }> {okelement}</span>
            <span className="iconbuttonspan"
              onClick={() => {
                onClose();
              }}
            >
              {cancelelement}
            </span>
          </div>
        );
      }
    });
  }




  closevehicleform = () => {
    // console.log('this is close form');
    this.setState({ showvehicleform: false, show: false });
  }

  closeonsiteform = () => {
    // console.log('this is close form closeonsiteform');
    this.setState({ showonsiteform: false, show: false });
  }





  handleClose(e) {
    e.preventDefault();
    this.setState({
      show: false,
      errors: {}
    });
  }


  closemessage = () => {
    this.setState({ showoverlimit: false });
  }



  togglePhoneView = (e, which) => {
    e.preventDefault();
    this.setState({ whichphoneview: which });
  }

  renderCarRecords = () => {
    // console.log('render car records');
    const vehicles_ary = this.props.parking.current_vehicles_owner;
    let rdata = [];
    let dstr = getTodayAsString();
    // console.log('dstr', dstr);
    vehicles_ary.forEach((vehicle, index) => {
      let bcolor = getRowHighlightColor('warning',vehicle);
      let edate = vehicle['enddate'];
      let onsite = getOnsiteLabel(edate, dstr,vehicle);
      let displaydate = generateDateDisplay(edate, dstr);

      let datarow = (
            <div key={index} className='grid-container-10a' style={{ backgroundColor: bcolor }}>
          <div className="grid-item-normal">
            <Link to=""
              onClick={(e) => this.showAddVehicleForm(e, vehicle)} >{vehicle.plate} - {vehicle.plate_state}</Link>
          </div>
          <div className="grid-item-normal">{vehicle.vehicle_type} - {vehicle.make}</div>
          <div className="grid-item-normal">{vehicle.model}</div>
          <div className="grid-item-normal">{vehicle.year}</div>
          <div className="grid-item-normal">{vehicle.carowner_lname}, {vehicle.carowner_fname}</div>
          <div className="grid-item-normal">{vehicle.carownertype}-{vehicle.status_flag}</div>

          <div className="grid-item-normal">{displaydate}</div>



          <div className="grid-item-normal">
            {vehicle.carownertype === 'renter' ? (onsite) : (
              <Link to=""
                onClick={e => {
                  this.changeVehicleStatus(
                    e,
                    vehicle
                  );
                }}
              >
                {onsite}
              </Link>
            )}
          </div>
          <div className="grid-item-normal">
          <Link to=""
                onClick={(e) => this.showChangeHistory(e, vehicle)} >
            {vehicle.number_of_changes}
            </Link>
            </div>
          <div className="grid-item-normal">
            <Link to=""
              onClick={(e) => this.deleteVehicle(e, vehicle)} >delete</Link>
          </div>
        </div>

      )

      if (vehicle.carownertype !== 'renter' || onsite === 'Yes') {
        rdata.push(datarow);
      }

    })
    return (<div> {rdata}</div >)
  }


  showChangeHistory = (e,veh) => {

    this.setState({selected_vid: veh['_id'],showpopup:true});

  }

  renderCarRecordsPhone = () => {
    const vehicles_ary = this.props.parking.current_vehicles_owner;
    let rdata = [];

    let dstr = getTodayAsString();

    vehicles_ary.forEach((vehicle, index) => {
      let bcolor = getRowHighlightColor('warning',vehicle);
      let edate = vehicle['enddate'];
      let displaydate = generateDateDisplay(edate, dstr);
      let onsite = getOnsiteLabel(edate, dstr,vehicle);

      let datarow = (
        <div className="bordershadow" key={index} style={{backgroundColor:bcolor}}>
          {/* <div className="grid-item-bold">{index + 1}</div> */}
          <div key={index} className="grid-container-3" >
            <div className="grid-item-bold">Plate</div>
            <div className="grid-item-bold">Name</div>
            <div className="grid-item-bold">Onsite</div>
            <div className="grid-item-normal">
              <Link to=""
                onClick={(e) => this.showAddVehicleForm(e, vehicle)} >{vehicle.plate} - {vehicle.plate_state}</Link>
            </div>
            <div className="grid-item-normal">{vehicle.carowner_lname}, {vehicle.carowner_fname}</div>
            <div className="grid-item-normal">
              {vehicle.carownertype === 'renter' ? (onsite) : (
                <Link to=""
                  onClick={e => {
                    this.changeVehicleStatus(
                      e,
                      vehicle
                    );
                  }}
                >
                  {onsite}
                </Link>
              )}
            </div>
            <div className="grid-item-bold">Make</div>
            <div className="grid-item-bold">Model</div>
            <div className="grid-item-bold">Year</div>

            <div className="grid-item-normal">{vehicle.make}</div>
            <div className="grid-item-normal">{vehicle.model}</div>
            <div className="grid-item-normal">{vehicle.year}</div>

            <div className="grid-item-bold">Checkout</div>
            <div className="grid-item-bold">Type</div>
            <div className="grid-item-bold">Action</div>
            <div className="grid-item-normal">{displaydate}</div>



            <div className="grid-item-normal">{vehicle.carownertype}</div>

            <div className="grid-item-normal">
              <Link to=""
                onClick={(e) => this.deleteVehicle(e, vehicle)} >delete</Link>
            </div>
          </div>
        </div>

      )

      if (vehicle.carownertype !== 'renter' || onsite === 'Yes') {
        rdata.push(datarow);
      }

      // rdata.push(datarow);
      // 
    })

    return (<div>{rdata}</div>)
  }

  closepopup = () => {
    // console.log('this is close form');
    this.setState({ showpopup: false });
  }

  render() {
    // const { errors } = this.state;
    //const { properties_ary } = this.state;
    const { showvehicleform } = this.state;

    let { selected_vehicle } = this.state;
    const { showoverlimit } = this.state;
    const { showoverlimitmessage } = this.state;

    // console.log('we are rendering vehicles');


    // const { isadmin } = this.state;
    const vehicles_ary = this.props.parking.current_vehicles_owner;

    let numvehicles = vehicles_ary.length;

    let millis = Date.now();

    //  console.log('rendering ', numvehicles, this.props.parking);

    let isitloading = this.props.parking.isloading;
    //isitloading = true;

    if (isitloading) {
      // return <Spinner />
      return <LoadingMessage />

    }


    let { showphoneview } = this.state;
    let { whichphoneview } = this.state;

    let popupclass = 'popupdialog bordershadow';
    if (whichphoneview !== 1) popupclass = 'popupdialog_phone bordernoshadow';

    // let modalclass = 'modal-main';
    // if (whichphoneview !== 1) modalclass = 'modal-main-phone';


    let chbclass = 'normalbuttonwrapper';

    const addicon = <FontAwesomeIcon icon={faPlusCircle} color="#65598a" size="2x" />

    let ptop = window.scrollY;
    let itop = 100 + ptop;

    let { showonsiteform } = this.state;

    let { vehicle_id } = this.state;
    let { vehicle_status } = this.state;
    let { vehicle_ownerid } = this.state;
    let { onsite_vehicle } = this.state;

   

    let { showpopup } = this.state;
    let { selected_vid } = this.state;



    //  console.log('RENDERING OWNER VEHICLES');

    return (
      <div style={{ marginTop: "10px" }}>



        <div className="inputbox">
          <div className="innerinputbox2">

            {/* {(this.props.parking.isloading) ? (<LoadingMessage />) : null} */}
            {showoverlimit ? (
              <ErrorMessageConstantClose message={showoverlimitmessage} closemessage={this.closemessage} />
            ) : (null)}
            <div className="parking_label">My Vehicles <span style={{paddingLeft:'15px'}}>
              {sessionStorage.getItem('username')}&nbsp;*&nbsp; 
            {sessionStorage.getItem('email')}&nbsp;*&nbsp;{sessionStorage.getItem('unitnumber')}</span>

            </div>

            <div style={{ marginBottom: "10px" }}>
              <Link to=""
                onClick={(e) => this.showAddVehicleForm(e, null)} >{addicon}</Link>
            </div>

            {this.state.show ? (
              <div className={popupclass} style={{ top: itop }}>
                <VehicleFormOwner vehicle={selected_vehicle} carownertype={"owner"} carcount={0} sendclosetoparent={this.closevehicleform} />
              </div>
            ) : (null)}



            <div style={{ fontSize: "10pt" }}>
              {numvehicles === 0 ? (
                <h3>No vehicles registered</h3>
              ) : (
                <div>

                  <div id="one" className="phoneview">
                    {/* <div>{vehicles_template_grid_phone}</div> */}
                    <div>{this.renderCarRecordsPhone()}</div>
                  </div>
                  <div id="one" className="tableview">
                    <div>
                      <div className="grid-container-10a">
                        <div className="grid-item-bold">Plate</div>
                        <div className="grid-item-bold">Make</div>
                        <div className="grid-item-bold">Model</div>
                        <div className="grid-item-bold">Year</div>
                        <div className="grid-item-bold">Name</div>
                        <div className="grid-item-bold">Type</div>
                        <div className="grid-item-bold">Checkout</div>
                        <div className="grid-item-bold">Onsite</div>
                        <div className="grid-item-bold">History</div>
                        <div className="grid-item-bold">Action</div>
                      </div>
                      <div>
                        {this.renderCarRecords()}

                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>






          </div>
        </div>
        {
          this.state.showonsiteform ? (
            <div className={popupclass} style={{ top: itop }}>
              <VehicleOnsite
                onsite_vehicle={onsite_vehicle}
                sendclosetoparent={this.closeonsiteform} />
            </div>
          ) : (null)
        }




{
                  this.state.showpopup ? (
                      <div className={popupclass} style={{ top: itop,width:"500px" }}>
                          <ChangeHistory
                              selected_vid={selected_vid}
                              which={'owner'}
                              sendclosetoparent={this.closepopup} />
                      </div>
                  ) : (null)
              }





      </div >

    );
  }
}



// map errors to this component
// then we can use this.props.auth.user etc
// since we mapped error, we can use componentWillReceiveProps method
const mapStateToProps = state => ({
  auth: state.auth,
  parking: state.parking,
  errors: state.errors
});
// the state.auth above comes from rootReducer in index.js in reducers.

export default connect(
  mapStateToProps,
  { getVehiclesForOwner, showStatusMessage,deleteVehicleModifyStatus }
)((OwnerVehicles));
// wrap the Register with withRouter so the authAction can use history to redirect
