import React from "react";



const TermsAndConditions_Renter = () => {
  let hoaname = sessionStorage.getItem('hoaname')
  let hoastr = sessionStorage.getItem('selectedhoa');
  let hoaobj = { help_text: { general: "<h2>Nothing general" } };

  if (hoastr) hoaobj = JSON.parse(hoastr);

  return (
    <div className="tenpoint bordernoshadow" >

      <h5 style={{ textAlign: "center" }}>
        {hoaname} Parking<br />
      </h5>
      <h6 style={{ textAlign: "center" }}>
        Renter Terms and Conditions
      </h6>

      {/* <h6>For purposes of these Terms and Conditions:</h6>
      <ul>
        <li>“Renter” is defined as those who rent a unit on a nightly basis.</li>
        <li>“Vehicle” is defined as a motorized vehicle (car, pickup, van, motorcycle, etc.) OR a trailer (recreational, contractor, etc.).</li>

      </ul> */}


      {/* <h6>Renter Parking Rules:</h6> */}

      <div dangerouslySetInnerHTML={{ __html: hoaobj['help_text']['renter_tc'] }}></div>


      {/* <ul>
        <li> Renters must register their vehicles under the unit number they are renting from, if parked in our lots at any time. The Checkout Date entered cannot be exceeded. </li>
        <li> Renters may park no more than 2 registered vehicles in our lots at any given time. Please only register a second vehicle if it is necessary, as parking is limited. </li>
        <li> Park within marked parking spaces, using good judgment when snow is on the ground.</li>
        <li> Do not obstruct or block driveways, walkways, parking spaces, trash receptacles, shuttle bus turnaround, or snow storage areas.</li>
        <li> No parking in driveway leading into upper parking lot.</li>
        <li> When new snow falls, move your vehicle daily to allow for snow plowing.</li>
        <li> No inoperative, immobile, stored, or expired tag vehicles. </li>
        <li> No vehicle major maintenance, including oil changes.</li>
        <li> No playing baseball, softball, or any other activity which may damage vehicles or property.</li>
        <li> Parking violations may be subject to immediate towing or booting, at owner expense. </li>
        <li> No trailers or oversized vehicles permitted. </li>
      </ul>

      <h6>VIOLATION OF THESE TERMS AND CONDITIONS MAY RESULT IN YOUR VEHICLE BEING TOWED OR BOOTED, AT YOUR EXPENSE!!
      </h6> */}


















    </div>
  );
};



export default TermsAndConditions_Renter;
