import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router';

import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
//import { withRouter } from "react-router-dom";
import ContactAdmin from "../admin/ContactAdmin";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faColumns, faUserCircle, faSignOutAlt, faQuestionCircle, faHome, faCog, faBell, faPhone, faExclamationCircle, faComment, faFileAlt, faCircleInfo } from '@fortawesome/free-solid-svg-icons'


//import logo from "../../img/LD_logo_helv_neue_v2-941d006539f1f008baaf2a0a7edae64eceb153f578bce4d06b16de9ccf1d8cf3.png";
//import home from "../../img/home_circle_white2.png";

//import { withRouter } from "react-router-dom";

function Navbar(props) {
  //  console.log('navprops', props)
  // constructor(props) {
  //   super(props);
  //   // console.log("Navbar  props", props);
  //   this.state = { isphoneview: false, showcontactadmin: false, };
  // }

  const [errors, setErrors] = useState({ message: "heck" });
  const [perrors, setPErrors] = useState({ message: 'we have a problem' });
  const [isphoneview, setIsphoneview] = useState(false);
  const [showcontactadmin, setShowcontactadmin] = useState(false);

  const auth = useSelector(state => state.auth);
  const reduxerrors = useSelector(state => state.errors);
  let dispatch = useDispatch();
  let navigate = useNavigate();

  const isAuthenticated = auth.isAuthenticated;

  // componentDidMount() {

  //   let isphoneview = false;
  //   if (window.innerWidth < 800) {
  //     isphoneview = true;
  //   }
  //   this.setState({ isphoneview: isphoneview });
  //   // this.getDictionaryData();
  // }

  const onLogoutClick = (e) => {
    e.preventDefault();
    // this.props.logoutUser();
    dispatch(logoutUser());
    // sessionStorage.removeItem('hoaid');
    // no need to do this.  logoutUser wipes out token and then PrivateRoute directs to / which is the landing page
    let url = "/" + sessionStorage.getItem('hoaid');
    // console.log('Navbar onLogoutClick url doing a navigate', url);
    sessionStorage.setItem('userRole', '');

    props.onNavigation(url)
    // navigate(url)
    // window.location.href = url; //"/";

    //this.props.history.push("/");
  }

  const closeform = () => {
    // console.log('this is close form');
    setShowcontactadmin(false)
    //this.setState({ showcontactadmin: false });
  }




  const goHome = (e) => {
    e.preventDefault();
    //  console.log('hoaid is ', sessionStorage.getItem('hoaid'));
    let url = "/"; // + sessionStorage.getItem('hoaid');
    dispatch(logoutUser());
    // this.props.logoutUser();
    //console.log('Navbar goHome url ', url);
    props.onNavigation(url)
    // window.location.href = url;
    // this.props.history.push(url);
  }

  const followHelpLink = (e) => {
    e.preventDefault();
    // console.log('followLink hoaid is ', sessionStorage.getItem('hoaid'));
    let url = "/helptabs/landing"; // + sessionStorage.getItem('hoaid');

    // this.props.logoutUser();
    //console.log('Navbar goHome url ', url);
    props.onHelpClick(url)
    // window.location.href = url;
    // this.props.history.push(url);
  }


  const showContactAdmin = (e) => {
    e.preventDefault();
    // let showcontactadmin = !this.state.showcontactadmin;
    setShowcontactadmin(true)
    // this.setState({ showcontactadmin: true });
  }




  // const { isAuthenticated } = this.props.auth; // shorthand
  //const { isphoneview } = this.state;



  const hoaid = sessionStorage.getItem('hoaid');
  const userRole = sessionStorage.getItem('userRole');
  const helpicon = <FontAwesomeIcon icon={faQuestionCircle} color="#17a2b8" size="lg" />
  const signouticon = <FontAwesomeIcon icon={faSignOutAlt} color="#17a2b8" size="lg" />
  const usericon = <FontAwesomeIcon icon={faUserCircle} color="#17a2b8" size="lg" />
  const dashboardicon = <FontAwesomeIcon icon={faColumns} color="#17a2b8" size="lg" />
  const maintenanceicon = <FontAwesomeIcon icon={faCog} color="#17a2b8" size="lg" />
  const notificationicon = <FontAwesomeIcon icon={faBell} color="#17a2b8" size="lg" />
  const commenticon = <FontAwesomeIcon icon={faComment} color="#17a2b8" size="lg" />
  const reportsicon = <FontAwesomeIcon icon={faFileAlt} color="#17a2b8" size="lg" />


  const phoneicon = <FontAwesomeIcon icon={faPhone} color="#17a2b8" size="lg" />

  const contactadminicon = <FontAwesomeIcon icon={faExclamationCircle} color="#17a2b8" size="lg" />



  //const { showcontactadmin } = this.state;





  // console.log('nav bar is renderings hoaid is ', hoaid);

  //  console.log('userRole is ', userRole);
  //  let linkout = "/" + hoaid;



  const adminLinks = (
    <div>
      <span className="iconbuttonnav" title="Dashboard">
        <Link to="/admindashboard">
          {dashboardicon}
        </Link>
      </span>
      <span className="iconbuttonnav" title="Profile">
        <Link to="/adminprofile">
          {usericon}
        </Link>
      </span>
      <span className="iconbuttonnav" title="Maintenance">
        <Link to="/maintenancedashboard">
          {maintenanceicon}
        </Link>
      </span>
      {/* <span className="iconbuttonnav" title="Reports">
          <Link to="/reporttabs/admin">
            {reportsicon}
          </Link>
        </span> */}
      <span className="iconbuttonnav" title="Help">
        <Link to="" onClick={(e) => followHelpLink(e)}>
          {helpicon}
        </Link>
        {/* <Link to="/helptabs/admin">
          {helpicon}
        </Link> */}
      </span>
      <span className="iconbuttonnav" title="Logout">
        <Link to="" onClick={(e) => onLogoutClick(e)}>
          {signouticon}
        </Link>
      </span>
    </div>
  );

  const adminLinksRO = (
    <div>
      <span className="iconbuttonnav" title="Dashboard">
        <Link to="/admindashboard">
          {dashboardicon}
        </Link>
      </span>
      <span className="iconbuttonnav" title="Profile">
        <Link to="/adminprofile">
          {usericon}
        </Link>
      </span>

      {/* <span className="iconbuttonnav" title="Reports">
          <Link to="/reporttabs/admin">
            {reportsicon}
          </Link>
        </span> */}
      <span className="iconbuttonnav" title="Help">
        <Link to="" onClick={(e) => followHelpLink(e)}>
          {helpicon}
        </Link>
        {/* <Link to="/helptabs/admin">
          {helpicon}
        </Link> */}
      </span>
      <span className="iconbuttonnav" title="Logout">
        <Link to="" onClick={(e) => onLogoutClick(e)}>
          {signouticon}
        </Link>
      </span>
    </div>
  );



  const userLinks = (
    <div style={{ border: "0px solid white" }}>
      <span className="iconbuttonnav" title="Dashboard">
        <Link to="/dashboard">
          {dashboardicon}
        </Link>
      </span>
      <span className="iconbuttonnav" title="Profile">
        <Link to="/profile">
          {usericon}
        </Link>
      </span>
      {/* <span className="iconbuttonnav" title="Contact Us">
        <Link to="" onClick={(e) => showContactAdmin(e)}>
          {phoneicon}
        </Link>
      </span> */}
      {/* <span className="iconbuttonnav">
          <Link to="recordnotification" >
            {notificationicon}
          </Link>
        </span> */}
      <span className="iconbuttonnav" title="Help">
        <Link to="" onClick={(e) => followHelpLink(e)}>
          {helpicon}
        </Link>
        {/* <Link to="/helptabs/owner">
          {helpicon}
        </Link> */}
      </span>
      <span className="iconbuttonnav" title="Logout">
        <Link to="" onClick={(e) => onLogoutClick(e)}>
          {signouticon}
        </Link>
      </span>


    </div >
  );




  const guestLinks = (
    <div>
      {/* <span className="iconbuttonnav" title="Contact Us">
        <Link to="" onClick={(e) => showContactAdmin(e)}>
          {phoneicon}
        </Link>
      </span> */}
      <span className="iconbuttonnav" title="Help">
        {/* <Link to="/helptabs/renter">
          {helpicon}
        </Link> */}
        <Link to="" onClick={(e) => followHelpLink(e)}>
          {helpicon}
        </Link>
      </span>
      <span className="iconbuttonnav" title="Logout">
        <Link to="" onClick={(e) => onLogoutClick(e)}>
          {signouticon}
        </Link>
      </span>
    </div>
  );

  const startLinks = (
    <div>
      {/* <span className="iconbuttonnav">
          <Link to="" onClick={(e) => this.showContactAdmin(e)}>
            {commenticon}
          </Link>
        </span> */}


      <span className="iconbuttonnav" title="Help">
        {/* <Link to="/helptabs/landing">1
          {helpicon}
        </Link>
        xxx */}
        <Link to="" onClick={(e) => followHelpLink(e)}>
          {helpicon}
        </Link>
      </span>
    </div>
  );

  const startLinks2 = (
    <div>
      <span className="iconbuttonnav" title="Help">
        <Link to="/helptabs/app">2
          {helpicon}
        </Link>
      </span>
    </div>
  );


  // startLinks = (<span>&nbsp;</span>);
  let theLinks = null;
  if (userRole === null) {
    theLinks = startLinks;
  }

  // if (userRole === null && sessionStorage.getItem('hoaid')) {
  //   theLinks = startLinks2;
  // }



  if (userRole === 'admin') {
    //  if (this.props.auth.user.crud === 'read') {
    //    theLinks = adminLinksRO;
    // } else {
    theLinks = adminLinks;
    // }
  }

  if (userRole === 'owner') {
    theLinks = userLinks;
  }
  if (userRole === 'renter') {
    theLinks = guestLinks;
  }

  if (hoaid === null) {
    // theLinks = startLinks2;
    // startLinks = (null);
  }
  const homeicon = <FontAwesomeIcon icon={faHome} color="#17a2b8" size="lg" />; // <FontAwesomeIcon icon={faCoffee} />
  // const element2 = <FontAwesomeIcon icon={faCheckSquare} color="white" size="2x" />;

  // <img src={home} alt="HOA Logo" />

  let navbarstuffhome = null;
  let navbarstuffhomephone = null;
  let navbarstuffnormal = null;
  let navbarstuffphone_o = null;
  let navbarstuffphone = null;

  // let isphoneview = false;
  // if (window.innerWidth < 800) {
  //   isphoneview = true;
  // }

  let hoaname = sessionStorage.getItem('hoaname')

  if (!isAuthenticated) {


    navbarstuffhome = (
      <div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>

          <div title="Home">
            {/* <Link to=""
              onClick={(e) => goHome(e)} >
              {homeicon}
            </Link> */}

            <Link to=""
              onClick={(e) => goHome(e)} >
              {homeicon}
            </Link>

            <span style={{ color: "white", fontSize: "12pt", marginLeft: "10px" }}>HOA PARKING SOLUTIONS</span>


          </div>
          <div>
            {startLinks}
          </div>

        </div>
      </div>
    )


    navbarstuffhomephone = (
      <div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <Link to=""
              onClick={(e) => goHome(e)} >
              {homeicon}
            </Link>
          </div>
          <div>
            <span style={{ color: "white", fontSize: "12pt" }}>HOA PARKING SOLUTIONS</span>

          </div>
          <div >
            {startLinks}
          </div>
        </div>
      </div>
    )

  }
  if (isAuthenticated) {


    navbarstuffnormal = (
      <div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div >
            <span title="Home">
              <Link to=""
                onClick={(e) => goHome(e)} >
                {homeicon}
              </Link>
            </span>
            <span style={{ color: "white", fontSize: "12pt", marginLeft: "10px" }}> HOA PARKING SOLUTION</span>

          </div>
          <div >
            {theLinks}
          </div>
        </div>
      </div>
    )


    navbarstuffphone = (
      <div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <span title="Home">
              <Link to=""
                onClick={(e) => goHome(e)} >
                {homeicon}
              </Link>
            </span>

          </div>

          <div className="flex_item_right">

            {theLinks}


          </div>

        </div>

        <div style={{ textAlign: "center", marginTop: "5px" }}>
          <span style={{ color: "white", fontSize: "12pt" }}> HOA PARKING SOLUTION</span>
        </div>



        {/* <div style={{ textAlign: "center" }}>
          <span style={{ color: "white", fontSize: "12pt", marginTop: "5px" }}>
            HOA
            <span id="breadcrumbdivphone" style={{ color: "white", fontSize: "12pt", marginLeft: "10px" }}>
              {hoaname}
            </span>
          </span>
        </div> */}

        {/* <div style={{ textAlign: "center" }}>
          <span id="breadcrumbdivphone" style={{ color: "white", fontSize: "12pt" }}>
            {hoaname}
          </span>
        </div> */}
      </div>

    )



  }

  let popupclass = 'popupdialog bordershadow';
  if (isphoneview) popupclass = 'popupdialog_phone bordershadow';


  let ptop = window.scrollY;
  let itop = 100 + ptop;

  //console.log('RENDERING NAVBAR', sessionStorage.getItem('hoaid'))
  // let hoaname = sessionStorage.getItem('hoaname')



  return (
    <>
      <div className="navbar-custom" style={{ padding: "10px" }}>
        <div className="container-fluid " style={{ border: "0px solid yellow" }}>
          {!isAuthenticated ? (
            <div>
              <div className="navbariconsnormal">
                {navbarstuffhome}
              </div>
              <div className="navbariconsphone">
                {navbarstuffhomephone}
              </div>
            </div>
          ) :
            (
              <div>
                <div className="navbariconsnormal">
                  {navbarstuffnormal}
                </div>
                <div className="navbariconsphone">
                  {navbarstuffphone}
                </div>
              </div>
            )
          }






          {showcontactadmin ? (
            <div className={popupclass} style={{ top: itop }}>
              <ContactAdmin closeform={closeform} />
            </div>
          ) : (null)}



        </div>
        {/* <div style={{
          position: "absolute", paddingTop: "15px", color: "white",
          paddingLeft: "10px", zIndex: "99"
        }}>{hoaname}</div> */}

      </div>
    </>
  );
}

// Navbar.propTypes = {
//   logoutUser: PropTypes.func.isRequired,
//   auth: PropTypes.object.isRequired,
// };

// const mapStateToProps = (state) => ({
//   auth: state.auth,
//   statusMsg: state.statusMsg,
// });

export default Navbar;
