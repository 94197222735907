import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'

import { confirmAlert } from 'react-confirm-alert';
//import 'react-confirm-alert/src/react-confirm-alert.css'

//import { withRouter } from "react-router-dom";
//import axios from "axios";


import { connect } from "react-redux";
import { changePin, changeName, changePassword, changeAvailability, changeRulesAndRegs } from "../../actions/authActions";
import { deleteOwner } from "../../actions/parkingActions";
//import VehicleForm from "./VehicleForm";
//

import TermsAndConditions_Owner from "./TermsAndConditions_Owner";



//import axios from "axios";
//import classnames from "classnames"; 


import TextFieldGroup from "../common/TextFieldGroupPlain";
import SelectNameValuePlain from "../common/SelectNameValuePlain";


class OwnerProfile extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      fname: "",
      lname: "",
      phone: "",
      email: "",
      unitnumber: "",
      pincode: "",
      password: "",
      password2: "",
      userid: "",
      status_flag: 1,
      terms: 0,
      termsaccepted: false,


      errors: {}
    };

    this.onChange = this.onChange.bind(this);
    this.onCBChange = this.onCBChange.bind(this);

    this.onSubmitName = this.onSubmitName.bind(this);
    this.onSubmitPin = this.onSubmitPin.bind(this);
    this.onSubmitPassword = this.onSubmitPassword.bind(this);
    this.submitRulesAndRegs = this.submitRulesAndRegs.bind(this);

  }

  componentDidMount() {
    // console.log('owner profile mounted');
    if (this.props.auth.isAuthenticated) {
      // this.props.history.push("/dashboard");
      let role = this.props.auth.user.role;
      let uid = this.props.auth.user._id;
      let has_read_terms = this.props.auth.user.has_read_terms;

      let isadmin = false;
      if (role === 'admin') isadmin = true;
      this.setState({
        name: this.props.auth.user.name,
        fname: this.props.auth.user.fname,
        lname: this.props.auth.user.lname,
        phone: this.props.auth.user.phone,
        email: this.props.auth.user.email,
        unitnumber: this.props.auth.user.unitnumber,
        pincode: this.props.auth.user.pincode,
        status_flag: this.props.auth.user.status_flag,
        userid: uid,
        isadmin: isadmin,
        terms: has_read_terms
      })

    }
  }



  // if we have errors this will run
  // componentWillReceiveProps(nextProps) {
  //   //console.log("register componentWillReceiveProps");
  //   if (nextProps.errors) {
  //     this.setState({ errors: nextProps.errors });
  //     // setState triggers a render
  //   }
  // }
  static getDerivedStateFromProps(props, state) {
    return {
      errors: props.errors
    };
  }

  //componentDidUpdate(prevProps) {
  // Typical usage (don't forget to compare props):
  // if (this.props.errors) {
  //   this.setState({ errors: this.props.errors });
  // }
  //}

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }



  onSubmitPassword(e) {
    e.preventDefault();
    //  console.log('submit password', this.state);
    const userdata = {
      userid: this.state.userid,
      password: this.state.password,
      password2: this.state.password2
    };
    this.props.changePassword(userdata);
  }
  onSubmitPin(e) {
    e.preventDefault();
    //  console.log('submit pin', this.state);
    const userdata = {
      userid: this.state.userid,
      pincode: this.state.pincode,
    };
    this.props.changePin(userdata);
  }
  onSubmitAvailability(e) {
    e.preventDefault();
    //  console.log('submit pin', this.state);
    const userdata = {
      userid: this.state.userid,
      status_flag: parseInt(this.state.status_flag)
    };
    //  console.log('userdata', userdata);
    this.props.changeAvailability(userdata);
  }
  onSubmitName(e) {
    e.preventDefault();
    // console.log('submit name', this.state);
    const userdata = {
      which: 'owner',
      userid: this.state.userid,
      name: this.state.name,
      fname: this.state.fname,
      lname: this.state.lname,
      unitnumber: this.state.unitnumber,
      phone: this.state.phone,
    };
    this.props.changeName(userdata);
  }

  submitRulesAndRegs(e) {
    e.preventDefault();
    const userdata = {
      userid: this.state.userid,
      terms: this.state.terms,
      termsaccepted: this.state.termsaccepted
    };
    // console.log(userdata)
    this.props.changeRulesAndRegs(userdata);
  }

  deleteTheAccount(oid) {
    console.log('deleteing account for ', oid);
    this.props.deleteOwner(oid);
  }

  submitDeleteAccount(e) {
    e.preventDefault();
    const userdata = {
      userid: this.state.userid
    };


    const okelement = <FontAwesomeIcon icon={faCheckCircle} color="black" size="2x" title="enter / submit" />
    const cancelelement = <FontAwesomeIcon icon={faTimesCircle} color="black" size="2x" title="cancel / close" />

    confirmAlert({
      customUI: ({ onClose }) => {
        let oid = this.state.userid;
        return (
          <div className='infomessage' >
            <h5>Are you sure?</h5>
            <p>You want to delete this account?</p>
            <span className="iconbuttonspan"
              onClick={() => {
                this.deleteTheAccount(oid);
                onClose();
              }
              }> {okelement}</span>
            <span className="iconbuttonspan"
              onClick={() => {
                onClose();
              }}
            >

              {cancelelement}
            </span>

          </div>
        );
      }
    });

  }





  onCBChange(e) {
    let tval = 0;
    let ischecked = e.target.checked;
    if (ischecked) tval = 1;
    this.setState({ termsaccepted: ischecked, terms: tval });

  }



  // FOR DEVELOPMENT PURPOSES PREFILL OBJ









  render() {
    const { errors } = this.state;

    const perrors = this.props.errors;
    // console.log('perrors', perrors);
    //const { properties_ary } = this.state;

    const okelement = <FontAwesomeIcon icon={faCheckCircle} color="#65598a" size="2x" title="enter / submit" />
    const cancelelement = <FontAwesomeIcon icon={faTimesCircle} color="white" size="1x" title="cancel / close" />
    let rentchoices = [{ name: "Available for rent", value: 1 }, { name: "NOT available for rent", value: 0 }];

    let { terms } = this.state;

    /*
      {terms ? (<input type="checkbox" checked onChange={this.onCBChange} />) :
                    (<input type="checkbox" onChange={this.onCBChange} />)}
                    */


    return (
      <div className="container-fluid">
        <h4 style={{ textAlign: "center", color: "white", marginTop: "10px" }} >Profile</h4>
        <div className="inputbox">
          <div className="innerinputbox2">
            <p className="parking_label">Owner
              <span style={{ marginLeft: "20px" }}>{this.state.email}</span>
              <span style={{ float: "right" }}>
                <Link to="/dashboard">
                  {cancelelement}
                </Link>
              </span>
            </p>
            {perrors && perrors['message'] ? (<h2 className="error-display">{perrors['message']}</h2>) : (null)}

            <div className="row">
              <div className="col-md-6">
                <div className="bordernoshadowone">
                  <div style={{ display: "flex", gap: "5px", flexDirection: "column" }}>
                    <div className="row">
                      <div className="col-sm-4">
                        <b>First Name</b>
                      </div>
                      <div className="col-sm-8">
                        <TextFieldGroup
                          type="text"
                          label="First Name"
                          placeholder="First Name"
                          name="fname"
                          value={this.state.fname}
                          onChange={this.onChange}
                          error={errors.fname}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-4">
                        <b>Last Name</b>
                      </div>
                      <div className="col-sm-8">
                        <TextFieldGroup
                          type="text"
                          label="Last Name"
                          placeholder="Last Name"
                          name="lname"
                          value={this.state.lname}
                          onChange={this.onChange}
                          error={errors.lname}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-4">
                        <b>Phone</b>
                      </div>
                      <div className="col-sm-8">
                        <TextFieldGroup
                          type="text"
                          label="Phone"
                          placeholder="Phone"
                          name="phone"
                          value={this.state.phone}
                          onChange={this.onChange}
                          error={errors.phone}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-4">
                        <b>Unit #</b>
                      </div>
                      <div className="col-sm-8">

                        <TextFieldGroup
                          type="text"
                          label="Unit Number"
                          placeholder="Unit Number"
                          name="unitnumber"
                          value={this.state.unitnumber}
                          disabled={true}
                          onChange={this.onChange}
                          error={errors.unitnumber}
                        />
                      </div>
                    </div>


                    <div className="row" style={{ marginTop: "10px" }}>
                      <div className="col-sm-4">&nbsp;</div>
                      <div className="col-sm-8">
                        <Link to=""
                          onClick={(e) => this.onSubmitName(e)} >
                          {okelement}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bordernoshadowone">
                  <div style={{ display: "flex", gap: "5px", flexDirection: "column" }}>
                    <div className="row">
                      <div className="col-sm-4">
                        <b>Password</b>
                      </div>
                      <div className="col-sm-8">
                        <TextFieldGroup
                          type="password"
                          label="Password"
                          placeholder="Password"
                          name="password"
                          value={this.state.password}
                          onChange={this.onChange}
                          error={errors.password}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-4">
                        <b>Confirm</b>
                      </div>
                      <div className="col-sm-8">
                        <TextFieldGroup
                          type="password"
                          label="Confirm"
                          placeholder="Confirm Password"
                          name="password2"
                          value={this.state.password2}
                          onChange={this.onChange}
                          error={errors.password2}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-4">

                      </div>
                      <div className="col-sm-8">
                        <Link to=""
                          onClick={(e) => this.onSubmitPassword(e)} >
                          {okelement}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>


                <div className="bordernoshadowone">
                  <div style={{ display: "flex", gap: "5px", flexDirection: "column" }}>
                    <div className="row">
                      <div className="col-sm-4">
                        <b>Pin</b>
                      </div>
                      <div className="col-sm-8">
                        <TextFieldGroup
                          type="text"
                          label="Pin Code"
                          placeholder="Pin Code"
                          name="pincode"
                          value={this.state.pincode}
                          onChange={this.onChange}
                          error={errors.pincode}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-4">

                      </div>
                      <div className="col-sm-8">
                        <Link to=""
                          onClick={(e) => this.onSubmitPin(e)} >
                          {okelement}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bordernoshadowone">
                  <div style={{ display: "flex", gap: "5px", flexDirection: "column" }}>
                    <div className="row">
                      <div className="col-sm-4">
                        <b>Availability</b>
                      </div>
                      <div className="col-sm-8">
                        <SelectNameValuePlain
                          label="Availability"
                          list={rentchoices}
                          name="status_flag"
                          value={this.state.status_flag}
                          onChange={this.onChange}
                          error={errors.status_flag}
                        />
                        <div style={{ fontSize: "10pt", paddingBottom: "10px" }}>Set to <b>NOT available</b> if you are not renting your unit. </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-4">

                      </div>
                      <div className="col-sm-8">
                        <Link to=""
                          onClick={(e) => this.onSubmitAvailability(e)} >
                          {okelement}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="bordernoshadowone">
                  <TermsAndConditions_Owner />
                </div>
              </div>
            </div>
          </div>
        </div >
      </div >

    );
  }
}


// this is good practice because it will help debugging
// it is not checked when in production mode.
//onClick={(e) => props.gotoBooking(e, obj.accommodations.booking_url)}
OwnerProfile.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

// map errors to this component
// then we can use this.props.auth.user etc
// since we mapped error, we can use componentWillReceiveProps method
const mapStateToProps = state => ({
  auth: state.auth,
  parking: state.parking,
  errors: state.errors
});
// the state.auth above comes from rootReducer in index.js in reducers.

export default connect(
  mapStateToProps,
  { changePin, changeName, changePassword, changeAvailability, changeRulesAndRegs, deleteOwner }
)((OwnerProfile));
// wrap the Register with withRouter so the authAction can use history to redirect
