import React, { Component } from "react";
//import PropTypes from "prop-types";
//import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
//import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';



import { connect } from "react-redux";
//import isEmpty from "../../validation/is-empty";


//import Spinner from "../common/Spinner";
//import TermsAndConditions from "./TermsAndConditions";

//
//import TextFieldGroup from "../../common/TextFieldGroup";
import TextFieldGroupPlain from "../common/TextFieldGroupPlain";
//import SelectNameValuePlain from "../common/SelectNameValuePlain";
import TextAreaGroup from "../common/TextAreaGroup";

//import CheckboxGroup from "../common/CheckboxGroup";

import { insertViolation, showStatusMessage } from "../../actions/parkingActions";
import { getTodayAsString } from "../parking/DateHelper";

import { validateRecordViolationInput } from "../../validation/client_recordviolation";





class RecordViolation_modal extends Component {
  constructor(props) {
    super(props);
    // console.log("props in VehicleForm", props);
    // console.log("id is", this.props.match.params.id);
    let d = new Date();


    let dstr = getTodayAsString();

    let hrs = d.getHours();
    let mins = d.getMinutes();
    let secs = d.getSeconds();
    if (hrs < 10) hrs = "0" + hrs;
    if (mins < 10) mins = "0" + mins;
    if (secs < 10) secs = "0" + secs;

    let tstr = hrs + ':' + mins + ':' + secs;

    this.state = {
      violation_plate: "",
      violation_state: "",
      violation_location: "",
      violation_description: "",
      violation_type: "PARKING_VIOLATION",
      violation_reporter: sessionStorage.getItem('username'),
      violation_date: dstr,
      violation_time: tstr,
      hoaid: sessionStorage.getItem('hoaid'),
      datasubmitted: false,
      errors: {},
    };



    this.onChange = this.onChange.bind(this);

    // this.onFileInputChange = this.onFileInputChange.bind(this);
    // this.handleMapClick = this.handleMapClick.bind(this);
    // this.closeDialog = this.closeDialog.bind(this);
  }



  static getDerivedStateFromProps(props, state) {
    if (props.vehicle !== state.vehicle) {
      return {
        vehicle: props.vehicle
      }
    }
    return null;
  }

  componentDidMount() {
    //  console.log('vehicle form component did mount loading state');
    // this.loadState(this.props);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.vehicle !== this.state.vehicle) {
      this.loadState(this.props);
    }
  }




  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  // handleChange(event) {
  //   this.setState({value: event.target.value});
  // }



  onSubmit(e) {
    e.preventDefault();
    let submitdata = {};
    submitdata['violation_type'] = this.state.violation_type;
    submitdata['violation_plate'] = this.state.violation_plate;
    submitdata['violation_state'] = this.state.violation_state;
    submitdata['violation_date'] = this.state.violation_date;
    submitdata['violation_location'] = this.state.violation_location;
    submitdata['violation_description'] = this.state.violation_description;
    submitdata['violation_reporter'] = this.state.violation_reporter;
    submitdata['violation_time'] = this.state.violation_time;
    submitdata['hoaid'] = this.state.hoaid;

    // console.log('submit data', submitdata);



    const { errors, isValid } = validateRecordViolationInput(submitdata);
    if (!isValid) {
      this.setState({ errors: errors });
      return;
    }
    // let url = "/api/parking/insert_violation";
    // axios.post(url, submitdata).then(data => {
    //   console.log(data);
    //   this.props.showStatusMessage("Violation has been recorded");
    //   this.props.closeform();
    // }).catch(err => {
    //   // console.log("in getActive4380Devices response");
    //   console.log(err);
    // });

    this.props.insertViolation(submitdata);
    setTimeout(() => {
      this.props.closeform();
    }, 3000);
    // this.setState({ datasubmitted: true });


  }




  closeDialog = () => {
    this.props.closeform();
  }




  closeForm = (e) => {
    e.preventDefault();
    // console.log('goint to close form');
    this.props.closeform();
  }



  render() {
    // const { errors } = this.state;
    const perrors = this.props.errors;
    const errors = this.state.errors;
    const { datasubmitted } = this.state;

    // console.log('this.props', this.props);
    let isloading = this.props.parking.isloading;
    // console.log('isloading', isloading);

    // console.log('perrors', perrors, isEmpty(perrors));
    // if (datasubmitted && isEmpty(perrors)) {
    //   setTimeout(() => {
    //     this.props.closeform();
    //   }, 3000);

    // }

    //  console.log('datasubmitted', datasubmitted, 'isloading', isloading, 'perrors', perrors, 'empty', isEmpty(perrors));

    // if (datasubmitted && !isloading && isEmpty(perrors)) {
    //   console.log('WE ARE SETTING THE TIMEOUT');
    //   setTimeout(() => {
    //     this.props.closeform();
    //   }, 3000);

    // }





    const okelement = <FontAwesomeIcon icon={faCheckCircle} color="#65598a" size="2x" title="enter / submit" />
    const cancelelement = <FontAwesomeIcon icon={faTimesCircle} color="black" size="2x" title="cancel / close" />

    return (
      <div className="register" >
        <div className="xbordernoshadow" style={{ marginBottom: "15px", padding: "5px" }}>
          <p className=" text-center"><b>Violation</b></p>
          <form >
            <div style={{ display: "flex", gap: "5px", flexDirection: "column" }}>
              <div className="row ">
                <div className="col-sm-4">
                  <b>Reporter</b>
                </div>
                <div className="col-sm-8">
                  <TextFieldGroupPlain
                    type="text"
                    placeholder="Reporter"
                    name="violation_reporter"
                    value={this.state.violation_reporter}
                    onChange={this.onChange}
                    error={errors.violation_reporter}
                  />
                </div>
              </div>
              <div className="row ">
                <div className="col-sm-4">
                  <b>Plate</b>
                </div>
                <div className="col-sm-8">
                  <TextFieldGroupPlain
                    type="text"
                    placeholder="Plate"
                    name="violation_plate"
                    value={this.state.violation_plate}
                    onChange={this.onChange}
                    error={errors.violation_plate}
                  />
                </div>
              </div>
              <div className="row ">
                <div className="col-sm-4">
                  <b>State</b>
                </div>
                <div className="col-sm-8">
                  <TextFieldGroupPlain
                    type="text"
                    placeholder="State"
                    name="violation_state"
                    value={this.state.violation_state}
                    onChange={this.onChange}
                    error={errors.violation_state}
                  />
                </div>
              </div>



              <div className="row ">
                <div className="col-sm-4">
                  <b>Location</b>
                </div>
                <div className="col-sm-8">
                  <TextFieldGroupPlain
                    type="text"
                    placeholder="Location"
                    name="violation_location"
                    value={this.state.violation_location}
                    onChange={this.onChange}
                    error={errors.violation_location}
                  />
                </div>
              </div>
              <div className="row ">
                <div className="col-sm-4">
                  <b>Description</b>
                </div>
                <div className="col-sm-8">
                  <TextAreaGroup
                    type="text"
                    label="Description"
                    placeholder="Description"
                    name="violation_description"
                    value={this.state.violation_description}
                    onChange={this.onChange}
                    error={errors.violation_description}
                  />
                </div>
              </div>

              <div className="row ">
                <div className="col-sm-4">
                  <b>Date</b>
                </div>
                <div className="col-sm-8">
                  <TextFieldGroupPlain
                    type="date"
                    placeholder="Plate"
                    name="violation_date"
                    value={this.state.violation_date}
                    onChange={this.onChange}
                    error={errors.violation_date}
                  />
                </div>
              </div>
              <div className="row ">
                <div className="col-sm-4">
                  <b>Time</b>
                </div>
                <div className="col-sm-8">
                  <TextFieldGroupPlain
                    type="time"
                    placeholder="Plate"
                    name="violation_time"
                    value={this.state.violation_time}
                    onChange={this.onChange}
                    error={errors.violation_time}
                  />
                </div>
              </div>
            </div>
          </form>
          <div className="row" style={{ marginTop: "10px" }}>
            <div className="col-sm-8 offset-sm-4">
              <span className="iconbuttonspan">
                <Link to=""
                  onClick={(e) => this.onSubmit(e)} >{okelement}</Link>
              </span>
              <span className="iconbuttonspan">
                <Link to=""
                  onClick={(e) => this.closeForm(e)} >{cancelelement}</Link>
              </span>
            </div>
          </div>

          {perrors && (
            <div className="error-display">{perrors.message}</div>
          )}

        </div>

      </div >



    );
  }





  // this is good practice because it will help debugging
  // it is not checked when in production mode.
  // Business.propTypes = {
  //   createBusiness: PropTypes.func.isRequired,
  //   modifyBusiness: PropTypes.func.isRequired,
  //   auth: PropTypes.object.isRequired,
  //   errors: PropTypes.object.isRequired
};

// map errors to this component
// then we can use this.props.auth.user etc
// since we mapped error, we can use componentWillReceiveProps method
const mapStateToProps = state => ({
  auth: state.auth,
  parking: state.parking,
  errors: state.errors,
});
// the state.auth above comes from rootReducer in index.js in reducers.

export default connect(
  mapStateToProps,
  { insertViolation, showStatusMessage }
)((RecordViolation_modal));
// wrap the Register with withRouter so the authAction can use history to redirect
